import React from "react"
import { Input } from "antd"
import { useTranslation } from "react-i18next"
import styles from "./friends-components.module.css"

export const FriendsInput = ({
	currentUser, nameInput, setNameInput
}) => {
	const { t } = useTranslation()
	return (
		<div className={styles.input_form}>
			<div className={styles.input_text}>
				<div>
					{t("relatives")}
					:
					{" "}
					{currentUser.family.length}
				</div>
				<div>
					{t("friends")}
					:
					{" "}
					{currentUser.friends.length}
				</div>
			</div>
			<Input
				className={styles.input_field}
				placeholder={t("search")}
				value={nameInput}
				allowClear
				onChange={(e) => {
					setNameInput(e.target.value)
				}}
			/>
		</div>
	)
}