export const getPosts = async ({ userId, page }) => {
  const token = localStorage.token ? localStorage.getItem("token") : sessionStorage.getItem("token")
  try {
    const path = "/api/posts/pusblished-posts"
    const baseUrl = `${path}?page=${page}`
    const userUrl = `${path}?userId=${userId}&page=${page}`
    const res = await fetch(userId ? userUrl : baseUrl, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: token
      }
    })

    const json = await res.json()
    return json
  } catch (error) {
    const message = "Something went wrong"
    return message
  }
}