import React from "react"
import {
	Modal, Card, Avatar
} from "antd"
import { UserOutlined, DeleteOutlined } from "@ant-design/icons"
import { getImageUri } from "shared/utils"
import { useTranslation } from "react-i18next"
import { socket } from "app/socket"

import styles from "./delete-chat-member-modal.module.css"

const { Meta } = Card

export const DeleteChatMemberModal = ({
	isShowDeleteModal, setIsShowDeleteModal, chatMembers, roomId
}) => {
	const { t } = useTranslation()

	return (
		<Modal
			footer={null}
			title={(
				<div>
					{t("select-person-who-you-wnat-to-delete")}
				</div>
			)}
			visible={isShowDeleteModal}
			className={styles.modal}
			keyboard
			onCancel={() => {
				setIsShowDeleteModal(false)
			}}
		>
			<div>
				<div className={styles.list_friends_chat}>
					{chatMembers.map(user => (
						<div
							key={user._id}
							role="button"
							tabIndex={0}
						>
							<Card
								className={styles.list_friends_card}
								size="small"
								style={{
									margin: "4px 0",
									padding: "4px",
									width: "100%",
									borderRadius: "6px",
									background: "#FFFFFF"
								}}
							>
								<Meta
									avatar={(
										<Avatar
											style={{
												background: "rgba(136, 133, 133, 0.5)",
												display: "flex",
												alignItems: "center",
												justifyContent: "center"
											}}
											src={!user.avatar ? (
												<UserOutlined style={{
													fontSize: "28px", width: "100%", height: "100%", display: "flex", alignItems: "center", justifyContent: "center"
												}}
												/>
											) : getImageUri(user.avatar)}
										/>
									)}
									title={`${user.firstName} ${user.lastName}`}
									description={!user.location.name ? "" : user.location.name}
								/>
								<button
									type="button"
									className={styles.friends_chat_delete}
									onClick={async () => {
										socket.emit("LEAVE_CHAT",
											{
												roomId,
												userId: user._id,
												text: "KICKED_FROM_CHAT"
											})
									}}
								>
									<DeleteOutlined />
								</button>
							</Card>
						</div>
					))}
				</div>
			</div>
		</Modal>
	)
}