import { combineReducers } from "redux"
import { people } from "./people"
import { zoom } from "./zoom"
import { selectedPersonId } from "./selected-person-id"
import { dragMode } from "./drag"
import { treeDirection } from "./tree-direction"
import { adaptiveZoom } from "./adaptive-zoom"
import { isShowModal } from "./show-modal"

export const familyTree = combineReducers({
	zoom,
	people,
	selectedPersonId,
	dragMode,
	treeDirection,
	adaptiveZoom,
	isShowModal
})

export * from "./zoom"
export * from "./selected-person-id"
export * from "./people"
export * from "./drag"
export * from "./tree-direction"
export * from "./adaptive-zoom"
export * from "./show-modal"