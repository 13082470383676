import React, { useState, useEffect } from "react"
import { store } from "app/redux/store"
import { Result } from "antd"
import { setUserInTree, setPeople, setDefaultState } from "modules/family-tree/ducks"
import { autoLogin } from "app/api"
import { AppSpinner } from "shared/app-spinner/app-spinner"
import { SidePanel, Diagram, Toolbar } from "./components"
import styles from "./family-tree.module.css"
import "antd/dist/antd.css"

export const FamilyTree = () => {
	const [loadingError, setLoadingError] = useState(false)
	const [isLoad, setIsLoad] = useState(false)
	useEffect(() => {
		const token = localStorage.token ? localStorage.getItem("token") : sessionStorage.getItem("token")
		let mounted = true
		if (mounted) {
			const getData = async () => {
				if (token) {
					const loadedData = await autoLogin(token, true)
					const { people } = store.getState().familyTree
					const userInTree = people.find(p => p.isUser)
					if (!loadedData || !loadedData.userTree) {
						setLoadingError(true)
						setIsLoad(true)
						return null
					}
					if (loadedData.userTree) {
						if (loadedData.userTree.length > 0) {
							const persons = loadedData.userTree
							store.dispatch(setPeople(persons))
							setIsLoad(true)
							store.dispatch(setUserInTree({
								...userInTree,
								name: !loadedData.firstName && !loadedData.lastName ? "User" : `${loadedData.firstName} ${loadedData.lastName}`,
								from: !loadedData.birthPlace ? "" : `${loadedData.birthPlace}`,
								livesIn: !loadedData.location.name ? "" : `${loadedData.location.name}`,
								birthDate: !loadedData.birthDate ? "" : `${loadedData.birthDate}`,
								avatar: !loadedData.avatar ? "" : `${loadedData.avatar}`,
								profileId: loadedData._id,
								gender: loadedData.gender ? loadedData.gender : "unknown"
							}))
						}
						if (loadedData.userTree.length === 0) {
							store.dispatch(setDefaultState())
							setIsLoad(true)
							store.dispatch(setUserInTree({
								...userInTree,
								name: !loadedData.firstName && !loadedData.lastName ? "User" : `${loadedData.firstName} ${loadedData.lastName}`,
								from: !loadedData.birthPlace ? "" : `${loadedData.birthPlace}`,
								livesIn: !loadedData.location.name ? "" : `${loadedData.location.name}`,
								birthDate: !loadedData.birthDate ? "" : `${loadedData.birthDate}`,
								avatar: !loadedData.avatar ? "" : `${loadedData.avatar}`,
								profileId: loadedData._id,
								gender: loadedData.gender ? loadedData.gender : "unknown"
							}))
						}
					}
				}
			}
			getData()
		}
		return function cleanup() {
			mounted = false
		}
	}, [])

	if (!isLoad) {
		return <AppSpinner style={{ width: "100%", height: "100%" }} />
	}

	if (loadingError) {
		return (
			<Result
				status="warning"
				title="Something went wrong"
				style={{
					width: "100vw", height: "100vh", padding: 0, display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center"
				}}
			/>
		)
	}

	return (
		<div className={styles.content}>
			<Toolbar />
			<Diagram />
			<SidePanel />
		</div>
	)
}