import { v4 as uuidv4 } from "uuid"
import { ConnectorConstraints, AnnotationConstraints } from "@syncfusion/ej2-react-diagrams"
import { getPersonById } from "."

const constraints = ConnectorConstraints.Default & ~ConnectorConstraints.Select
const annotations = [{ constraints: AnnotationConstraints.ReadOnly }]

const isIncludesConnect = (connect, connections) => connections.find(
	(c) => c.sourceID === connect.sourceID && c.targetID === connect.targetID
)

export const getConnect = ({ source, target }) => {
	if (target.includes("uncle")) {
		const visible = !getPersonById(target).hide && !getPersonById(source).hide
		return ({
			visible,
			id: `${uuidv4()}_connector`,
			sourceID: source,
			targetID: target,
			style: {
				strokeColor: "#e0e0e0",
				fill: "#e0e0e0",
				strokeWidth: 5
			},
			constraints,
			annotations
		})
	}
	if (!target.includes("uncle")) {
		const visible = !getPersonById(source).hide
		return ({
			visible,
			id: `${uuidv4()}_connector`,
			sourceID: source,
			targetID: target,
			style: {
				strokeColor: "#e0e0e0",
				fill: "#e0e0e0",
				strokeWidth: 5
			},
			constraints,
			annotations
		})
	}
	return true
}

export const getConnectors = (people) => {
	const connections = []
	people.map((person) => person.parents.map((parent) => {
		const connect = getConnect({ source: parent, target: person.positionId })
		if (!isIncludesConnect(connect, connections)) {
			connections.push(connect)
		}
		return connections
	}))
	return connections
}
