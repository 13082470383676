export const getAlbum = async () => {
  try {
    const token = localStorage.token ? localStorage.getItem("token") : sessionStorage.getItem("token")
    const res = await fetch("/api/photo-gallery", {
      method: "GET",
      headers: {
        Authorization: token
      }
    })
    const json = await res.json()
    return json
  } catch (error) {
    const message = "Something went wrong"
    return message
  }
}