import React from "react"
import { Button } from "antd"
import { useTranslation } from "react-i18next"
import { AddVideo } from "shared/empty-screen-images"
import styles from "./no-videos.module.css"

export const NoVideos = ({ setVisible }) => {
	const { t } = useTranslation()

	return (
		<div className={styles.main_no_albums}>
			<AddVideo />
			<Button
				onClick={() => {
					setVisible(true)
				}}
				className={styles.confirm_button}
			>
				{t("upload-videos")}
			</Button>
		</div>
	)
}