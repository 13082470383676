export const deleteAccount = async () => {
  try {
    const token = localStorage.token ? localStorage.getItem("token") : sessionStorage.getItem("token")
    const res = await fetch("/api/remove-account", {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        Authorization: token
      }
    })
    const json = await res.json()
    return json
  } catch (error) {
    const message = "Something went wrong"
    return message
  }
}