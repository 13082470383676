export const SET_SHOW_MODAL = "SET_SHOW_MODAL"

export const setShowModal = showModal => ({
  type: SET_SHOW_MODAL,
  showModal
})

export const isShowModal = (state = false, action) => {
  switch (action.type) {
    case SET_SHOW_MODAL:
      return action.showModal
    default:
      return state
  }
}