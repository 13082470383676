export const getProfile = async (
  profileId, loadTree
) => {
  const userInfo = {
    profileId,
    loadTree
  }
  try {
    const res = await fetch("/api/connections/get-profile", {
      method: "POST",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify(userInfo)
    })
    const json = await res.json()
    return json
  } catch (error) {
    const message = "Something went wrong"
    return message
  }
}