import React from "react"
import { Route, Switch } from "react-router-dom"
import styles from "./video-page.module.css"
import { Videos } from "./components/videos"
import { Album } from "./components/album"

export const VideosPage = () => (
	<div className={styles.wrap}>
		<Switch>
			<Route exact path="/video-gallery" component={Album} />
			<Route exact path="/video-gallery/video/:albumId" component={Videos} />
		</Switch>
	</div>
)