export const setUserOffline = async (token) => {
  try {
    const res = await fetch("/api/auth/logout", {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Authorization: token
      }
    })
    const json = await res.json()
    return json
  } catch (error) {
    console.error("Error:", error)
  }
}