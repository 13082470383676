import React, { useState } from "react"
import { UserAvatar } from "shared/avatar"
import { updateUser } from "app/redux/user"
import { store } from "app/redux/store"
import { useSelector } from "react-redux"
import { UsersListModal } from "shared/users-list-modal"
import { socket } from "app/socket"
import { Modal, Popover } from "antd"
import { useHistory } from "react-router-dom"
import { ExclamationCircleOutlined } from "@ant-design/icons"
import { inviteAsFriend, inviteAsFamily } from "modules/search-people/api"
import moment from "moment-timezone"
import { useTranslation } from "react-i18next"
import { ModalVideoCall } from "modules/video-chat/components/modal-video-call"
import styles from "./friend-card.module.css"

const { confirm } = Modal

export const FriendCard = ({
	user, currentUser, removeConnection, friendsOrFamilySelected
}) => {
	const { messages } = useSelector(s => s)
	const { t } = useTranslation()
	const history = useHistory()

	const userInFriends = user.friends.includes(currentUser._id)
	const userInFamily = user.family.includes(currentUser._id)

	const [
		userHaveFriendRequest,
		setUserHaveFriendRequest
	] = useState(user.invitesAsFriend.includes(currentUser._id))
	const [
		userHaveFamilyRequest,
		setUserHaveFamilyRequest
	] = useState(user.invitesAsFamily.includes(currentUser._id))

	const [isShowFamiliars, setIsShowFamiliars] = useState(false)

	const [visiblePopover, setVisiblePopover] = useState(false)

	const [isShowModalVideoChat, setIsShowModalVideoChat] = useState(false)
	const [selectedConnections, setSelectedConnections] = useState(null)

	const popoverContent = (
		<div className={styles.popover_buttons} onMouseLeave={() => setVisiblePopover(false)}>
			<button
				type="button"
				className={
					userHaveFriendRequest
						|| userInFriends
						|| currentUser.invitesAsFriend.includes(user._id)
						? styles.request_note
						: styles.confirm_button
				}
				disabled={userHaveFriendRequest
					|| userInFriends
					|| currentUser.invitesAsFriend.includes(user._id)}
				onClick={async () => {
					await inviteAsFriend(user._id)
					setUserHaveFriendRequest(true)
					socket.emit("SEND_FRIEND_REQUEST", { userId: user._id })
				}}
			>
				{t("a-friend")}
			</button>
			<div>
				<button
					type="button"
					className={userHaveFamilyRequest
						|| userInFamily
						|| currentUser.invitesAsFamily.includes(user._id)
						? styles.request_note
						: styles.confirm_button}
					disabled={userHaveFamilyRequest
						|| userInFamily
						|| currentUser.invitesAsFamily.includes(user._id)}
					onClick={async () => {
						await inviteAsFamily(user._id)
						setUserHaveFamilyRequest(true)
						socket.emit("SEND_FAMILY_REQUEST", { userId: user._id })
					}}
				>
					{t("a-family")}
				</button>
			</div>
		</div>
	)

	const showConfirm = () => {
		confirm({
			title: friendsOrFamilySelected === "friends" ? `${t("are-you-sure-you-want-to-remove")} ${user.firstName} ${user.lastName} ${t("from-your-friends")}?` : `${t("are-you-sure-you-want-to-remove")} ${user.firstName} ${user.lastName} ${t("from-your-family")}`,
			icon: <ExclamationCircleOutlined />,
			okText: `${t("yes")}`,
			okType: "danger",
			cancelText: `${t("no")}`,
			onOk() {
				removeConnection(user._id)
				if (friendsOrFamilySelected === "friends") {
					const friends = currentUser.friends.filter(friend => friend !== user._id)
					store.dispatch(updateUser({
						...currentUser,
						friends
					}))
				}
				if (friendsOrFamilySelected === "family") {
					const family = currentUser.family.filter(parent => parent !== user._id)
					store.dispatch(updateUser({
						...currentUser,
						family
					}))
				}
			}
		})
	}

	return (
		<>
			<div className={styles.wrap}>
				<UserAvatar
					user={user}
					style={{
						width: "60px", height: "60px", fontSize: "56px", color: "#fff"
					}}
				/>
				<div className={styles.user_info}>
					<div className={styles.user_name}>
						<div>
							{user.firstName}
							{" "}
							{user.lastName}
							{" "}
							{user.maidenName && user.maidenName !== "" ? `(${user.maidenName})` : ""}
						</div>
						<div className={styles.user_livesIn}>
							{user.location.name}
						</div>
					</div>
					<div className={styles.buttons}>
						<button
							type="button"
							className={styles.confirm_button}
							onClick={() => {
								if (messages.find(message => message.users.length === 2
									&& !message.roomName
									&& message.users.find(u => u._id === user._id)
									&& message.users.find(u => u._id === currentUser._id))) {
									history.push({ pathname: "/messages", userId: user._id })
								} else {
									socket.emit("START_CHAT", { roomName: null, recipients: [user._id], userId: currentUser._id })
									socket.on("START_CHAT", chat => {
										const recepient = chat.users.find(user => user._id !== currentUser._id)
										history.push({ pathname: "/messages", userId: recepient._id })
									})
								}
							}}
						>
							{t("send-messages")}
						</button>
						<button
							type="button"
							className={styles.confirm_button}
							onClick={() => {
								history.push({ pathname: "/profile", userId: user._id })
							}}
						>
							{t("view-profile")}
						</button>
						{(user.isOnline && (user.email === "andrusha.v47@gmail.com"
							|| user.email === "sagetarius@gmail.com" || user.email === "yakushevich@live.com" || user.email === "test11@test.com" || user.email === "test10@test.com"
						) && (currentUser.email === "andrusha.v47@gmail.com"
							|| currentUser.email === "sagetarius@gmail.com" || currentUser.email === "yakushevich@live.com" || currentUser.email === "test10@test.com" || currentUser.email === "test11@test.com"
							)) && (
								<button
									type="button"
									className={styles.confirm_button}
									onClick={() => {
										setIsShowModalVideoChat(true)
										setSelectedConnections(user)
									}}
								>
									{t("start-video-call")}
								</button>
							)}
						{(isShowModalVideoChat) && (
							<ModalVideoCall
								isShowModalVideoChat={isShowModalVideoChat}
								setIsShowModalVideoChat={setIsShowModalVideoChat}
								selectedConnections={selectedConnections}
							/>
						)}
						{friendsOrFamilySelected === "suggestions" ? (
							<>
								<Popover placement="bottom" content={popoverContent} trigger="click" visible={visiblePopover}>
									<button
										className={
											(userHaveFriendRequest
												|| userInFriends)
												&& (userHaveFamilyRequest
													|| userInFamily)
												? styles.request_note
												: styles.confirm_button
										}
										type="button"
										onClick={() => {
											setVisiblePopover(!visiblePopover)
										}}
									>
										{t("invite-as")}
									</button>
								</Popover>
								<button
									className={styles.confirm_button}
									type="button"
									onClick={() => {
										setIsShowFamiliars(!visiblePopover)
									}}
								>
									{t("familiars")}
								</button>
							</>
						) : (
							<button
								type="button"
								className={`${styles.confirm_button} ${styles.remove_button}`}
								onClick={showConfirm}
							>
								{t("remove")}
							</button>
						)}
					</div>
				</div>
				{!user.isOnline ? (
					<div
						title={moment(user.lastSession).tz(`${moment.tz.guess()}`).fromNow()}
						className={styles.card_last_session}
					>
						{moment(user.lastSession).tz(`${moment.tz.guess()}`).fromNow()}
					</div>
				) : (<div className={styles.card_last_session} />)}
			</div>
			{isShowFamiliars
				&& user.familiars.length > 0
				&& (
					<UsersListModal
						isShowList={isShowFamiliars}
						setIsShowList={setIsShowFamiliars}
						usersIds={user.familiars}
						titleName="familiars"
					/>
				)}
		</>
	)
}