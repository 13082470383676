/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-nested-ternary */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useState } from "react"
import {
	Modal, Input, Upload, message
} from "antd"
import { EditOutlined } from "@ant-design/icons"
import { useTranslation } from "react-i18next"
import { useHistory } from "react-router-dom"
import { socket } from "app/socket"
import { GoBackIcon } from "shared/icons"
import { IMAGE_MAXIMUM_SIZE } from "shared/constants"
import { ChatAvatar } from "../../chat-avatar"
import { ButtonsMessageContent } from "../message-buttons/message-buttons"

import styles from "./messages_header.module.css"

export const MessagesHeader = ({
	userId, setVisiblePopover, setSelectedChat, chat, selectedChat, setIsShowDeleteModal, currentUser,
	setCurrentUserConnections, setIsShowModal,
	visiblePopover, chatCompanion, setIsShowParticipiantsModal
}) => {
	const { t } = useTranslation()
	const history = useHistory()
	const [chatName, setChatName] = useState(null)
	const [isModalVisible, setIsModalVisible] = useState(false)

	const getBase64 = file => new Promise((resolve, reject) => {
		const reader = new FileReader()
		reader.readAsDataURL(file)
		reader.onload = () => resolve(reader.result)
		reader.onerror = error => reject(error)
	})

	const selectPhotos = async ({ file }) => {
		if (file.status !== "uploading") {
			if (file.size > IMAGE_MAXIMUM_SIZE) {
				message.error(t("photo-size-exceeded"))
			} else {
				const base64Image = await getBase64(file.originFileObj)
				const newImage = base64Image.split(",")
				socket.emit("CHANGE_CHAT_AVATAR", chat._id, newImage[1])
			}
		}
	}

	const renderGroupChatAvatar = () => {
		if (chat.roomAvatar) {
			return <ChatAvatar friendAvatar={chat.roomAvatar} online={null} />
		}
		return (
			<div className={styles.messages_header_groupchat}>
				{chat.roomName[0].toUpperCase()}
			</div>
		)
	}

	return (
		<div
			className={styles.messages_header}
		>
			<div
				className={styles.messages_header_content}
			>
				<button
					type="button"
					className={styles.messages_header_back_button}
					onClick={() => {
						if (!userId) {
							setVisiblePopover(false)
							setSelectedChat("")
						} else {
							history.push("/messages")
							setSelectedChat("")
						}
					}}
				>
					<GoBackIcon />
				</button>
				{chat.roomName
					? (
						<>
							<Upload
								onChange={selectPhotos}
								showUploadList={false}
							>
								<div
									style={{ cursor: "pointer" }}
									onClick={() => {
										setVisiblePopover(false)
									}}
								>
									{renderGroupChatAvatar()}
								</div>
							</Upload>

							<Modal
								title={(
									<div>
										{t("rename-chat")}
									</div>
								)}
								visible={isModalVisible}
								keyboard
								onCancel={() => {
									setIsModalVisible(false)
									setChatName("")
								}}
								onOk={() => {
									setIsModalVisible(false)
									socket.emit("RENAME_CHAT", chat._id, chatName)
									setChatName("")
								}}
							>
								<Input
									maxLength="40"
									placeholder={t("enter-chat-name")}
									className={styles.input_field}
									value={chatName}
									onChange={(e) => {
										setChatName(e.target.value.trimLeft())
									}}
								/>
							</Modal>
						</>
					)
					: (chatCompanion ? (
						<ChatAvatar
							friendAvatar={chatCompanion.avatar}
							online={chatCompanion.isOnline}
						/>
					) : (
						<ChatAvatar
							friendAvatar={chat?.removedUsers[0]?.avatar}
							online={chat?.removedUsers[0]?.isOnline}
						/>
					)
					)}
				<div className={styles.messages_header_author}>
					{chat.roomName
						? (
							<div className={styles.messages_header_author_name}>
								{chat.roomName}
								{" "}
								<EditOutlined
									className={styles.messages_header_rename}
									onClick={() => {
										setIsModalVisible(true)
									}}
								/>
							</div>
						)
						: (chatCompanion ? (
							<div className={styles.messages_header_author_name}>
								{chatCompanion.firstName}
								{" "}
								{chatCompanion.lastName}
							</div>
						) : (
							<div className={styles.messages_header_author_name}>
								{(chat?.removedUsers[0]?.firstName || t("unknown-user"))}
								{" "}
								{chat?.removedUsers[0]?.lastName}
							</div>
						)
						)}
					{chat.roomName
						? (
							<div className={styles.messages_header_online}>
								{chat.users.length}
								{" "}
								{t("members")}
							</div>
						)
						: (chatCompanion ? (
							<div className={styles.messages_header_online}>
								{chatCompanion.isOnline ? `${t("online")}` : `${t("offline")}`}
							</div>
						) : (
							<div className={styles.messages_header_online}>
								{chat?.removedUsers[0]?.isOnline ? `${t("online")}` : `${t("offline")}`}
							</div>
						)
						)}
				</div>
				{chat.roomName
					&& (
						<ButtonsMessageContent
							chat={chat}
							visiblePopover={visiblePopover}
							setVisiblePopover={setVisiblePopover}
							currentUser={currentUser}
							setCurrentUserConnections={setCurrentUserConnections}
							setIsShowModal={setIsShowModal}
							setIsShowDeleteModal={setIsShowDeleteModal}
							setIsShowParticipiantsModal={setIsShowParticipiantsModal}
							selectedChat={selectedChat}
							setSelectedChat={setSelectedChat}
						/>
					)}
			</div>
		</div>
	)
}