import React from "react"

export const AddAlbumImage = () => (

	<svg width="176" height="153" viewBox="0 0 176 153" fill="none">
		<path d="M87.8109 133.924C122.785 133.924 151.139 105.163 151.139 69.5597C151.139 33.9567 122.66 5.19556 87.8109 5.19556C52.8366 5.19556 24.4824 33.9567 24.4824 69.5597C24.4824 105.163 52.8366 133.924 87.8109 133.924Z" fill="#EAEEF9" />
		<path d="M147.015 27.3675C149.843 27.3675 152.135 25.0418 152.135 22.1728C152.135 19.3038 149.843 16.978 147.015 16.978C144.187 16.978 141.895 19.3038 141.895 22.1728C141.895 25.0418 144.187 27.3675 147.015 27.3675Z" fill="#E7EBF6" />
		<path d="M154.513 7.09644C156.444 7.09644 158.009 5.50785 158.009 3.54822C158.009 1.58859 156.444 0 154.513 0C152.581 0 151.016 1.58859 151.016 3.54822C151.016 5.50785 152.581 7.09644 154.513 7.09644Z" fill="#E7EBF6" />
		<path d="M27.8563 27.2418C29.7876 27.2418 31.3532 25.6532 31.3532 23.6936C31.3532 21.734 29.7876 20.1454 27.8563 20.1454C25.925 20.1454 24.3594 21.734 24.3594 23.6936C24.3594 25.6532 25.925 27.2418 27.8563 27.2418Z" fill="#E7EBF6" />
		<path d="M6.49485 95.6593C10.0819 95.6593 12.9897 92.7096 12.9897 89.0709C12.9897 85.4322 10.0819 82.4824 6.49485 82.4824C2.90784 82.4824 0 85.4322 0 89.0709C0 92.7096 2.90784 95.6593 6.49485 95.6593Z" fill="#E7EBF6" />
		<path d="M169.505 69.1769C173.092 69.1769 175.999 66.2272 175.999 62.5885C175.999 58.9498 173.092 56 169.505 56C165.918 56 163.01 58.9498 163.01 62.5885C163.01 66.2272 165.918 69.1769 169.505 69.1769Z" fill="#E7EBF6" />
		<g filter="url(#filter0_d)">
			<rect width="102.426" height="98.7534" rx="8" transform="matrix(0.999538 -2.51059e-05 -2.47108e-05 1.00046 36.7227 20.7399)" fill="url(#paint0_linear)" />
		</g>
		<rect width="78.0477" height="74.696" rx="4" transform="matrix(0.999538 -1.52666e-05 -1.49831e-05 1.00046 49.0059 32.7684)" fill="#EAEEF9" />
		<path d="M89.8945 82.1125L62.8027 82.3035L76.2326 66.6239L89.8945 82.1125Z" fill="#B0B7C4" />
		<path d="M112.36 81.9271L73.3049 82.2088L92.6469 59.6919L112.36 81.9271Z" fill="#CED6E2" />
		<path d="M76.208 59.7278C78.3501 57.859 78.5914 54.5807 76.747 52.4055C74.9026 50.2302 71.671 49.9817 69.5289 51.8505C67.3869 53.7192 67.1456 56.9975 68.9899 59.1728C70.8343 61.3481 74.066 61.5965 76.208 59.7278Z" fill="#B0B7C4" />
		<path d="M106.722 116.813C106.335 118.483 105.754 120.251 105.076 121.724C103.236 125.358 100.331 128.207 96.7487 130.073C93.0692 131.939 88.7119 132.725 84.3546 131.743C74.0907 129.582 67.5064 119.367 69.6366 108.956C71.7668 98.5446 81.7402 91.7675 92.0041 94.0266C95.6836 94.8123 98.879 96.6785 101.59 99.2322C106.141 103.848 108.078 110.527 106.722 116.813Z" fill="url(#paint1_linear)" />
		<path d="M94.2302 111.215H89.8732V106.795C89.8732 105.911 89.1954 105.125 88.2272 105.125C87.3558 105.125 86.5812 105.813 86.5812 106.795V111.215H82.2241C81.3527 111.215 80.5781 111.902 80.5781 112.885C80.5781 113.867 81.2559 114.554 82.2241 114.554H86.5812V118.974C86.5812 119.858 87.2589 120.644 88.2272 120.644C89.0986 120.644 89.8732 119.956 89.8732 118.974V114.554H94.2302C95.1016 114.554 95.8762 113.867 95.8762 112.885C95.8762 111.902 95.1016 111.215 94.2302 111.215Z" fill="white" />
		<defs>
			<filter id="filter0_d" x="14.7207" y="9.73755" width="146.38" height="142.801" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
				<feFlood floodOpacity="0" result="BackgroundImageFix" />
				<feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" />
				<feOffset dy="11" />
				<feGaussianBlur stdDeviation="11" />
				<feColorMatrix type="matrix" values="0 0 0 0 0.397708 0 0 0 0 0.47749 0 0 0 0 0.575 0 0 0 0.27 0" />
				<feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
				<feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
			</filter>
			<linearGradient id="paint0_linear" x1="51.1795" y1="-2.28426" x2="51.1795" y2="99.818" gradientUnits="userSpaceOnUse">
				<stop stopColor="#FDFEFF" />
				<stop offset="0.9964" stopColor="#ECF0F5" />
			</linearGradient>
			<linearGradient id="paint1_linear" x1="69.219" y1="112.884" x2="107.154" y2="112.884" gradientUnits="userSpaceOnUse">
				<stop stopColor="#B0BACC" />
				<stop offset="1" stopColor="#969EAE" />
			</linearGradient>
		</defs>
	</svg>
)