import React from "react"
import Autocomplete from "react-google-autocomplete"
import {
	Form, Input, Select, DatePicker
} from "antd"
import {
	CityIcon, HearthIcon,
	LocationIcon, BirthDateIcon, CircleIcon
} from "shared/icons"
import moment from "moment"
import { updateUser } from "app/redux/user"
import { store } from "app/redux/store"
import { useSelector } from "react-redux"
import { useTranslation } from "react-i18next"
import styles from "../personal-info.module.css"

export const InputsContainer = (props) => {
	const {
		form,
		setCurrentUserLocation
	} = props

	const { user } = useSelector(s => s)
	const { currentUser } = user

	const familyStatuses = ["not-selected", "single", "married", "in-relationship", "widowed", "engaged", "complicated", "separated", "divorced"]
	const genderValues = ["male", "female", "unknown"]

	const regexp = new RegExp(/(?:[\u2700-\u27bf]|(?:\ud83c[\udde6-\uddff]){2}|[\ud800-\udbff][\udc00-\udfff]|[\u0023-\u0039]\ufe0f?\u20e3|\u3299|\u3297|\u303d|\u3030|\u24c2|\ud83c[\udd70-\udd71]|\ud83c[\udd7e-\udd7f]|\ud83c\udd8e|\ud83c[\udd91-\udd9a]|\ud83c[\udde6-\uddff]|\ud83c[\ude01-\ude02]|\ud83c\ude1a|\ud83c\ude2f|\ud83c[\ude32-\ude3a]|\ud83c[\ude50-\ude51]|\u203c|\u2049|[\u25aa-\u25ab]|\u25b6|\u25c0|[\u25fb-\u25fe]|\u00a9|\u00ae|\u2122|\u2139|\ud83c\udc04|[\u2600-\u26FF]|\u2b05|\u2b06|\u2b07|\u2b1b|\u2b1c|\u2b50|\u2b55|\u231a|\u231b|\u2328|\u23cf|[\u23e9-\u23f3]|[\u23f8-\u23fa]|\ud83c\udccf|\u2934|\u2935|[\u2190-\u21ff])/gm)
	const space = new RegExp(/^[\s\uFEFF\xA0]+|[\s\uFEFF\xA0]+$/g)

	const { Option } = Select
	const { t } = useTranslation()

	const disabledDate = (current) => current > moment().endOf("day")

	const itemChange = (e) => {
		const name = e.target.id.slice(14)

		if (name === "firstName" || name === "lastName" || name === "maidenName") {
			e.target.value = e.target.value.replace(space, "")
		}

		e.target.value = e.target.value.replace(regexp, "")

		form.setFieldsValue({ [name]: e.target.value })
	}

	return (
		<div className={styles.inputs_container}>
			<div className={styles.inputs}>
				<Form.Item
					onChange={itemChange}
					name="firstName"
					className={styles.input_item}
					rules={[
						{
							required: true,
							message: `${t("first-name-is-required")}`
						},
						{
							max: 30,
							message: `${t("max-first-name-length-reached")}`
						}
					]}
					label={`${t("first-name")}`}
				>
					<Input
						className={styles.input_field}
						placeholder={t("first-name")}
					/>
				</Form.Item>
				<Form.Item
					className={styles.input_item}
					onChange={itemChange}
					name="lastName"
					rules={[
						{
							required: true,
							message: `${t("last-name-is-required")}`
						},
						{
							max: 30,
							message: `${t("max-last-name-length-reached")}`
						}
					]}
					label={`${t("last-name")}`}
				>
					<Input
						className={styles.input_field}
						placeholder={t("last-name")}
					/>
				</Form.Item>
			</div>
			<div className={styles.inputs}>
				<Form.Item
					className={styles.input_item}
					onChange={itemChange}
					name="city"
					label={(
						<div>
							<CityIcon />
							<span style={{ marginLeft: "6px" }}>{t("city")}</span>
						</div>
					)}
				>
					<Autocomplete
						className={styles.input_field}
						apiKey={process.env.REACT_APP_GOOGLE_MAP_API_KEY}
						placeholder={t("select-city")}
						onChange={(e) => {
							setCurrentUserLocation({
								name: e.target.value.replace(regexp, ""),
								id: null
							})
						}}
						onPlaceSelected={(place) => {
							setCurrentUserLocation({
								name: place.formatted_address,
								id: place.place_id
							})
							form.setFieldsValue({ city: place.formatted_address })
						}}
					/>
				</Form.Item>
				<Form.Item
					className={styles.input_item}
					onChange={itemChange}
					name="birthPlace"
					label={(
						<div>
							<LocationIcon />
							<span style={{ marginLeft: "6px" }}>{t("birth-place")}</span>
						</div>
					)}
				>
					<Autocomplete
						className={styles.input_field}
						apiKey={process.env.REACT_APP_GOOGLE_MAP_API_KEY}
						placeholder={t("select-birthplace")}
						onChange={(e) => {
							form.setFieldsValue({ birthPlace: e.target.value })
						}}
						onPlaceSelected={(place) => {
							form.setFieldsValue({ birthPlace: place.formatted_address })
						}}
					/>
				</Form.Item>
			</div>
			<div className={styles.inputs}>
				<Form.Item
					className={styles.input_item}
					name="birthDate"
					label={(
						<div>
							<BirthDateIcon />
							<span style={{ marginLeft: "6px" }}>
								{t("birth-date")}
							</span>
						</div>
					)}
				>
					<DatePicker placeholder={t("select-birth-date")} disabledDate={disabledDate} className={styles.input_field} format="DD/MM/YYYY" />
				</Form.Item>
				<Form.Item
					className={styles.input_item}
					name="familyStatus"
					label={(
						<div>
							<HearthIcon />
							<span style={{ marginLeft: "6px" }}>
								{t("family-status")}
							</span>
						</div>
					)}
				>
					<Select
						className={`${styles.input_field} ${styles.select_field}`}
						dropdownClassName={styles.dropdown_container}
						placeholder={t("select-family-status")}
						bordered={false}
						onChange={(value) => store.dispatch(updateUser({
							...currentUser,
							familyStatus: value
						}))}
						allowClear
					>
						{familyStatuses.map(status => (
							<Option
								key={status}
								value={status}
							>
								{t(status)}
							</Option>
						))}
					</Select>
				</Form.Item>
			</div>
			<div className={styles.inputs}>
				<Form.Item
					className={styles.input_item}
					name="gender"
					label={(
						<div>
							<CircleIcon />
							<span style={{ marginLeft: "6px" }}>
								{t("gender")}
							</span>
						</div>
					)}
				>
					<Select
						className={`${styles.input_field} ${styles.select_field}`}
						dropdownClassName={styles.dropdown_container}
						placeholder={t("select-gender")}
						bordered={false}
						onChange={(value) => store.dispatch(updateUser({
							...currentUser,
							gender: value
						}))}
						allowClear
					>
						{genderValues.map(gender => {
							if (gender === "unknown") {
								return (
									<Option
										key={gender}
										value={gender}
									>
										{t("gender-neutral")}
									</Option>
								)
							}
							return (
								<Option
									key={gender}
									value={gender}
								>
									{t(gender)}
								</Option>
							)
						})}
					</Select>
				</Form.Item>
				<Form.Item
					className={styles.input_item}
					onChange={itemChange}
					name="maidenName"
					rules={[
						{
							max: 30,
							message: "Max maiden name length reached"
						}
					]}
					label={<div>{t("maiden-name")}</div>}
				>
					<Input
						className={styles.input_field}
						placeholder={t("maiden-name")}
					/>
				</Form.Item>
			</div>
		</div>
	)
}
