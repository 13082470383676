/* eslint-disable jsx-a11y/control-has-associated-label */
import React, { useState } from "react"
import {
	updatePerson, setShowModal, setSelectedPersonId
} from "modules/family-tree/ducks"
import { store } from "app/redux/store"
import { useSelector, useDispatch } from "react-redux"
import {
	getPersonById, findChildren, findParents,
	selectPerson, removeChildCard, hideParents
} from "modules/family-tree/utils"
import { saveUserTree } from "modules/family-tree/api"
import { message } from "antd"
import CustomScroll from "react-scrollbars-custom"
import { useTranslation } from "react-i18next"
import {
	MobileHeader,
	MobileAvatarUpload,
	MobileMainSection,
	MobileSelectGender,
	MobileDeletePerson,
	MobileDeleteNotification,
	MobileSaveChangesButton
} from "./sections"
import styles from "./mobile-edit-modal.module.css"

const isShowDeleteButton = (id, selectedPerson, personPartner, token) => {
	const personChildren = id === "person21" ? findChildren("person22") : findChildren(id)
	const childGenders = personChildren.map(p => p.gender)
	const personParents = findParents(id)
	const parentGenders = personParents.map(p => p.gender)
	const addChildToParentCard = childGenders.find(gender => gender === "")

	if (selectedPerson.parents.length !== 0 && parentGenders.every(p => p === "") && personChildren.length === 1 && childGenders !== "") {
		return (
			<MobileDeletePerson
				person={selectedPerson}
				personChildren={personChildren}
				childGenders={childGenders}
				parentGenders={parentGenders}
				addChildToParentCard={addChildToParentCard}
			/>
		)
	}

	if (selectedPerson.parents.length !== 0 && parentGenders.every(p => p === "") && addChildToParentCard === "") {
		const unhiddenChilds = personChildren.filter(child => !child.hide)
		if (personChildren.length <= 2) {
			return (
				<MobileDeletePerson
					person={selectedPerson}
					personChildren={personChildren}
					childGenders={childGenders}
					parentGenders={parentGenders}
					addChildToParentCard={addChildToParentCard}
					selectedPersonPartner={personPartner}
				/>
			)
		}
		if (personChildren.length >= 2
			&& unhiddenChilds.length === 2
			&& unhiddenChilds.find(child => child.name === "Add child ")
			&& personChildren.find(child => child.hide)) {
			return (
				<MobileDeletePerson
					person={selectedPerson}
					personChildren={personChildren}
					childGenders={childGenders}
					parentGenders={parentGenders}
					addChildToParentCard={addChildToParentCard}
					token={token}
				/>
			)
		}
	}

	if (selectedPerson.parents.length !== 0 && personChildren.length === 1 && addChildToParentCard === "") {
		return (
			<MobileDeletePerson
				person={selectedPerson}
				personChildren={personChildren}
				childGenders={childGenders}
				parentGenders={parentGenders}
				addChildToParentCard={addChildToParentCard}
				token={token}
			/>
		)
	}
	return (<MobileDeleteNotification person={selectedPerson} />)
}

export const MobileEditModal = ({ token }) => {
	const { selectedPersonId, isShowModal } = useSelector(s => s.familyTree)
	const dispatch = useDispatch()
	const selectedPerson = getPersonById(selectedPersonId)
	const personPartner = getPersonById("person21")
	const { t } = useTranslation()

	const [nameInfo, setNameInfo] = useState("")
	const [fromInfo, setFromInfo] = useState("")
	const [livesInInfo, setLivesInInfo] = useState("")
	const [descriptionInfo, setDescriptionInfo] = useState("")

	const saveData = async () => {
		dispatch(updatePerson({
			...selectedPerson,
			name: nameInfo,
			from: fromInfo,
			livesIn: livesInInfo,
			description: descriptionInfo
		}))
		dispatch(setSelectedPersonId(null))
		hideParents()
		removeChildCard()
		selectPerson(null)
		const { people } = store.getState().familyTree
		const result = await saveUserTree(people, token)
		if (result.status === "error") {
			message.error(t("something-went-wrong"))
		}
		dispatch(setShowModal(false))
	}

	const getContent = (id, selectedPerson) => {
		if (id) {
			return (
				<>
					<MobileHeader person={selectedPerson} token={token} />
					<MobileAvatarUpload
						person={selectedPerson}
						token={token}
						withoutBtns={selectedPerson.isUser || selectedPerson.profileId}
					/>
					<MobileMainSection
						person={selectedPerson}
						id={id}
						isPersonAlive={selectedPerson.deathDate === "" || !selectedPerson.deathDate}
						setNameInfo={setNameInfo}
						setFromInfo={setFromInfo}
						setLivesInInfo={setLivesInInfo}
						setDescriptionInfo={setDescriptionInfo}
						nameInfo={nameInfo}
						fromInfo={fromInfo}
						livesInInfo={livesInInfo}
						descriptionInfo={descriptionInfo}
						token={token}
					/>
					{!selectedPerson.isUser && selectedPerson.gender !== "" && selectedPerson.gender === "" ? null : (<MobileSelectGender person={selectedPerson} />)}
					{!selectedPerson.isUser
						&& (
							<MobileSaveChangesButton
								saveData={saveData}
							/>
						)}
					{isShowDeleteButton(id, selectedPerson, personPartner, token)}
				</>
			)
		}
		return null
	}

	return (
		<div
			className={selectedPersonId && isShowModal ? `${styles.wrap}` : `${styles.wrap} ${styles.disabled}`}

		>
			<div
				className={styles.close_wrap}
				role="button"
				tabIndex={0}
				onClick={() => {
					saveData()
				}}
			/>

			<div className={styles.container}>
				<CustomScroll>
					{getContent(selectedPersonId, selectedPerson, personPartner)}
				</CustomScroll>
			</div>

		</div>
	)
}
