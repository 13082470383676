import { store } from "app/redux/store"
import {
	setSelectedPersonId, updatePerson, removeChild, removeParent
} from "modules/family-tree/ducks"
import {
	addChildNode, addRightBranchUncleNode, addLeftBranchUncleNode, getPersonById, addParentNode
} from "modules/family-tree/utils"

const addChildCard = (firstParentId, chosedParentId) => {
	addChildNode(firstParentId, chosedParentId)
}

export const removeChildCard = () => {
	const { people } = store.getState().familyTree
	const addChildCards = people.filter(p => p.name === "Add child")
	addChildCards.forEach(card => {
		store.dispatch(removeChild(card.positionId))
	})
}

export const findChildren = (id) => {
	const { people } = store.getState().familyTree
	const personChildren = people.filter(p => p.parents.includes(id))
	return personChildren
}

const addLeftBranchUncleCard = (firstParentId, chosedParentId) => {
	const chosedPersonChildren = findChildren(chosedParentId)
	const hiddenChild = chosedPersonChildren.find(p => p.hide)
	if (hiddenChild) {
		store.dispatch(updatePerson({ ...hiddenChild, hide: false }))
	}
	if (!hiddenChild) {
		addLeftBranchUncleNode(firstParentId, chosedParentId)
	}
}

const addRightBranchUncleCard = (firstParentId, chosedParentId) => {
	const chosedPersonChildren = findChildren(chosedParentId)
	const hiddenChild = chosedPersonChildren.find(p => p.hide)
	if (hiddenChild) {
		store.dispatch(updatePerson({ ...hiddenChild, hide: false }))
	}
	if (!hiddenChild) {
		addRightBranchUncleNode(firstParentId, chosedParentId)
	}
}

export const findParents = (id) => {
	const { people } = store.getState().familyTree
	const curentPerson = people.find(p => p.positionId === id)
	const personParents = []
	if (curentPerson) {
		curentPerson.parents.forEach(parentId => {
			const parent = getPersonById(parentId)
			personParents.push(parent)
		})
	}
	return personParents
}

const addParentCard = id => {
	const { people } = store.getState().familyTree
	const lastGenerationPersons = people.filter(p => p.lastGeneration)
	lastGenerationPersons.forEach(card => {
		addParentNode(card.positionId)
		store.dispatch(updatePerson({ ...card, lastGeneration: false }))
		if (id) {
			const personParents = findParents(id)
			personParents.forEach(parent => {
				store.dispatch(updatePerson({ ...parent, hide: false }))
			})
		}
	})
}

const showParents = id => {
	const { people } = store.getState().familyTree
	const curentPerson = people.find(p => p.positionId === id)
	if (curentPerson) {
		curentPerson.parents.forEach(parentId => {
			const parent = getPersonById(parentId)
			store.dispatch(updatePerson({ ...parent, hide: false }))
		})
	}
}

export const hideParents = () => {
	const { people } = store.getState().familyTree
	const ghosts = people.filter(p => !p.gender && !p.hide)
	ghosts.forEach(g => {
		store.dispatch(updatePerson({ ...g, hide: true }))
	})
}

export const deleteHiddenLastGeneration = () => {
	const { people } = store.getState().familyTree
	const lastGenParents = people.filter(p => p.lastGeneration)
	const lastGenParentsChildren = []
	const findLastGenChildren = () => {
		lastGenParents.map(
			person => {
				const child = people.find(
					child => child.parents[0] === person.positionId || child.parents[1] === person.positionId
				)
				if (!lastGenParentsChildren.includes(child)) {
					lastGenParentsChildren.push(child)
				}
				return true
			}
		)
	}
	findLastGenChildren()
	if (!lastGenParents.find(p => !p.hide)) {
		lastGenParents.forEach(parent => {
			store.dispatch(removeParent(parent.positionId))
		})

		lastGenParentsChildren.forEach(child => {
			store.dispatch(updatePerson({
				...child,
				parents: [],
				lastGeneration: true
			}))
		})
	}
}

export const selectPerson = (addInfo, id) => {
	if (!id) {
		hideParents()
		removeChildCard()
	}
	const { people } = store.getState().familyTree
	store.dispatch(setSelectedPersonId(id))
	if (id) {
		hideParents()
		removeChildCard()
		if (people.find(p => p.lastGeneration === false)) {
			deleteHiddenLastGeneration()
			addParentCard(id)
		}
		showParents(id)
		if (!addInfo.withoutChild) {
			if (id === "person33" || id === "person34") {
				if (id === "person33") {
					addRightBranchUncleCard(addInfo.partner, id)
				} else { addRightBranchUncleCard(id, addInfo.partner) }
			} else if (id === "person31" || id === "person32") {
				if (id === "person32") {
					addLeftBranchUncleCard(addInfo.partner, id)
				} else { addLeftBranchUncleCard(id, addInfo.partner) }
			} else {
				addChildCard(addInfo.partner, id)
			}
		}
		if (addInfo.withoutChild && addInfo.lastGeneration) {
			addParentCard(id)
		}
	} else {
		hideParents()
		removeChildCard()
	}
}