export const inviteAsFamily = async (
  id,
  externalToken = null
) => {
  try {
    let token = externalToken
    if (!token) {
      token = localStorage.token ? localStorage.getItem("token") : sessionStorage.getItem("token")
    }
    const userInfo = {
      id
    }
    const res = await fetch("/api/search-people/family", {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Authorization: token
      },
      body: JSON.stringify(userInfo)
    })
    const json = await res.json()
    return json
  } catch (error) {
    const message = "Something went wrong"
    return message
  }
}