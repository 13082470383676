export const SET_MESSAGES = "SET_MESSAGES"
export const UPDATE_MESSAGES = "UPDATE_MESSAGES"
export const UPDATE_CHAT = "UPDATE_CHAT"
export const UPDATE_NAME_CHAT = "UPDATE_NAME_CHAT"
export const SET_MESSAGES_READED = "SET_MESSAGES_READED"
export const ADD_NEW_CHAT = "ADD_NEW_CHAT"
export const SORT_CHATS = "SORT_CHATS"
export const LEAVE_CHAT = "LEAVE_CHAT"
export const UPDATE_USERS = "UPDATE_USERS"
export const UPDATE_CHAT_AVATAR = "UPDATE_CHAT_AVATAR"
export const UPDATE_CHAT_ADMIN = "UPDATE_CHAT_ADMIN"

export const setMessages = (newMessages) => ({
  type: SET_MESSAGES,
  newMessages
})

export const updateMessages = (chatId, message, chatDate) => ({
  type: UPDATE_MESSAGES,
  chatId,
  message,
  chatDate
})

export const updateNameChat = (chatId, newChatName) => ({
  type: UPDATE_NAME_CHAT,
  chatId,
  newChatName
})

export const updateChatAvatar = (chatId, newChatAvatar) => ({
  type: UPDATE_CHAT_AVATAR,
  chatId,
  newChatAvatar
})

export const updateChatAdmin = (chatId, newChatAdmin) => ({
  type: UPDATE_CHAT_ADMIN,
  chatId,
  newChatAdmin
})

export const updateChat = (chatId, messages) => ({
  type: UPDATE_CHAT,
  chatId,
  messages
})

export const updateUsers = (chatId, usersIds, users) => ({
  type: UPDATE_USERS,
  chatId,
  usersIds,
  users
})

export const setMessagesReaded = (chatId, messages) => ({
  type: SET_MESSAGES_READED,
  chatId,
  messages
})

export const addNewChat = (newChat) => ({
  type: ADD_NEW_CHAT,
  newChat
})

export const leaveChat = (chatId) => ({
  type: LEAVE_CHAT,
  chatId
})

export const sortChats = () => ({
  type: SORT_CHATS
})

const initialState = []

export const messages = (state = initialState, action) => {
  switch (action.type) {
    case SET_MESSAGES:
      return action.newMessages
    case UPDATE_NAME_CHAT:
      return state.map(chat => {
        if (chat._id === action.chatId) {
          chat.roomName = action.newChatName
        }
        return chat
      })
    case UPDATE_CHAT_AVATAR:
      return state.map(chat => {
        if (chat._id === action.chatId) {
          chat.roomAvatar = action.newChatAvatar
        }
        return chat
      })
    case UPDATE_CHAT_ADMIN:
      return state.map(chat => {
        if (chat._id === action.chatId) {
          chat.chatAdmin = action.newChatAdmin
        }
        return chat
      })
    case UPDATE_MESSAGES:
      return state.map(message => {
        if (message._id === action.chatId) {
          message.messages.push(action.message)
          message.roomDate = action.chatDate
        }
        return message
      })
    case UPDATE_USERS:
      return state.map(message => {
        if (message._id === action.chatId) {
          if (message.users.length > action.usersIds.length) {
            message.removedUsers = [...message.removedUsers, ...action.users]
            message.users = message.users.filter(u => action.usersIds.includes(u._id))
          }
          if (message.users.length < action.usersIds.length) {
            message.users = action.users
          }
        }
        return message
      })
    case SET_MESSAGES_READED:
      return state.map(message => {
        if (message._id === action.chatId) {
          message.messages = action.messages
        }
        return message
      })
    case ADD_NEW_CHAT:
      return [action.newChat, ...state]
    case LEAVE_CHAT:
      return state.filter(chat => chat._id !== action.chatId)
    case UPDATE_CHAT:
      return state.map(chat => {
        if (chat._id === action.chatId) {
          chat.messages = [...action.messages]
        }
        return chat
      })
    case SORT_CHATS:
      return state.sort((a, b) => new Date(b.roomDate) - new Date(a.roomDate))
    default:
      return state
  }
}