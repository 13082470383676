import React from "react"
import { UserOutlined } from "@ant-design/icons"
import styles from "./chat-avatar.module.css"

export const ChatAvatar = ({ friendAvatar, online }) => (
	<div className={styles.avatar}>
		{friendAvatar ? <img src={friendAvatar} alt="avatar" /> : (
			<UserOutlined style={{
				width: "44px", height: "44px", fontSize: "40px", color: "#fff"
			}}
			/>
		)}
		{online && (<div className={styles.avatar_online} />)}
	</div>
)