import { NavLink } from "react-router-dom"
import {
	Button, Input, Modal, message, Popover
} from "antd"
import React, { useState, useEffect } from "react"
import { useTranslation } from "react-i18next"
import CustomScroll from "react-scrollbars-custom"
import {
	createAlbum, getAlbum, deleteAlbum, renameAlbum
} from "modules/photos/api"
import { getImageUri } from "shared/utils"
import {
	EditIcon, DeleteIcon, MoreIcon, MessageIcon
} from "shared/icons"
import { useSelector } from "react-redux"
import { AlbumComments } from "shared/album-comments"
import { AddPhotoImage } from "shared/empty-screen-images"
import { AddAlbumImage } from "shared/empty-screen-images/add-albums"
import { AppSpinner } from "shared/app-spinner/app-spinner"
import styles from "./albums-card.module.css"

const PopoverContent = ({
	setIsEditMode,
	fullAlbume,
	album,
	setVisible,
	setVisiblePopover,
	setCurrentAlbum,
	setIsAlbumCommentsActive,
	setSelectedAlbum
}) => {
	const { t } = useTranslation()

	return (
		<div
			className={styles.popover_content}
		>
			<button
				type="button"
				className={styles.popover_btn}
				onClick={async () => {
					setSelectedAlbum(fullAlbume)
					setIsAlbumCommentsActive(true)
					setVisiblePopover(false)
				}}
			>
				<MessageIcon />
				<div className={styles.popover_btn_text}>{t("comments")}</div>
			</button>
			<button
				type="button"
				className={styles.popover_btn}
				onClick={async () => {
					setIsEditMode(true)
					setVisible(true)
					setVisiblePopover(false)
				}}
			>
				<EditIcon />
				<div className={styles.popover_btn_text}>{t("rename-album")}</div>
			</button>
			<button
				type="button"
				className={styles.popover_btn}
				onClick={() => setCurrentAlbum(album)}
			>
				<DeleteIcon />
				<div className={styles.popover_btn_text}>
					{" "}
					{t("delete-album")}
				</div>
			</button>
		</div>
	)
}

export const AlbumCard = () => {
	const { t } = useTranslation()
	const { user } = useSelector(s => s)
	const { currentUser } = user

	const [visible, setVisible] = useState(false)
	const [confirmLoading, setConfirmLoading] = useState(false)
	const [modalText, setModalText] = useState("")
	const [albums, setAlbums] = useState([])
	const [visiblePopover, setVisiblePopover] = useState(false)
	const [isEditMode, setIsEditMode] = useState(false)
	const [curKey, setCurKey] = useState(null)
	const [isLoading, setIsLoading] = useState(false)
	const [isAlbumCommentsActive, setIsAlbumCommentsActive] = useState(false)
	const [selectedAlbum, setSelectedAlbum] = useState(null)
	const [currentAlbum, setCurrentAlbum] = useState(null)

	const videoDelete = async () => {
		setVisiblePopover(false)
		const response = await deleteAlbum(currentAlbum)
		setAlbums(response.data.user.photoGallery)
		setCurrentAlbum(null)
	}
	useEffect(() => {
		const get = async () => {
			setIsLoading(true)
			const res = await getAlbum()
			setAlbums(res.data.photoGallery)
			setIsLoading(false)
			return res
		}
		get()
	}, [])

	const handleOk = async () => {
		if (!modalText || modalText === "") {
			message.error(t("please-enter-album-name"))
		} else {
			setConfirmLoading(true)
			setTimeout(() => {
				setVisible(false)
				setModalText("")
				setConfirmLoading(false)
			}, 500)
			if (isEditMode) {
				const response = await renameAlbum(modalText, curKey)
				setIsEditMode(false)
				setAlbums(response.data.user.photoGallery)
				setVisiblePopover(false)
			} else {
				const response = await createAlbum(modalText)
				setAlbums(response.data.user.photoGallery)
				if (!response.status) {
					message.error(t("Something went wrong"))
				}
			}
		}
	}

	const handleCancel = () => {
		setModalText("")
		setVisible(false)
		setIsEditMode(false)
	}

	return (
		<>
			<div
				className={styles.container}
			>
				{!isLoading ? (
					<CustomScroll>
						<div
							className={styles.header}
						>
							<span style={{ padding: "0 24px 0" }}>{t("my-albums")}</span>
							{albums.length > 0 && (
								<Button
									onClick={() => {
										setVisible(true)
									}}
									className={styles.confirm_button}
								>
									+
									{t("create-albums")}
								</Button>
							)}
						</div>
						<Modal
							title={isEditMode ? t("rename-album") : t("create-albums")}
							visible={visible}
							onOk={handleOk}
							confirmLoading={confirmLoading}
							onCancel={handleCancel}
						>
							<Input
								value={modalText}
								onChange={(e) => setModalText(e.target.value)}
							/>
						</Modal>
						<Modal title={t("delete-album")} visible={currentAlbum} onOk={videoDelete} onCancel={() => setCurrentAlbum(null)}>
							<p>{t("are-you-sure-that-you-want-to-delete-the-album")}</p>
						</Modal>
						<div className={styles.main_content_container}>
							{albums.length === 0
								&& (
									<div className={styles.main_no_albums}>
										<AddAlbumImage />
										<div className={styles.no_albums_text}>{t("create-your-first-album")}</div>
										<Button
											onClick={() => setVisible(true)}
											className={styles.confirm_button}
										>
											{t("create-albums")}
										</Button>
									</div>
								)}
							{albums?.length > 0 && albums.map(album => (
								<div
									key={album.id}
									className={styles.album_card}
									onMouseLeave={() => {
										setVisiblePopover(false)
									}}
								>
									<Popover placement="bottomRight" content={() => <PopoverContent setCurrentAlbum={setCurrentAlbum} setIsEditMode={setIsEditMode} fullAlbume={album} album={album.id} setVisible={setVisible} setVisiblePopover={setVisiblePopover} setAlbums={setAlbums} setIsAlbumCommentsActive={setIsAlbumCommentsActive} setSelectedAlbum={setSelectedAlbum} />} trigger="click" visible={curKey === album.id && visiblePopover}>
										<button
											className={styles.albumn_more_btn}
											style={{ fontSize: "24px" }}
											type="button"
											onClick={() => {
												setCurKey(album.id)
												setVisiblePopover(!visiblePopover)
											}}
										>
											<MoreIcon />
										</button>
									</Popover>
									<NavLink to={`/photo/album/${album.id}`}>
										{" "}
										<div className={styles.album_card_content}>
											{album.photos.length > 0 ? (
												<div
													style={{
														position: "absolute",
														top: "0",
														left: "0",
														width: "100%",
														height: "100%",
														border: "1px solid #EAEEF9",
														borderRadius: "8px",
														display: "flex",
														alignItems: "center",
														justifyContent: "center",
														cursor: "pointer",
														backgroundImage: `url(${getImageUri(album.photos[0].path)})`,
														backgroundPosition: "center center",
														backgroundSize: "cover"
													}}
												/>
											) : <div className={styles.img}><AddPhotoImage /></div>}
										</div>
										<h3 className={styles.title_album}>{album.name}</h3>
									</NavLink>
									<h4 className={styles.counter_items}>
										{album.photos.length}
										{" "}
										{t("photo")}
									</h4>
								</div>
							))}
						</div>
					</CustomScroll>
				) : (
					<AppSpinner style={{ width: "100%", height: "100%" }} />
				)}

			</div>
			<AlbumComments
				isAlbumCommentsActive={isAlbumCommentsActive}
				setIsAlbumCommentsActive={setIsAlbumCommentsActive}
				ownerId={currentUser._id}
				album={selectedAlbum}
				setSelectedAlbum={setSelectedAlbum}
				userId={currentUser._id}
			/>
		</>
	)
}