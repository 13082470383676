export const facebookAuth = async ({
  email, fBId, firstName, lastName
}) => {
  const authInfo = {
    email,
    fBId,
    firstName,
    lastName
  }
  try {
    const res = await fetch("/api/facebook-auth", {
      method: "POST",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify(authInfo)
    })
    const json = await res.json()
    return json
  } catch (error) {
    console.error("Error:", error)
  }
}