import React, { useState, useEffect } from "react"
import {
	Form, Input, Button, Checkbox, message
} from "antd"
import { NavLink, useHistory } from "react-router-dom"
import { AppLogoIcon } from "shared/logo-icon"
import { useTranslation } from "react-i18next"
import { resendCodeToEmail, registerUser } from "./api"
import "antd/dist/antd.css"
import styles from "./auth.module.css"

export const Registration = () => {
	const [registrationStatus, setRegistrationStatus] = useState("")
	const [buttonDisabled, setButtonMode] = useState(true)
	const [fieldsValues, setFieldsValues] = useState({})
	const history = useHistory()
	const { t } = useTranslation()

	const whiteSpacesRegexp = /^\S*$/

	const onFinish = async (values) => {
		const status = await registerUser(
			values.firstName,
			values.lastName,
			values.email,
			values.password
		)
		setRegistrationStatus(status.message)
		if (status.status === "OK") {
			await resendCodeToEmail(values.email)
			history.push({ pathname: "/registration-confirm", email: values.email })
		}
		if (status.status === "Not Allowed") {
			message.error(t("passworde-notice"))
		}
	}

	const onAgree = (e) => {
		setFieldsValues({ ...fieldsValues, checked: e.target.checked })
	}

	useEffect(() => {
		const validateData = async () => {
			if (Object.keys(fieldsValues).length === 5) {
				if (fieldsValues?.checked) {
					setButtonMode(false)
				} else {
					setButtonMode(true)
				}
			} else {
				setButtonMode(true)
			}
		}
		validateData()
	}, [fieldsValues])
	return (
		<div className={styles.wrap}>
			<div className={styles.auth_container}>
				<div className={styles.auth_logo}><AppLogoIcon /></div>
				<Form
					name="registration"
					className={styles.register_form}
					initialValues={{
						remember: true
					}}
					onChange={() => setRegistrationStatus("")}
					onFinish={onFinish}
				>
					<div className={styles.register_form_header}>{t("sign-up")}</div>
					<div className={styles.register_form_login}>
						{t("already-have-an-account")}
						{" "}
						<NavLink to="/login" className={styles.login_form_link}>
							{t("sign-in")}
						</NavLink>
					</div>
					<Form.Item className={styles.name_register_input_container}>
						<Form.Item
							className={styles.name_register_input}
							name="firstName"
							rules={[
								{
									required: true,
									message: `${t("required-field")}`
								}
							]}
						>
							<Input
								placeholder={t("first-name")}
								className={styles.auth_input}
								onChange={(e) => {
									if (e.target.value.trim()) {
										setFieldsValues({ ...fieldsValues, firstName: e.target.value })
									} else {
										const data = fieldsValues
										delete data.firstName
										setFieldsValues({ ...data })
									}
								}}
							/>
						</Form.Item>
						<Form.Item
							className={styles.name_register_input}
							name="lastName"
							rules={[
								{
									required: true,
									message: `${t("required-field")}`
								}
							]}
						>
							<Input
								placeholder={t("last-name")}
								className={styles.auth_input}
								onChange={(e) => {
									if (e.target.value.trim()) {
										setFieldsValues({ ...fieldsValues, lastName: e.target.value })
									} else {
										const data = fieldsValues
										delete data.lastName
										setFieldsValues({ ...data })
									}
								}}
							/>
						</Form.Item>
					</Form.Item>
					<Form.Item
						className={styles.auth_from_item_input}
						name="email"
						rules={[
							{
								type: "email",
								message: `${t("invalid-email-address")}`
							},
							{
								required: true,
								message: `${t("please-input-your-email-address")}`
							}
						]}
					>
						<Input
							placeholder="Email address"
							className={styles.auth_input}
							onChange={(e) => {
								if (e.target.value.trim()) {
									setFieldsValues({ ...fieldsValues, email: e.target.value })
								} else {
									const data = fieldsValues
									delete data.email
									setFieldsValues({ ...data })
								}
							}}
						/>
					</Form.Item>
					<Form.Item
						className={styles.auth_from_item_input}
						name="password"
						rules={[
							{
								required: true,
								message: `${t("please-input-your-password!")}`
							},
							{
								min: 8,
								message: `${t("password-is-too-short")}`
							},
							{
								max: 20,
								message: `${t("passworde-length-notice")}`
							},
							{
								pattern: whiteSpacesRegexp,
								message: `${t("no-white-spaces-allowed")}`
							}
						]}
					>
						<Input.Password
							className={styles.auth_input}
							placeholder="Password"
							onChange={(e) => {
								if (e.target.value.trim()) {
									setFieldsValues({ ...fieldsValues, password: e.target.value })
								} else {
									const data = fieldsValues
									delete data.password
									setFieldsValues({ ...data })
								}
							}}
						/>
					</Form.Item>
					<Form.Item name="agree" valuePropName="checked" noStyle>
						<span className={styles.checkbox_on_agree}>
							<Checkbox onChange={onAgree}>
								<div className={styles.register_terms}>
									{t("by-creating-an-accoun-,-you-agree-to-our")}
									{" "}
									<NavLink to="/terms-and-conditions" className={styles.login_form_link}>
										{t("terms-of-use")}
										{" "}
									</NavLink>
									{t("and")}
									{" "}
									<NavLink to="/privacy-policy" className={styles.login_form_link}>
										{t("privacy-policy")}
										{" "}
									</NavLink>
								</div>
							</Checkbox>
						</span>
					</Form.Item>
					<Form.Item
						className={styles.auth_from_item_remember}
					>
						<div className={registrationStatus ? `${styles.auth_error} ${styles.reg_error}` : `${styles.auth_error} ${styles.auth_error_hide}`}>{registrationStatus}</div>
					</Form.Item>
					<Form.Item style={{ margin: "0px" }}>
						<Button type="primary" htmlType="submit" className={styles.register_form_button} disabled={buttonDisabled}>
							{t("sign-up")}
						</Button>
					</Form.Item>
				</Form>
			</div>
			<div className={styles.auth_image} />
		</div>

	)
}