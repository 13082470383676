import React, { useEffect, useRef, useState } from "react"
import { socket } from "app/socket"
import { store } from "app/redux/store"
import { updateUser } from "app/redux/user"
import { useSelector } from "react-redux"
import { useTranslation } from "react-i18next"
import { CALL_ENDED, CALL_CANCELED } from "app/navigation/main"
import { SET_AUDIO } from "../../create-video-chat/components/nav-btn"
import {
	CallCanceledInfo,
	CallDisconnectedInfo,
	CallEndedInfo,
	IsPublishSupported,
	MediaDeviceError
} from "./notification"
import { ModalVideoCall as ModalVideoCallBase } from "../modal-video-call"

export const ModalVideoCall = (props) => {
	const {
		isShowModalVideoChat,
		setIsShowModalVideoChat,
		selectedConnections,
		mode
	} = props

	const { user } = useSelector(s => s)
	const { currentUser } = user

	const [receivingCall, setReceivingCall] = useState(false)
	const [stream, setStream] = useState()
	const [callAccepted, setCallAccepted] = useState(false)
	const [callEnded, setCallEnded] = useState(false)
	const [callBtnDisabled, setCallBtnDisabled] = useState(false)
	const [videoTrecksEnabled, setVideoTrecksEnabled] = useState(true)
	const [videoDisabled, setVideoDisabled] = useState(false)
	const [videoCameraUser, setVideoCameraUser] = useState(true)
	const [peers, setPeers] = useState()
	const [loader, setLoader] = useState(false)

	const { t } = useTranslation()

	const myVideo = useRef()
	const userVideo = useRef()
	const isInitiator = useRef()
	const isIceRestartCurrent = useRef()
	const currentMode = useRef(mode)

	const offerAnswerOptions = {
		offerToReceiveAudio: true,
		offerToReceiveVideo: true
	}

	const personName = `${selectedConnections?.firstName || currentUser.callUser.name} ${selectedConnections?.lastName || " "}`
	const name = selectedConnections?.firstName || currentUser.callUser?.name

	const createRoom = () => {
		const room = currentUser.callUser?.from || currentUser._id
		socket.emit("CREATE_ROOM", room)
	}

	useEffect(() => {
		createRoom()
		IsPublishSupported()
		const onSuccess = (stream, mediaOptions) => {
			if (mode === SET_AUDIO && mediaOptions.video) {
				stream.getVideoTracks()[0].enabled = !stream.getVideoTracks()[0].enabled
				setVideoTrecksEnabled(stream.getVideoTracks()[0].enabled)
				setVideoCameraUser(false)
			}
			if (!mediaOptions.video) {
				setVideoDisabled(true)
				setVideoTrecksEnabled(false)
				setVideoCameraUser(false)
				currentMode.current = SET_AUDIO
				socket.emit("CAMERA_ENABLED_REPORT", false)
			}
			setStream(stream)
			myVideo.current.srcObject = stream
		}
		const getUserMedia = async (mediaOptions) => {
			try {
				if (currentUser.callUser && !selectedConnections?.firstName) {
					setReceivingCall(true)
				}
				await navigator.mediaDevices
					.getUserMedia(mediaOptions)
					.then(stream => {
						onSuccess(stream, mediaOptions)
					})
					.catch(error => {
						if (mediaOptions.video) {
							getUserMedia({ audio: true, video: false })
						} else {
							console.log(error)
							MediaDeviceError(t)
							setIsShowModalVideoChat(false)
						}
					})
			} catch (err) {
				console.log(err)
			}
		}
		getUserMedia({ audio: true, video: true })
		return () => {
			if (currentUser.callUser) {
				store.dispatch(updateUser({
					...currentUser,
					callUser: {}
				}))
			}
		}
	}, [])

	const close = (curretPeer) => {
		const peer = peers || curretPeer
		if (peer) {
			console.log("Destroying peer connection")

			peer.removeEventListener("negotiationneeded", _onNegotiationNeeded)
			peer.removeEventListener("track", _onTrack)
			peer.removeEventListener("icecandidate", _onIceCandidate)
			peer.removeEventListener("icecandidateerror", _onIceCandidateError)
			peer.removeEventListener("iceconnectionstatechange", _iceConnectionStateChange)
			peer.removeEventListener("connectionstatechange", _onConnectionStateChange)

			peer.close()
		}
	}

	const leaveCall = (curretPeer) => {
		close(curretPeer)
		socket.emit("LEAVE_ROOM")
		setCallEnded(true)
		setReceivingCall(false)
		setCallAccepted(false)
		stream?.getTracks()?.forEach(track => {
			track.stop()
		})
		setIsShowModalVideoChat(false)
	}

	const createOffer = async (peer, isIceRestart) => {
		isIceRestartCurrent.current = isIceRestart
		const offer = await peer.createOffer({ iceRestart: isIceRestart, ...offerAnswerOptions })
		await peer.setLocalDescription(offer)
	}

	const createAnswer = async (peer) => {
		await peer.setRemoteDescription(currentUser.callUser.signal)
		const answer = await peer.createAnswer(offerAnswerOptions)
		await peer.setLocalDescription(answer)
	}

	const sendOffer = (offer) => {
		socket.emit("CALL_USER", {
			userToCall: selectedConnections?._id,
			signalData: offer,
			from: currentUser._id.toString(),
			name: `${currentUser.firstName}  ${currentUser.lastName}`,
			modeData: currentMode.current,
			callBack: isIceRestartCurrent.current
		})
	}

	const sendAnswer = (answer) => {
		socket.emit("ANSWER_CALL", {
			signal: answer,
			to: currentUser.callUser.from
		})
	}

	const restart = (peer) => {
		console.log("Peer restart")
		createOffer(peer, true)
	}

	const _onNegotiationNeeded = async (e) => {
		const curretPeer = e.currentTarget
		if (curretPeer.signalingState !== "stable") { return }

		if (isInitiator.current) {
			await createOffer(curretPeer, false)
		} else {
			await createAnswer(curretPeer)
		}
	}

	const _onTrack = ({ streams }) => {
		const [stream] = streams
		if (!userVideo.current.srcObject) {
			userVideo.current.srcObject = stream
		}
	}

	const _onIceCandidate = (iceEvent) => {
		if (!iceEvent.candidate) {
			const localDescription = iceEvent.currentTarget.localDescription.toJSON()
			if (isInitiator.current) {
				sendOffer(localDescription)
			} else {
				sendAnswer(localDescription)
			}
		}
	}

	const _onIceCandidateError = (e) => {
		if (e.errorCode === 701) {
			console.log(e.url, e.errorText)
		}
	}

	const _iceConnectionStateChange = (e) => {
		const curretPeer = e.currentTarget
		if (curretPeer.iceConnectionState === "connected") {
			setLoader(false)
		} else {
			setLoader(true)
		}
	}

	const _onConnectionStateChange = (e) => {
		const curretPeer = e.currentTarget
		switch (curretPeer.connectionState) {
			case "new":
			case "checking":
				console.log("Connecting...")
				break
			case "connected":
				console.log("Online")
				break
			case "disconnected":
				console.log("Disconnecting...")
				if (curretPeer.sctp?.state === "closed") {
					CallDisconnectedInfo(t)
					leaveCall(curretPeer)
				}
				break
			case "closed":
				console.log("Offline")
				break
			case "failed":
				console.log("Error")
				if (isInitiator.current) {
					restart(curretPeer)
				}
				break
			default:
				console.log("Unknown")
				break
		}
	}

	const _buildPeer = () => {
		const peer = new RTCPeerConnection({
			iceServers: [
				{
					urls: ["stun:stun.l.google.com:19302?transport=udp", "stun:stun1.l.google.com:19302?transport=udp"]
				}
			]
		})

		stream.getTracks().forEach(track => peer.addTrack(
			track,
			stream
		))

		peer.createDataChannel("videochat", { negotiated: true, id: 0 })

		// peer.addEventListener("negotiationneeded", _onNegotiationNeeded)

		if (peer.signalingState !== "stable") { return }

		if (isInitiator.current) {
			createOffer(peer, false)
		} else {
			createAnswer(peer)
		}

		peer.addEventListener("track", _onTrack)

		peer.addEventListener("icecandidate", _onIceCandidate)

		peer.addEventListener("icecandidateerror", _onIceCandidateError)

		peer.addEventListener("iceconnectionstatechange", _iceConnectionStateChange)

		peer.addEventListener("connectionstatechange", _onConnectionStateChange)

		return peer
	}

	const RemDescription = async (signal) => {
		setCallAccepted(true)

		socket.emit("CAMERA_ENABLED_REPORT", videoCameraUser)

		if (peers.signalingState !== "stable") {
			await peers.setRemoteDescription(signal).catch(err => console.log(err))
		}
	}

	const callUser = () => {
		isInitiator.current = true
		setPeers(_buildPeer())
		setCallBtnDisabled(true)
	}

	const answerCall = () => {
		isInitiator.current = false
		setPeers(_buildPeer())
		setCallAccepted(true)
	}

	const CameraBtnDisabled = () => {
		stream.getVideoTracks()[0].enabled = !stream.getVideoTracks()[0].enabled
		setVideoTrecksEnabled(stream.getVideoTracks()[0].enabled)
		socket.emit("CAMERA_ENABLED_REPORT", stream.getVideoTracks()[0].enabled)
	}

	useEffect(() => {
		socket.on("CALL_ACCEPTED", (signal) => {
			RemDescription(signal)
		})
		socket.on("RECONNECT_TO_THE_ROOM", () => {
			createRoom()
		})
		socket.on("CALL_CANCELED_ENDED", (type) => {
			if (type === CALL_ENDED) {
				leaveCall()
				CallEndedInfo(t, personName)
			} else if (type === CALL_CANCELED) {
				CallCanceledInfo(t, personName)
				setCallBtnDisabled(false)
				close()
			}
		})
		socket.on("CAMERA_ENABLED_REPORT", (data) => {
			setVideoCameraUser(data)
		})
		if (currentUser.callUser?.callBack && peers) {
			const isIceRestart = currentUser.callUser?.callBack
			createAnswer(peers, isIceRestart)
		}
		return () => {
			socket.removeListener("CALL_CANCELED_ENDED")
			socket.removeListener("CALL_ACCEPTED")
			socket.removeListener("CAMERA_ENABLED_REPORT")
			socket.removeListener("RECONNECT_TO_THE_ROOM")
		}
	}, [stream, peers, currentUser])

	return (
		<ModalVideoCallBase
			isShowModalVideoChat={isShowModalVideoChat}
			personName={personName}
			receivingCall={receivingCall}
			name={name}
			answerCall={answerCall}
			callAccepted={callAccepted}
			leaveCall={leaveCall}
			callEnded={callEnded}
			selectedConnections={selectedConnections}
			callBtnDisabled={callBtnDisabled}
			callUser={callUser}
			currentUser={currentUser}
			myVideo={myVideo}
			userVideo={userVideo}
			stream={stream}
			loader={loader}
			videoDisabled={videoDisabled}
			videoTrecksEnabled={videoTrecksEnabled}
			videoCameraUser={videoCameraUser}
			CameraBtnDisabled={CameraBtnDisabled}
		/>
	)
}