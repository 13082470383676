import React from "react"
import { InputsContainer } from "./inputs-container"
import { UserPreviewAvatar } from "./user-preview-avatar"

export const UserPreview = (props) => {
	const {
		form,
		setCurrentUserLocation,
		avatarChange,
		personalInfoAvatar,
		setPersonalInfoAvatar
	} = props

	return (
		<>
			<UserPreviewAvatar
				avatarChange={avatarChange}
				personalInfoAvatar={personalInfoAvatar}
				setPersonalInfoAvatar={setPersonalInfoAvatar}
			/>
			<InputsContainer
				form={form}
				setCurrentUserLocation={setCurrentUserLocation}
			/>
		</>
	)
}
