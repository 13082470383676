import React from "react"
import { getImageUri } from "shared/utils"
import { Avatar } from "antd"
import { UserOutlined } from "@ant-design/icons"

export const AvatarCom = (props) => {
	const {
		avatar,
		personName
	} = props
	return (
		<div className="video-avatar">
			{avatar && (
				<div
					className="video-avatar-bgc-img"
					style={{
						background: `url(${avatar})`,
						backgroundPosition: "center",
						backgroundSize: "cover",
						backgroundRepeat: "no-repeat"
					}}
				/>
			)}
			<Avatar
				style={{
					backgroundColor: "#343438",
					display: "flex",
					alignItems: "center",
					justifyContent: "center"
				}}
				size={128}
				src={
					!avatar ? (
						<UserOutlined
							style={{
								fontSize: "80px",
								width: "100%",
								height: "100%",
								display: "flex",
								alignItems: "center",
								justifyContent: "center",
								color: "#6d6f73"
							}}
						/>
					) : (
						getImageUri(avatar)
					)
				}
			/>
			<div className="video-avatar-name">
				{personName}
			</div>
		</div>
	)
}