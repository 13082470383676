import React from "react"
import { Popover, message } from "antd"
import { socket } from "app/socket"
import { useTranslation } from "react-i18next"
import { MoreIcon } from "shared/icons"
import { getUsersByIds } from "shared/utils"
import { MoreButtons } from "./more-buttons/more-buttons"
import styles from "./message-buttons.module.css"

export const ButtonsMessageContent = ({
	chat,
	visiblePopover,
	setVisiblePopover,
	currentUser,
	setCurrentUserConnections,
	setIsShowModal,
	setIsShowDeleteModal,
	setSelectedChat,
	setIsShowParticipiantsModal
}) => {
	const { t } = useTranslation()

	return (
		<div
			className={styles.messages_buttons}
		>
			<div className={styles.content_buttons}>
				<button
					className={styles.add_human_button}
					type="button"
					onClick={async () => {
						const newUserCha = []
						const usersToSelect = [...currentUser.friends, ...currentUser.family]
						usersToSelect
							.filter((item, index) => usersToSelect.indexOf(item) === index)
							.forEach((user) => {
								if (
									typeof chat.users.find((u) => u._id === user) === "undefined"
								) {
									newUserCha.push(user)
								}
							})
						const usersResponse = await getUsersByIds([...newUserCha])
						if (usersResponse.status === "successful") {
							setCurrentUserConnections(usersResponse.users)
							setIsShowModal(true)
						}
						setVisiblePopover(false)
					}}
				>
					{t("add-members")}
				</button>
				{chat.roomName && chat.users.length > 1 && (
					<button
						type="button"
						className={`${styles.remove_human_button} ${styles.show_members}`}
						onClick={() => {
							setIsShowParticipiantsModal(true)
							setVisiblePopover(false)
						}}
					>
						{t("show-members")}
					</button>
				)}
				<button
					type="button"
					className={styles.remove_human_button}
					onClick={async () => {
						socket.emit("LEAVE_CHAT", {
							roomId: chat._id,
							userId: currentUser._id,
							text: "LEAVED_CHAT"
						})
						setVisiblePopover(false)
						setSelectedChat(null)
					}}
				>
					{t("leave-chat")}
				</button>
				{chat.roomName && chat.users.length > 1 && (
					<button
						type="button"
						className={styles.remove_human_button}
						onClick={() => {
							if (chat?.chatAdmin && chat?.chatAdmin === currentUser._id) {
								setIsShowDeleteModal(true)
								setVisiblePopover(false)
							} else {
								message.error(t("only-chat-admin-can-delete"))
							}
						}}
					>
						{t("delete-member")}
					</button>
				)}
			</div>
			<div className={styles.popover_content}>
				<Popover
					placement="bottomRight"
					content={(
						<MoreButtons
							chat={chat}
							currentUser={currentUser}
							setCurrentUserConnections={setCurrentUserConnections}
							setIsShowModal={setIsShowModal}
							setVisiblePopover={setVisiblePopover}
							setIsShowDeleteModal={setIsShowDeleteModal}
							setIsShowParticipiantsModal={setIsShowParticipiantsModal}
							setSelectedChat={setSelectedChat}
						/>
					)}
					trigger="click"
					visible={visiblePopover}
				>
					<button
						className={styles.more_button}
						style={{ fontSize: "24px" }}
						type="button"
						onClick={async () => {
							setVisiblePopover(!visiblePopover)
						}}
					>
						<MoreIcon />
					</button>
				</Popover>
			</div>
		</div>
	)
}
