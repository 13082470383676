import React, { useState } from "react"
import { useTranslation } from "react-i18next"
import {
	Input, Button, message
} from "antd"
import { sendInvite } from "modules/search-people/api"
import styles from "./search-people-components.module.css"

export const EmailInvite = ({ emptyScreen }) => {
	const { t } = useTranslation()
	const [email, setEmail] = useState("")
	const regexp = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
	const validateEmail = (email) => regexp.test(email)
	return (
		<>
			<span>
				{" "}
				{emptyScreen ? t("do-you-want-to-send-invite-to-life-twig-via-email") : t("or-you-can-send-invite-to-life-twig-via-email")}
			</span>
			<div className={styles.invite_panel}>
				<Input
					className={styles.input_field_email}
					placeholder={t("email-adress")}
					value={email}
					onChange={(e) => setEmail(e.target.value)}
				/>
				<Button
					className={styles.confirm_button}
					onClick={async () => {
						if (validateEmail(email)) {
							const response = await sendInvite(email)
							if (response.status === "OK") {
								if (response.message === "invite sended") {
									message.success(t("invite-has-been-sent"))
								}
								if (response.message === "invite already sent") {
									message.error(t("you-already-ivite-this-user"))
								}
							}
							if (response.status === "error") {
								message.error(t("something-went-wrong"))
							}
						} else { message.error(`${t("invalid-email-address")}`) }
					}}
				>
					{t("invite")}
				</Button>
			</div>
		</>
	)
}