/* eslint-disable no-mixed-operators */
import React from "react"
import { Image } from "antd"
import { UserOutlined } from "@ant-design/icons"
import { getImageUri } from "shared/utils"
import {
	CityIcon, LocationIcon, BirthDateIcon, PhoneIcon, EmailIcon
} from "shared/icons"
import { NavLink } from "react-router-dom"
import moment from "moment"
import { useTranslation } from "react-i18next"
import styles from "./user-profile-header-card.module.css"

export const UserProfileHeaderCard = ({ currentUser, posts }) => {
	const userConnections = []
	currentUser.friends.forEach(friend => userConnections.push(friend))
	currentUser.family.forEach(parent => {
		if (!userConnections.includes(parent)) {
			userConnections.push(parent)
		}
	})
	const { t } = useTranslation()

	return (
		<div className={styles.profile_header_card}>
			<div className={styles.profile_header_card_main}>
				<div className={styles.profile_avatar}>
					{currentUser.avatar
						? (
							<>
								<Image
									width={120}
									height={120}
									src={getImageUri(currentUser.avatar)}
									onError={({ currentTarget }) => {
										currentTarget.onerror = null
										currentTarget.src = getImageUri("./user.png")
									}}
									className={styles.profile_avatar_img}
								/>
							</>
						)
						: (
							<div className={styles.profile_avatar_img}>
								<UserOutlined style={{
									width: "120px", height: "120px", fontSize: "120px", color: "#fff"
								}}
								/>
							</div>
						)}
				</div>
				<div className={styles.profile_main_info}>
					<div title={`${currentUser.firstName} ${currentUser.lastName} ${currentUser.maidenName}`} className={styles.profile_name}>
						{currentUser.firstName}
						{" "}
						{currentUser.lastName}
						{" "}
						{currentUser.maidenName && currentUser.maidenName !== "" ? `(${currentUser.maidenName})` : ""}
					</div>
					{currentUser.phoneNumber && (
						<div className={styles.profile_about}>
							<PhoneIcon />
							<span>
								{currentUser.phoneNumber}
							</span>
						</div>
					)}
					{currentUser.email && (
						<div className={styles.profile_about}>
							<EmailIcon />
							<span>
								{currentUser.email}
							</span>
						</div>
					)}
					{currentUser.birthDate && (
						<div className={styles.profile_about}>
							<BirthDateIcon />
							<span>
								{t("born in")}
								{" "}
								<b>{moment(currentUser.birthDate, "DD/MM/YYYY").format("LL")}</b>
							</span>
						</div>
					)}
					{currentUser.birthPlace && (
						<div className={styles.profile_about}>
							<LocationIcon />
							<span>
								{t("from")}
								{" "}
								<b>{currentUser.birthPlace}</b>
							</span>
						</div>
					)}
					{currentUser.location.name && (
						<div className={styles.profile_about}>
							<CityIcon />
							<span>
								{t("lives-in")}
								{" "}
								<b>{currentUser.location.name}</b>
							</span>
						</div>
					)}
				</div>
			</div>
			<div className={styles.profile_header_card_footer}>
				<NavLink to="/user-profile/feed" className={styles.profile_footer_link} activeClassName={styles.profile_footer_link_active}>
					{t("posts")}
					<span>
						{posts < 10000 ? posts
							: `${posts.toString().slice(0, -3)}K`}
					</span>
				</NavLink>
				<NavLink
					to="/user-profile/connections"
					className={styles.profile_footer_link}
					activeClassName={styles.profile_footer_link_active}
					title={`Friends: ${currentUser.friends.length} Relatives: ${currentUser.family.length}`}
				>
					{t("connections")}
					<span>
						{userConnections.length}
					</span>
				</NavLink>
			</div>
		</div>
	)
}