import React from "react"
import { useDispatch, useSelector } from "react-redux"
import { setZoom } from "modules/family-tree/ducks"
import { ZoomOutIcon, ZoomInIcon, ResetIcon } from "shared/icons"
import styles from "./profile-tree-zoom-pan.module.css"

export const ProfileTreeZoomPan = () => {
	const { zoom } = useSelector(s => s.familyTree)
	const dispatch = useDispatch()

	const zoomIn = () => {
		window.diagramInstance.zoomTo({ type: "ZoomIn", zoomFactor: 0.2 })
		dispatch(setZoom(window.diagramInstance.scroller.currentZoom))
	}

	const zoomOut = () => {
		window.diagramInstance.zoomTo({ type: "ZoomOut", zoomFactor: 0.2 })
		dispatch(setZoom(window.diagramInstance.scroller.currentZoom))
	}

	const reset = () => {
		window.diagramInstance.reset()
		window.diagramInstance.fitToPage()
		dispatch(setZoom(1))
	}

	return (
		<div className={styles.zoom_panel}>
			<div className={styles.zoom_panel_loupes}>
				<button
					type="button"
					onClick={zoomOut}
					title="Zoom Out [Ctrl + Scroll Down]"
				>
					<ZoomOutIcon />
				</button>
				<span>{`${Math.round(zoom * 100)}%`}</span>
				<button
					type="button"
					onClick={zoomIn}
					title="Zoom In [Ctrl + Scroll Up]"
				>
					<ZoomInIcon />
				</button>
			</div>
			<div className={styles.zoom_panel_reset}>
				<button
					type="button"
					onClick={reset}
					title="Reset Viewport"
				>
					<ResetIcon />
				</button>
			</div>
		</div>
	)
}