import React, { useState } from "react"
import {
	Form, Input, Button, message
} from "antd"
import { NavLink, useHistory } from "react-router-dom"
import { AppLogoIcon } from "shared/logo-icon"
import { useTranslation } from "react-i18next"
import { findUserByEmail, resendCodeToEmail } from "./api"
import "antd/dist/antd.css"
import styles from "./auth.module.css"

export const RestorePassword = () => {
	const { t } = useTranslation()

	const emailRegexp = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
	const validateEmail = (email) => emailRegexp.test(email)
	const [disabled, setDisabled] = useState(true)

	const history = useHistory()
	const onFinish = async (values) => {
		const status = await findUserByEmail(values.email)
		if (status.status === "OK") {
			await resendCodeToEmail(values.email)
			history.push({ pathname: "/restore-password-confirm", email: values.email })
		} else if (status.status === "Not Allowed") {
			setDisabled(true)
			message.error(status.message)
		} else {
			message.error(status.message)
		}
	}
	return (
		<div className={styles.wrap}>
			<div className={styles.auth_container}>
				<div className={styles.auth_logo}><AppLogoIcon /></div>
				<Form
					name="restore-password"
					className={styles.register_form}
					onFinish={onFinish}
				>
					<div className={styles.restore_form_header}>{t("restore-password")}</div>

					<Form.Item
						className={styles.auth_from_item_input}
						name="email"
						rules={[
							{
								type: "email",
								message: `${t("invalid-email-address")}`
							},
							{
								required: true,
								message: `${t("please-enter-your-email-address")}`
							}
						]}
					>
						<Input
							placeholder={t("email-adress")}
							className={styles.auth_input}
							onChange={(e) => {
								if (validateEmail(e.target.value)) {
									setDisabled(false)
								} else {
									setDisabled(true)
								}
							}}
						/>
					</Form.Item>
					<div className={styles.restore_text}>
						{t("enter-your-email-address-to-send-confirmation-code.")}
					</div>
					<Form.Item style={{ margin: "0px" }}>
						<Button
							type="primary"
							htmlType="submit"
							className={styles.login_form_button}
							disabled={disabled}
						>
							{t("SEND")}
						</Button>
					</Form.Item>
					<div className={styles.register_form_login}>
						<NavLink to="/login" className={styles.login_form_link}>
							{t("go-back-to-login-page")}
						</NavLink>
					</div>
				</Form>
			</div>
			<div className={styles.auth_image} />
		</div>
	)
}