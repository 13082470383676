/* eslint-disable no-nested-ternary */
import React,
{
	useState,
	useEffect,
	useRef,
	memo
} from "react"
import {
	message
} from "antd"
import { useSelector } from "react-redux"
import { FullScreenImage } from "shared/full-screen-image"
import { useTranslation } from "react-i18next"
import "emoji-mart/css/emoji-mart.css"
import { UsersListModal } from "shared/users-list-modal"
import { VIDEO_MAXIMUM_SIZE } from "shared/constants"
import { FeedComments } from "./components/comments"
import { PostHeader } from "./components/post-header"
import { PostFooter } from "./components/post-footer"
import { PostMainContent } from "./components/post-main-content"
import styles from "./feed-post.module.css"

export const FeedPost = memo(({
	id,
	post,
	personalFeedType,
	profile,
	allPosts,
	setAllPosts
}) => {
	const inputVideo = useRef()
	const { t } = useTranslation()
	const { user } = useSelector(s => s)
	const { currentUser } = user
	const postAuthor = post.user
	const [toggle, setToggle] = useState(false)
	const [visiblePopover, setVisiblePopover] = useState(false)
	const [isEditMode, setIsEditMode] = useState(false)
	const [isShowComments, setIsShowComments] = useState(false)
	const [inputValue, setInputValue] = useState("")
	const [images, setImages] = useState(post.images)
	const [files, setFiles] = useState(post.files)
	const [newImages, setNewImages] = useState([])
	const [commentValue, setCommentValue] = useState("")
	const [isShowLikeList, setIsShowLikeList] = useState(false)
	const [isShowSharesList, setIsShowSharesList] = useState(false)
	const [visibleCloseCom, setVisibleCloseCom] = useState(false)
	const [activeInput, setActiveInput] = useState(false)
	const [isActive, setIsActive] = useState(false)
	const [selectedPhoto, setSelectedPhoto] = useState(null)
	const [allPhotos, setAllPhotos] = useState([])
	const [videos, setVideos] = useState(post.videos)
	const [fileName, setFileName] = useState([])
	const [formData, setFormData] = useState(new FormData())
	const [videoFiles, setVideoFiles] = useState([])
	const [imageFiles, setImageFiles] = useState([])
	const [oldImages, setOldImages] = useState([])
	const [postLikes, setPostsLikes] = useState(post.likes || [])
	const [postShares, setPostShares] = useState(post.shares || [])
	const [postComments, setPostComments] = useState(post.comments || [])
	const [videosToView, setVideosToView] = useState(post.videos)
	const [postText, setPostText] = useState(post.text)

	useEffect(() => {
		const { images } = post
		const { videos } = post
		setOldImages(images)
		setImages(images)
		setVideos(videos)
	}, [post])

	const getVideoDuration = (file) => new Promise(resolve => {
		const video = document.createElement("video")
		video.onloadedmetadata = () => resolve(video.duration)
		video.src = URL?.createObjectURL(file)
	})

	const addVideo = async (e) => {
		if (e.target.files[0].size > VIDEO_MAXIMUM_SIZE) {
			message.error(t("video-size-exceeded"))
		} else if (fileName.length + images.length + videos.length === 5) {
			message.error(`${t("maximum-number-of-files-reached")}`)
		} else if (fileName.includes(e.target.files[0].name)) {
			message.error(`${t("video-already-added")}`)
		} else if (e.target.files.length) {
			const duration = await getVideoDuration(e.target.files[0])
			setFileName([...fileName, e.target.files[0].name])
			setVideoFiles([...videoFiles, e.target.files[0]])
			formData.append(`duration${e.target.files[0].name}`, duration)
		}
	}

	return (
		<>
			<input
				ref={inputVideo}
				type="file"
				name="file"
				accept="video/*"
				onChange={addVideo}
			/>
			<div
				id={id}
				className={personalFeedType ? styles.personal_post : styles.post}
				onMouseLeave={() => {
					setVisiblePopover(false)
					setToggle(false)
				}}
			>
				<PostHeader
					postAuthor={postAuthor}
					post={post}
					isEditMode={isEditMode}
					setInputValue={setInputValue}
					setImages={setImages}
					setNewImages={setNewImages}
					setFiles={setFiles}
					setIsEditMode={setIsEditMode}
					setVisiblePopover={setVisiblePopover}
					visiblePopover={visiblePopover}
					inputVideo={inputVideo}
					setVideoFiles={setVideoFiles}
					setFormData={setFormData}
					setFileName={setFileName}
					images={images}
					inputValue={inputValue}
					files={files}
					videos={videos}
					videoFiles={videoFiles}
					fileName={fileName}
					formData={formData}
					setOldImages={setOldImages}
					oldImages={oldImages}
					setImageFiles={setImageFiles}
					imageFiles={imageFiles}
					profile={profile}
					allPosts={allPosts}
					setAllPosts={setAllPosts}
					setVideos={setVideos}
					setVideosToView={setVideosToView}
					setPostText={setPostText}
				/>
				<PostMainContent
					isEditMode={isEditMode}
					post={post}
					inputVideo={inputVideo}
					setInputValue={setInputValue}
					inputValue={inputValue}
					postLikes={postLikes}
					setPostsLikes={setPostsLikes}
					postShares={postShares}
					setPostShares={setPostShares}
					setVideoFiles={setVideoFiles}
					newImages={newImages}
					setNewImages={setNewImages}
					images={images}
					fileName={fileName}
					setSelectedPhoto={setSelectedPhoto}
					formData={formData}
					setFileName={setFileName}
					videoFiles={videoFiles}
					videos={videos}
					setVideos={setVideos}
					setIsActive={setIsActive}
					setAllPhotos={setAllPhotos}
					setIsShowComments={setIsShowComments}
					isShowComments={isShowComments}
					setIsShowSharesList={setIsShowSharesList}
					setIsShowLikeList={setIsShowLikeList}
					setOldImages={setOldImages}
					oldImages={oldImages}
					setImageFiles={setImageFiles}
					imageFiles={imageFiles}
					profile={profile}
					postComments={postComments}
					videosToView={videosToView}
					postText={postText}
				/>
				{
					isShowComments && postComments.length > 0 && (
						<FeedComments
							post={post}
							postComments={postComments}
							user={currentUser}
							setPostComments={setPostComments}
						/>
					)
				}
				<PostFooter
					setActiveInput={setActiveInput}
					activeInput={activeInput}
					commentValue={commentValue}
					setCommentValue={setCommentValue}
					setVisibleCloseCom={setVisibleCloseCom}
					setToggle={setToggle}
					toggle={toggle}
					visibleCloseCom={visibleCloseCom}
					post={post}
					postComments={postComments}
					setPostComments={setPostComments}
				/>
			</div>
			<FullScreenImage
				isActive={isActive}
				selectedPhoto={selectedPhoto}
				allPhotos={allPhotos}
				setIsActive={setIsActive}
				setSelectedPhoto={setSelectedPhoto}
				isPhotoWithComments={false}
			/>
			{isShowLikeList
				&& postLikes.length > 0
				&& (
					<UsersListModal
						isShowList={isShowLikeList}
						setIsShowList={setIsShowLikeList}
						usersIds={postLikes}
						titleName="likes"
					/>
				)}
			{isShowSharesList
				&& postShares.length > 0
				&& (
					<UsersListModal
						isShowList={isShowSharesList}
						setIsShowList={setIsShowSharesList}
						usersIds={postShares}
						titleName="shares"
					/>
				)}
		</>
	)
})