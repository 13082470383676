import React from "react"
import { UserOutlined } from "@ant-design/icons"
import { getImageUri } from "shared/utils"
import styles from "./user-avatar.module.css"

export const UserAvatar = ({ user, style }) => (
	<div className={style ? `${styles.user_avatar_big}` : `${styles.user_avatar}`}>
		{user.avatar ? (
			<img
				src={getImageUri(user.avatar)}
				onError={({ currentTarget }) => {
					currentTarget.onerror = null
					currentTarget.src = getImageUri("./user.png")
				}}
				alt="avatar"
			/>
		) : (
			<UserOutlined style={style || {
				width: "32px", height: "32px", fontSize: "30px", color: "#fff"
			}}
			/>
		)}
		{user.isOnline && (<div className={style ? `${styles.user_avatar_online_big}` : `${styles.user_avatar_online}`} />)}
	</div>
)