export const dropdownElementsData = [
  {
    labelText: "friends-and-relatives-list",
    labelDescription: "who-can-see-my-friends-and-relatives-list"
  },
  {
    labelText: "feed",
    labelDescription: "notify-when-feed-updates"
  },
  {
    labelText: "family-tree",
    labelDescription: "who-can-see-my-family-tree"
  },
  {
    labelText: "about-information",
    labelDescription: "who-can-see-my-about-information"
  },
  {
    labelText: "location",
    labelDescription: "who-can-see-my-location-on-map"
  },
  {
    labelText: "photos",
    labelDescription: "who-can-see-my-photos"
  },
  {
    labelText: "videos",
    labelDescription: "who-can-see-my-videos"
  }
]

export const dropDownOptions = ["public", "only-me", "relatives-only", "friends-only", "friends-and-relatives"]

export const switchElementsData = [
  {
    labelText: "password-change",
    labelDescription: "notify-about-password-change-via-email"
  },
  {
    labelText: "connection-accepted",
    labelDescription: "notify-when-connection-request-accepted"
  },
  {
    labelText: "birthdays",
    labelDescription: "notify-about-upcoming-friends-/-relatives-birthdays"
  },
  {
    labelText: "likes-comments-and-shares",
    labelDescription: "notify-about-my-posts-likes-comments-and-shares"
  }
]

export const linksPaths = [
  {
    path: "/settings/privacy",
    label: "privacy"
  },
  {
    path: "/settings/security",
    label: "security"
  },
  {
    path: "/settings/notifications",
    label: "notifications"
  }
]