export const appleRegistration = async (appleId, firstName, lastName) => {
  const data = {
    appleId,
    firstName,
    lastName
  }
  try {
    const res = await fetch("/api/apple/registration", {
      method: "POST",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify(data)
    })
    const json = await res.json()
    return json
  } catch (error) {
    console.error("Error:", error)
  }
  return true
}