import React from "react"
import moment from "moment"
import ReactPlayer from "react-player"
import { getImageUri } from "shared/utils"
import { baseUri } from "shared/constants"
import styles from "./header.module.css"

export const birthday = (notification, t) => (
	<div>
		<span className={styles.popover_element_text}>
			{moment(moment.tz(notification.user.birthDate, moment.tz.guess()), "DD/MM").calendar(null, {
				sameDay: "DD.MM",
				nextDay: "DD.MM",
				lastDay: "DD.MM",
				sameElse: "DD.MM"
			})}
		</span>
		{" "}
		{notification?.user.firstName}
		{" "}
		{notification?.user.lastName}
		{" "}
		<span className={styles.popover_element_text}>
			{t("birthday")}
		</span>
	</div>
)

export const friendRequestAccept = (notification, t) => (
	<div>
		<span className={styles.popover_element_text}>
			{t("friend-request")}
		</span>
		{" "}
		{notification?.user.firstName}
		{" "}
		{notification?.user.lastName}
		{" "}
		<span className={styles.popover_element_text} style={{ color: "#00875A" }}>
			{t("accepted")}
		</span>
	</div>
)

export const friendRequestDecline = (notification, t) => (
	<div>
		<span className={styles.popover_element_text}>
			{t("friend-request")}
		</span>
		{" "}
		{notification?.user.firstName}
		{" "}
		{notification?.user.lastName}
		{" "}
		<span className={styles.popover_element_text} style={{ color: "red" }}>
			{t("declined")}
		</span>
	</div>
)

export const familyRequestAccept = (notification, t) => (
	<div>
		<span className={styles.popover_element_text}>
			{t("family-request")}
		</span>
		{" "}
		{notification?.user.firstName}
		{" "}
		{notification?.user.lastName}
		{" "}
		<span className={styles.popover_element_text} style={{ color: "#00875A" }}>
			{t("accepted")}
		</span>
	</div>
)

export const familyRequestDecline = (notification, t) => (
	<div>
		<span className={styles.popover_element_text}>
			{t("family-request")}
		</span>
		{" "}
		{notification?.user.firstName}
		{" "}
		{notification?.user.lastName}
		{" "}
		<span className={styles.popover_element_text} style={{ color: "red" }}>
			{t("declined")}
		</span>
	</div>
)

export const comment = (notification, t, history) => {
	const postText = notification?.post?.text?.split(" ")
	const commentText = notification?.text?.split(" ")
	return (
		<section
			role="button"
			tabIndex={0}
			onClick={() => {
				history.push({ pathname: "/feed", selectedPostId: notification.postId })
			}}
			style={{ cursor: "pointer" }}
		>
			<div>
				{notification?.user.firstName}
				{" "}
				{notification?.user.lastName}
				{" "}
				<span className={styles.popover_element_text}>
					{t("left-a-comment")}
					{" "}
				</span>
				<span className={styles.popover_element_text}>
					{`"${commentText[0]} ${commentText[1] ? `${commentText[1]}` : ""} ${commentText[2] ? `${commentText[2]}` : ""}"`}
				</span>
				<span className={styles.popover_element_text}>
					{" "}
					{t("to-your-post")}
					{notification?.mentionedUser && ` ${t("and")} ${notification?.user.gender === "female" ? t("mentioned female") : t("mentioned male")}`}
					:
				</span>
			</div>
			<div className={styles.popover_element_minipost}>
				{notification?.post.text && (
					<div className={styles.popover_element_minipost_text}>
						{postText[0]}
						{" "}
						{postText[1]}
						{" "}
						{postText[2]}
						...
					</div>
				)}
				{notification?.post.images.length > 0 && (
					<div className={styles.popover_element_minipost_image}>
						<img src={notification.post.images[0]} alt="post-img" />
					</div>
				)}
				{notification?.post?.videos.length > 0 && (
					<div className={styles.popover_element_minipost_video}>
						<ReactPlayer
							width="100%"
							height="100%"
							url={`${baseUri}/${notification?.post?.videos[notification?.post?.videos.length - 1].path}`}
						/>
					</div>
				)}
			</div>
		</section>
	)
}

export const commentMedia = (notification, t) => {
	const commentText = notification?.text?.split(" ")
	return (
		<>
			<div>
				{" "}
				{notification?.user.firstName}
				{" "}
				{notification?.user.lastName}
				{" "}
				<span className={styles.popover_element_text}>
					{t("left-a-comment")}
					{" "}
				</span>
				<span className={styles.popover_element_text}>
					{`"${commentText[0]} ${commentText[1] ? `${commentText[1]}` : ""} ${commentText[2] ? `${commentText[2]}` : ""}"`}
				</span>
				<span className={styles.popover_element_text}>
					{" "}
					{notification?.imagePath ? t("to-your-photo") : t("to-your-video")}
					{notification?.mentionedUser && ` ${t("and")} ${notification?.user.gender === "female" ? t("mentioned female") : t("mentioned male")}`}
					:
				</span>
			</div>
			<div className={styles.popover_element_minipost}>
				{notification?.imagePath && (
					<div className={styles.popover_element_minipost_image}>
						<img src={getImageUri(notification.imagePath)} alt="post-img" />
					</div>
				)}
				{notification?.videoPath && (
					<div className={styles.popover_element_minipost_image}>
						<ReactPlayer
							width="100%"
							height="100%"
							url={`${baseUri}/${notification.videoPath}`}
						/>
					</div>
				)}
			</div>
		</>
	)
}

export const commentAlbum = (notification, t) => {
	const postText = notification?.name?.split(" ")
	const commentText = notification?.text?.split(" ")
	return (
		<>
			<div>
				{" "}
				{notification?.user.firstName}
				{" "}
				{notification?.user.lastName}
				{" "}
				<span className={styles.popover_element_text}>
					{t("left-a-comment")}
					{" "}
				</span>
				<span className={styles.popover_element_text}>
					{`"${commentText[0]} ${commentText[1] ? `${commentText[1]}` : ""} ${commentText[2] ? `${commentText[2]}` : ""}"`}
				</span>
				<span className={styles.popover_element_text}>
					{" "}
					{t("to-your-album")}
					{notification?.mentionedUser && ` ${t("and")} ${notification?.user.gender === "female" ? t("mentioned female") : t("mentioned male")}`}
					:
				</span>
			</div>
			<div className={styles.popover_element_minipost}>
				{notification?.name && (
					<div className={styles.popover_element_minipost_text}>
						{postText[0]}
						{" "}
						{postText[1]}
						{" "}
						{postText[2]}
						...
					</div>
				)}
			</div>
		</>
	)
}

export const share = (notification, t, history) => {
	const postText = notification?.post?.text.split(" ")
	return (
		<section
			role="button"
			tabIndex={0}
			onClick={() => {
				history.push({ pathname: "/feed", selectedPostId: notification.postId })
			}}
			style={{ cursor: "pointer" }}
		>
			<div>
				{notification?.user.firstName}
				{" "}
				{notification?.user.lastName}
				{" "}
				<span className={styles.popover_element_text}>
					{notification?.user?.gender === "female" ? t("shared-female") : t("shared-male")}
					{" "}
				</span>
				<span className={styles.popover_element_text}>
					{" "}
					{t("your-post")}
					:
				</span>
			</div>
			<div className={styles.popover_element_minipost}>
				{notification?.post?.text && (
					<div className={styles.popover_element_minipost_text}>
						{postText[0]}
						{" "}
						{postText[1]}
						{" "}
						{postText[2]}
						...
					</div>
				)}
				{notification?.post?.images.length > 0 && (
					<div className={styles.popover_element_minipost_image}>
						<img src={notification.post.images[0]} alt="post-img" />
					</div>
				)}
			</div>
		</section>
	)
}

export const like = (notification, t, history) => {
	const postText = notification?.post?.text.split(" ")
	return (
		<section
			role="button"
			tabIndex={0}
			onClick={() => {
				history.push({ pathname: "/feed", selectedPostId: notification.postId })
			}}
			style={{ cursor: "pointer" }}
		>
			<div>
				{notification?.users[0].firstName}
				{" "}
				{notification?.users[0].lastName}
				{" "}
				{notification?.users.length > 1 && (
					<span className={styles.popover_element_text}>
						{t("and")}
					</span>
				)}
				{" "}
				{notification?.users.length > 1 && (
					<span className={styles.popover_element_text}>
						{t("other-people")}
					</span>
				)}
				{" "}
				{notification?.users.length > 1 && (
					<span className={styles.popover_element_text}>
						{`(${notification?.users.length - 1})`}
					</span>
				)}
				{" "}
				<span className={styles.popover_element_text}>
					{notification?.users?.length > 1 ? t("liked-your-post") : t("liked-your-post-one-person")}
					:
				</span>
			</div>
			<div className={styles.popover_element_minipost}>
				{notification?.post?.text && (
					<div className={styles.popover_element_minipost_text}>
						{postText[0]}
						{" "}
						{postText[1]}
						{" "}
						{postText[2]}
						...
					</div>
				)}
				{notification?.post?.images?.length > 0 && (
					<div className={styles.popover_element_minipost_image}>
						<img src={notification?.post?.images[0]} alt="post-img" />
					</div>
				)}
				{notification?.post?.videos.length > 0 && (
					<div className={styles.popover_element_minipost_video}>
						<ReactPlayer
							width="100%"
							height="100%"
							url={`${baseUri}/${notification?.post?.videos[notification?.post?.videos.length - 1].path}`}
						/>
					</div>
				)}
			</div>
		</section>
	)
}

export const mentions = (notification, t, currentUser) => {
	let postText
	if (notification?.post && notification?.post !== null) {
		postText = notification?.post[0]?.text?.split(" ")
	}
	return (
		<>
			<div>
				{" "}
				{notification?.user.firstName}
				{" "}
				{notification?.user.lastName}
				{" "}
				<span className={styles.popover_element_text}>
					{notification?.user.gender === "female" ? t("mentioned female") : t("mentioned male")}
					:
					{" "}
				</span>
			</div>
			<div className={styles.popover_element_text}>
				{currentUser._id === notification.ownerId ? `${t("in-the")} ${t("your")} ` : `${t("in-the")} ${notification.ownerFirstName} ${notification.ownerLastName} `}
				{notification.albumName && (
					<>
						<span>{t("album")}</span>
						<span className={styles.album_name}>{` ${notification.albumName}`}</span>
					</>
				)}
				{(notification.post !== null && notification.post) && (
					<>
						<span>{t("post")}</span>
					</>
				)}
				{(notification.video !== null && notification.video) && (
					<>
						<span>{t("video")}</span>
					</>
				)}
				{notification.photo !== null && notification.photo && (
					<>
						<span>{t("_photo")}</span>
					</>
				)}
			</div>
			{notification?.post !== null && notification?.post && (
				<div className={styles.popover_element_minipost}>
					{notification?.post !== null && (
						<div className={styles.popover_element_minipost_text}>
							{postText[0]}
							{" "}
							{postText[1]}
							{" "}
							{postText[2]}
							...
						</div>
					)}
					{notification?.post[0]?.images.length > 0 && (
						<div className={styles.popover_element_minipost_image}>
							<img src={notification.post[0].images[0]} alt="post-img" />
						</div>
					)}
					{notification?.post[0]?.videos.length > 0 && (
						<div className={styles.popover_element_minipost_video}>
							<ReactPlayer
								width="100%"
								height="100%"
								url={`${baseUri}/${notification.post[0].videos[notification.post[0].videos.length - 1].path}`}
							/>
						</div>
					)}
				</div>
			)}
			{notification?.photo !== null && notification?.photo && (
				<div className={styles.popover_element_minipost_image}>
					<img src={`${baseUri}/${notification?.photo?.path}`} alt="post-img" />
				</div>
			)}
			{(notification?.video !== null && notification?.video) && (
				<div className={styles.popover_element_minipost}>
					{notification?.video && (
						<div className={styles.popover_element_minipost_video}>
							<ReactPlayer
								width="100%"
								height="100%"
								url={`${baseUri}/${notification.video.path}`}
							/>
						</div>
					)}
				</div>
			)}
		</>
	)
}

export const newTreeElement = (notification, t, history) => (
	<>
		<div>
			{" "}
			{notification?.user.firstName}
			{" "}
			{notification?.user.lastName}
			{" "}
			<span className={styles.popover_element_text}>
				{t("added-new-person-to")}
			</span>
			<button
				type="button"
				className={styles.popover_element_text_button}
				onClick={() => {
					history.push({ pathname: "/profile/tree", userId: notification.userId })
				}}
			>
				{t("family-tree")}
			</button>
		</div>
	</>
)