import React, { useState } from "react"
import { UserAvatar } from "shared/avatar"
import { useSelector } from "react-redux"
import { store } from "app/redux/store"
import { socket } from "app/socket"
import { Popover } from "antd"
import { useHistory } from "react-router-dom"
import moment from "moment-timezone"
import { useTranslation } from "react-i18next"
import { inviteAsFriend, inviteAsFamily } from "modules/search-people/api"
import styles from "./profile-connection-card.module.css"

export const ProfileConnectionCard = ({
	user
}) => {
	const { messages } = useSelector(s => s)
	const { currentUser } = store.getState().user
	const { t } = useTranslation()

	const history = useHistory()
	const [visiblePopover, setVisiblePopover] = useState(false)
	const userInFriends = user.friends.includes(currentUser._id)
	const userInFamily = user.family.includes(currentUser._id)
	const [
		userHaveFriendRequest,
		setUserHaveFriendRequest
	] = useState(user.invitesAsFriend.includes(currentUser._id))
	const [
		userHaveFamilyRequest,
		setUserHaveFamilyRequest
	] = useState(user.invitesAsFamily.includes(currentUser._id))
	const popoverContent = (
		<div className={styles.popover_buttons} onMouseLeave={() => setVisiblePopover(false)}>
			{!userHaveFamilyRequest && !userHaveFriendRequest && !userInFriends && !userInFamily
				&& (
					<button
						type="button"
						className={styles.confirm_button}
						onClick={async () => {
							await inviteAsFriend(user._id)
							setUserHaveFriendRequest(true)
							socket.emit("SEND_FRIEND_REQUEST", { userId: user._id })
						}}
					>
						{t("a-friend")}
					</button>
				)}
			<div>
				{!userHaveFamilyRequest && !userHaveFriendRequest && !userInFriends && !userInFamily
					&& (
						<button
							type="button"
							className={styles.confirm_button}
							onClick={async () => {
								await inviteAsFamily(user._id)
								setUserHaveFamilyRequest(true)
								socket.emit("SEND_FAMILY_REQUEST", { userId: user._id })
							}}
						>
							{t("a-family")}
						</button>
					)}
			</div>
			{(userHaveFamilyRequest || userHaveFriendRequest) && (
				<div>{t("you-already-send-connection-requests-to-this-user")}</div>
			)}
		</div>
	)
	return (
		<div className={styles.wrap}>
			<UserAvatar
				user={user}
				style={{
					width: "60px", height: "60px", fontSize: "56px", color: "#fff"
				}}
			/>
			<div className={styles.user_info}>
				<div className={styles.user_name}>
					<div>
						{user.firstName}
						{" "}
						{user.lastName}
						{" "}
					</div>
					<div className={styles.user_livesIn}>
						{user.location.name}
					</div>
				</div>
				<div className={styles.buttons}>
					{currentUser._id !== user._id && (
						<button
							type="button"
							className={styles.confirm_button}
							onClick={() => {
								if (messages.find(message => message.users.length === 2
									&& !message.roomName
									&& message.users.find(u => u._id === user._id)
									&& message.users.find(u => u._id === currentUser._id))) {
									history.push({ pathname: "/messages", userId: user._id })
								} else {
									socket.emit("START_CHAT", { roomName: null, recipients: [user._id], userId: currentUser._id })
									socket.on("START_CHAT", chat => {
										const recepient = chat.users.find(user => user._id !== currentUser._id)
										history.push({ pathname: "/messages", userId: recepient._id })
									})
								}
							}}
						>
							{t("send-messages")}
						</button>
					)}
					{currentUser._id !== user._id && (
						<button
							type="button"
							className={styles.confirm_button}
							onClick={() => {
								history.push({ pathname: "/profile", userId: user._id })
							}}
						>
							{t("view-profile")}
						</button>
					)}
					<Popover placement="bottom" content={popoverContent} trigger="click" visible={visiblePopover}>
						{currentUser._id !== user._id && !userInFriends && !userInFamily
							&& (
								<button
									className={styles.confirm_button}
									type="button"
									onClick={() => {
										setVisiblePopover(!visiblePopover)
									}}
								>
									{t("invite-as")}
								</button>
							)}
					</Popover>
				</div>
			</div>
			{!user.isOnline ? (
				<div
					title={moment(user.lastSession).tz(`${moment.tz.guess()}`).fromNow()}
					className={styles.card_last_session}
				>
					{moment(user.lastSession).tz(`${moment.tz.guess()}`).fromNow()}
				</div>
			) : (<div className={styles.card_last_session} />)}
		</div>
	)
}