import React from "react"
import { PhoneOutlined, CameraOutlined } from "@ant-design/icons"
import styles from "../create-video-chat.module.css"

export const SET_AUDIO = "audio"
export const SET_CAMERA = "camera"

export const NavBtn = (props) => {
	const {
		CallUser,
		user
	} = props

	return (
		<div className={styles.chat_utils}>
			<button
				type="button"
				className={styles.ch_nav_button}
				onClick={CallUser.bind(this, { userData: user, mode: SET_AUDIO })}
			>
				<PhoneOutlined
					className={styles.ch_nav_btn_icon}
				/>
			</button>
			<button
				type="button"
				className={styles.ch_nav_button}
				onClick={CallUser.bind(this, { userData: user, mode: SET_CAMERA })}
			>
				<CameraOutlined
					className={styles.ch_nav_btn_icon}
				/>
			</button>
		</div>
	)
}