import React, { useState, useEffect } from "react"
import { setUser } from "app/redux/user"
import {
	Form, Input, Button, Checkbox, Modal
} from "antd"
import { NavLink, useHistory } from "react-router-dom"
import { AppLogoIcon } from "shared/logo-icon"
import { GooglePlayIcon, AppStoreIcon } from "shared/icons"
import { useSelector, useDispatch } from "react-redux"
import { useTranslation } from "react-i18next"
import { v4 as uuidv4 } from "uuid"
import FacebookLogin from "react-facebook-login"
import AppleSignin from "react-apple-signin-auth"
import {
	loginUser,
	resendCodeToEmail,
	facebookAuth,
	appleLogin,
	appleRegistration
} from "./api"
import "antd/dist/antd.css"
import styles from "./auth.module.css"

export const Auth = () => {
	const urlQuery = window?.location?.search

	const { user } = useSelector(s => s)
	const dispatch = useDispatch()
	const history = useHistory()
	const { t } = useTranslation()

	const [loginErrorText, setLoginErrorText] = useState("")
	const [key, setKey] = useState(null)
	const [resultTest, setResultTest] = useState(false)
	const [countError, setCountError] = useState(0)
	const [appleSignUpModal, setAppleSignUpModal] = useState(false)
	const [fieldsValues, setFieldsValues] = useState({})
	const onFinish = async values => {
		if (!user.isAuth) {
			const status = await loginUser(values.email, values.password)
			if (status.candidate) {
				const userData = status.candidate
				if (userData.verified || (countError >= 3 && resultTest === true)) {
					if (values.remember) {
						localStorage.setItem("token", status.token)
					} else {
						sessionStorage.setItem("token", status.token)
					}
					dispatch(setUser(userData))
					history.push("/feed")
					window.location.reload()
				}
				if (!userData.verified) {
					const sendCodeToEmail = async () => {
						await resendCodeToEmail(userData.email)
					}
					sendCodeToEmail()
					history.push({
						pathname: "/registration-confirm",
						email: userData.email
					})
				}
			} else {
				setLoginErrorText(status.message)
				setCountError(prev => prev + 1)
			}
		}
		if (user.isAuth) {
			const token = localStorage.token
				? localStorage.getItem("token")
				: sessionStorage.getItem("token")
			if (token) {
				if (user?.currentUser?.verified) {
					history.push("/feed")
				}
			}
		}
	}
	const handleChange = e => setResultTest(e.target.value === key)
	useEffect(() => {
		setKey(uuidv4().slice(0, 6))
	}, [countError])

	useEffect(() => {
		if (urlQuery.includes("verified=true")) {
			const getAppleData = async () => {
				const appleID = urlQuery.split("&")[0].slice(1).replace("appleId=", "")
				const parts = appleID.split(".")
				const appleToken = await JSON.parse(
					Buffer.from(parts[1], "base64").toString("ascii")
				)
				const response = await appleLogin(appleToken.email)
				if (response.status === "OK") {
					const userData = response.candidate
					localStorage.setItem("token", response.token)
					dispatch(setUser(userData))
					history.push("/feed")
					window.location.reload()
				}
			}
			getAppleData()
		}
		if (urlQuery.includes("verified=false")) {
			setAppleSignUpModal(true)
		}
	}, [urlQuery])

	const responseFacebook = async response => {
		if (response.name && response.email && response.userID) {
			const firstName = response.name.split(" ")[0]
			const lastName = response.name.split(" ")[1]
			const responseData = await facebookAuth({
				email: response.email,
				fBId: response.userID,
				firstName,
				lastName
			})
			if (responseData.status === "OK") {
				const userData = responseData.candidate
				localStorage.setItem("token", responseData.token)
				dispatch(setUser(userData))
				history.push("/feed")
				window.location.reload()
			}
		}
	}

	const onAppleFinish = async values => {
		const appleID = urlQuery.split("&")[0].slice(1).replace("appleId=", "")
		const parts = appleID.split(".")
		const appleToken = await JSON.parse(
			Buffer.from(parts[1], "base64").toString("ascii")
		)
		const response = await appleRegistration(
			appleToken.email,
			values.firstName,
			values.lastName
		)
		if (response.status === "OK") {
			const userData = response.candidate
			localStorage.setItem("token", response.token)
			dispatch(setUser(userData))
			history.push("/feed")
			window.location.reload()
		}
	}

	return (
		<>
			<Modal
				title={t("fill-in-the-fields-to-continue")}
				visible={appleSignUpModal}
				footer={null}
			>
				<Form
					name="apple-sign-up"
					initialValues={{
						remember: true
					}}
					onFinish={onAppleFinish}
				>
					<Form.Item
						className={styles.auth_from_item_input}
						name="firstName"
						rules={[
							{
								required: true,
								message: `${t("required-field")}`
							}
						]}
					>
						<Input
							placeholder={t("first-name")}
							className={styles.auth_input}
							onChange={e => {
								if (e.target.value.trim()) {
									setFieldsValues({
										...fieldsValues,
										firstName: e.target.value
									})
								} else {
									const data = fieldsValues
									delete data.firstName
									setFieldsValues({ ...data })
								}
							}}
						/>
					</Form.Item>
					<Form.Item
						className={styles.auth_from_item_input}
						name="lastName"
						rules={[
							{
								required: true,
								message: `${t("required-field")}`
							}
						]}
					>
						<Input
							placeholder={t("last-name")}
							className={styles.auth_input}
							onChange={e => {
								if (e.target.value.trim()) {
									setFieldsValues({
										...fieldsValues,
										lastName: e.target.value
									})
								} else {
									const data = fieldsValues
									delete data.lastName
									setFieldsValues({ ...data })
								}
							}}
						/>
					</Form.Item>
					<Form.Item style={{ margin: "0px" }}>
						<Button
							type="primary"
							htmlType="submit"
							className={styles.register_form_button}
						>
							{t("login")}
						</Button>
					</Form.Item>
				</Form>
			</Modal>
			<div className={styles.wrap}>
				<div className={styles.auth_container}>
					<div className={styles.auth_logo}>
						<AppLogoIcon />
					</div>
					<Form
						name="login"
						className={styles.login_form}
						initialValues={{
							remember: true
						}}
						onChange={() => setLoginErrorText("")}
						onFinish={onFinish}
					>
						<div className={styles.login_form_header}>
							{t("let’s-sign-you-in")}
						</div>
						<Form.Item
							className={styles.auth_from_item_input}
							name="email"
							rules={[
								{
									required: true,
									message: `${t("please-input-your-email-address")}`
								},
								{
									type: "email",
									message: `${t("invalid-email-address")}`
								}
							]}
						>
							<Input
								placeholder={t("email-adress")}
								className={styles.auth_input}
							/>
						</Form.Item>
						<Form.Item
							className={styles.auth_from_item_input}
							name="password"
							rules={[
								{
									required: true,
									message: `${t("please-input-your-password!")}`
								}
							]}
						>
							<Input.Password
								className={styles.auth_input_password}
								placeholder={t("password")}
							/>
						</Form.Item>
						{countError >= 3 && (
							<div
								className={styles.captcha_container}
								style={{ border: `${resultTest ? "3px green solid" : ""}` }}
							>
								<div className={styles.key}>{key}</div>
								<input
									className={styles.auth_input}
									style={{
										textAlign: "center",
										border: "none",
										margin: "10px 0",
										outline: 0
									}}
									type="text"
									placeholder={t("input-text-from-the-image")}
									onChange={handleChange}
								/>
							</div>
						)}
						<Form.Item className={styles.auth_from_item_remember}>
							<div className={styles.login_forgot_remember}>
								<div
									className={
										loginErrorText
											? `${styles.auth_error}`
											: `${styles.auth_error} ${styles.auth_error_hide}`
									}
								>
									{loginErrorText === "Email or password field not correct"
										? t("email-or-password-incorrect")
										: loginErrorText}
								</div>
								<Form.Item name="remember" valuePropName="checked" noStyle>
									<Checkbox>{t("remember-me")}</Checkbox>
								</Form.Item>
								<NavLink
									to="/restore-password"
									className={styles.login_form_link}
								>
									{t("forgot-password")}
								</NavLink>
							</div>
						</Form.Item>
						<Form.Item style={{ margin: "0px" }}>
							<Button
								type="primary"
								htmlType="submit"
								className={styles.login_form_button}
							>
								{t("login")}
							</Button>
							<div className={styles.login_form_register}>
								{t("don't-have-an account")}
								{" "}
								<NavLink to="/registration" className={styles.login_form_link}>
									{t("sign-up")}
								</NavLink>
							</div>
						</Form.Item>
						<FacebookLogin
							appId={process.env.REACT_APP_FACEBOOK_APP_ID}
							fields="name,email,picture"
							callback={responseFacebook}
							isMobile={false}
							redirectUri={process.env.REACT_APP_APP_URI}
							cssClass={styles.facebook_button}
						/>
						<AppleSignin
							authOptions={{
								clientId: process.env.REACT_APP_APPLE_LOGIN_CLIENT_KEY,
								scope: "email name",
								redirectURI: `${process.env.REACT_APP_APP_URI}api/apple/callback`
							}}
							uiType="dark"
							className="apple-auth-btn"
							noDefaultStyle={false}
							buttonExtraChildren="Login with Apple"
							onSuccess={response => console.log(response)}
							onError={error => console.error(error)}
							skipScript={false}
							render={props => (
								<button
									type="button"
									className={styles.apple_button}
									{...props}
								>
									Login with Apple
								</button>
							)}
						/>
						<div className={styles.auth_icon_wrapper}>
							<a className={styles.auth_icon} href="https://play.google.com/store/apps/details?id=life.twig" target="_blank" rel="noreferrer">
								<GooglePlayIcon />
							</a>
							<a className={styles.auth_icon} href="https://apps.apple.com/by/app/lifetwig/id1582361916" target="_blank" rel="noreferrer">
								<AppStoreIcon />
							</a>
						</div>
					</Form>
				</div>
				<div className={styles.auth_image} />
			</div>
		</>
	)
}
