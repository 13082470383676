import React from "react"
import { useTranslation } from "react-i18next"
import styles from "../side-panel.module.css"

export const DeleteNotification = ({ person }) => {
	const { t } = useTranslation()
	return (
		<div className={styles.delete_person_section}>
			{!person.isUser && (
				<span className={styles.side_panel_labels}>
					{t("to-remove-this-person-first-remove-all-childrents-in-his-branch")}
				</span>
			)}
		</div>
	)
}