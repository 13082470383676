/* eslint-disable no-unused-vars */
import React, { useState } from "react"
import { Card, Avatar } from "antd"
import { UserOutlined } from "@ant-design/icons"
import { useSelector } from "react-redux"
import { updatePerson } from "modules/family-tree/ducks"
import { updateUser } from "app/redux/user"
import { inviteAsFamily } from "modules/search-people/api"
import { useTranslation } from "react-i18next"
import { store } from "app/redux/store"
import { getImageUri } from "shared/utils"
import { UserProfileModal } from "./user-profile-modal"
import styles from "../side-panel.module.css"

const { Meta } = Card

export const ModalPersonsList = ({
	persons,
	setVisible,
	setGlobalPersons,
	setPersons,
	setIsNameFieldEmpty,
	setLivesInInput,
	setIsClickedSearch,
	setNameInput,
	selectedPerson,
	isGlobal = false,
	token = null
}) => {
	const { user } = useSelector(s => s)
	const { currentUser } = user

	const { t } = useTranslation()

	const [showGlobalUserProfileModal, setShowGlobalUserProfileModal] = useState(false)
	const [userToShowInModal, setUserToShowInModal] = useState(null)

	return (
		<>
			{showGlobalUserProfileModal
				&& userToShowInModal
				&& (
					<UserProfileModal
						showGlobalUserProfileModal={showGlobalUserProfileModal}
						setShowGlobalUserProfileModal={setShowGlobalUserProfileModal}
						setUserToShowInModal={setUserToShowInModal}
						userToShowInModal={userToShowInModal}
						selectedPerson={selectedPerson}
					/>
				)}
			{isGlobal
				&& (
					<div
						className={styles.modal_global_copy}
					>
						{t("users-found-globally")}
						:
					</div>
				)}
			<ul className={styles.modal_search_result}>
				{persons.map(person => (
					<li className={styles.modal_search_list_element} key={person._id}>
						<div
							role="button"
							tabIndex={0}
							className={styles.modal_search_list_element_wrap}
							title={t("more-data")}
							onClick={async () => {
								if (person.isGlobalUser) {
									setUserToShowInModal(person)
									setShowGlobalUserProfileModal(true)
								} else {
									store.dispatch(updatePerson({
										...selectedPerson,
										name: `${person.firstName} ${person.lastName}`,
										from: person.birthPlace,
										livesIn: !person.location.name ? "" : person.location.name,
										birthDate: person.birthDate,
										deathDate: "",
										gender: !person.gender ? "unknown" : person.gender,
										description: "",
										avatar: person.avatar,
										profileId: person._id
									}))
									setVisible(false)
									setIsClickedSearch(false)
									setNameInput("")
									setLivesInInput("")
									setPersons([])
									setGlobalPersons([])
									setIsNameFieldEmpty(false)
									if (
										currentUser.friends.includes(person._id)
										&& person.friends.includes(currentUser._id)
									) {
										const resp = await inviteAsFamily(person._id, token)
										if (resp.status === "Updated successfully") {
											store.dispatch(updateUser({
												...currentUser,
												family: resp.family,
												friends: resp.friends
											}))
										}
									}
								}
							}}
						>
							<div className={styles.modal_search_list_element_content}>
								<Avatar
									style={{
										background: "rgba(136, 133, 133, 0.5)",
										display: "flex",
										alignItems: "center",
										justifyContent: "center",
										height: "90px",
										width: "90px",
										marginRight: "16px"
									}}
									src={!person.avatar ? (
										<UserOutlined style={{
											fontSize: "88px", width: "100%", height: "100%", display: "flex", alignItems: "center", justifyContent: "center"
										}}
										/>
									) : getImageUri(person.avatar)}
								/>
								<div>
									<div
										className={styles.modal_search_list_element_name}
									>
										{`${person.firstName} ${person.lastName}`}
									</div>
									<div
										className={styles.modal_search_list_element_lives_in}
									>
										{!person.location.name ? "" : person.location.name}
									</div>
								</div>
							</div>
						</div>
					</li>
				))}
			</ul>
		</>
	)
}