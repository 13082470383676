import React, { useState } from "react"
import { Modal, Input, Button } from "antd"
import { SearchOutlined } from "@ant-design/icons"
import CustomScroll from "react-scrollbars-custom"
import { useSelector } from "react-redux"
import { getPersonById } from "modules/family-tree/utils"
import { findUsers, findUsersGlobal } from "modules/family-tree/api"
import { useTranslation } from "react-i18next"
import mobile from "is-mobile"
import { ModalPersonsList } from "./modal-presons-list"
import styles from "../side-panel.module.css"

export const SearchModal = ({
	visible, setVisible, header, token
}) => {
	const { selectedPersonId } = useSelector(s => s.familyTree)
	const { people } = useSelector(s => s.familyTree)
	const selectedPerson = getPersonById(selectedPersonId)
	const { t } = useTranslation()
	const [nameInput, setNameInput] = useState("")
	const [isClickedSearch, setIsClickedSearch] = useState(false)
	const [livesInInput, setLivesInInput] = useState("")
	const [persons, setPersons] = useState([])
	const [globalPersons, setGlobalPersons] = useState([])
	const [loading, setLoading] = useState(false)
	const [isNameFieldEmpty, setIsNameFieldEmpty] = useState(false)

	const isMobile = mobile()

	return (
		<Modal
			title={header || t("search|")}
			centered
			visible={visible}
			onCancel={() => {
				setVisible(false)
				setIsClickedSearch(false)
				setNameInput("")
				setLivesInInput("")
				setPersons([])
				setGlobalPersons([])
				setIsNameFieldEmpty(false)
			}}
			width={!isMobile ? "60%" : "90%"}
			footer={[
				<Button
					key="submit"
					danger
					onClick={() => {
						setVisible(false)
						setIsClickedSearch(false)
						setNameInput("")
						setLivesInInput("")
						setPersons([])
						setGlobalPersons([])
						setIsNameFieldEmpty(false)
					}}
					style={{ borderRadius: "6px" }}
				>
					{t("close")}
				</Button>
			]}
		>
			<div className={isClickedSearch && (persons.length !== 0 || globalPersons.length !== 0) ? `${styles.modal_content} ${styles.modal_content_big}` : `${styles.modal_content} ${styles.modal_content_small}`}>
				<div className={styles.modal_inputs_wrapper}>
					<div className={styles.modal_inputs}>
						<span>
							{t("name")}
						</span>
						<div className={styles.modal_name_input}>
							<Input
								value={nameInput}
								onInput={e => {
									if (isNameFieldEmpty) {
										setIsNameFieldEmpty(false)
									}
									setNameInput(e.target.value)
								}}
								style={{ width: "100%", borderRadius: "6px" }}
								placeholder={t("enter-user-name")}
							/>
							<div className={isNameFieldEmpty ? `${styles.modal_name_input_error}` : `${styles.modal_name_input_error} ${styles.modal_name_input_error_disabled}`}>
								{t("please-input-username")}
								{" "}
							</div>
						</div>
					</div>

					<div className={styles.modal_inputs}>
						<span>
							{t("lives-in")}
							{" "}
						</span>
						<Input value={livesInInput} onInput={e => setLivesInInput(e.target.value)} style={{ width: "60%", borderRadius: "6px" }} placeholder={t("enter-user-place-of-living")} />
					</div>
					<Button
						type="primary"
						className={styles.modal_search_button}
						style={{ width: "100px", margin: "32px 0 0 16px", borderRadius: "6px" }}
						icon={<SearchOutlined />}
						loading={loading}
						onClick={async () => {
							if (nameInput !== "") {
								setLoading(true)
								const result = await findUsers(nameInput, livesInInput, token)
								const globalResult = await findUsersGlobal(nameInput, livesInInput, token)
								const peopleNotInTree = []
								const globalPeopleNotInTree = []
								if (typeof result === "object" && Array.isArray(result)) {
									result.forEach(person => {
										const personInTree = people.find(p => p.profileId === person._id)
										if (!personInTree) {
											peopleNotInTree.push(person)
										}
									})
								}
								if (typeof globalResult === "object" && Array.isArray(globalResult)) {
									globalResult.forEach(person => {
										const personInTree = people.find(p => p.profileId === person._id)
										if (!personInTree) {
											person.isGlobalUser = true
											globalPeopleNotInTree.push(person)
										}
									})
								}
								setPersons(peopleNotInTree)
								setGlobalPersons(globalPeopleNotInTree)
								setLoading(false)
								setIsClickedSearch(true)
							} else {
								setIsNameFieldEmpty(true)
							}
						}}
					>
						{t("search|")}
					</Button>
				</div>
				{isClickedSearch && (persons.length !== 0 || globalPersons.length !== 0) ? (
					<CustomScroll>
						{persons.length !== 0 && (
							<ModalPersonsList
								persons={persons}
								setVisible={setVisible}
								setGlobalPersons={setGlobalPersons}
								setPersons={setPersons}
								setIsNameFieldEmpty={setIsNameFieldEmpty}
								setLivesInInput={setLivesInInput}
								setIsClickedSearch={setIsClickedSearch}
								setNameInput={setNameInput}
								selectedPerson={selectedPerson}
								token={token}
							/>
						)}
						{globalPersons.length !== 0 && (
							<ModalPersonsList
								persons={globalPersons}
								setVisible={setVisible}
								setGlobalPersons={setGlobalPersons}
								setPersons={setPersons}
								setIsNameFieldEmpty={setIsNameFieldEmpty}
								setLivesInInput={setLivesInInput}
								setIsClickedSearch={setIsClickedSearch}
								setNameInput={setNameInput}
								selectedPerson={selectedPerson}
								isGlobal
								token={token}
							/>
						)}
					</CustomScroll>
				)
					: (
						<div className={!isClickedSearch ? `${styles.modal_inputs} ${styles.empty_response_disabled}` : `${styles.modal_inputs}`}>{t("no-results-found")}</div>
					)}
			</div>
		</Modal>
	)
}