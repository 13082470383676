import React from "react"

export const EmptyPostImage = () => (
	<svg width="200" height="161" viewBox="0 0 200 161" fill="none">
		<path d="M98.8224 149C138.183 149 170.092 117.001 170.092 77.3897C170.092 37.7785 138.042 5.77942 98.8224 5.77942C59.4622 5.77942 27.5523 37.7785 27.5523 77.3897C27.5523 117.001 59.4622 149 98.8224 149Z" fill="#EAEEF9" />
		<path d="M173.889 7.89406C176.062 7.89406 177.825 6.12692 177.825 3.94703C177.825 1.76715 176.062 0 173.889 0C171.715 0 169.953 1.76715 169.953 3.94703C169.953 6.12692 171.715 7.89406 173.889 7.89406Z" fill="#E7EBF6" />
		<path d="M31.347 30.3075C33.5208 30.3075 35.283 28.5404 35.283 26.3605C35.283 24.1806 33.5208 22.4135 31.347 22.4135C29.1732 22.4135 27.4109 24.1806 27.4109 26.3605C27.4109 28.5404 29.1732 30.3075 31.347 30.3075Z" fill="#E7EBF6" />
		<path d="M7.30976 106.429C11.3468 106.429 14.6195 103.147 14.6195 99.0984C14.6195 95.05 11.3468 91.7682 7.30976 91.7682C3.27269 91.7682 0 95.05 0 99.0984C0 103.147 3.27269 106.429 7.30976 106.429Z" fill="#E7EBF6" />
		<g filter="url(#filter0_d)">
			<path d="M166.735 39.7758V122.716C166.735 125.15 164.712 127.178 162.286 127.178H36.6037C34.177 127.279 32.1548 125.251 32.1548 122.818V39.7758C32.1548 37.2409 34.177 35.3144 36.6037 35.3144H162.286C164.814 35.3144 166.735 37.3423 166.735 39.7758Z" fill="url(#paint0_linear)" />
		</g>
		<path d="M166.735 39.7758V46.1636H32.1548V39.7758C32.1548 37.2409 34.177 35.3144 36.6037 35.3144H162.286C164.814 35.3144 166.735 37.3423 166.735 39.7758Z" fill="url(#paint1_linear)" />
		<path d="M80.8906 78.3057C82.9568 78.3057 84.6317 76.6261 84.6317 74.5541C84.6317 72.4822 82.9568 70.8025 80.8906 70.8025C78.8244 70.8025 77.1495 72.4822 77.1495 74.5541C77.1495 76.6261 78.8244 78.3057 80.8906 78.3057Z" fill="#989FB0" />
		<path d="M118.1 78.3057C120.166 78.3057 121.841 76.6261 121.841 74.5541C121.841 72.4822 120.166 70.8025 118.1 70.8025C116.034 70.8025 114.359 72.4822 114.359 74.5541C114.359 76.6261 116.034 78.3057 118.1 78.3057Z" fill="#989FB0" />
		<path d="M105.562 102.944H93.8332C92.0132 102.944 90.4965 101.424 90.4965 99.5985C90.4965 97.7734 92.0132 96.2524 93.8332 96.2524H105.461C107.281 96.2524 108.798 97.7734 108.798 99.5985C108.899 101.424 107.382 102.944 105.562 102.944Z" fill="#989FB0" />
		<path d="M38.1195 42.6145C38.9572 42.6145 39.6362 41.9336 39.6362 41.0936C39.6362 40.2536 38.9572 39.5727 38.1195 39.5727C37.2819 39.5727 36.6028 40.2536 36.6028 41.0936C36.6028 41.9336 37.2819 42.6145 38.1195 42.6145Z" fill="#F9FAFB" />
		<path d="M42.872 42.6145C43.7096 42.6145 44.3886 41.9336 44.3886 41.0936C44.3886 40.2536 43.7096 39.5727 42.872 39.5727C42.0343 39.5727 41.3553 40.2536 41.3553 41.0936C41.3553 41.9336 42.0343 42.6145 42.872 42.6145Z" fill="#F9FAFB" />
		<path d="M47.7263 42.6145C48.564 42.6145 49.243 41.9336 49.243 41.0936C49.243 40.2536 48.564 39.5727 47.7263 39.5727C46.8887 39.5727 46.2097 40.2536 46.2097 41.0936C46.2097 41.9336 46.8887 42.6145 47.7263 42.6145Z" fill="#F9FAFB" />
		<path d="M161.275 24.5668C160.264 24.5668 159.455 23.7556 159.455 22.7417V2.36142C159.455 1.34747 160.264 0.536316 161.275 0.536316C162.286 0.536316 163.095 1.34747 163.095 2.36142V22.7417C163.095 23.7556 162.286 24.5668 161.275 24.5668Z" fill="#989FB0" />
		<path d="M176.036 40.1812C176.036 39.1673 176.845 38.3561 177.857 38.3561H198.18C199.191 38.3561 200 39.1673 200 40.1812C200 41.1952 199.191 42.0063 198.18 42.0063H177.857C176.845 42.0063 176.036 41.1952 176.036 40.1812Z" fill="#989FB0" />
		<path d="M174.419 27C173.711 26.2903 173.711 25.0735 174.419 24.3638L188.878 9.96576C189.585 9.256 190.799 9.256 191.507 9.96576C192.214 10.6755 192.214 11.8923 191.507 12.602L177.149 27C176.34 27.7098 175.126 27.7098 174.419 27Z" fill="#989FB0" />
		<defs>
			<filter id="filter0_d" x="10.1548" y="24.3144" width="178.58" height="135.867" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
				<feFlood floodOpacity="0" result="BackgroundImageFix" />
				<feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" />
				<feOffset dy="11" />
				<feGaussianBlur stdDeviation="11" />
				<feColorMatrix type="matrix" values="0 0 0 0 0.397708 0 0 0 0 0.47749 0 0 0 0 0.575 0 0 0 0.27 0" />
				<feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
				<feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
			</filter>
			<linearGradient id="paint0_linear" x1="99.4009" y1="33.1894" x2="99.4009" y2="128.172" gradientUnits="userSpaceOnUse">
				<stop stopColor="#FDFEFF" />
				<stop offset="0.9964" stopColor="#ECF0F5" />
			</linearGradient>
			<linearGradient id="paint1_linear" x1="161.396" y1="35.3144" x2="36.49" y2="40.7089" gradientUnits="userSpaceOnUse">
				<stop stopColor="#B0BACC" />
				<stop offset="1" stopColor="#969EAE" />
			</linearGradient>
		</defs>
	</svg>

)