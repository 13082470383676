import React, { useState, useEffect } from "react"
import { useTranslation } from "react-i18next"
import { getProfileAlbum } from "modules/connection-profile-card/components/profile-photo/api"
import { getImageUri } from "shared/utils"
import { MessageIcon } from "shared/icons"
import { AddAlbumImage } from "shared/empty-screen-images/add-albums"
import { useSelector } from "react-redux"
import { AlbumComments } from "shared/album-comments"
import { AppSpinner } from "shared/app-spinner/app-spinner"
import styles from "./photo-connection-content.module.css"
import { ProfileAlbum } from "./components/connection-profile-album"

export const UserProfilePhotos = ({ profile }) => {
	const { user } = useSelector(s => s)
	const { currentUser } = user
	const { t } = useTranslation()
	const [albums, setAlbums] = useState([])
	const [curId, setCurId] = useState(null)
	const [isLoading, setIsLoading] = useState(false)
	const [isAlbumCommentsActive, setIsAlbumCommentsActive] = useState(false)
	const [selectedAlbum, setSelectedAlbum] = useState(null)

	useEffect(() => {
		const getData = async () => {
			setIsLoading(true)
			if (profile.privacySettings.photos !== "only-me") {
				if (profile.privacySettings.photos === "public") {
					const res = await getProfileAlbum(profile._id)
					setAlbums(res.data.photoGallery)
				}
				if (profile.privacySettings.photos === "relatives-only" && profile.family.includes(currentUser._id)) {
					const res = await getProfileAlbum(profile._id)
					setAlbums(res.data.photoGallery)
				}
				if (profile.privacySettings.photos === "friends-only" && profile.friends.includes(currentUser._id)) {
					const res = await getProfileAlbum(profile._id)
					setAlbums(res.data.photoGallery)
				}
				if (profile.privacySettings.photos === "friends-and-relatives" && (profile.friends.includes(currentUser._id) || profile.family.includes(currentUser._id))) {
					const res = await getProfileAlbum(profile._id)
					setAlbums(res.data.photoGallery)
				}
			}
			setIsLoading(false)
		}
		getData()
	}, [currentUser._id, profile])
	return (
		<>
			<div className={styles.wrap}>
				<div
					className={styles.container}
				>
					{curId !== null
						? <ProfileAlbum albumId={curId} curProfileId={profile._id} setCurId={setCurId} />
						: (
							<>
								<div className={styles.main_content_container}>
									{!isLoading ? (
										<>
											{
												albums.filter(a => a.photos.length > 0).length === 0
												&& (
													<div className={styles.main_no_albums}>
														<AddAlbumImage />
														<div className={styles.no_albums_text}>{t("this-user-hasn't-photos")}</div>
													</div>
												)
											}
											{albums?.length > 0 && albums.filter(a => a.photos.length > 0).map(album => (
												<div
													key={album.id}
													className={styles.album_card}
												>
													<div
														className={styles.album_card_content}
													>
														<button
															type="button"
															onClick={() => setCurId(album.id)}
														>
															<div
																style={{
																	position: "absolute",
																	top: "0",
																	left: "0",
																	width: "100%",
																	height: "100%",
																	border: "1px solid #EAEEF9",
																	borderRadius: "8px",
																	display: "flex",
																	alignItems: "flex-start",
																	justifyContent: "flex-end",
																	padding: "8px",
																	cursor: "pointer",
																	backgroundImage: `url(${getImageUri(album.photos[0].path)})`,
																	backgroundPosition: "center center",
																	backgroundSize: "cover"
																}}
															/>
														</button>
														<button
															type="button"
															className={styles.comments_btn}
															onClick={() => {
																setSelectedAlbum(album)
																setIsAlbumCommentsActive(true)
															}}
														>
															<MessageIcon />
														</button>
													</div>
													<h3
														className={styles.title_album}
														onClick={() => setCurId(album.id)}
													>
														{album.name}
													</h3>
													<h4 className={styles.counter_items}>
														{album.photos.length}
														{" "}
														{t("photo")}
													</h4>
												</div>
											))}
										</>
									) : (
										<div
											style={{
												zIndex: "9999", position: "fixed", top: "0", left: "0", right: "0", bottom: "0", backgroundColor: "#F2F2F2", width: "100vw", height: "100vh", display: "flex", alignItems: "center", justifyContent: "center"
											}}
										>
											<AppSpinner />
										</div>
									)}
								</div>
							</>
						)}
				</div>
			</div>
			<AlbumComments
				isAlbumCommentsActive={isAlbumCommentsActive}
				setIsAlbumCommentsActive={setIsAlbumCommentsActive}
				ownerId={profile._id}
				album={selectedAlbum}
				setSelectedAlbum={setSelectedAlbum}
				userId={currentUser._id}
			/>
		</>
	)
}