import React from "react"
import { EmptySearchPeopleImage } from "shared/empty-screen-images"
import { useTranslation } from "react-i18next"
import { EmailInvite } from "./email-invite"
import styles from "./search-people-components.module.css"

export const SearchPeopleNoResultScreen = () => {
	const { t } = useTranslation()

	return (
		<div className={styles.results_content_empty_wrap}>
			<div>
				<EmptySearchPeopleImage />
			</div>
			<span>
				{t("no-results-found")}
				.
			</span>
			<p />
			<EmailInvite emptyScreen />
		</div>
	)
}