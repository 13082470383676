import React from "react"
import { AppSpinner } from "shared/app-spinner/app-spinner"

export const Loader = ({
	loadingOutlinedStyle, size, style, tip
}) => (
	<div>
		<AppSpinner
			loadingOutlinedStyle={loadingOutlinedStyle}
			size={size}
			style={style}
			tip={tip}
		/>
	</div>
)