/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useRef } from "react"
import CustomScroll from "react-scrollbars-custom"
import { Empty } from "antd"
import { useSelector } from "react-redux"
import { EmptyPostImage } from "shared/empty-screen-images"
import { useLocation } from "react-router-dom"
import {
	BackToTopButton
} from "shared/back-to-top-btn"
import { useTranslation } from "react-i18next"
import { FeedInputForm } from "shared/feed-components/feed-input-form"
import { FeedPost } from "shared/feed-components/feed-post"
import { getPosts } from "shared/feed-components/api"
import { AppSpinner } from "shared/app-spinner/app-spinner"
import styles from "./feed.module.css"

export const Feed = () => {
	const { user } = useSelector(s => s)
	const { currentUser } = user

	const [posts, setPosts] = useState([])
	const [scrollPosition, setScrollPosition] = useState(false)
	const [page, setPage] = useState(1)
	const [totalPages, setTotalPages] = useState(1)
	const [isLoading, setIsLoading] = useState(false)
	const { t } = useTranslation()

	const location = useLocation()
	const scrollbar = useRef()

	const { isReload } = location

	useEffect(() => {
		const getData = async () => {
			setIsLoading(true)
			if (isReload) {
				window.location.reload()
			}
			if (page === 1) {
				const data = await getPosts({ page })
				setPosts(data.posts)
				setTotalPages(data.totalPages)
			} if (page > 1 && page <= totalPages) {
				const data = await getPosts({ page })
				setPosts([...posts, ...data.posts])
			}
			setIsLoading(false)
		}
		getData()
	}, [page, isReload])

	const scrollToPost = () => {
		if (location.selectedPostId) {
			const element = document.getElementById(`${location.selectedPostId}${currentUser._id}`)
			if (element) {
				const scrollBar = document.getElementById("main-feed-scroll")
				element.scrollIntoView()
				scrollBar.style.top = "60px"
				element.style.outline = "3px solid #00875A"
				delete location.isReload
				delete location.selectedPostId
				setTimeout(() => {
					element.style.outline = null
				}, 1500)
			} else {
				scrollbar?.current?.scrollToBottom()
			}
		}
	}

	useEffect(() => {
		scrollToPost()
	}, [location.selectedPostId])

	return (
		<div className={styles.wrap}>
			{isLoading && (
				<AppSpinner
					style={{
						position: "absolute",
						width: "100vw",
						height: "100vh",
						background: "transparent",
						zIndex: 9999
					}}
				/>
			)}
			<CustomScroll
				id="main-feed-scroll"
				ref={scrollbar}
				onScroll={(values) => {
					if ((values.scrollHeight - values.scrollTop) <= values.clientHeight + 1) {
						if (page >= 1 && page < totalPages) {
							setPage(page + 1)
						}
					}
					if (values.scrollTop > 300) {
						setScrollPosition(true)
					} else {
						setScrollPosition(false)
					}
				}}
			>
				<div className={styles.input_wrap}>
					<FeedInputForm
						setAllPosts={setPosts}
						feedType="full"
						topPosition="8%"
						leftPosition="63%"
						rightPosition="auto"
						maxHeightValue="40vh"
					/>
				</div>
				<div className={styles.posts_wrap}>
					{posts?.length === 0 && !isLoading
						? (
							<Empty
								image={<EmptyPostImage />}
								description={<span>{t("there-are-no-posts-here-yet")}</span>}
							/>
						)
						: (
							<>
								{posts?.map(post => (
									<FeedPost
										id={`${post._id}${post.sharedCopyAuthorId ? post.sharedCopyAuthorId : post.userId}`}
										post={post}
										key={post.sharedCopyAuthorId ? `${post.sharedCopyAuthorId + post._id}` : post._id}
										profile={null}
										allPosts={posts}
										setAllPosts={setPosts}
									/>
								))}
							</>
						)}
				</div>
				<BackToTopButton scrollPosition={scrollPosition} scrollbar={scrollbar} />
			</CustomScroll>
		</div>
	)
}