import React from "react"
import { UserAvatar } from "shared/avatar"
import { useHistory } from "react-router-dom"
import { useTranslation } from "react-i18next"
import moment from "moment"

import styles from "./event-card.module.css"

export const EventCard = ({ user }) => {
	const history = useHistory()
	const { t } = useTranslation()
	function birthDateToAge(date) {
		const now = new Date()
		const addOneYear = now.getMonth() - date.getMonth() >= 0 && now.getDate() - date.getDate() >= 0
		const age = now.getFullYear() - date.getFullYear()
		return age + (addOneYear ? 1 : 0)
	}
	const willBeYears = birthDateToAge(user.birthDate)

	return (
		<>
			<div
				className={styles.wrap}
				role="button"
				tabIndex={0}
				onClick={() => {
					if (user._id) {
						history.push({ pathname: "/profile", userId: user._id })
					}
				}}
			>
				<div className={styles.card_info}>
					<UserAvatar user={user} />
					<div className={styles.card_name}>
						{user.firstName ? user.firstName : user.name}
						{" "}
						{user.lastName}
						{" "}
						{user.maidenName && user.maidenName !== "" ? `(${user.maidenName})` : ""}
					</div>
				</div>
				<div className={styles.card_full_years}>
					{`${t("will-be")} ${willBeYears} ${willBeYears < 2 ? t("year") : t("years")}`}
				</div>
				<div className={styles.card_birth_date}>{moment(user.birthDate).format("MM DD YYYY")}</div>
			</div>
		</>
	)
}