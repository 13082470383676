import { v4 as uuidv4 } from "uuid"
import { addChild } from "modules/family-tree/ducks"
import { store } from "app/redux/store"

export const addChildNode = (firstParentId, chosedParentId) => {
	const child = {
		positionId: `person${uuidv4()}`,
		name: "Add child",
		from: "",
		livesIn: "",
		birthDate: "",
		deathDate: "",
		gender: "",
		description: "",
		avatar: "",
		profileId: ""
	}
	if (chosedParentId === "person21" || chosedParentId === "person22") {
		if (chosedParentId === "person21") {
			store.dispatch(addChild({
				parents: [firstParentId],
				...child
			}, chosedParentId))
		} else {
			store.dispatch(addChild({
				parents: [chosedParentId],
				...child
			}, chosedParentId))
		}
	} else {
		store.dispatch(addChild({
			parents: [chosedParentId],
			...child
		}, chosedParentId))
	}
}