import React from "react"
import { useSelector, useDispatch } from "react-redux"
import { setSelectedPersonId } from "modules/family-tree/ducks"
import { MaleIcon, FemaleIcon, UnknownGenderIcon } from "modules/family-tree/icons"
import { getImageUri, truncateText } from "shared/utils"
import styles from "./profile-tree-person.module.css"

export const ProfileTreePerson = props => {
	const { addInfo, id } = props
	const { selectedPersonId } = useSelector(s => s.familyTree)

	const dispatch = useDispatch()

	const isSelected = selectedPersonId === id
	const choseGender = () => {
		let gender = null
		if (addInfo.gender === "male") {
			gender = <MaleIcon />
		} else if (addInfo.gender === "female") {
			gender = <FemaleIcon />
		} else {
			gender = <UnknownGenderIcon />
		}
		return gender
	}

	return (
		<div
			className={`${styles.wrap} ${isSelected && addInfo.gender === "male" ? styles.selected_male : ""} ${isSelected && addInfo.gender === "female" ? styles.selected_female : ""} ${isSelected && addInfo.gender === "unknown" ? styles.selected_gender_neutral : ""}`}
			role="button"
			tabIndex={0}
			onClick={() => dispatch(setSelectedPersonId(id))}
		>
			<div className={styles.person_default_avatar}>
				{addInfo.avatar !== "" ? (<img src={getImageUri(addInfo.avatar)} alt="Something went wrong" className={styles.person_avatar} />) : choseGender()}
			</div>
			<div className={styles.person_description}>
				<div title={addInfo.name} className={`${styles.person_name} ${styles.person_description_elements}`}>{!addInfo.name || addInfo.name === "" ? "Name" : addInfo.name}</div>
				<div className={`${styles.person_lives_in} ${styles.person_description_elements}`}>{!addInfo.livesIn || addInfo.livesIn === "" ? "" : truncateText(addInfo.livesIn, 30)}</div>
				<div className={`${styles.person_lifespan} ${styles.person_description_elements}`}>
					{!addInfo.birthDate || addInfo.birthDate === "" ? "" : addInfo.birthDate}
					{" "}
					{addInfo.birthDate !== "" && addInfo.deathDate !== "" ? "-" : ""}
					{addInfo.birthDate === "" && addInfo.deathDate !== "" ? "Died in: " : ""}
					{" "}
					{!addInfo.deathDate || addInfo.deathDate === "" ? "" : addInfo.deathDate}
				</div>
			</div>
		</div>
	)
}