import {
	Button, Input, Modal, message
} from "antd"
import React, { useState, useEffect } from "react"
import { useTranslation } from "react-i18next"
import CustomScroll from "react-scrollbars-custom"
import {
	createAlbum, getAlbum, deleteAlbum, renameAlbum
} from "modules/videos/api"
import { EditIcon, DeleteIcon, MessageIcon } from "shared/icons"
import { AlbumComments } from "shared/album-comments"
import { useSelector } from "react-redux"
import { AppSpinner } from "shared/app-spinner/app-spinner"
import styles from "./album.module.css"
import { EmptyAlbum } from "./empty-album/empty-album"
import { AldumList } from "./album-list/album-list"

const PopoverContent = ({
	setIsEditMode, album, setVisible, setVisiblePopover,
	setIsAlbumCommentsActive,
	fullAlbume, setSelectedAlbum, setIsVideoAlbum, setCurrentAlbum
}) => {
	const { t } = useTranslation()

	return (
		<div
			className={styles.popover_content}
		>
			<button
				type="button"
				className={styles.popover_btn}
				onClick={async () => {
					setSelectedAlbum(fullAlbume)
					setIsAlbumCommentsActive(true)
					setVisiblePopover(false)
					setIsVideoAlbum(true)
				}}
			>
				<MessageIcon />
				<div className={styles.popover_btn_text}>{t("comments")}</div>
			</button>
			<button
				type="button"
				className={styles.popover_btn}
				onClick={async () => {
					setIsEditMode(true)
					setVisible(true)
					setVisiblePopover(false)
				}}
			>
				<EditIcon />
				<div className={styles.popover_btn_text}>{t("rename-album")}</div>
			</button>
			<button
				type="button"
				className={styles.popover_btn}
				onClick={() => setCurrentAlbum(album)}
			>
				<DeleteIcon />
				<div className={styles.popover_btn_text}>
					{" "}
					{t("delete-album")}
				</div>
			</button>
		</div>
	)
}

export const Album = () => {
	const { t } = useTranslation()
	const [visible, setVisible] = useState(false)
	const [confirmLoading, setConfirmLoading] = useState(false)
	const [modalText, setModalText] = useState("")
	const [albums, setAlbums] = useState([])
	const [isEditMode, setIsEditMode] = useState(false)
	const [currentKey, setCurrentKey] = useState(null)
	const [isLoading, setIsLoading] = useState(false)
	const [visiblePopover, setVisiblePopover] = useState(false)
	const [isAlbumCommentsActive, setIsAlbumCommentsActive] = useState(false)
	const [selectedAlbum, setSelectedAlbum] = useState(null)
	const [isVideoAlbum, setIsVideoAlbum] = useState(false)
	const { user } = useSelector(s => s)
	const { currentUser } = user
	const [currentAlbum, setCurrentAlbum] = useState(null)

	const videoDelete = async () => {
		setVisiblePopover(false)
		setCurrentAlbum(null)
		const response = await deleteAlbum(currentAlbum)
		setAlbums(response.data.user.videoGallery)
	}

	useEffect(() => {
		const get = async () => {
			setIsLoading(true)
			const res = await getAlbum()
			setAlbums(res.data.videoGallery)
			setIsLoading(false)
		}
		get()
	}, [])
	const handleOk = async () => {
		if (!modalText || modalText === "") {
			message.error(t("please-enter-album-name"))
		} else {
			setConfirmLoading(true)
			setTimeout(() => {
				setVisible(false)
				setModalText("")
				setConfirmLoading(false)
			}, 1000)
			if (isEditMode) {
				const response = await renameAlbum(modalText, currentKey)
				setIsEditMode(false)
				setAlbums(response.data.user.videoGallery)
				setVisiblePopover(false)
			} else {
				const response = await createAlbum(modalText)
				setAlbums(response.data.user.videoGallery)
				if (!response.status) {
					message.error(t("Something went wrong"))
				}
			}
		}
	}

	const handleCancel = () => {
		setModalText("")
		setVisible(false)
		setIsEditMode(false)
	}

	return (
		<div
			className={styles.container}
		>
			{!isLoading ? (
				<CustomScroll>
					<div
						className={styles.header}
					>
						<span style={{ padding: "0 24px 0" }}>{t("my-video-albums")}</span>
						{albums.length > 0 && (
							<Button
								onClick={() => {
									setVisible(true)
								}}
								className={styles.confirm_button}
							>
								+
								{t("create-video-albums")}
							</Button>
						)}
					</div>
					<Modal
						title={isEditMode ? t("rename-album") : t("create-video-albums")}
						visible={visible}
						onOk={handleOk}
						confirmLoading={confirmLoading}
						onCancel={handleCancel}
					>
						<Input
							value={modalText}
							onChange={(e) => setModalText(e.target.value)}
						/>
					</Modal>
					<Modal title={t("delete-album")} visible={currentAlbum} onOk={videoDelete} onCancel={() => setCurrentAlbum(false)}>
						<p>{t("are-you-sure-that-you-want-to-delete-the-album")}</p>
					</Modal>
					<div className={styles.main_content_container}>
						{albums.length === 0
							&& (
								<EmptyAlbum setVisible={setVisible} />
							)}
						{albums?.length > 0 && (
							<AldumList
								albums={albums}
								setVisiblePopover={setVisiblePopover}
								PopoverContent={PopoverContent}
								setIsEditMode={setIsEditMode}
								setVisible={setVisible}
								setCurrentKey={setCurrentKey}
								visiblePopover={visiblePopover}
								setAlbums={setAlbums}
								currentKey={currentKey}
								setIsAlbumCommentsActive={setIsAlbumCommentsActive}
								setSelectedAlbum={setSelectedAlbum}
								setIsVideoAlbum={setIsVideoAlbum}
								setCurrentAlbum={setCurrentAlbum}
							/>
						)}
					</div>
				</CustomScroll>
			) : (
				<AppSpinner style={{ width: "100%", height: "100%" }} />
			)}
			<AlbumComments
				isAlbumCommentsActive={isAlbumCommentsActive}
				setIsAlbumCommentsActive={setIsAlbumCommentsActive}
				ownerId={currentUser._id}
				album={selectedAlbum}
				setSelectedAlbum={setSelectedAlbum}
				userId={currentUser._id}
				setIsVideoAlbum={setIsVideoAlbum}
				isVideoAlbum={isVideoAlbum}
			/>
		</div>
	)
}