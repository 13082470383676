export const findUsers = async (inputName, inputLivesIn, token) => {
  if (inputName !== "") {
    const inputInfo = {
      name: inputName,
      livesIn: inputLivesIn
    }
    try {
      const res = await fetch("/api/family-tree/search-persons", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: token
        },
        body: JSON.stringify(inputInfo)
      })
      const json = await res.json()
      return json.result
    } catch (error) {
      console.error("Error:", error)
    }
  }
  return true
}