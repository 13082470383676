import React from "react"
import { Image, Modal, Button } from "antd"
import { UserOutlined } from "@ant-design/icons"
import { useTranslation } from "react-i18next"
import { store } from "app/redux/store"
import { updatePerson } from "modules/family-tree/ducks"
import mobile from "is-mobile"
import { getImageUri } from "shared/utils"
import { inviteAsFamily } from "modules/search-people/api"
import { socket } from "app/socket"
import { UserProfileAbout } from "./user-profile-about-data"
import styles from "../side-panel.module.css"

export const UserProfileModal = ({
	userToShowInModal,
	setShowGlobalUserProfileModal,
	setUserToShowInModal,
	showGlobalUserProfileModal,
	selectedPerson,
	token = null
}) => {
	const { t } = useTranslation()
	const isMobile = mobile()

	const onPersonSelect = async () => {
		store.dispatch(updatePerson({
			...selectedPerson,
			name: `${userToShowInModal.firstName} ${userToShowInModal.lastName[0]}.`,
			from: "",
			livesIn: "",
			birthDate: "",
			deathDate: "",
			gender: "unknown",
			description: "",
			avatar: "",
			profileId: userToShowInModal._id,
			inPending: true
		}))
		setShowGlobalUserProfileModal(false)
		setUserToShowInModal(null)
		await inviteAsFamily(userToShowInModal._id, token)
		socket.emit("SEND_FAMILY_REQUEST", { userId: userToShowInModal._id })
	}

	return (
		<Modal
			title={`${userToShowInModal?.firstName} ${userToShowInModal?.lastName}`}
			centered
			visible={showGlobalUserProfileModal}
			onCancel={() => {
				setShowGlobalUserProfileModal(false)
				setUserToShowInModal(null)
			}}
			width={!isMobile ? "60%" : "90%"}
			footer={[
				<Button
					key="cancel"
					danger
					onClick={() => {
						setShowGlobalUserProfileModal(false)
						setUserToShowInModal(null)
					}}
					style={{ borderRadius: "6px", margin: "8px 0" }}
				>
					{t("cancel")}
				</Button>,
				<Button
					key="submit"
					onClick={onPersonSelect}
					style={{ borderRadius: "6px", borderColor: "#00875a", color: "#00875a" }}
				>
					{t("invite-as-family")}
				</Button>
			]}
		>
			<div className={styles.profile_data}>
				<div className={styles.profile_avatar}>
					{userToShowInModal?.avatar
						? (
							<>
								<Image
									width={window?.innerWidth < 480 ? 80 : 120}
									height={window?.innerWidth < 480 ? 80 : 120}
									src={getImageUri(userToShowInModal?.avatar)}
									className={styles.profile_avatar_img}
								/>
							</>
						)
						: (
							<div className={styles.profile_avatar_img}>
								<UserOutlined style={{
									width: window?.innerWidth < 480 ? "80px" : "120px",
									height: window?.innerWidth < 480 ? "80px" : "120px",
									fontSize: window?.innerWidth < 480 ? "80px" : "120px",
									color: "#fff"
								}}
								/>
							</div>
						)}
				</div>
				<UserProfileAbout userToShowInModal={userToShowInModal} />
			</div>
		</Modal>
	)
}