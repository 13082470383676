export const deleteComment = async (
  postId, commentId
) => {
  try {
    const token = localStorage.token ? localStorage.getItem("token") : sessionStorage.getItem("token")
    const postInfo = {
      postId, commentId
    }
    const res = await fetch("/api/posts/pusblished-posts/comment/delete", {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Authorization: token
      },
      body: JSON.stringify(postInfo)
    })
    const json = await res.json()
    return json
  } catch (error) {
    const message = "Something went wrong"
    return message
  }
}