import React, { useState, useEffect } from "react"
import { useTranslation } from "react-i18next"
import { useSelector } from "react-redux"
import { getProfileAlbum } from "modules/connection-profile-card/components/profile-videos/api"
import { AddVideo } from "shared/empty-screen-images"
import ReactPlayer from "react-player"
import { baseUri } from "shared/constants"
import { AlbumComments } from "shared/album-comments"
import { MessageIcon } from "shared/icons"
import { AppSpinner } from "shared/app-spinner/app-spinner"
import styles from "./profile-album.module.css"
import { ProfileVideos } from "../profile-videos/profile-videos"

export const ProfileVideoAlbum = ({ profile }) => {
	const { t } = useTranslation()
	const { user } = useSelector(s => s)
	const { currentUser } = user
	const [albums, setAlbums] = useState([])
	const [currentId, setCurrentId] = useState(null)
	const [isLoading, setIsLoading] = useState(false)
	const [isAlbumCommentsActive, setIsAlbumCommentsActive] = useState(false)
	const [selectedAlbum, setSelectedAlbum] = useState(null)
	const [isVideoAlbum, setIsVideoAlbum] = useState(false)

	useEffect(() => {
		const getData = async () => {
			setIsLoading(true)
			if (profile.privacySettings.friendList !== "only-me") {
				if (profile.privacySettings.friendList === "public") {
					const res = await getProfileAlbum(profile._id)
					setAlbums(res.data.videoGallery)
				}
				if (profile.privacySettings.friendList === "relatives-only" && profile.family.includes(currentUser._id)) {
					const res = await getProfileAlbum(profile._id)
					setAlbums(res.data.videoGallery)
				}
				if (profile.privacySettings.friendList === "friends-only" && profile.friends.includes(currentUser._id)) {
					const res = await getProfileAlbum(profile._id)
					setAlbums(res.data.videoGallery)
				}
				if (profile.privacySettings.friendList === "friends-and-relatives" && (profile.friends.includes(currentUser._id) || profile.family.includes(currentUser._id))) {
					const res = await getProfileAlbum(profile._id)
					setAlbums(res.data.videoGallery)
				}
			}
			setIsLoading(false)
		}
		getData()
	}, [currentUser._id, profile])
	return (
		<div className={styles.wrap}>
			<div
				className={styles.container}
			>
				{currentId !== null
					? (
						<ProfileVideos
							albumId={currentId}
							currentProfileId={profile._id}
							setCurrentId={setCurrentId}
						/>
					)
					: (
						<div className={styles.main_content_container}>
							{!isLoading ? (
								<>
									{albums.filter(a => a.videos.length > 0).length === 0 ? (
										<div className={styles.main_no_albums}>
											<AddVideo />
											<div className={styles.no_albums_text}>{t("no-videos")}</div>
										</div>
									)
										: (
											albums.filter(a => a.videos.length > 0).map(album => (
												<div
													key={album.id}
													className={styles.album_card}
												>
													<div
														role="button"
														tabIndex={0}
														onClick={() => setCurrentId(album.id)}
														className={styles.album_card_content}
													>
														<div
															className={styles.video_palyer}
														>
															<ReactPlayer
																style={{ cursor: "pointer" }}
																width="100%"
																height="100%"
																url={`${baseUri}/${album.videos[album.videos.length - 1].path}`}
															/>
														</div>
													</div>
													<button
														type="button"
														className={styles.comments_btn}
														onClick={() => {
															setSelectedAlbum(album)
															setIsAlbumCommentsActive(true)
															setIsVideoAlbum(true)
														}}
													>
														<MessageIcon />
													</button>
													<h3 className={styles.title_album}>{album.name}</h3>
													<h4 className={styles.counter_items}>
														{album.videos.length}
														{" "}
														{t("videos")}
													</h4>
												</div>
											))
										)}
								</>
							) : (
								<div
									style={{
										zIndex: "9999", position: "fixed", top: "0", left: "0", right: "0", bottom: "0", backgroundColor: "#F2F2F2", width: "100vw", height: "100vh", display: "flex", alignItems: "center", justifyContent: "center"
									}}
								>
									<AppSpinner />
								</div>
							)}
						</div>
					)}
			</div>
			<AlbumComments
				isAlbumCommentsActive={isAlbumCommentsActive}
				setIsAlbumCommentsActive={setIsAlbumCommentsActive}
				ownerId={profile._id}
				album={selectedAlbum}
				setSelectedAlbum={setSelectedAlbum}
				userId={currentUser._id}
				setIsVideoAlbum={setIsVideoAlbum}
				isVideoAlbum={isVideoAlbum}
			/>
		</div>

	)
}