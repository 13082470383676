export const SET_SELECTED_PERSON_ID = "SET_SELECTED_PERSON_ID"

export const setSelectedPersonId = id => ({
  type: SET_SELECTED_PERSON_ID,
  id
})

export const selectedPersonId = (state = null, action) => {
  switch (action.type) {
    case SET_SELECTED_PERSON_ID:
      return action.id || null
    default:
      return state
  }
}