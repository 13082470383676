/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useEffect, useState } from "react"
import CustomScroll from "react-scrollbars-custom"
import { PlusOutlined } from "@ant-design/icons"
import { Input, Popover } from "antd"
import { useSelector } from "react-redux"
import { useTranslation } from "react-i18next"
import { getUsersByIds } from "shared/utils"
import { CreateGroupChat } from "../create-group-chat"
import { CreateChat } from "../create-chat/create-chat"
import { ChatListCard } from "../chat-list-card"
import styles from "./chat-list.module.css"

const PopoverContent = ({
	chatlist, user,
	currentUser, setCurrentUserConnections,
	setIsShowModalChat, setIsShowModalGroup,
	setVisible
}) => {
	const { t } = useTranslation()

	return (
		<div className={styles.popover_content}>
			<button
				type="button"
				onClick={async () => {
					setVisible(false)
					const currentUserCha = []
					const newUserCha = []
					chatlist.forEach(chat => {
						if (chat.users.length === 2 && !chat.roomName) {
							chat.users.forEach(u => {
								if (u._id !== user.currentUser._id) { currentUserCha.push(u._id) }
							})
						}
					})
					const usersToSelect = [...currentUser.friends, ...currentUser.family]
					usersToSelect.forEach(user => {
						if (typeof currentUserCha.find((u) => u === user) === "undefined") {
							newUserCha.push(user)
						}
					})
					const usersResponse = await getUsersByIds([...newUserCha])
					if (usersResponse.status === "successful") {
						setCurrentUserConnections(usersResponse.users)
						setIsShowModalChat(true)
					}
				}}
			>
				{t("start-new-chat")}
			</button>
			<button
				type="button"
				onClick={async () => {
					setVisible(false)
					const usersResponse = await getUsersByIds([
						...currentUser.friends, ...currentUser.family
					])
					if (usersResponse.status === "successful") {
						setCurrentUserConnections(usersResponse.users)
						setIsShowModalGroup(true)
					}
				}}
			>
				{t("start-new-group-chat")}
			</button>
		</div>
	)
}

export const ChatList = ({
	selectedChat, setSelectedChat, userId
}) => {
	const { messages } = useSelector(s => s)
	const { user } = useSelector(s => s)
	const { currentUser } = user
	const { t } = useTranslation()

	const [chatlist, setChatList] = useState([])
	const [isShowModalGroup, setIsShowModalGroup] = useState(false)
	const [isShowModalChat, setIsShowModalChat] = useState(false)
	const [currentUserConnections, setCurrentUserConnections] = useState([])
	const [visible, setVisible] = useState(false)

	useEffect(() => {
		if (userId && selectedChat === "") {
			const selected = messages.find(message => message.users.length === 2
				&& !message.roomName
				&& message.users.find(u => u._id === userId)
				&& message.users.find(u => u._id === currentUser._id))
			setSelectedChat(selected._id)
		}
		setChatList([...messages])
	}, [currentUser._id, messages, selectedChat, setSelectedChat, userId])

	return (
		<>
			<div
				className={`${styles.chatlist_wrap} ${selectedChat === "" ? styles.chatlist_wrap_small_screen : styles.chatlist_wrap_small_screen_disabled} `}
				onMouseLeave={() => {
					setVisible(false)
				}}
			>
				<div
					className={styles.chatlist_header}
					onClick={() => { setVisible(false) }}
				>
					{t("MESSAGES")}
				</div>
				<div
					className={styles.chatlist_add_chat}
				>
					<Popover
						placement="bottom"
						content={() => (
							<PopoverContent
								chatlist={chatlist}
								user={user}
								currentUser={currentUser}
								setCurrentUserConnections={setCurrentUserConnections}
								setIsShowModalChat={setIsShowModalChat}
								setIsShowModalGroup={setIsShowModalGroup}
								setVisible={setVisible}
								visible={visible}
							/>
						)}
						trigger="click"
						visible={visible}
					>
						<button
							className={styles.button_add_chat}
							style={{ fontSize: "24px" }}
							type="button"
							onClick={() => {
								setVisible(!visible)
							}}
						>
							<PlusOutlined />
						</button>
					</Popover>
					<div className={styles.all_add_chat}>
						<button
							type="button"
							onClick={async () => {
								const currentUserCha = []
								const newUserCha = []
								chatlist.forEach(chat => {
									if (chat.users.length === 2 && !chat.roomName) {
										chat.users.forEach(u => {
											if (u._id !== user.currentUser._id) { currentUserCha.push(u._id) }
										})
									}
								})
								const usersToSelect = [...currentUser.friends, ...currentUser.family]
								usersToSelect.forEach(user => {
									if (typeof currentUserCha.find((u) => u === user) === "undefined") {
										newUserCha.push(user)
									}
								})
								const usersResponse = await getUsersByIds([...newUserCha])
								if (usersResponse.status === "successful") {
									setCurrentUserConnections(usersResponse.users)
									setIsShowModalChat(true)
								}
							}}
						>
							{t("start-new-chat")}
						</button>
						<button
							type="button"
							onClick={async () => {
								const usersResponse = await getUsersByIds([
									...currentUser.friends, ...currentUser.family
								])
								if (usersResponse.status === "successful") {
									setCurrentUserConnections(usersResponse.users)
									setIsShowModalGroup(true)
								}
							}}
						>
							{t("start-new-group-chat")}
						</button>
					</div>
				</div>
				<CustomScroll
					onClick={() => { setVisible(false) }}
				>
					{chatlist.map(chat => {
						if (chat.users.length === 1) {
							return (
								<ChatListCard
									key={chat._id}
									id={chat._id}
									friendFirstName={chat.roomName
										? currentUser.firstName
										: (chat?.removedUsers[0]?.firstName || t("unknown-user"))}
									friendLastName={chat.roomName
										? currentUser.lastName
										: chat?.removedUsers[0]?.lastName}
									friendAvatar={chat.roomName
										? currentUser.avatar
										: chat?.removedUsers[0]?.avatar}
									chatMessages={chat.messages}
									online={chat.roomName
										? currentUser.isOnline
										: chat?.removedUsers[0]?.isOnline}
									selectedChat={selectedChat}
									setSelectedChat={setSelectedChat}
									roomName={chat.roomName}
									setVisible={setVisible}
								/>
							)
						}
						const userFriend = chat.users.find(friend => friend._id !== currentUser._id)
						if (!userFriend) {
							return null
						}
						return (
							<ChatListCard
								key={chat._id}
								id={chat._id}
								friendFirstName={userFriend.firstName}
								friendLastName={userFriend.lastName}
								friendAvatar={userFriend.avatar}
								chatMessages={chat.messages}
								online={userFriend.isOnline}
								selectedChat={selectedChat}
								setSelectedChat={setSelectedChat}
								roomName={chat.roomName}
								setVisible={setVisible}
								chatAvatar={chat.roomAvatar ? chat.roomAvatar : null}
							/>
						)
					})}
				</CustomScroll>
				<div
					onClick={() => { setVisible(false) }}
					className={styles.chatlist_footer}
				>
					<Input
						className={styles.chatlist_input_field}
						placeholder={t("search")}
						allowClear
						onChange={(e) => {
							const filteredChat = chatlist
								.filter(chat => chat.users
									.find(user => user._id !== currentUser._id && !chat.roomName
										&& (`${user.firstName} ${user.lastName}`)
											.toLowerCase()
											.includes(e.target.value.toLowerCase())))
							const filteredGroupChat = chatlist
								.filter(chat => chat.roomName?.toLowerCase()
									.includes(e.target.value.toLowerCase()))
							setChatList([...filteredChat, ...filteredGroupChat])
							if (e.target.value === "") {
								setChatList(messages)
							}
						}}
					/>
				</div>
			</div>
			<CreateGroupChat
				isShowModal={isShowModalGroup}
				setIsShowModal={setIsShowModalGroup}
				currentUserConnections={currentUserConnections}
				isAddUser={false}
				chatMembers={[]}
			/>
			<CreateChat
				isShowModal={isShowModalChat}
				setIsShowModal={setIsShowModalChat}
				currentUserConnections={currentUserConnections}
			/>
		</>
	)
}