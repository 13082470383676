export const resizeImage = async (
  token, formData
) => {
  try {
    const res = await fetch("/api/user-tree/resize-image", {
      method: "POST",
      headers: {
        Authorization: token
      },
      body: formData
    })
    const json = await res.json()
    return json
  } catch (error) {
    const message = "Something went wrong"
    return message
  }
}