import React, { useState, useRef } from "react"
import { debounce } from "lodash"
import { Mentions } from "antd"
import { useTranslation } from "react-i18next"
import { findUsers } from "./api/get-users"
import styles from "./mention.module.css"

export const MentionUser = ({
	setMentionedUsers,
	setVisible, setToggle, inputValue, setInputValue
}) => {
	const [users, setUsers] = useState([])
	const { t } = useTranslation()
	const [loading, setLoading] = useState(true)
	const { current: handleData } = useRef(debounce(async (search) => {
		setLoading(true)
		const res = await findUsers(search)
		const users = []
		res.forEach(el => {
			const userInfo = {
				id: el._id,
				display: `${el.firstName} ${el.lastName}`
			}
			users.push(userInfo)
		})
		setUsers(users)
		setLoading(false)
	}, 1000))
	const { Option } = Mentions
	return (
		<Mentions
			split=","
			autoSize
			value={inputValue}
			loading={loading}
			onChange={(text) => {
				setLoading(true)
				if (text === "") {
					setVisible(false)
				} else {
					setVisible(true)
				}
				setInputValue(text.replace(/,@/, "@"))
				setToggle(false)
			}}
			onSearch={handleData}
			placeholder={t("leave-a-comment")}
			placement="top"
			className={styles.mention}
			onSelect={(e) => setMentionedUsers(users => [...users, e.key])}
		>
			{users.map(user => (
				<Option className={styles.mention_user} key={user.id} value={`${user.display}`}>{user.display}</Option>
			))}
		</Mentions>
	)
}