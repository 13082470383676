/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/control-has-associated-label */
import React, { Fragment } from "react"
import { Empty } from "antd"
import { getImageUri } from "shared/utils"
import moment from "moment"
import CustomScroll from "react-scrollbars-custom"
import { EmptyChatImage } from "shared/empty-screen-images"
import { useTranslation } from "react-i18next"
import { CheckedIcon } from "shared/icons"
import { ChatAvatar } from "../../chat-avatar"
import styles from "./rendering-messages.module.css"

export const RenderingMessages = ({
	chat, setVisiblePopover, chatScrollbar, currentUser, selectedMessages,
	setIsActive, setSelectedPhoto, setSelectedMessages, setAllPhotos
}) => {
	const { t } = useTranslation()
	const filterMessages = (currentUser) => chat.users.find(user => user._id === currentUser)

	const findDeletedUserGroup = (userId) => chat.removedUsers
		.find(user => user._id.toString() === userId.toString())

	const setSystemText = (text) => {
		if (text === "JOINED_CHAT") {
			return t("joined-chat")
		}
		if (text === "KICKED_FROM_CHAT") {
			return t("was-kicked-from-chat")
		}
		if (text === "LEAVED_CHAT") {
			return t("leaved-chat")
		}
		if (text === "NEW_CHAT_ADMIN") {
			return t("new-chat-admin")
		}
	}

	return (
		<div
			onClick={() => {
				setVisiblePopover(false)
			}}
			className={styles.messages_main_content}
		>
			{chat.messages?.length > 0
				? (
					<div className={styles.content_container}>
						<CustomScroll
							ref={chatScrollbar}
						>
							{
								chat.messages
									.filter(message => !(message.user === currentUser._id && message.isDeleted))
									.map((message, index) => {
										const setAvatar = () => {
											let currentСhatCompanion = filterMessages(message.user)
											if (!currentСhatCompanion) {
												if (chat.roomName) {
													currentСhatCompanion = findDeletedUserGroup(message.user)
												}
												if (!chat.roomName) {
													currentСhatCompanion = chat.removedUsers
														.find(user => user._id !== currentUser._id)
												}
											}
											if (index === 0 && !message.system) {
												return (
													<>
														{
															message.user !== currentUser._id && (
																<ChatAvatar
																	friendAvatar={currentСhatCompanion.avatar}
																	online={currentСhatCompanion.isOnline}
																/>
															)
														}
													</>
												)
											}
											if (index !== 0 && !message.system) {
												if (currentСhatCompanion) {
													if (chat.messages[index - 1].user !== currentСhatCompanion._id
														&& message.user !== currentUser._id
													) {
														return (
															<ChatAvatar
																friendAvatar={currentСhatCompanion.avatar}
																online={currentСhatCompanion.isOnline}
															/>
														)
													}
													return <div className={styles.hidden_avatar} />
												}
												return (
													<ChatAvatar
														friendAvatar={null}
														online={null}
													/>
												)
											}
										}
										let userData
										const currentUserData = filterMessages(message?.user)
										if (currentUserData) {
											userData = currentUserData
										}
										if (!currentUserData) {
											userData = findDeletedUserGroup(message?.user)
										}
										return (
											<Fragment key={message.id}>
												{message.system
													? (
														<div className={styles.system_message}>{`${t("user")} ${userData && userData?.firstName} ${userData && userData?.lastName} ${setSystemText(message.text)}`}</div>
													)
													: (
														<div
															className={
																selectedMessages.includes(message)
																	? styles.message_delete : null
															}
														>
															<div
																className={message.user === currentUser._id
																	? styles.user_message
																	: styles.message}
															>
																{setAvatar()}
																<div
																	className={message.user === currentUser._id
																		? styles.user_message_text_wrap
																		: styles.message_text_wrap}
																	onClick={() => {
																		if (message.user === currentUser._id) {
																			if (selectedMessages.includes(message)) {
																				setSelectedMessages(
																					[...selectedMessages.filter(m => m !== message)]
																				)
																			} else {
																				setSelectedMessages([...selectedMessages, message])
																			}
																		}
																	}}
																>
																	<div
																		className={styles.message_text}
																	>
																		{message.text}
																	</div>
																	{message.image && (
																		<div className={styles.image_content}>
																			<div
																				style={{
																					position: "absolute",
																					top: "0",
																					left: "0",
																					width: "100%",
																					height: "100%",
																					display: "flex",
																					alignItems: "center",
																					justifyContent: "center",
																					cursor: "pointer",
																					backgroundImage: `url(${getImageUri(message.image)})`,
																					backgroundPosition: "center center",
																					backgroundSize: "cover"
																				}}
																				role="button"
																				tabIndex={0}
																				onClick={(e) => {
																					e.stopPropagation()
																					setIsActive(true)
																					setSelectedPhoto(getImageUri(message.image))
																					setAllPhotos([message.image])
																				}}
																			/>
																		</div>
																	)}
																	<div
																		className={message.user === currentUser._id
																			? styles.user_message_date : styles.message_date}
																	>
																		{moment(message.date).format("lll")}
																		&nbsp;
																		{message.whoRead.length > 1
																			&& message.user === currentUser._id
																			&& (<CheckedIcon />)}
																	</div>
																</div>
															</div>
														</div>
													)}
											</Fragment>
										)
									})
							}
						</CustomScroll>
					</div>
				)
				: (
					<Empty
						className={styles.empty_wrap}
						description={(
							<div>{t("there-are-no-messages-yet")}</div>
						)}
						image={(
							<EmptyChatImage />
						)}
					/>
				)}
		</div>
	)
}