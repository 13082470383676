import React, { useState, useEffect } from "react"
import { useSelector } from "react-redux"
import { EmptyConnectionsImage } from "shared/empty-screen-images"
import { v4 as uuidv4 } from "uuid"
import { getUserConnections } from "shared/right-panel/api"
import { NavLink } from "react-router-dom"
import { removeFromFriends, removeFromFamily } from "modules/friends/api"
import { useTranslation } from "react-i18next"
import { UserConnectionCard } from "./connection-card"
import styles from "./user-personal-connections.module.css"

export const UserPersonalConnections = () => {
	const { user } = useSelector(s => s)
	const { currentUser } = user
	const { t } = useTranslation()
	const [friendsOrFamilySelected, setFriendsOrFamilySelected] = useState("family")
	const [userConnections, setUserConnections] = useState([])

	useEffect(() => {
		const getData = async () => {
			const result = await getUserConnections()
			setUserConnections([...result.result.family, ...result.result.friends])
		}
		getData()
	}, [currentUser.family, currentUser.friends])

	const removeFamilyMember = async (parentId) => {
		await removeFromFamily(parentId)
	}

	const removeFriend = async (friendId) => {
		await removeFromFriends(friendId)
	}

	const friendsContent = () => {
		if (friendsOrFamilySelected === "family") {
			if (currentUser.family.length === 0) {
				return (
					<div className={styles.content_e}>
						<div>
							<EmptyConnectionsImage />
						</div>
						<div>
							{t("looks-like-you-haven't-added-any-relatives-yet-Start-your-search")}
							{" "}
							<NavLink to="/search-people">{t("now")}</NavLink>
						</div>
					</div>
				)
			}
			return (
				<div className={styles.content}>
					{
						currentUser.family.map(parent => {
							const user = userConnections.find(p => p._id === parent)
							if (!user) {
								return null
							}
							return (
								<UserConnectionCard
									key={uuidv4()}
									user={user}
									currentUser={currentUser}
									removeConnection={removeFamilyMember}
									friendsOrFamilySelected={friendsOrFamilySelected}
								/>
							)
						})
					}
				</div>
			)
		}
		if (friendsOrFamilySelected === "friends") {
			if (currentUser.friends.length === 0) {
				return (
					<div className={styles.content_e}>
						<div>
							<EmptyConnectionsImage />
						</div>
						<div>
							{t("looks-like-you-haven't-added-any-friends-yet-Start-your-search")}
							{" "}
							<NavLink to="/search-people">{t("now")}</NavLink>
						</div>
					</div>
				)
			}
			return (
				<div className={styles.content}>
					{currentUser.friends.map(friend => {
						const user = userConnections.find(p => p._id === friend)
						if (!user) {
							return null
						}
						return (
							<UserConnectionCard
								key={uuidv4()}
								user={user}
								currentUser={currentUser}
								removeConnection={removeFriend}
								friendsOrFamilySelected={friendsOrFamilySelected}
							/>
						)
					})}
				</div>
			)
		}

		return null
	}

	return (
		<div className={styles.wrap}>
			<div className={styles.container}>
				<div className={styles.results_header}>
					<button
						type="button"
						className={friendsOrFamilySelected === "family" ? `${styles.header_button} ${styles.button_selected}` : styles.header_button}
						onClick={() => {
							setFriendsOrFamilySelected("family")
						}}
					>
						{t("family")}
						<div>{currentUser.family.length}</div>
					</button>
					<button
						type="button"
						className={friendsOrFamilySelected === "friends" ? `${styles.header_button} ${styles.button_selected}` : styles.header_button}
						onClick={() => {
							setFriendsOrFamilySelected("friends")
						}}
					>
						{t("friends")}
						<div>{currentUser.friends.length}</div>
					</button>
				</div>
				{friendsContent()}
			</div>
		</div>
	)
}