import React, { useState, useEffect } from "react"
import CustomScroll from "react-scrollbars-custom"
import { getVideos } from "modules/videos/api"
import { AppSpinner } from "shared/app-spinner/app-spinner"
import styles from "./videos.module.css"
import { NoVideos } from "./no-videos"
import { Video } from "./video"
import { VideosHeader } from "./videos-header"

export const Videos = ({ match }) => {
	const { albumId } = match.params
	const [videos, setVideos] = useState([])
	const [albumName, setAlbumName] = useState(null)
	const [isLoading, setIsLoading] = useState(false)
	const [file, setFile] = useState(null)
	const [filename, setFilename] = useState("New video")
	const [visible, setVisible] = useState(false)
	const [videoName, setVideoName] = useState(null)
	const [isEditMode, setIsEditMode] = useState(false)
	useEffect(() => {
		const get = async () => {
			setIsLoading(true)
			const res = await getVideos(albumId)
			setAlbumName(res.data.album.name)
			setVideos(res.data.album.videos)
			setIsLoading(false)
		}
		get()
	}, [albumId, setAlbumName, setVideos])
	return (
		<div className={styles.container}>
			{!isLoading ? (
				<CustomScroll>
					<VideosHeader
						videos={videos}
						albumName={albumName}
						isEditMode={isEditMode}
						visible={visible}
						file={file}
						videoName={videoName}
						albumId={albumId}
						setVideos={setVideos}
						setVideoName={setVideoName}
						setFilename={setFilename}
						filename={filename}
						setFile={setFile}
						setVisible={setVisible}
						setIsEditMode={setIsEditMode}
					/>
					<div className={styles.main_content_container}>

						{videos.length === 0
							&& (
								<NoVideos setVisible={setVisible} />
							)}
						{videos && (
							videos.map(video => (
								<Video
									video={video}
									key={video.path}
									albumId={albumId}
									setVideos={setVideos}
									fileName={filename}
								/>
							))
						)}
					</div>
				</CustomScroll>
			)
				: <AppSpinner style={{ width: "100%", height: "100%" }} />}
		</div>
	)
}