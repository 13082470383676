export const SET_ADAPTIVE_ZOOM = "SET_ADAPTIVE_ZOOM"

export const setAdaptiveZoom = adaptiveZoom => ({
  type: SET_ADAPTIVE_ZOOM,
  adaptiveZoom
})

export const adaptiveZoom = (state = false, action) => {
  switch (action.type) {
    case SET_ADAPTIVE_ZOOM:
      return action.adaptiveZoom
    default:
      return state
  }
}