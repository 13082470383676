import React from "react"
import { Provider } from "react-redux"
import { BrowserRouter as Router } from "react-router-dom"
import { Navigation } from "./navigation"
import { store } from "./redux/store"
import "./global-style.css"
import styles from "./app.module.css"

export const App = () => (
	<Provider store={store}>
		<Router>
			<div className={styles.wrap}>
				<Navigation />
			</div>
		</Router>
	</Provider>
)