import React, { useState } from "react"
import { Popover, Button } from "antd"
import { EditOutlined } from "@ant-design/icons"
import { updatePerson, setSelectedPersonId } from "modules/family-tree/ducks"
import { useHistory } from "react-router-dom"
import { useDispatch } from "react-redux"
import { store } from "app/redux/store"
import { useTranslation } from "react-i18next"
import { CloseCircleIcon } from "shared/icons"
import { SearchModal } from "./modal"
import styles from "../side-panel.module.css"

const getModal = (visible, setVisible, token) => (
	<SearchModal visible={visible} setVisible={setVisible} token={token} />
)

export const Header = ({ person }) => {
	const { t } = useTranslation()
	const dispatch = useDispatch()
	const history = useHistory()
	const [visible, setVisible] = useState(false)
	const [visiblePopover, setVisiblePopover] = useState(false)
	const token = localStorage.token ? localStorage.getItem("token") : sessionStorage.getItem("token")

	const translateName = () => {
		const splitedName = person.name.split(" ")
		const translatedSplitedName = splitedName.map((name, index) => {
			if (name === "Father") {
				if (index === 0) {
					name = `${t("father-one")}`
				} else {
					name = `${t("father-next")}`
				}
			}
			if (name === "Mother") {
				if (index === 0) {
					name = `${t("mother-one")}`
				} else {
					name = `${t("mother-next")}`
				}
			}
			if (name === "Child") {
				if (index === 0) {
					name = `${t("child-one")}`
				} else {
					name = `${t("child-next")}`
				}
			}
			if (name === "of") {
				name = `${t("of")}`
			}
			return name
		})
		const translatedName = translatedSplitedName.join(" ")
		return translatedName
	}

	const contentPopover = (
		<>
			<p>{t("you-want-to-change-selected-user-profile-link-or-clear-it?")}</p>
			<div className={styles.buttons_popover}>
				<Button size="small" onClick={() => setVisiblePopover(false)} type="button">{t("close")}</Button>
				<Button
					size="small"
					onClick={() => {
						setVisiblePopover(false)
						setVisible(true)
					}}
					type="button"
				>
					{t("change")}
				</Button>
				<Button
					size="small"
					onClick={() => store.dispatch(updatePerson({
						...person,
						profileId: "",
						from: "",
						livesIn: "",
						birthDate: "",
						deathDate: "",
						gender: "unknown",
						description: "",
						avatar: "",
						inPending: false
					}))}
					type="button"
				>
					{t("clear")}
				</Button>
			</div>
		</>
	)

	const handleVisibleChange = (visible) => {
		setVisiblePopover(visible)
	}

	return (
		<div className={styles.side_panel_header}>
			<button
				className={styles.header_back_button}
				type="button"
				onClick={async () => {
					dispatch(setSelectedPersonId(null))
				}}
			>
				<CloseCircleIcon />
			</button>
			<div className={styles.side_panel_item_title}>{translateName()}</div>
			{person.profileId !== "" && !person.isUser && (
				<div className={styles.side_panel_link}>
					<button
						type="button"
						className={styles.side_panel_view_profile}
						onClick={() => {
							history.push({ pathname: "/profile", userId: person.profileId })
						}}
					>
						{t("view-profile")}
					</button>
					<div>
						<Popover
							overlayStyle={{ width: "300px" }}
							placement="topLeft"
							trigger="click"
							content={contentPopover}
							arrowPointAtCenter
							visible={visiblePopover}
							onVisibleChange={handleVisibleChange}
						>
							<button className={`${styles.side_panel_link_btn} ${styles.side_panel_edit_link}`} type="button">
								<EditOutlined />
							</button>
						</Popover>
						{getModal(visible, setVisible, token)}
					</div>
				</div>
			)}
		</div>
	)
}