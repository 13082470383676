export const loginUser = async (inputEmail, inputPassword) => {
	if (inputEmail !== "" && inputPassword !== "") {
		const inputInfo = {
			email: inputEmail,
			password: inputPassword
		}
		try {
			const res = await fetch("/api/auth/login", {
				method: "POST",
				headers: {
					"Content-Type": "application/json"
				},
				body: JSON.stringify(inputInfo)
			})
			const json = await res.json()
			return json
		} catch (error) {
			console.error("Error:", error)
		}
	}
	return true
}