/* eslint-disable guard-for-in */
/* eslint-disable no-restricted-syntax */
import React, { useState, useEffect } from "react"
import { useSelector } from "react-redux"
import GoogleMapReact from "google-map-react"
import { EmptyMapImage } from "shared/empty-screen-images"
import { useTranslation } from "react-i18next"
import { getUsers } from "./api"
import styles from "./map.module.css"
import { Marker } from "./marker"
import { MarkerForMoreUsers } from "./markers-for-more-users"

export const MapPage = () => {
	const { user } = useSelector(s => s)
	const { currentUser } = user
	const [usersList, setUsersList] = useState([])
	const [stylerPosition, setStylerPosition] = useState("left")
	const [show, setShow] = useState(null)
	const { t } = useTranslation()

	useEffect(() => {
		const getUserData = async () => {
			const users = await getUsers(currentUser.family)
			const filteredUsers = users.users.filter(u => u.coordinates.latitude
				&& u.coordinates.longitude)
			setUsersList(filteredUsers)
		}
		getUserData()
	}, [currentUser.family])

	const handleChange = async (value) => {
		if (value.target.value === "Friends") {
			const users = await getUsers(currentUser.friends)
			setUsersList(users.users)
			setStylerPosition("right")
		}
		if (value.target.value === "Family") {
			const users = await getUsers(currentUser.family)
			setUsersList(users.users)
			setStylerPosition("left")
		}
	}

	const usersGroups = usersList.reduce((acc, user) => {
		const { latitude, longitude } = user.coordinates
		const key = `latitude:${latitude}, longitude:${longitude}`
		if (!acc[key]) { acc[key] = [] }
		acc[key].push(user)
		return acc
	}, {})

	const markers = []
	for (const key in usersGroups) {
		const users = usersGroups[key]
		users.map((user, index) => markers.push(
			users.length < 3 ? (
				<Marker
					key={user._id}
					lat={user.coordinates?.latitude}
					lng={user.coordinates?.longitude}
					person={user}
					isCurrentUser={currentUser._id}
					show={show}
					index={index}
					setShow={setShow}
				/>
			) : (
				<MarkerForMoreUsers
					key={user._id}
					lat={user.coordinates?.latitude}
					lng={user.coordinates?.longitude}
					users={users}
					conterUsers={users.length}
				/>
			)
		))
	}
	return (
		<>
			<div
				className={styles.card_container}
				style={{
					border: `${usersList.length === 0
						&& !currentUser.coordinates.latitude
						&& !currentUser.coordinates.longitude
						? "1px solid lightgrey"
						: "none"}`
				}}
			>
				<div className={styles.buttons_container}>
					<input className={styles.input} style={{ color: `${stylerPosition === "left" ? "#fff" : "#626262"}` }} type="button" value="Family" onClick={(value) => handleChange(value)} />
					<input className={styles.input} style={{ color: `${stylerPosition === "right" ? "#fff" : "#626262"}` }} type="button" value="Friends" onClick={(value) => handleChange(value)} />
					<div className={stylerPosition === "left" ? styles.input_styler : `${styles.input_styler} ${styles.input_styler_right}`} />
				</div>
			</div>
			<div
				className={styles.map_wrap}
			>
				<div className={styles.map_container}>
					{usersList.length === 0
						&& !currentUser.coordinates.latitude
						&& !currentUser.coordinates.longitude
						? (
							<div
								className={styles.map_empty}
							>
								<EmptyMapImage />
								<div>{t("no-users-to-locate-on-map")}</div>
							</div>
						)
						: (
							<div className={styles.map}>
								<GoogleMapReact
									defaultZoom={1}
									defaultCenter={{
										lat: currentUser.coordinates.latitude
											? currentUser.coordinates.latitude
											: 39.106667,
										lng: currentUser.coordinates.longitude
											? currentUser.coordinates.longitude
											: -94.676392
									}}
									bootstrapURLKeys={{
										key: process.env.REACT_APP_GOOGLE_MAP_API_KEY,
										language: "en",
										region: "en",
										libraries: ["places"]
									}}
								>
									{markers}
									{currentUser.coordinates.latitude && currentUser.coordinates.longitude && (
										<Marker
											key={currentUser._id}
											lat={currentUser.coordinates?.latitude}
											lng={currentUser.coordinates?.longitude}
											person={currentUser}
											isCurrentUser={currentUser._id}
											show={show}
											setShow={setShow}
										/>
									)}
								</GoogleMapReact>
							</div>
						)}
				</div>
			</div>
		</>
	)
}