import React from "react"
import { UserAvatar } from "shared/avatar"
import { DeleteIcon } from "shared/icons"
import moment from "moment-timezone"
import { deletePhotoComment } from "../api"
import styles from "./image-comment.module.css"

export const ImageComment = ({
	comment, userId, ownerId, photoPath, albumId, setPhotoComments
}) => (
	<div
		className={styles.comment_card}
	>
		<div className={styles.comment_card_avatar}>
			<UserAvatar user={comment.user} />
		</div>
		<div className={styles.comment_card_info}>
			<div className={styles.comment_card_author}>
				{comment.user.firstName}
				{" "}
				{comment.user.lastName}
				{comment.commentAuthor === userId && (
					<button
						type="button"
						onClick={async () => {
							const comments = await deletePhotoComment(
								albumId, photoPath, comment.commentId, ownerId
							)
							setPhotoComments(comments.data.comments.reverse())
						}}
					>
						<DeleteIcon />
					</button>
				)}
				{comment.commentAuthor !== userId && userId === ownerId && (
					<button
						type="button"
						onClick={async () => {
							const comments = await deletePhotoComment(
								albumId, photoPath, comment.commentId, ownerId
							)
							setPhotoComments(comments.data.comments.reverse())
						}}
					>
						<DeleteIcon />
					</button>
				)}
			</div>
			<div className={styles.comment_card_text}>{comment.commentText}</div>
			<div
				className={styles.comment_card_time}
			>
				{moment(comment.commentDate).tz(`${moment.tz.guess()}`).fromNow()}
			</div>
		</div>
	</div>
)
