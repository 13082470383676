/* eslint-disable react/jsx-filename-extension */
import React, { Suspense } from "react"
import ReactDOM from "react-dom"
import { App } from "./app"
import "./app/i18n"

ReactDOM.render(
	<Suspense fallback={(<div>Loading</div>)}>
		<App />
	</Suspense>,
	document.getElementById("root")
)