import React from "react"
import "antd/dist/antd.css"
import { HomeOutlined } from "@ant-design/icons"
import { NavLink } from "react-router-dom"
import { useSelector, useDispatch } from "react-redux"
import CustomScroll from "react-scrollbars-custom"
import { userLogOut, updateUser } from "app/redux/user"
import { socket } from "app/socket"
import { UserAvatar } from "shared/avatar"
import { Select } from "antd"
import { useTranslation } from "react-i18next"
import {
	TreeIcon, MapIcon, FriendsIcon
} from "shared/header/header-icons"
import { setUserOffline, setLanguage } from "./api"
import {
	SelectorIcon,
	MessagesIcon,
	SettingsIcon,
	PersonalInfoIcon,
	ContactUsIcon,
	LogOutIcon,
	LanguageIcon,
	PhotosIcon,
	VideosIcon,
	CalendarIcon,
	VideoChatIcon
} from "./left-panel-icons"
import styles from "./left-panel.module.css"

const { Option } = Select

export const LeftPanel = ({ isBurgerActive, setIsBurgerActive }) => {
	const { user } = useSelector(s => s)
	const { messages } = useSelector(s => s)
	const { currentUser } = user
	const dispatch = useDispatch()
	const { t, i18n } = useTranslation()
	const logOut = async () => {
		const token = localStorage.token ? localStorage.getItem("token") : sessionStorage.getItem("token")
		await setUserOffline(token)
		localStorage.clear()
		sessionStorage.clear()
		dispatch(userLogOut())
		socket.emit("USER_LOGOUT")
		window.location.reload()
	}

	return (
		<>
			<div className={`${styles.wrap} ${isBurgerActive ? styles.burger_wrap : styles.burger_wrap_closed}`}>
				<button
					type="button"
					className={styles.user_info_button}
					onClick={() => setIsBurgerActive(false)}
				>
					<NavLink to="/user-profile" className={styles.user_info} activeClassName={styles.user_info_active}>
						<UserAvatar user={currentUser} />
						<div className={styles.user_info_card}>
							<div className={styles.user_info_card_name}>
								{currentUser.firstName}
								{" "}
								{currentUser.lastName}
								{" "}
								{currentUser.maidenName && currentUser.maidenName !== "" ? `(${currentUser.maidenName})` : ""}
							</div>
							<div className={styles.user_info_card_tag_name}>{currentUser.email}</div>
						</div>
					</NavLink>
				</button>
				<ul className={styles.list}>
					<CustomScroll>
						<li className={styles.list_element}>
							<button
								type="button"
								className={styles.list_element_button}
								onClick={() => setIsBurgerActive(false)}
							>
								<NavLink to="/feed" activeClassName={styles.list_element_link_active} className={styles.list_element_link}>
									<div className={styles.list_selector}><SelectorIcon /></div>
									<div className={styles.list_icon}><HomeOutlined style={{ fontSize: "24px" }} /></div>
									<p>{t("Feed")}</p>
								</NavLink>
							</button>
						</li>
						<li className={styles.list_element}>
							<button
								type="button"
								className={styles.list_element_button}
								onClick={() => setIsBurgerActive(false)}
							>
								<NavLink to="/photo" activeClassName={styles.list_element_link_active} className={styles.list_element_link}>
									<div className={styles.list_selector}><SelectorIcon /></div>
									<div className={styles.list_icon}><PhotosIcon /></div>
									<p>{t("photos")}</p>
								</NavLink>
							</button>
						</li>
						<li className={styles.list_element}>
							<button
								type="button"
								className={styles.list_element_button}
								onClick={() => setIsBurgerActive(false)}
							>
								<NavLink to="/video-gallery" activeClassName={styles.list_element_link_active} className={styles.list_element_link}>
									<div className={styles.list_selector}><SelectorIcon /></div>
									<div className={styles.list_icon}><VideosIcon /></div>
									<p>{t("videos")}</p>
								</NavLink>
							</button>
						</li>
						<li className={styles.list_element}>
							<button
								type="button"
								className={styles.list_element_button}
								onClick={() => setIsBurgerActive(false)}
							>
								<NavLink to="/calendar" activeClassName={styles.list_element_link_active} className={styles.list_element_link}>
									<div className={styles.list_selector}><SelectorIcon /></div>
									<div className={styles.list_icon}><CalendarIcon /></div>
									<p>{t("calendar")}</p>
								</NavLink>
							</button>
						</li>
						{(currentUser.email === "andrusha.v47@gmail.com"
							|| currentUser.email === "sagetarius@gmail.com" || currentUser.email === "yakushevich@live.com" || currentUser.email === "test11@test.com" || currentUser.email === "test10@test.com") && (
								<li className={styles.list_element}>
									<button
										type="button"
										className={styles.list_element_button}
										onClick={() => setIsBurgerActive(false)}
									>
										<NavLink to="/videochat" activeClassName={styles.list_element_link_active} className={styles.list_element_link}>
											<div className={styles.list_selector}><SelectorIcon /></div>
											<div className={styles.list_icon}><VideoChatIcon /></div>
											<p>{t("videochat")}</p>
										</NavLink>
									</button>
								</li>
							)}
						<li className={styles.list_element}>
							<button
								type="button"
								className={styles.list_element_button}
								onClick={() => setIsBurgerActive(false)}
							>
								<NavLink to="/personal-info" activeClassName={styles.list_element_link_active} className={styles.list_element_link}>
									<div className={styles.list_selector}><SelectorIcon /></div>
									<div className={styles.list_icon}><PersonalInfoIcon /></div>
									<p>{t("personal-info")}</p>
								</NavLink>
							</button>
						</li>
						<li className={styles.list_element}>
							<button
								type="button"
								className={styles.list_element_button}
								onClick={() => setIsBurgerActive(false)}
							>
								<NavLink to="/messages" activeClassName={styles.list_element_link_active} className={styles.list_element_link}>
									<div className={styles.list_selector}><SelectorIcon /></div>
									<div className={styles.list_icon}><MessagesIcon /></div>
									<p>{t("messages")}</p>
									{messages
										.find(message => message.messages
											.find(m => !m.whoRead
												.find(reader => reader === currentUser._id)))
										? (
											<div className={styles.messages_link_unreaded_messages}>
												{messages
													.filter(message => message.messages
														.find(m => !m.whoRead
															.find(reader => reader === currentUser._id))).length}
											</div>
										) : null}
								</NavLink>
							</button>
						</li>
						<li className={`${styles.list_element} ${styles.list_element_small_screen}`}>
							<button
								type="button"
								className={styles.list_element_button}
								onClick={() => setIsBurgerActive(false)}
							>
								<NavLink to="/family-tree" activeClassName={styles.list_element_link_active} className={styles.list_element_link}>
									<div className={styles.list_selector}><SelectorIcon /></div>
									<div className={styles.list_icon}><TreeIcon /></div>
									<p>{t("family-tree")}</p>
								</NavLink>
							</button>
						</li>
						<li className={`${styles.list_element} ${styles.list_element_small_screen}`}>
							<button
								type="button"
								className={styles.list_element_button}
								onClick={() => { setIsBurgerActive(false) }}
							>
								<NavLink to="/map" activeClassName={styles.list_element_link_active} className={styles.list_element_link}>
									<div className={styles.list_selector}><SelectorIcon /></div>
									<div className={styles.list_icon}><MapIcon /></div>
									<p>{t("map")}</p>
								</NavLink>
							</button>
						</li>
						<li className={`${styles.list_element} ${styles.list_element_small_screen}`}>
							<button
								type="button"
								className={styles.list_element_button}
								onClick={() => setIsBurgerActive(false)}
							>
								<NavLink to="/connections" activeClassName={styles.list_element_link_active} className={styles.list_element_link}>
									<div className={styles.list_selector}><SelectorIcon /></div>
									<div className={styles.list_icon}><FriendsIcon /></div>
									<p>{t("connections")}</p>
								</NavLink>
							</button>
						</li>
						<li className={styles.list_element}>
							<button
								type="button"
								className={styles.list_element_button}
								onClick={() => setIsBurgerActive(false)}
							>
								<NavLink to="/settings" activeClassName={styles.list_element_link_active} className={styles.list_element_link}>
									<div className={styles.list_selector}><SelectorIcon /></div>
									<div className={styles.list_icon}><SettingsIcon /></div>
									<p>{t("settings")}</p>
								</NavLink>
							</button>
						</li>
						<li className={styles.list_element}>
							<button
								type="button"
								className={styles.list_element_button}
								onClick={() => setIsBurgerActive(false)}
							>
								<NavLink to="/contact-us" activeClassName={styles.list_element_link_active} className={styles.list_element_link}>
									<div className={styles.list_selector}><SelectorIcon /></div>
									<div className={`${styles.list_icon}`}><ContactUsIcon /></div>
									<p>{t("contact-us")}</p>
								</NavLink>
							</button>
						</li>
						<li className={styles.list_element}>
							<button type="button" className={`${styles.list_element_link} ${styles.list_element_dropdown}`}>
								<LanguageIcon />
								<p>{t("change-language")}</p>
								<Select
									className={styles.selector}
									dropdownClassName={styles.dropdown_container}
									value={currentUser.language}
									bordered={false}
									onChange={async (value) => {
										const status = await setLanguage(value)
										if (status.status === "OK") {
											dispatch(updateUser({
												...currentUser,
												language: value
											}))
											i18n.changeLanguage(value)
										}
									}}
								>
									<Option value="en">English</Option>
									<Option value="ru">Русский</Option>
									<Option value="bel">Беларускі</Option>
									<Option value="ro">Română</Option>

								</Select>
							</button>
						</li>
					</CustomScroll>
				</ul>
				<button
					type="button"
					className={`${styles.list_element_link} ${styles.logout}`}
					onClick={() => {
						logOut()
					}}
				>
					<div className={`${styles.list_icon} ${styles.logout_icon}`}><LogOutIcon /></div>
					<p>{t("logout")}</p>
				</button>
			</div>
			<div
				className={`${styles.blur} ${isBurgerActive ? styles.burger_blur : styles.burger_blur_closed}`}
				role="button"
				tabIndex={0}
				onClick={() => setIsBurgerActive(!isBurgerActive)}
			>
				{" "}
			</div>
		</>
	)
}