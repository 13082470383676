import React, { useState } from "react"
import { Input, Button, message } from "antd"
import { useTranslation } from "react-i18next"
import { changePassword } from "../api"
import styles from "../settings.module.css"

export const SettingsSecurity = () => {
	const [oldPassword, setOldPasssword] = useState("")
	const [newPassword, setNewPassword] = useState("")
	const [newPasswordCheck, setNewPasswordCheck] = useState("")
	const { t } = useTranslation()

	const passwordInputsData = [
		{
			value: oldPassword,
			placeholder: "enter-current-password",
			setValue: setOldPasssword
		},
		{
			value: newPassword,
			placeholder: "enter-new-password",
			setValue: setNewPassword
		},
		{
			value: newPasswordCheck,
			placeholder: "enter-new-password-again",
			setValue: setNewPasswordCheck
		}
	]

	return (
		<div className={styles.section_content}>
			{passwordInputsData.map(el => (
				<Input.Password
					className={styles.input_field}
					value={el.value}
					placeholder={t(el.placeholder)}
					onChange={(e) => el.setValue(e.target.value)}
					key={el.placeholder}
				/>
			))}
			<Button
				type="primary"
				className={oldPassword.length >= 8 && newPassword.length >= 8 ? styles.confirm_button : `${styles.confirm_button} ${styles.confirm_button_disabled}`}
				disabled={oldPassword.length < 8 && newPassword.length < 8}
				onClick={async () => {
					if (oldPassword.length < 8) {
						message.error(`${t("old-password-is-too-short")}`)
					} else if (newPassword.length < 8) {
						message.error(`${t("password-is-too-short")}`)
					} else if (oldPassword === newPassword) {
						message.error(`${t("old-and-new-passwords-must-not-match")}`)
					} else if (newPassword !== newPasswordCheck) {
						message.error(`${t("both-new-password-fields-must-be-equal")}`)
					} else {
						const response = await changePassword(oldPassword, newPassword)
						if (response.status === "reject") {
							message.error(`${t("please-enter-the-correct-old-password")}`)
						}
						if (response.status === "success") {
							message.success(`${t("password-updated-successfully")}`)
						}
						if (response.status === "Not Allowed") {
							message.error(t("passworde-notice"))
						}
						if (!response.status) {
							message.error(`${t("please-enter-the-correct-old-password")}`)
						}
						setOldPasssword("")
						setNewPassword("")
						setNewPasswordCheck("")
					}
				}}
			>
				{t("change")}
			</Button>
		</div>
	)
}