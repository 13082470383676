import React, { useState } from "react"
import { useSelector } from "react-redux"
import { Button, message } from "antd"
import { v4 as uuidv4 } from "uuid"
import { UserAvatar } from "shared/avatar"
import { commentGalleryPhoto } from "shared/full-screen-image/api"
import { commentVideo } from "shared/video-modal/api"
import { Picker } from "emoji-mart"
import { socket } from "app/socket"
import { SendIcon, EmojiIcon, AllowClear } from "shared/icons"
import { commentPost } from "shared/feed-components/api"
import { useTranslation } from "react-i18next"
import { MentionUser } from "shared/mentions/mention"
import styles from "./post-footer.module.css"

export const PostFooter = ({
	setActiveInput,
	activeInput,
	commentValue,
	setCommentValue,
	setVisibleCloseCom,
	setToggle,
	toggle,
	visibleCloseCom,
	post,
	postComments,
	setPostComments
}) => {
	const { t } = useTranslation()
	const { user } = useSelector(s => s)
	const { currentUser } = user
	const [mentionedUsers, setMentionedUsers] = useState([])
	const addEmoji = (emoji) => {
		setCommentValue(`${commentValue}${emoji}`)
	}

	return (
		<div className={styles.post_footer}>
			<UserAvatar user={currentUser} />
			<div className={`${styles.comment_input_field} ${activeInput && styles.active}`}>
				<MentionUser
					setMentionedUsers={setMentionedUsers}
					setVisible={setVisibleCloseCom}
					setToggle={setToggle}
					inputValue={commentValue}
					setInputValue={setCommentValue}
				/>
				<button
					type="button"
					className={`${styles.btns}  ${styles.emoji_picker_disabled}`}
					onClick={() => setToggle(!toggle)}
				>
					<EmojiIcon />
				</button>
				<button
					type="button"
					className={`${styles.btns} ${!visibleCloseCom ? styles.btns_none : styles.btns_visible}`}
					onClick={() => {
						setCommentValue("")
						setActiveInput(false)
						setVisibleCloseCom(false)
					}}
				>
					<AllowClear />
				</button>
			</div>
			{toggle && (
				<Picker
					showPreview={false}
					useButton={false}
					title="Emoji"
					style={{
						position: "absolute", bottom: "80px", right: "0", overflow: "hidden", maxHeight: "30vh", zIndex: 10, width: "300px", background: "#f2f2f2"
					}}
					onSelect={el => addEmoji(el.native)}
				/>
			)}
			<Button
				className={styles.confirm_button}
				onClick={async () => {
					setVisibleCloseCom(false)
					setActiveInput(false)
					setMentionedUsers([])
					if (!commentValue || !commentValue.trim()) {
						message.error(`${t("no-comment-to-post")}`)
					} else {
						const commentId = `comment_${uuidv4()}`
						const mentionedUser = mentionedUsers.includes(post.userId)
						const comment = {
							commentText: commentValue, commentAuthor: currentUser._id, commentId
						}
						if (post.isUploadedPhoto) {
							const response = await commentGalleryPhoto(
								post.albumId, post.images[0], comment, post.userId, mentionedUser
							)
							setPostComments(response.data.comments)
							if (!mentionedUser) {
								socket.emit("MENTIONED_USERS", {
									usersId: mentionedUsers,
									id: currentUser._id,
									photoAlbum: post.albumId,
									photoPath: post.images[0],
									ownerId: post.userId
								})
							}
						} else if (post.isUploadedVideo) {
							const response = await commentVideo(
								post.albumId, post.videos[0].path, comment, post.userId, mentionedUser
							)
							setPostComments(response.data.comments)
							if (!mentionedUser) {
								socket.emit("MENTIONED_USERS", {
									usersId: mentionedUsers,
									id: currentUser._id,
									videoAlbum: post.albumId,
									videoPath: post.videos[0].path,
									ownerId: post.userId
								})
							}
						} else {
							const response = await commentPost(post._id, commentValue,
								currentUser._id, mentionedUser)
							const newComment = {
								commentAuthor: currentUser._id,
								commentDate: response.data.commentDate,
								commentId: response.data.commentId,
								commentText: commentValue,
								user: currentUser
							}
							setPostComments([...postComments, newComment])
							if (!mentionedUser) {
								socket.emit("MENTIONED_USERS", {
									usersId: mentionedUsers,
									id: currentUser._id,
									postId: post._id,
									ownerId: post.userId
								})
							}
						}
						setCommentValue("")
						socket.emit("COMMENT_POST", { userId: post.userId })
					}
				}}
			>
				<SendIcon />
			</Button>
		</div>
	)
}