/* eslint-disable jsx-a11y/control-has-associated-label */
import React from "react"
import { useDispatch, useSelector } from "react-redux"
import { getPersonById } from "modules/family-tree/utils"
import { setSelectedPersonId } from "modules/family-tree/ducks"
import CustomScroll from "react-scrollbars-custom"
import styles from "./profile-tree-side-panel.module.css"
import { ProfileTreeSidePanelHeader } from "./profile-tree-side-panel-header"
import { ProfileTreeSidePanelMain } from "./profile-tree-side-panel-main"

const getContent = (id, selectedPerson) => {
	if (id) {
		return (
			<>
				<ProfileTreeSidePanelHeader person={selectedPerson} />
				<CustomScroll>
					<ProfileTreeSidePanelMain
						person={selectedPerson}
					/>
				</CustomScroll>
			</>
		)
	}
	return null
}

export const ProfileTreeSidePanel = () => {
	const { selectedPersonId } = useSelector(s => s.familyTree)
	const selectedPerson = getPersonById(selectedPersonId)
	const personPartner = getPersonById("person21")

	const dispatch = useDispatch()

	return (

		<div className={selectedPersonId ? `${styles.side_panel}` : `${styles.side_panel} ${styles.disabled}`}>
			<div
				className={selectedPersonId ? `${styles.side_panel_border}` : `${styles.side_panel_border_disabled}`}
				role="button"
				tabIndex={0}
				onClick={() => {
					dispatch(setSelectedPersonId(null))
				}}
			/>
			{getContent(selectedPersonId, selectedPerson, personPartner)}
		</div>
	)
}