/* eslint-disable no-mixed-operators */
import React, { useState, useEffect } from "react"
import { Image, Modal, Popover } from "antd"
import { UserOutlined, ExclamationCircleOutlined } from "@ant-design/icons"
import { getImageUri } from "shared/utils"
import {
	SendMessageIcon, RemoveConnectionIcon, CityIcon,
	LocationIcon, BirthDateIcon, HearthIcon, CircleIcon, VideoChatIcon
} from "shared/icons"
import { useSelector } from "react-redux"
import { removeFromFriends, removeFromFamily } from "modules/friends/api"
import { inviteAsFriend, inviteAsFamily } from "modules/search-people/api"
import { updateUser } from "app/redux/user"
import { store } from "app/redux/store"
import { NavLink, useHistory } from "react-router-dom"
import { socket } from "app/socket"
import moment from "moment-timezone"
import { getUserPostsLength } from "modules/connection-profile-card/api"
import { useTranslation } from "react-i18next"
import { ModalVideoCall } from "modules/video-chat/components/modal-video-call"
import styles from "./profile-card-header.module.css"

export const ProfileHeader = ({ profile }) => {
	const history = useHistory()
	const { user } = useSelector(s => s)
	const { messages } = useSelector(s => s)
	const { currentUser } = user
	const { t } = useTranslation()
	const [blocked, setBlocked] = useState(profile.blacklist?.includes(currentUser._id))
	const [posts, setPosts] = useState([])
	const [isShowModalVideoChat, setIsShowModalVideoChat] = useState(false)
	const [selectedConnections, setSelectedConnections] = useState(null)

	useEffect(() => {
		const getData = async () => {
			if (profile.privacySettings.feed !== "only-me") {
				if (profile.privacySettings.feed === "public") {
					const data = await getUserPostsLength(profile._id)
					setPosts(data.postsLength)
				}
				if (profile.privacySettings.feed === "relatives-only" && profile.family.includes(currentUser._id)) {
					const data = await getUserPostsLength(profile._id)
					setPosts(data.postsLength)
				}
				if (profile.privacySettings.feed === "friends-only" && profile.friends.includes(currentUser._id)) {
					const data = await getUserPostsLength(profile._id)
					setPosts(data.postsLength)
				}
				if (profile.privacySettings.feed === "friends-and-relatives" && (profile.friends.includes(currentUser._id) || profile.family.includes(currentUser._id))) {
					const data = await getUserPostsLength(profile._id)
					setPosts(data.postsLength)
				}
			}
		}
		getData()
	}, [currentUser._id,
	profile._id,
	profile.family,
	profile.friends,
	profile.privacySettings.feed])

	const { confirm } = Modal

	const [visiblePopover, setVisiblePopover] = useState(false)

	const [
		userHaveFriendRequest,
		setUserHaveFriendRequest
	] = useState(profile.invitesAsFriend.includes(currentUser._id))

	const [
		userHaveFamilyRequest,
		setUserHaveFamilyRequest
	] = useState(profile.invitesAsFamily.includes(currentUser._id))

	const userConnections = []
	if (profile && profile.friends && profile.family) {
		profile.friends.forEach(friend => userConnections.push(friend))
		profile.family.forEach(parent => {
			if (!userConnections.includes(parent)) {
				userConnections.push(parent)
			}
		})
	}
	const showFriendDeleteConfirm = () => {
		confirm({
			title: `${t("are-you-sure-you-want-to-remove")} ${profile.firstName} ${profile.lastName} ${t("from-your-friends")}?`,
			icon: <ExclamationCircleOutlined />,
			okText: `${t("remove-from-friends")}`,
			cancelText: `${t("cancel")}`,
			okType: "danger",
			onOk() {
				removeFromFriends(profile._id)
				const friends = currentUser.friends.filter(friend => friend !== profile._id)
				store.dispatch(updateUser({
					...currentUser,
					friends
				}))
			}
		})
	}

	const showFamilyDeleteConfirm = () => {
		confirm({
			title: `${t("are-you-sure-you-want-to-remove")} ${profile.firstName} ${profile.lastName} ${t("from-your-family")}?`,
			icon: <ExclamationCircleOutlined />,
			okText: `${t("remove-from-family")}`,
			cancelText: `${t("cancel")}`,
			okType: "danger",
			onOk() {
				removeFromFamily(profile._id)
				const family = currentUser.family.filter(parent => parent !== profile._id)
				store.dispatch(updateUser({
					...currentUser,
					family
				}))
			}
		})
	}

	const popoverContent = (
		<div className={styles.buttons}>
			{currentUser.friends.includes(profile._id) && (
				<button
					className={styles.confirm_button}
					type="button"
					onClick={() => {
						setVisiblePopover(false)
						showFriendDeleteConfirm()
					}}
				>
					{t("from-friends")}
				</button>
			)}
			{currentUser.family.includes(profile._id) && (
				<button
					className={styles.confirm_button}
					type="button"
					onClick={() => {
						setVisiblePopover(false)
						showFamilyDeleteConfirm()
					}}
				>
					{t("from-family")}
				</button>
			)}
		</div>
	)

	const popoverInviteContent = (
		<div className={styles.buttons}>

			{!userHaveFriendRequest && !userHaveFamilyRequest && (
				<button
					className={`${styles.confirm_button} ${styles.invite_btn}`}
					type="button"
					onClick={async () => {
						setVisiblePopover(false)
						await inviteAsFriend(profile._id)
						setUserHaveFriendRequest(true)
						socket.emit("SEND_FRIEND_REQUEST", { userId: user._id })
					}}
				>
					{t("as-friend")}
				</button>
			)}

			{!userHaveFamilyRequest && !userHaveFriendRequest && (
				<button
					className={`${styles.confirm_button} ${styles.invite_btn}`}
					type="button"
					onClick={async () => {
						setVisiblePopover(false)
						await inviteAsFamily(profile._id)
						setUserHaveFamilyRequest(true)
						socket.emit("SEND_FAMILY_REQUEST", { userId: user._id })
					}}
				>
					{t("as-family")}
				</button>
			)}

			{(userHaveFamilyRequest || userHaveFriendRequest) && (
				<div>{t("you-already-send-connection-requests-to-this-user")}</div>
			)}

		</div>
	)

	const profileAboutContent = (
		<>
			{
				profile.birthDate && (
					<div className={styles.profile_about}>
						<BirthDateIcon />
						<span>
							{t("born in")}
							{" "}
							{profile.privacySettings.aboutInfo === "public"
								? (<b>{moment(profile.birthDate, "DD/MM/YYYY").format("YYYY")}</b>)
								: (<b>{moment(profile.birthDate, "DD/MM/YYYY").format("LL")}</b>)}
						</span>
					</div>
				)
			}
			{
				profile.birthPlace && (
					<div className={styles.profile_about}>
						<LocationIcon />
						<span>
							{t("from")}
							{" "}
							<b>{profile.birthPlace}</b>
						</span>
					</div>
				)
			}
			{
				profile.location.name && (
					<div className={styles.profile_about}>
						<CityIcon />
						<span>
							{t("lives-in")}
							{" "}
							<b>{profile.location.name}</b>
						</span>
					</div>
				)
			}
			{
				profile.familyStatus && (
					<div className={styles.profile_about}>
						<HearthIcon />
						<span>
							{t("family-status")}
							{" "}
							<b>{t(profile.familyStatus)}</b>
						</span>
					</div>
				)
			}
			{
				profile.gender && (
					<div className={styles.profile_about}>
						<CircleIcon />
						<span>
							{t("gender")}
							{" "}
							<b>{t(profile.gender === "unknown" ? "gender-neutral" : profile.gender)}</b>
						</span>
					</div>
				)
			}
		</>
	)

	const postsButtonContent = (
		<button
			type="button"
			className={styles.footer_button}
			onClick={() => {
				history.push({ pathname: "/profile/feed", userId: profile._id })
			}}
		>
			<NavLink
				to="/profile/feed"
				className={styles.profile_footer_link}
				activeClassName={styles.profile_footer_link_active}
			>
				{t("posts")}
				<span>
					{posts}
				</span>
			</NavLink>
		</button>
	)

	const connectionsButtonContent = (
		<button
			type="button"
			className={styles.footer_button}
			onClick={() => {
				history.push({ pathname: "/profile/connections", userId: profile._id })
			}}
		>
			<NavLink
				to="/profile/connections"
				className={styles.profile_footer_link}
				activeClassName={styles.profile_footer_link_active}
			>
				{t("connections")}
				<span>
					{userConnections.length}
				</span>
			</NavLink>
		</button>
	)

	const treeButtonContent = (
		<button
			type="button"
			className={styles.footer_button}
			onClick={() => {
				history.push({ pathname: "/profile/tree", userId: profile._id })
			}}
		>
			<NavLink
				to="/profile/tree"
				className={styles.profile_footer_link}
				activeClassName={styles.profile_footer_link_active}
			>
				{t("family-tree")}
			</NavLink>
		</button>
	)

	const connectionsPhotoContent = (
		<button
			type="button"
			className={styles.footer_button}
			onClick={() => {
				history.push({ pathname: "/profile/photos", userId: profile._id })
			}}
		>
			<NavLink
				to="/profile/photos"
				className={styles.profile_footer_link}
				activeClassName={styles.profile_footer_link_active}
			>
				{t("photos")}
			</NavLink>
		</button>
	)

	const connectionsVideoContent = (
		<button
			type="button"
			className={styles.footer_button}
			onClick={() => {
				history.push({ pathname: "/profile/videos", userId: profile._id })
			}}
		>
			<NavLink
				to="/profile/videos"
				className={styles.profile_footer_link}
				activeClassName={styles.profile_footer_link_active}
			>
				{t("videos")}
			</NavLink>
		</button>
	)

	const profileAboutView = () => {
		if (profile.privacySettings.aboutInfo !== "only-me") {
			if (profile.privacySettings.aboutInfo === "public") {
				return profileAboutContent
			}
			if (profile.privacySettings.aboutInfo === "relatives-only" && profile.family.includes(currentUser._id)) {
				return profileAboutContent
			}
			if (profile.privacySettings.aboutInfo === "friends-only" && profile.friends.includes(currentUser._id)) {
				return profileAboutContent
			}
			if (profile.privacySettings.aboutInfo === "friends-and-relatives" && (profile.friends.includes(currentUser._id) || profile.family.includes(currentUser._id))) {
				return profileAboutContent
			}
			return null
		} return null
	}

	const postsButtonView = () => {
		if (profile.privacySettings.feed !== "only-me") {
			if (profile.privacySettings.feed === "public") {
				return postsButtonContent
			}
			if (profile.privacySettings.feed === "relatives-only" && profile.family.includes(currentUser._id)) {
				return postsButtonContent
			}
			if (profile.privacySettings.feed === "friends-only" && profile.friends.includes(currentUser._id)) {
				return postsButtonContent
			}
			if (profile.privacySettings.feed === "friends-and-relatives" && (profile.friends.includes(currentUser._id) || profile.family.includes(currentUser._id))) {
				return postsButtonContent
			}
			return null
		} return null
	}

	const connectionsButtonView = () => {
		if (profile.privacySettings.friendList !== "only-me") {
			if (profile.privacySettings.friendList === "public") {
				return connectionsButtonContent
			}
			if (profile.privacySettings.friendList === "relatives-only" && profile.family.includes(currentUser._id)) {
				return connectionsButtonContent
			}
			if (profile.privacySettings.friendList === "friends-only" && profile.friends.includes(currentUser._id)) {
				return connectionsButtonContent
			}
			if (profile.privacySettings.friendList === "friends-and-relatives" && (profile.friends.includes(currentUser._id) || profile.family.includes(currentUser._id))) {
				return connectionsButtonContent
			}
			return null
		} return null
	}

	const treeButtonView = () => {
		if (profile.privacySettings.familyTree !== "only-me") {
			if (profile.privacySettings.familyTree === "public") {
				return treeButtonContent
			}
			if (profile.privacySettings.familyTree === "relatives-only" && profile.family.includes(currentUser._id)) {
				return treeButtonContent
			}
			if (profile.privacySettings.familyTree === "friends-only" && profile.friends.includes(currentUser._id)) {
				return treeButtonContent
			}
			if (profile.privacySettings.familyTree === "friends-and-relatives" && (profile.friends.includes(currentUser._id) || profile.family.includes(currentUser._id))) {
				return treeButtonContent
			}
			return null
		} return null
	}

	const photoButtonView = () => {
		if (profile.privacySettings.photos !== "only-me") {
			if (profile.privacySettings.photos === "public") {
				return connectionsPhotoContent
			}
			if (profile.privacySettings.photos === "relatives-only" && profile.family.includes(currentUser._id)) {
				return connectionsPhotoContent
			}
			if (profile.privacySettings.photos === "friends-only" && profile.friends.includes(currentUser._id)) {
				return connectionsPhotoContent
			}
			if (profile.privacySettings.photos === "friends-and-relatives" && (profile.friends.includes(currentUser._id) || profile.family.includes(currentUser._id))) {
				return connectionsPhotoContent
			}
			return null
		} return null
	}

	const videoButtonView = () => {
		if (profile.privacySettings.videos !== "only-me") {
			if (profile.privacySettings.videos === "public") {
				return connectionsVideoContent
			}
			if (profile.privacySettings.videos === "relatives-only" && profile.family.includes(currentUser._id)) {
				return connectionsVideoContent
			}
			if (profile.privacySettings.videos === "friends-only" && profile.friends.includes(currentUser._id)) {
				return connectionsVideoContent
			}
			if (profile.privacySettings.videos === "friends-and-relatives" && (profile.friends.includes(currentUser._id) || profile.family.includes(currentUser._id))) {
				return connectionsVideoContent
			}
			return null
		} return null
	}

	return (
		<div className={styles.profile_header_card}>
			<div
				onMouseLeave={() => setVisiblePopover(false)}
				className={styles.profile_header_card_main}
			>
				<div className={styles.profile_avatar}>
					{profile.avatar
						? (
							<>
								<Image
									width={120}
									height={120}
									src={getImageUri(profile.avatar)}
									className={styles.profile_avatar_img}
								/>
							</>
						)
						: (
							<div className={styles.profile_avatar_img}>
								<UserOutlined style={{
									width: "120px", height: "120px", fontSize: "120px", color: "#fff"
								}}
								/>
							</div>
						)}
				</div>
				<div
					className={styles.profile_main_info}
				>
					<div className={styles.profile_name}>
						{profile.firstName}
						{" "}
						{profile.lastName}
						{" "}
						{profile.maidenName && profile.maidenName !== "" ? `(${profile.maidenName})` : ""}
					</div>
					<div className={styles.profile_online}>
						{profile.isOnline ? "online" : `was online ${moment(profile.lastSession).tz(`${moment.tz.guess()}`).fromNow()}`}
					</div>
					<button
						type="button"
						className={styles.block_user}
						onClick={() => {
							socket.emit("CHANGE_BLACKLIST", { userId: profile._id, id: currentUser._id })
							setBlocked(!blocked)
						}}
					>
						{blocked ? t("Unblock-User") : t("Block-User")}
					</button>
					<div className={styles.profile_about_container}>
						{profileAboutView()}
					</div>
					<div
						className={styles.profile_btns}
					>
						{currentUser.friends.includes(profile._id)
							&& (
								<button
									type="button"
									className={styles.profile_invite_button}
									onClick={async () => {
										const resp = await inviteAsFamily(profile._id)
										if (resp.status === "Updated successfully") {
											store.dispatch(updateUser({
												...currentUser,
												family: resp.family,
												friends: resp.friends
											}))
										}
									}}
								>
									<div>
										{
											currentUser.friends.includes(profile._id)
												? t("move-to-family")
												: t("invite-as-family")
										}

									</div>
								</button>
							)}
						{currentUser.family.includes(profile._id)
							&& (
								<button
									type="button"
									className={styles.profile_invite_button}
									onClick={async () => {
										const resp = await inviteAsFriend(profile._id)
										if (resp.status === "Updated successfully") {
											store.dispatch(updateUser({
												...currentUser,
												family: resp.family,
												friends: resp.friends
											}))
										}
									}}
								>
									<div>
										{
											currentUser.family.includes(profile._id)
												? t("move-to-friend")
												: t("invite-as-friend")
										}

									</div>
								</button>
							)}
						<div className={styles.profile_buttons}>
							{currentUser.friends.includes(profile._id)
								&& currentUser.family.includes(profile._id)
								&& (
									<Popover placement="bottom" content={popoverContent} trigger="click" visible={visiblePopover}>
										<button
											type="button"
											className={styles.profile_button}
											onClick={() => setVisiblePopover(!visiblePopover)}
										>
											<RemoveConnectionIcon />
											<div>
												{t("remove-connection")}
											</div>
										</button>
									</Popover>
								)}
							{currentUser.friends.includes(profile._id)
								&& !currentUser.family.includes(profile._id)
								&& (
									<button
										type="button"
										className={styles.profile_button}
										onClick={() => showFriendDeleteConfirm()}
									>
										<RemoveConnectionIcon />
										<div>{t("remove-from-friends")}</div>
									</button>
								)}
							{currentUser.family.includes(profile._id)
								&& !currentUser.friends.includes(profile._id)
								&& (
									<button
										type="button"
										className={styles.profile_button}
										onClick={() => showFamilyDeleteConfirm()}
									>
										<RemoveConnectionIcon />
										<div>{t("remove-from-family")}</div>
									</button>
								)}
							{!currentUser.family.includes(profile._id)
								&& !currentUser.friends.includes(profile._id)
								&& !currentUser.invitesAsFamily.includes(profile._id)
								&& !currentUser.invitesAsFriend.includes(profile._id)
								&& (
									<Popover placement="bottom" content={popoverInviteContent} trigger="click" visible={visiblePopover}>
										<button
											type="button"
											className={styles.profile_invite_button}
											onClick={() => setVisiblePopover(!visiblePopover)}
										>
											<div>{t("invite-as")}</div>
										</button>
									</Popover>
								)}
							<button
								type="button"
								className={`${styles.profile_button} ${styles.send_message_button}`}
								onClick={() => {
									if (messages.find(message => message.users.length === 2
										&& !message.roomName
										&& message.users.find(u => u._id === profile._id)
										&& message.users.find(u => u._id === currentUser._id))) {
										history.push({ pathname: "/messages", userId: profile._id })
									} else {
										socket.emit("START_CHAT", { roomName: null, recipients: [profile._id], userId: currentUser._id })
										socket.on("START_CHAT", chat => {
											const recepient = chat.users.find(user => user._id !== currentUser._id)
											history.push({ pathname: "/messages", userId: recepient._id })
										})
									}
								}}
							>
								<SendMessageIcon />
								<div>{t("send-messages")}</div>
							</button>
							{(profile.isOnline && (profile.email === "andrusha.v47@gmail.com"
								|| profile.email === "sagetarius@gmail.com" || profile.email === "yakushevich@live.com" || profile.email === "test10@test.com"
							) && (currentUser.email === "andrusha.v47@gmail.com"
								|| currentUser.email === "sagetarius@gmail.com" || currentUser.email === "yakushevich@live.com" || profile.email === "test11@test.com"
								)) && (
									<button
										type="button"
										className={`${styles.profile_button} ${styles.send_message_button}`}
										onClick={() => {
											setIsShowModalVideoChat(true)
											setSelectedConnections(profile)
										}}
									>
										<VideoChatIcon />
										<div>{t("start-video-call")}</div>
									</button>
								)}
							{(isShowModalVideoChat) && (
								<ModalVideoCall
									isShowModalVideoChat={isShowModalVideoChat}
									setIsShowModalVideoChat={setIsShowModalVideoChat}
									selectedConnections={selectedConnections}
								/>
							)}
						</div>
					</div>
				</div>
			</div>
			<div className={styles.profile_header_card_footer}>
				{postsButtonView()}
				{connectionsButtonView()}
				{treeButtonView()}
				{photoButtonView()}
				{videoButtonView()}
			</div>
		</div>
	)
}