import React, { useState } from "react"
import { useHistory } from "react-router-dom"
import styles from "./map.module.css"

export const MarkerForMoreUsers = ({
	conterUsers, users
}) => {
	const history = useHistory()
	const [isShowlist, setIsShowList] = useState(false)
	const InfoWindow = () => (
		<div
			className={styles.infoWindowMoreUserStyle}
		>
			{users?.map(person => (
				<button
					type="button"
					className={styles.profile_link}
					onClick={() => {
						history.push({ pathname: "/profile", userId: person._id })
					}}
				>
					{person.firstName}
					{" "}
					{person.lastName}
				</button>

			))}
		</div>
	)

	return (
		<>
			<button
				type="button"
				style={{ outline: "none" }}
				className={styles.markerMoreUsersStyle}
				onClick={() => setIsShowList(!isShowlist)}
			>
				<span style={{ fontFamily: "Inter", fontSize: "15px", fontWeight: "bold" }}>{conterUsers}</span>
			</button>
			{isShowlist && <InfoWindow />}
		</>
	)
}