import React from "react"
import moment from "moment"
import { useTranslation } from "react-i18next"
import styles from "./profile-tree-side-panel-main.module.css"

export const ProfileTreeSidePanelMain = ({ person }) => {
	const { t } = useTranslation()
	return (
		<div className={styles.side_panel_main}>
			{person.name && (<span className={styles.side_panel_labels}>{t("name")}</span>)}
			{person.name && (
				<div
					className={styles.side_panel_inputs}
				>
					{person.name}
				</div>
			)}
			{person.from && (<span className={styles.side_panel_labels}>{t("from")}</span>)}
			{person.from && (
				<div
					className={styles.side_panel_inputs}
				>
					{person.from}
				</div>
			)}
			{person.livesIn && (<span className={styles.side_panel_labels}>{t("lives-in")}</span>)}
			{person.livesIn && (
				<div
					className={styles.side_panel_inputs}
				>
					{person.livesIn}
				</div>
			)}
			{person.birthDate && (
				<div className={styles.birth_date_picker}>
					<span className={styles.side_panel_labels}>{t("born in")}</span>
					<div
						className={styles.side_panel_inputs}
					>
						{moment(person.birthDate, "DD/MM/YYYY").format("LL")}
					</div>
				</div>
			)}
			{person.deathDate && (
				<div className={styles.death_date_picker}>
					<span className={styles.side_panel_labels}>{t("death-date")}</span>
					<div
						className={styles.side_panel_inputs}
					>
						{moment(person.deathDate, "DD/MM/YYYY").format("LL")}
					</div>
				</div>
			)}
			{person.description && (<span className={styles.side_panel_labels}>{t("description")}</span>)}
			{person.description && (
				<div
					className={styles.side_panel_inputs}
				>
					{person.description}
				</div>
			)}
		</div>
	)
}