import React, { useState } from "react"
import { SendIcon, EmojiIcon, AllowClear } from "shared/icons"
import { useTranslation } from "react-i18next"
import { Picker } from "emoji-mart"
import { message } from "antd"
import { v4 as uuidv4 } from "uuid"
import { socket } from "app/socket"
import { MentionUser } from "shared/mentions/mention"
import { commentPhotoAlbum, commentVideoAlbum } from "../api"
import styles from "./album-comments-footer.module.css"

export const AlbumCommentsFooter = ({
	setAlbumComments, ownerId, albumId, userId,
	isVideoAlbum, isAlbumCommentsActive
}) => {
	const { t } = useTranslation()
	const [inputValue, setInputValue] = useState("")
	const [visible, setVisible] = useState(false)
	const [toggle, setToggle] = useState(false)
	const [activeInput, setActiveInput] = useState(false)
	const [mentionedUsers, setMentionedUsers] = useState([])

	const addEmoji = (emoji) => {
		setInputValue(`${inputValue}${emoji}`)
	}

	return (
		<div className={isAlbumCommentsActive ? styles.comments_input : styles.comments_input_disabled}>
			<div className={`${styles.comment_input_field} ${activeInput && styles.active}`}>
				<MentionUser
					setMentionedUsers={setMentionedUsers}
					setVisible={setVisible}
					setToggle={setToggle}
					inputValue={inputValue}
					setInputValue={setInputValue}
				/>
				<button
					type="button"
					className={`${styles.btns}`}
					onClick={() => setToggle(!toggle)}
				>
					<EmojiIcon />
				</button>
				<button
					type="button"
					className={`${styles.btns} ${!visible ? styles.btns_none : styles.btns_visible}`}
					onClick={() => {
						setInputValue("")
						setActiveInput(false)
						setVisible(!visible)
					}}
				>
					<AllowClear />
				</button>
			</div>
			{
				toggle && (
					<Picker
						showPreview={false}
						useButton={false}
						title="Emoji"
						style={{
							position: "absolute", bottom: "144px", right: "202px", overflow: "hidden", maxHeight: "40vh", zIndex: 11, width: "240px", background: "#f2f2f2"
						}}
						onSelect={el => {
							setToggle(false)
							addEmoji(el.native)
						}}
					/>
				)
			}
			<button
				type="button"
				className={styles.confirm_button}
				onClick={async () => {
					setVisible(false)
					setActiveInput(false)
					if (inputValue.length === 0) {
						message.error(`${t("no-data-to-post")}`)
					} else {
						const commentId = `comment_${uuidv4()}`
						const mentionedUser = mentionedUsers.includes(ownerId)
						const comment = {
							commentText: inputValue, commentAuthor: userId, commentId
						}
						const response = isVideoAlbum ? await commentVideoAlbum(
							albumId, comment, ownerId, mentionedUser
						)
							: await commentPhotoAlbum(
								albumId, comment, ownerId, mentionedUser
							)
						setInputValue("")
						setMentionedUsers([])
						setAlbumComments(response.data.comments.reverse())
						socket.emit("COMMENT_ALBUM", { userId: ownerId })
						if (isVideoAlbum) {
							if (!mentionedUser) {
								socket.emit("MENTIONED_USERS", {
									usersId: mentionedUsers, id: userId, videoAlbumId: albumId, ownerId
								})
							}
						} else if (!mentionedUser) {
							socket.emit("MENTIONED_USERS", {
								usersId: mentionedUsers, id: userId, photoAlbumId: albumId, ownerId
							})
						}
					}
				}}
			>
				<SendIcon />
			</button>
		</div>
	)
}