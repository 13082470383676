import React, { useState } from "react"
import ReactPlayer from "react-player"
import { baseUri } from "shared/constants"
import { VideoModal } from "shared/video-modal"
import { useSelector } from "react-redux"
import { Popover } from "./popover"
import styles from "./video.module.css"

export const Video = ({
	video, setVideos, albumId, fileName
}) => {
	const [hoverCurrentVideo, setHoverCurrentVideo] = useState(null)
	const [isShowVedeoPlayer, setIsShowVedeoPlayer] = useState(false)
	const [isShowPopover, setIsShowPopover] = useState(false)
	const [selectVideo, setSelectVideo] = useState(null)

	const { user } = useSelector(s => s)
	const { currentUser } = user

	const hours = Math.floor(video.duration / 3600)
	const minutes = Math.floor((video.duration % 3600) / 60)
	const seconds = Math.floor(video.duration - hours * 3600 - minutes * 60)
	return (
		<div className={styles.video_container}>
			<Popover
				fileName={fileName}
				setVideos={setVideos}
				albumId={albumId}
				video={video}
				isShowPopover={isShowPopover}
				setIsShowPopover={setIsShowPopover}
			/>
			<div
				role="button"
				tabIndex={0}
				onClick={() => {
					setIsShowVedeoPlayer(true)
					setSelectVideo(video.path)
				}}
				className={styles.video_content}
			>
				<div
					className={styles.video_palyer}
					onMouseEnter={() => setHoverCurrentVideo(video.path)}
					onMouseLeave={() => setHoverCurrentVideo(null)}
				>
					<ReactPlayer
						playing={(hoverCurrentVideo && !isShowVedeoPlayer)
							&& hoverCurrentVideo === video.path}
						width="100%"
						height="100%"
						url={`${baseUri}/${video.path}`}
					/>
					<div className={styles.duration_container}>
						<span className={styles.duration_text}>{`${minutes}:${seconds}`}</span>
					</div>
				</div>
				<h3 style={{ whiteSpace: "nowrap" }}>{video.name}</h3>
			</div>
			<VideoModal
				selectVideo={selectVideo}
				video={video}
				setIsShowVedeoPlayer={setIsShowVedeoPlayer}
				isShowVedeoPlayer={isShowVedeoPlayer}
				isVideoWithComments
				albumId={albumId}
				ownerId={currentUser._id}
				userId={currentUser._id}
			/>
		</div>
	)
}