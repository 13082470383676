import React, { useState, useEffect } from "react"
import { Route, Switch } from "react-router-dom"
import { useDispatch } from "react-redux"
import { setUser, userLogOut } from "app/redux/user"
import { autoLogin } from "app/api"
import { AppSpinner } from "shared/app-spinner/app-spinner"
import {
	Auth, Registration, RestorePassword
} from "modules/auth"
import { RegistrationConfirm } from "modules/auth/registration-confirm"
import { RestorePasswordConfirm } from "modules/auth/restore-password-confirm"
import { MobileDiagram } from "modules/family-tree/components/mobile-diagram"
import { MobileProfileTree } from "modules/connection-profile-card/components/mobile-profile-tree"
import { PasswordChangePage } from "modules/auth/password-change-page"
import { TermsAndConditions } from "modules/auth/terms-and-condiotions"
import { PrivacyPolicy } from "modules/auth/privacy-policy"
import { useTranslation } from "react-i18next"
import { MainTab } from "./main"
import logo from "../assets/lifetwig_logo.png"
import apple from "../assets/apple.png"
import google from "../assets/google.png"
import check from "../assets/check.png"

export const Navigation = () => {
	const [isLoad, setIsLoad] = useState(false)

	const { i18n } = useTranslation()

	const dispatch = useDispatch()

	useEffect(() => {
		const token = localStorage.token ? localStorage.getItem("token") : sessionStorage.getItem("token")
		const getData = async () => {
			if (token) {
				const loadedData = await autoLogin(token)
				if (loadedData) {
					dispatch(setUser(loadedData))
					i18n.changeLanguage(loadedData.language)
				}
			} else {
				dispatch(userLogOut())
				localStorage.clear()
				sessionStorage.clear()
			}
			setIsLoad(true)
		}
		getData()
	}, [dispatch, i18n])

	if (!isLoad) {
		return <AppSpinner />
	}

	return (
		<Switch>
			<Route exact path="/IMG/app-logo">
				<img src={logo} alt="something went wrong" />
			</Route>
			<Route exact path="/IMG/apple-link">
				<img src={apple} alt="something went wrong" />
			</Route>
			<Route exact path="/IMG/google-link">
				<img src={google} alt="something went wrong" />
			</Route>
			<Route exact path="/IMG/check-img">
				<img src={check} alt="something went wrong" />
			</Route>
			<Route path="/mob/tree" component={MobileDiagram} />
			<Route path="/mob/public-tree" component={MobileProfileTree} />
			<Route path="/login" component={Auth} />
			<Route path="/registration" component={Registration} />
			<Route path="/registration-confirm" component={RegistrationConfirm} />
			<Route path="/restore-password-confirm" component={RestorePasswordConfirm} />
			<Route path="/new-password-enter" component={PasswordChangePage} />
			<Route path="/restore-password" component={RestorePassword} />
			<Route path="/terms-and-conditions" component={TermsAndConditions} />
			<Route path="/privacy-policy" component={PrivacyPolicy} />
			<Route path="/" component={MainTab} />
		</Switch>
	)
}