import React from "react"
import { CancelButton } from "shared/buttons"
import {
	Form, Input, Select, Button, message
} from "antd"
import CustomScroll from "react-scrollbars-custom"
import { useTranslation } from "react-i18next"
import { GooglePlayIcon, AppStoreIcon } from "shared/icons"
import { sendReport } from "./api"
import styles from "./contact-us.module.css"

const { Option } = Select
const { TextArea } = Input

export const ContactUsPage = () => {
	const [form] = Form.useForm()
	const { t } = useTranslation()

	const sendFeedback = async (fieldsValue) => {
		const response = await sendReport(
			fieldsValue.subject, fieldsValue.email, fieldsValue.description
		)
		if (response.status) {
			message.success(t("report-sended"))
			form.resetFields()
		}
	}

	return (
		<div className={styles.wrap}>
			<Form
				name="contact-us"
				className={styles.container}
				onFinish={sendFeedback}
				form={form}
			>
				<div className={styles.header}>{t("contact-us")}</div>
				<CustomScroll>
					<div className={styles.main_content_container}>
						<Form.Item
							className={styles.input_item}
							name="subject"
							rules={[
								{
									required: true,
									message: `${t("please-select-a-subject")}`
								}
							]}
						>
							<Select
								className={`${styles.input_field} ${styles.select_field}`}
								placeholder={t("select-subject")}
								bordered={false}
							>
								<Option value="feedback">{t("to-send-a-feedback")}</Option>
								<Option value="suggestion">{t("suggestion")}</Option>
								<Option value="report">{t("report-an-issue/bug")}</Option>
							</Select>
						</Form.Item>
						<Form.Item
							className={styles.input_item}
							name="email"
							type="email"
							rules={[
								{
									type: "email",
									message: `${t("invalid-email-address")}`
								},
								{
									required: true,
									message: `${t("email-is-required")}`
								}
							]}
						>
							<Input
								className={styles.input_field}
								placeholder={t("email-adress")}
							/>
						</Form.Item>
						<Form.Item
							className={styles.input_item}
							name="description"
							rules={[
								{
									required: true,
									message: `${t("please-enter-subject-description")}`
								}
							]}
						>
							<TextArea
								rows={4}
								className={styles.input_field}
								autoSize={{ minRows: 10, maxRows: 16 }}
								placeholder={t("write-something")}
							/>
						</Form.Item>
					</div>
				</CustomScroll>
				<span className={styles.check_app_in_store}>{t("rate-our-mobile-application-in-store")}</span>
				<div className={styles.auth_icon_wrapper}>
					<a className={styles.auth_icon} href="https://play.google.com/store/apps/details?id=life.twig" target="_blank" rel="noreferrer">
						<GooglePlayIcon />
					</a>
					<a className={styles.auth_icon} href="https://apps.apple.com/by/app/lifetwig/id1582361916" target="_blank" rel="noreferrer">
						<AppStoreIcon />
					</a>
				</div>
				<div className={styles.footer}>
					<CancelButton cancel={() => {
						form.resetFields()
					}}
					/>
					<Form.Item style={{ margin: "0px" }}>
						<Button type="primary" htmlType="submit" className={styles.confirm_button}>
							{t("SEND")}
						</Button>
					</Form.Item>
				</div>
			</Form>
		</div>
	)
}