import React, { useState } from "react"
import { useHistory } from "react-router-dom"
import { useSelector } from "react-redux"
import styles from "./map.module.css"

export const Marker = ({
	person, isCurrentUser, show, setShow, index
}) => {
	const { user } = useSelector(s => s)
	const { currentUser } = user
	const [markerImage, setMarkerImage] = useState(person.avatar ? person.avatar : "./user.png")
	const history = useHistory()
	const InfoWindow = ({ person }) => (
		<div
			className={styles.infoWindowStyle}
		>
			<button
				type="button"
				className={styles.profile_link}
				onClick={() => {
					if (person._id !== currentUser._id) {
						history.push({ pathname: "/profile", userId: person._id })
					}
					if (person._id === currentUser._id) {
						history.push("/user-profile/feed")
					}
				}}
			>
				{person.firstName}
				{" "}
				{person.lastName}
			</button>
		</div>
	)
	return (
		<>
			<img
				className={isCurrentUser === person._id ? styles.userMarkerStyle : styles.markerStyle}
				src={markerImage}
				onError={() => setMarkerImage("./user.png")}
				alt="User"
				style={{
					position: "relative", right: `${index * 15}px`, zIndex: `${show === person._id ? 999 : 0}`, minHeight: "40px", minWidth: "40px"
				}}
				onClick={() => {
					if (isCurrentUser !== person._id) {
						if (show !== null) {
							if (show === person._id) {
								setShow(null)
							}
							if (show !== person._id) {
								setShow(person._id)
							}
						}
						if (show === null) {
							setShow(person._id)
						}
					}
				}}
			/>
			{show === person._id && <InfoWindow person={person} />}
		</>
	)
}