import React, { useState } from "react"
import { Button } from "antd"
import { store } from "app/redux/store"
import { AppSpinner } from "shared/app-spinner/app-spinner"
import { updatePerson } from "modules/family-tree/ducks"
import {
	deleteTreeAvatar, resizeImage
} from "modules/family-tree/api"
import { ImageCropper } from "shared/image-cropper"
import { getImageUri } from "shared/utils"
import { useTranslation } from "react-i18next"
import { UploadIcon, ClearIcon } from "modules/family-tree/components/side-panel"
import { MaleIcon, FemaleIcon, UnknownGenderIcon } from "modules/family-tree/icons"
import {
	baseUri
} from "shared/constants"
import styles from "./mobile-modal-avatar.module.css"

export const MobileAvatarUpload = ({ person, token, withoutBtns }) => {
	const [resizedImage, setResizedImage] = useState("")
	const [imageProcessing, setImageProcessing] = useState(false)

	const beforeCropResize = async (file) => {
		setImageProcessing(true)
		const formData = new FormData()
		formData.append("image", file)
		const response = await resizeImage(
			token,
			formData
		)
		const { imageURL } = response
		setResizedImage(`${baseUri}${imageURL}`)
		setImageProcessing(false)
	}

	const { t } = useTranslation()

	const choseGender = () => {
		let gender = null
		if (person.gender === "male") {
			gender = <MaleIcon />
		} else if (person.gender === "female") {
			gender = <FemaleIcon />
		} else {
			gender = <UnknownGenderIcon />
		}
		return gender
	}

	return (
		<>
			<div className={styles.avatar_btn_wrap}>
				<div className={styles.person_default_avatar}>
					{person.avatar !== "" ? (<img src={getImageUri(person.avatar)} alt="Something went wrong" className={styles.person_avatar} />) : <div>{choseGender()}</div>}
				</div>
				{!withoutBtns && (
					<div className={styles.avatar_btns}>
						<ImageCropper
							token={token}
							person={person}
							resizedImage={resizedImage}
							setResizedImage={setResizedImage}
						/>
						<div style={{ position: "relative", cursor: "pointer", height: "40px" }}>
							<input
								type="file"
								name="file"
								id="file"
								accept="image/*"
								style={{
									position: "absolute",
									width: "100%",
									height: "100%",
									outline: "none",
									opacity: 0,
									zIndex: 9,
									cursor: "pointer"
								}}
								onChange={(e) => beforeCropResize(e.target.files[0])}
							/>
							<Button className={styles.avatar_btn} icon={<UploadIcon />}>
								<span>{!person.avatar ? (`${t("upload-avatar")}`) : (`${t("change")}`)}</span>
							</Button>
						</div>
						{person.avatar !== "" && (
							<Button
								className={`${styles.avatar_clear_btn} ${styles.avatar_btn}`}
								icon={<ClearIcon />}
								onClick={() => {
									deleteTreeAvatar(token, person.positionId)
									store.dispatch(updatePerson({ ...person, avatar: "" }))
								}}
							>
								<span>
									&nbsp;
									{t("clear")}
								</span>
							</Button>
						)}
					</div>
				)}
			</div>
			{imageProcessing && (
				<AppSpinner
					loadingOutlinedStyle={{ fontSize: 64, marginRight: "10px" }}
					style={{
						position: "fixed", width: "100%", height: "100%", top: 0, left: 0, zIndex: "9999"
					}}
					tip={t("processing")}
				/>
			)}
		</>
	)
}