import React from "react"
import { v4 as uuidv4 } from "uuid"
import { useSelector } from "react-redux"
import "emoji-mart/css/emoji-mart.css"
import { PostVideoContent } from "./post-video-content"
import { PostImageContent } from "./post-image-content"
import { PostActions } from "./post-actions"
import { PostMediaEditComponent } from "./post-media-edit-component"
import { PostTextEditComponent } from "./post-text-edit-component"
import styles from "./post-main-content.module.css"

export const PostMainContent = ({
	isEditMode,
	post,
	setInputValue,
	inputValue,
	setVideoFiles,
	newImages,
	setNewImages,
	images,
	fileName,
	setSelectedPhoto,
	formData,
	setFileName,
	videoFiles,
	videos,
	setVideos,
	setIsActive,
	setAllPhotos,
	setIsShowComments,
	isShowComments,
	setIsShowSharesList,
	setIsShowLikeList,
	setOldImages,
	oldImages,
	setImageFiles,
	imageFiles,
	profile,
	postLikes,
	setPostsLikes,
	postShares,
	setPostShares,
	postComments,
	inputVideo,
	videosToView,
	postText
}) => {
	const { user } = useSelector(s => s)
	const { currentUser } = user

	const mediaContentStyle = (post, type) => {
		if (type === "image") {
			if (post.images.length <= 3) {
				return styles.small_content
			}
			if (post.images.length === 4) {
				return styles.medium_content
			}
			return styles.large_content
		}
		if (type === "video") {
			if (post.videos.length <= 3) {
				return styles.small_content
			}
			if (post.videos.length === 4) {
				return styles.medium_content
			}
			return styles.large_content
		}
		return null
	}
	return (
		<div className={styles.post_main_content}>
			{!isEditMode
				? (
					<div className={styles.post_main_content_text}>
						{postText}
					</div>
				)
				: (
					<PostTextEditComponent
						setInputValue={setInputValue}
						inputValue={inputValue}
					/>
				)}
			{currentUser._id === post.userId && isEditMode && !profile && (
				<PostMediaEditComponent
					setVideoFiles={setVideoFiles}
					newImages={newImages}
					setNewImages={setNewImages}
					images={images}
					fileName={fileName}
					formData={formData}
					setFileName={setFileName}
					videoFiles={videoFiles}
					videos={videos}
					setImageFiles={setImageFiles}
					imageFiles={imageFiles}
					inputVideo={inputVideo}
				/>
			)}
			{post.images
				&& post.images.length > 0
				&& (
					<PostImageContent
						isEditMode={isEditMode}
						post={post}
						images={images}
						setSelectedPhoto={setSelectedPhoto}
						setIsActive={setIsActive}
						setAllPhotos={setAllPhotos}
						setOldImages={setOldImages}
						oldImages={oldImages}
						setImageFiles={setImageFiles}
						imageFiles={imageFiles}
						mediaContentStyle={mediaContentStyle}
						profile={profile}
					/>
				)}
			{post.videos
				&& post.videos.length > 0
				&& (
					<PostVideoContent
						isEditMode={isEditMode}
						post={post}
						images={images}
						videos={videos}
						setVideos={setVideos}
						mediaContentStyle={mediaContentStyle}
						profile={profile}
						videosToView={videosToView}
					/>
				)}
			<div className={styles.post_main_content_files}>
				{post.files.map(file => <div key={uuidv4()}>{file.name}</div>)}
			</div>
			<PostActions
				post={post}
				postLikes={postLikes}
				setPostsLikes={setPostsLikes}
				postShares={postShares}
				setPostShares={setPostShares}
				setIsShowComments={setIsShowComments}
				isShowComments={isShowComments}
				setIsShowSharesList={setIsShowSharesList}
				setIsShowLikeList={setIsShowLikeList}
				postComments={postComments}
			/>
		</div>
	)
}