import React from "react"

export const EmptyTreeImage = () => (
	<svg width="175" height="169" viewBox="0 0 175 169" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path fillRule="evenodd" clipRule="evenodd" d="M23.3163 147H151.684C166.154 131.398 175 110.499 175 87.5C175 39.0994 135.651 0 87.5 0C39.1765 0 0 39.0994 0 87.5C0 110.499 8.84613 131.398 23.3163 147Z" fill="#D9E0EC" />
		<path d="M43.4434 157.978H156.645" stroke="#CDD6E1" strokeWidth="3" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
		<path d="M17.5195 157.978H36.1981" stroke="#CDD6E1" strokeWidth="3" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
		<path d="M61.9316 167H122.31" stroke="#CDD6E1" strokeWidth="3" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
		<path d="M162.9 24.1053C162.9 28.6675 159.21 32.3569 154.609 32.3569C154.529 32.3569 153.855 32.3569 148.896 32.3569C145.444 32.3569 139.93 32.3569 131.123 32.3569H126.918C121.404 32.4759 117 28.0724 117 22.7564C117 17.4008 121.443 12.9576 127.037 13.2353C131.837 -1.76055 153.815 0.342037 155.68 15.8536C159.806 16.3693 162.9 19.8604 162.9 24.1053Z" fill="url(#paint0_linear)" />
		<path d="M154.608 32.3569C159.17 32.3569 162.899 28.6674 162.899 24.1052C162.899 19.543 159.17 15.8535 154.608 15.8535C150.046 15.8535 146.316 19.543 146.316 24.1052C146.316 28.6674 150.046 32.3569 154.608 32.3569Z" fill="url(#paint1_linear)" />
		<path d="M141.08 32.4362C149.213 32.4362 155.838 25.8508 155.838 17.7181C155.838 9.58547 149.213 3 141.08 3C132.947 3 126.322 9.58547 126.322 17.7181C126.322 25.8508 132.908 32.4362 141.08 32.4362Z" fill="url(#paint2_linear)" />
		<path d="M33.4 11.7556C35 11.3556 36.5 11.2556 38.1 11.2556C39.6 11.2556 41.2 11.3556 42.7 11.5556C44.2 11.7556 45.8 12.1556 47.3 12.8556C48.8 13.5556 50.3 14.5556 51.2 16.2556L49.5 15.8556L51.3 14.4556C52 14.0556 52.6 13.5556 53.2 13.1556C54.5 12.3556 55.9 11.6556 57.4 11.1556C58.9 10.6556 60.4 10.2556 61.9 10.0556C63.5 9.95556 65 9.95555 66.6 10.3556C66.9 10.4556 67 10.7556 66.9 10.9556C66.8 11.2556 66.6 11.3556 66.3 11.2556H66.2C64.9 10.9556 63.4 11.0556 62 11.2556C60.6 11.4556 59.2 11.9556 57.9 12.5556C56.6 13.1556 55.4 13.8556 54.2 14.6556C53.6 15.0556 53 15.5556 52.5 15.9556L50.9 17.3556C50.4 17.7556 49.8 17.6556 49.4 17.2556L49.3 17.3556L49.2 17.1556C48.8 16.0556 47.7 15.1556 46.5 14.5556C45.3 13.9556 43.9 13.4556 42.4 13.1556C41 12.8556 39.5 12.6556 38 12.5556C36.5 12.4556 35 12.4556 33.7 12.7556H33.6C33.3 12.8556 33.1 12.6556 33 12.3556C33 12.0556 33.1 11.7556 33.4 11.7556Z" fill="#989FB0" />
		<path d="M1.4 26.7556C3 26.3556 4.5 26.2556 6.1 26.2556C7.6 26.2556 9.2 26.3556 10.7 26.5556C12.2 26.7556 13.8 27.1556 15.3 27.8556C16.8 28.5556 18.3 29.5556 19.2 31.2556L17.5 30.8556L19.3 29.4556C20 29.0556 20.6 28.5556 21.2 28.1556C22.5 27.3556 23.9 26.6556 25.4 26.1556C26.9 25.6556 28.4 25.2556 29.9 25.0556C31.5 24.9556 33 24.9556 34.6 25.3556C34.9 25.4556 35 25.7556 34.9 25.9556C34.8 26.2556 34.6 26.3556 34.3 26.2556H34.2C32.9 25.9556 31.4 26.0556 30 26.2556C28.6 26.4556 27.2 26.9556 25.9 27.5556C24.6 28.1556 23.4 28.8556 22.2 29.6556C21.6 30.0556 21 30.5556 20.5 30.9556L18.9 32.3556C18.4 32.7556 17.8 32.6556 17.4 32.2556L17.3 32.1556L17.2 31.9556C16.8 30.8556 15.7 29.9556 14.5 29.3556C13.3 28.7556 11.9 28.2556 10.4 27.9556C9 27.6556 7.5 27.4556 6 27.3556C4.5 27.2556 3 27.2556 1.7 27.5556H1.6C1.3 27.6556 1.1 27.4556 1 27.1556C1 27.0556 1.2 26.7556 1.4 26.7556Z" fill="#989FB0" />
		<path d="M125 82.2773C125 77.4591 122.5 73.2233 118.669 70.8406C119.201 69.0404 119.467 67.1872 119.467 65.2282C119.467 57.6037 115.158 50.9323 108.828 47.5966C108.881 46.9612 108.934 46.3259 108.934 45.6376C108.934 36.3717 101.38 28.8002 92.0168 28.8002C88.8249 28.8002 85.8458 29.7003 83.2923 31.1829C81.5367 28.6414 78.5576 27 75.2061 27C70.1522 27 66.0027 30.6534 65.2047 35.4716C57.7569 37.2718 52.2242 43.9432 52.2242 51.8854C52.2242 54.268 52.703 56.5448 53.6074 58.6097C48.9791 62.2631 46 67.8755 46 74.2822C46 81.0066 49.2983 86.9367 54.4054 90.5371C51.7455 94.0317 50.1495 98.4263 50.1495 103.139C50.1495 114.681 59.5125 124 71.1098 124C77.0148 124 82.3347 121.564 86.165 117.646C89.9953 121.141 95.0492 123.259 100.635 123.259C112.498 123.259 122.074 113.728 122.074 101.921C122.074 98.6381 121.329 95.5142 119.999 92.7609C123.085 90.2724 125 86.5131 125 82.2773Z" fill="white" />
		<path d="M85.5996 59V147" stroke="#B9C0D0" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
		<path d="M86 93L98 82" stroke="#B9C0D0" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
		<path d="M85.6003 109.226L74.6738 96.1475" stroke="#B9C0D0" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
		<path d="M85 78L77 69" stroke="#B9C0D0" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
		<path d="M32.6002 42.2556C34.2002 41.8556 35.7002 41.7556 37.3002 41.7556C38.8002 41.7556 40.4002 41.8556 41.9002 42.0556C43.4002 42.2556 45.0002 42.6556 46.5002 43.3556C48.0002 44.0556 49.5002 45.0556 50.4002 46.7556L48.7002 46.3556L50.5002 44.9556C51.2002 44.5556 51.8002 44.0556 52.4002 43.6556C53.7002 42.8556 55.1002 42.1556 56.6002 41.6556C58.1002 41.1556 59.6002 40.7556 61.1002 40.5556C62.7002 40.4556 64.2002 40.4556 65.8002 40.8556C66.1002 40.9556 66.2002 41.2556 66.1002 41.4556C66.0002 41.7556 65.8002 41.8556 65.5002 41.7556H65.4002C64.1002 41.4556 62.6002 41.5556 61.2002 41.7556C59.8002 41.9556 58.4002 42.4556 57.1002 43.0556C55.8002 43.6556 54.6002 44.3556 53.4002 45.1556C52.8002 45.5556 52.2002 46.0556 51.7002 46.4556L50.1002 47.8556C49.6002 48.2556 49.0002 48.1556 48.6002 47.7556L48.5002 47.6556L48.4002 47.4556C48.0002 46.3556 46.9002 45.4556 45.7002 44.8556C44.5002 44.2556 43.1002 43.7556 41.6002 43.4556C40.2002 43.1556 38.7002 42.9556 37.2002 42.8556C35.7002 42.7556 34.2002 42.7556 32.9002 43.0556H32.8002C32.5002 43.1556 32.3002 42.9556 32.2002 42.6556C32.2002 42.5556 32.3002 42.2556 32.6002 42.2556Z" fill="#989FB0" />
		<rect x="4" y="147" width="164" height="3" fill="#CDD6E1" />
		<defs>
			<linearGradient id="paint0_linear" x1="139.95" y1="15.1535" x2="139.935" y2="32.6754" gradientUnits="userSpaceOnUse">
				<stop stopColor="white" />
				<stop offset="0.9964" stopColor="#ECF0F5" />
			</linearGradient>
			<linearGradient id="paint1_linear" x1="145.434" y1="14.0769" x2="152.784" y2="22.1159" gradientUnits="userSpaceOnUse">
				<stop stopColor="#BCCBE1" />
				<stop offset="0.9942" stopColor="white" stopOpacity="0" />
			</linearGradient>
			<linearGradient id="paint2_linear" x1="124.94" y1="10.9644" x2="136.525" y2="15.4978" gradientUnits="userSpaceOnUse">
				<stop stopColor="#E2E8F0" />
				<stop offset="0.9942" stopColor="white" stopOpacity="0" />
			</linearGradient>
		</defs>
	</svg>
)