export const appleLogin = async (appleId) => {
  const data = {
    appleId
  }
  try {
    const res = await fetch("/api/apple/login", {
      method: "POST",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify(data)
    })
    const json = await res.json()
    return json
  } catch (error) {
    console.error("Error:", error)
  }
  return true
}