export const deleteTreeAvatar = async (
  token, positionId
) => {
  try {
    const treeInfo = {
      positionId
    }
    const res = await fetch("/api/user-tree/delete-user-tree-avatar", {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Authorization: token
      },
      body: JSON.stringify(treeInfo)
    })
    const json = await res.json()
    return json
  } catch (error) {
    const message = "Something went wrong"
    return message
  }
}