import React, { useState } from "react"
import {
	Input, Button, message
} from "antd"
import { CancelButton } from "shared/buttons"
import { useTranslation } from "react-i18next"
import styles from "./search-people-components.module.css"
import { searchPeople } from "../api"

export const SearchPeopleInput = ({ setSearchPeopleResult, setSearchPeopleStatus }) => {
	const [searchUser, setSerchUser] = useState("")
	const { t } = useTranslation()

	return (
		<div className={styles.input_form}>
			<div className={styles.search_panel}>
				<div className={styles.input_form_inputs}>
					<Input
						className={styles.input_field}
						placeholder={t("serch-people-in-Life-Twig")}
						value={searchUser}
						onChange={(e) => setSerchUser(e.target.value)}
					/>
				</div>
				<div className={styles.input_form_buttons}>
					<CancelButton
						cancel={() => {
							setSerchUser("")
						}}
						style={{ width: "110px" }}
					/>
					<Button
						type="primary"
						className={styles.confirm_button}
						onClick={async () => {
							if (searchUser === "") {
								message.error(`${t("please-enter-person-first-name-and-last-name-for-search")}`)
							} else {
								const result = await searchPeople(searchUser)
								if (result.status) {
									setSearchPeopleStatus(result.status)
									setSearchPeopleResult(result.result)
								}
							}
						}}
					>
						{t("search|")}
					</Button>
				</div>
			</div>

		</div>
	)
}