import React, { useState } from "react"
import { Switch } from "antd"
import { LeftOutlined, RightOutlined } from "@ant-design/icons"
import { useDispatch } from "react-redux"
import { setTreeDirection } from "modules/family-tree/ducks"
import { useTranslation } from "react-i18next"
import styles from "./tree-direction.module.css"

export const TreeDirection = () => {
	const dispatch = useDispatch()
	const [direction, setDirection] = useState(true)
	const { t } = useTranslation()

	return (
		<div className={styles.switch}>
			<div className={styles.switch_label}>{t("bottom-to-top")}</div>
			<Switch
				size="large"
				checkedChildren={<LeftOutlined />}
				unCheckedChildren={<RightOutlined />}
				style={{ background: "#00af75" }}
				onChange={() => {
					if (direction) {
						dispatch(setTreeDirection("BottomToTop"))
						setDirection(false)
					}
					if (!direction) {
						dispatch(setTreeDirection("TopToBottom"))
						setDirection(true)
					}
				}}
			/>
			<div className={styles.switch_label}>{t("top-to-bottom")}</div>
		</div>
	)
}