import React, { useState } from "react"
import { socket } from "app/socket"
import { DeleteOutlined } from "@ant-design/icons"
import { useTranslation } from "react-i18next"
import { useSelector } from "react-redux"
import { Modal, Button } from "antd"

import styles from "./delete-message.module.css"

export const DeleteMessage = ({ selectedMessages, setSelectedMessages, chatId }) => {
	const { t } = useTranslation()

	const { user } = useSelector(s => s)
	const { currentUser } = user

	const [isModalVisible, setIsModalVisible] = useState(false)

	const onCancel = () => {
		setIsModalVisible(false)
		setSelectedMessages([])
	}

	const deleteForUser = () => {
		setIsModalVisible(false)
		socket.emit("ROOM:DELETE_MESSAGE_FOR_SENDER", chatId, selectedMessages, currentUser._id)
		setSelectedMessages([])
	}

	const deleteForAll = () => {
		setIsModalVisible(false)
		socket.emit("ROOM:DELETE_MESSAGE", chatId, selectedMessages)
		setSelectedMessages([])
	}

	return (
		<>
			<div className={selectedMessages.length !== 0
				? styles.main_content
				: styles.main_content_hidden}
			>
				<button
					type="button"
					className={styles.button_cancellation}
					onClick={() => {
						setSelectedMessages([])
					}}
				>
					Cancel
				</button>
				<button
					type="button"
					className={styles.button_delete}
					onClick={() => {
						setIsModalVisible(true)
					}}
				>
					<DeleteOutlined />
					<span>
						{`+ ${selectedMessages.length}`}
					</span>
				</button>
			</div>

			<Modal
				title={(
					<div>
						{t("delete-message")}
					</div>
				)}
				visible={isModalVisible}
				keyboard
				footer={[
					<Button key="back" onClick={onCancel}>
						{t("cancel")}
					</Button>,
					<Button key="delete" type="danger" onClick={deleteForUser}>
						{t("delete-for-your-self")}
					</Button>,
					<Button
						key="delete-all"
						type="danger"
						onClick={deleteForAll}
					>
						{t("delete-for-all")}
					</Button>
				]}
			>
				{`${t("do-you-really-want-to-delete")} ${selectedMessages.length} ${t("messages-?")}`}
			</Modal>

		</>
	)
}