import React from "react"
import { UserAvatar } from "shared/avatar"
import { Modal } from "antd"
import { updateUser } from "app/redux/user"
import { useSelector } from "react-redux"
import { socket } from "app/socket"
import { store } from "app/redux/store"
import { useHistory } from "react-router-dom"
import { ExclamationCircleOutlined } from "@ant-design/icons"
import moment from "moment-timezone"
import { useTranslation } from "react-i18next"
import styles from "./connection-card.module.css"

const { confirm } = Modal

export const UserConnectionCard = ({
	user, currentUser, removeConnection, friendsOrFamilySelected
}) => {
	const { messages } = useSelector(s => s)
	const { t } = useTranslation()
	const history = useHistory()

	const showConfirm = () => {
		confirm({
			title: friendsOrFamilySelected === "friends" ? `${t("are-you-sure-you-want-to-remove")} ${user.firstName} ${user.lastName} ${t("from-your-friends")}?` : `${t("are-you-sure-you-want-to-remove")} ${user.firstName} ${user.lastName} ${t("from-your-family")}?`,
			icon: <ExclamationCircleOutlined />,
			okText: `${t("yes")}`,
			okType: "danger",
			cancelText: `${t("no")}`,
			onOk() {
				removeConnection(user._id)
				if (friendsOrFamilySelected === "friends") {
					const friends = currentUser.friends.filter(friend => friend !== user._id)
					store.dispatch(updateUser({
						...currentUser,
						friends
					}))
				}
				if (friendsOrFamilySelected === "family") {
					const family = currentUser.family.filter(parent => parent !== user._id)
					store.dispatch(updateUser({
						...currentUser,
						family
					}))
				}
			}
		})
	}

	return (
		<div className={styles.wrap}>
			<UserAvatar
				user={user}
				style={{
					width: "60px", height: "60px", fontSize: "56px", color: "#fff"
				}}
			/>
			<div className={styles.user_info}>
				<div className={styles.user_name}>
					<div>
						{user.firstName}
						{" "}
						{user.lastName}
						{" "}
					</div>
					<div className={styles.user_livesIn}>
						{user.location.name}
					</div>
				</div>
				<div className={styles.buttons}>
					<button
						type="button"
						className={styles.confirm_button}
						onClick={() => {
							if (messages.find(message => message.users.length === 2
								&& !message.roomName
								&& message.users.find(u => u._id === user._id)
								&& message.users.find(u => u._id === currentUser._id))) {
								history.push({ pathname: "/messages", userId: user._id })
							} else {
								socket.emit("START_CHAT", { roomName: null, recipients: [user._id], userId: currentUser._id })
								socket.on("START_CHAT", chat => {
									const recepient = chat.users.find(user => user._id !== currentUser._id)
									history.push({ pathname: "/messages", userId: recepient._id })
								})
							}
						}}
					>
						{t("send-messages")}
					</button>
					<button
						type="button"
						className={styles.confirm_button}
						onClick={() => {
							history.push({ pathname: "/profile", userId: user._id })
						}}
					>
						{t("view-profile")}
					</button>
					<button
						type="button"
						className={`${styles.confirm_button} ${styles.remove_button}`}
						onClick={showConfirm}
					>
						{t("remove")}
					</button>
				</div>
			</div>
			{!user.isOnline ? (
				<div
					title={moment(user.lastSession).tz(`${moment.tz.guess()}`).fromNow()}
					className={styles.card_last_session}
				>
					{moment(user.lastSession).tz(`${moment.tz.guess()}`).fromNow()}
				</div>
			) : (<div className={styles.card_last_session} />)}
		</div>
	)
}