import React from "react"
import { UserAvatar } from "shared/avatar"
import { useHistory } from "react-router-dom"
import moment from "moment-timezone"
import styles from "./right-panel-card.module.css"

export const RightPanelCard = ({ user }) => {
	const history = useHistory()

	return (
		<div
			className={styles.card}
			role="button"
			tabIndex={0}
			onClick={() => {
				history.push({ pathname: "/profile", userId: user._id })
			}}
		>
			<UserAvatar user={user} />
			<div className={styles.card_name}>
				{user.firstName}
				{" "}
				{user.lastName}
				{" "}
				{user.maidenName && user.maidenName !== "" ? `(${user.maidenName})` : ""}
			</div>
			{!user.isOnline && (
				<div
					title={moment(user.lastSession).tz(`${moment.tz.guess()}`).fromNow()}
					className={styles.card_last_session}
				>
					{moment(user.lastSession).tz(`${moment.tz.guess()}`).fromNow()}
				</div>
			)}
		</div>
	)
}