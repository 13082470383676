import React, { useState, useEffect } from "react"
import { NavLink, useHistory } from "react-router-dom"
import { v4 as uuidv4 } from "uuid"
import { socket } from "app/socket"
import { UserAvatar } from "shared/avatar"
import { useSelector, useDispatch } from "react-redux"
import moment from "moment"
import { updateUser } from "app/redux/user"
import { Popover } from "antd"
import { Turn as Hamburger } from "hamburger-react"
import { AppLogoIcon } from "shared/logo-icon"
import { EmptyNotificationsImage } from "shared/empty-screen-images"
import { useTranslation } from "react-i18next"
import {
	getInvitesSenders, acceptAsFriend,
	declineFriendship, acceptAsFamilyMember,
	declineFamilyRequest, deleteNotification,
	getUpdateUserNotifications
} from "./api"
import {
	birthday,
	comment,
	commentAlbum,
	commentMedia,
	familyRequestAccept,
	familyRequestDecline,
	friendRequestAccept,
	friendRequestDecline,
	like,
	mentions,
	newTreeElement,
	share
} from "./notifications"
import {
	TreeIcon, MapIcon, FriendsIcon, SearchIcon, NotificationsIcon
} from "./header-icons"
import styles from "./header.module.css"

export const Header = ({ isBurgerActive, setIsBurgerActive }) => {
	const { user } = useSelector(s => s)
	const { currentUser } = user

	const { t } = useTranslation()
	const dispatch = useDispatch()
	const history = useHistory()
	const [visibleNotification, setVisibleNotificatio] = useState(false)
	const [inviteSenders, setInviteSenders] = useState([])
	const [unreadedNotifications, setUnreadedNotifications] = useState(0)

	useEffect(() => {
		const getData = async () => {
			const result = await getInvitesSenders()
			setInviteSenders([
				...result.result.invitesAsFamily,
				...result.result.invitesAsFriend
			])
		}
		getData()
		setUnreadedNotifications(currentUser.notifications.filter(notification => !notification.readed))
	}, [currentUser.invitesAsFamily, currentUser.invitesAsFriend, currentUser.notifications])

	const notificationContent = (notification) => {
		switch (notification.type) {
			case "birthday":
				return birthday(notification, t)
			case "friend-request-accept":
				return friendRequestAccept(notification, t)
			case "friend-request-decline":
				return friendRequestDecline(notification, t)
			case "family-request-accept":
				return familyRequestAccept(notification, t)
			case "family-request-decline":
				return familyRequestDecline(notification, t)
			case "comment":
				return comment(notification, t, history)
			case "commentMedia":
				return commentMedia(notification, t)
			case "commentAlbum":
				return commentAlbum(notification, t)
			case "share":
				return share(notification, t, history)
			case "like":
				return like(notification, t, history)
			case "mentions":
				return mentions(notification, t, currentUser)
			case "new-tree-element":
				return newTreeElement(notification, t, history)
			default:
				return null
		}
	}

	const popoverContent = (
		<div
			className={styles.popover_container}
			onMouseLeave={async () => {
				const userNotificationsResponse = await getUpdateUserNotifications()
				setVisibleNotificatio(false)
				if (userNotificationsResponse?.status === "Updated successfully") {
					dispatch(updateUser({
						...currentUser,
						notifications: currentUser.notifications.map(n => {
							n.readed = true
							return n
						})
					}))
				}
			}}
		>
			{currentUser.invitesAsFamily.map(inviter => {
				const sender = inviteSenders.find(sender => sender._id === inviter)
				if (!sender) {
					return null
				}
				return (
					<div key={uuidv4()} className={styles.popover_element}>
						<UserAvatar user={sender} />
						<div className={styles.popover_element_main_container}>
							<div>
								{sender.firstName}
								{" "}
								{sender.lastName}
								{" "}
								<span className={styles.popover_element_text}>
									{t("want-to-invite-you-as-family")}
								</span>
							</div>
							<div className={styles.popover_element_buttons}>
								<button
									type="button"
									className={styles.popover_element_decline_button}
									onClick={async () => {
										const response = await declineFamilyRequest(sender._id)
										if (response.status === "OK") {
											dispatch(updateUser({
												...currentUser,
												invitesAsFamily: currentUser.invitesAsFamily.filter(
													invite => invite !== sender._id
												)
											}))
											socket.emit("DECLINE_FAMILY_REQUEST", { userId: sender._id })
										}
									}}
								>
									{t("decline")}
								</button>
								<button
									type="button"
									onClick={async () => {
										const response = await acceptAsFamilyMember(sender._id)
										if (response.status === "OK") {
											dispatch(updateUser({
												...currentUser,
												invitesAsFamily: currentUser.invitesAsFamily.filter(
													invite => invite !== sender._id
												),
												family: [...currentUser.family, sender._id]
											}))
											socket.emit("ACCEPT_FAMILY_REQUEST", { userId: sender._id })
										}
									}}
								>
									{t("accept")}
								</button>
							</div>
						</div>
					</div>
				)
			})}
			{
				currentUser.invitesAsFriend.map(inviter => {
					const sender = inviteSenders.find(sender => sender._id === inviter)
					if (!sender) {
						return null
					}
					return (
						<div key={uuidv4()} className={styles.popover_element}>
							<UserAvatar user={sender} />
							<div className={styles.popover_element_main_container}>
								<div>
									{sender.firstName}
									{" "}
									{sender.lastName}
									{" "}
									<span className={styles.popover_element_text}>
										{t("want-to-invite-you-as-friend")}
									</span>
								</div>
								<div className={styles.popover_element_buttons}>
									<button
										type="button"
										className={styles.popover_element_decline_button}
										onClick={async () => {
											const response = await declineFriendship(sender._id)
											if (response.status === "OK") {
												dispatch(updateUser({
													...currentUser,
													invitesAsFriend: currentUser.invitesAsFriend.filter(
														invite => invite !== sender._id
													)
												}))
												socket.emit("DECLINE_FRIENDSHIP", { userId: sender._id })
											}
										}}
									>
										{t("decline")}
									</button>
									<button
										type="button"
										onClick={async () => {
											const response = await acceptAsFriend(sender._id)
											if (response.status === "OK") {
												dispatch(updateUser({
													...currentUser,
													invitesAsFriend: currentUser.invitesAsFriend.filter(
														invite => invite !== sender._id
													),
													friends: [...currentUser.friends, sender._id]
												}))
												socket.emit("ACCEPT_FRIENDSHIP", { userId: sender._id })
											}
										}}
									>
										{t("accept")}
									</button>
								</div>
							</div>
						</div>
					)
				})
			}
			{
				currentUser.notifications.map(notification => (
					<div
						key={notification.id}
						className={styles.popover_element}
						onMouseEnter={() => {
							if (!notification.readed) {
								dispatch(updateUser({
									...currentUser,
									notifications: currentUser.notifications.map(n => {
										if (notification.id === n.id) {
											n.readed = true
											return n
										}
										return n
									})
								}))
							}
						}}
					>
						{!notification.readed && (
							<div
								className={styles.new_notification_label}
							>
								{t("new")}
							</div>
						)}
						<UserAvatar user={notification.user ? notification.user : notification?.users[0]} />
						<div className={styles.popover_element_main_container}>
							{notificationContent(notification)}
						</div>
						{
							notification?.date
							&& (
								<div
									className={styles.notification_date}
								>
									{moment(notification?.date).format("lll")}
								</div>
							)
						}
						<button
							className={styles.delete_notification_btn}
							type="button"
							onClick={async () => {
								const response = await deleteNotification(notification.id)
								if (response.status === "OK") {
									dispatch(updateUser({
										...currentUser,
										notifications: currentUser.notifications
											.filter(n => n.id !== notification.id)
									}))
								}
							}}
						>
							x
						</button>
					</div>
				))
			}
		</div>
	)

	const noContentMesage = (
		<div
			className={styles.notifications_container_empty}
			onMouseLeave={async () => {
				const userNotificationsResponse = await getUpdateUserNotifications()
				setVisibleNotificatio(false)
				if (userNotificationsResponse?.status === "Updated successfully") {
					dispatch(updateUser({
						...currentUser,
						notifications: currentUser.notifications.map(n => {
							n.readed = true
							return n
						})
					}))
				}
			}}
		>
			<EmptyNotificationsImage />
			<div>{t("there-are-no-notifications-yet")}</div>
		</div>
	)

	return (
		<div
			className={styles.wrap}
			onMouseLeave={() => {
				setVisibleNotificatio(false)
			}}
		>
			<button
				type="button"
				className={styles.burger_btn}
				onClick={() => {
					setIsBurgerActive(!isBurgerActive)
				}}
			>
				<Hamburger rounded color="#8F92A1" toggled={isBurgerActive} toggle={setIsBurgerActive} size={20} />
			</button>
			<button
				type="button"
				onClick={() => {
					history.push({ pathname: "/feed", isReload: true })
				}}
				className={styles.logo}
				style={{ border: "none", outline: "none" }}
			>
				<AppLogoIcon />
			</button>
			<NavLink activeClassName={styles.header_navigation_people_search_active} className={styles.header_navigation_people_search} to="/search-people">
				<SearchIcon />
				&nbsp;
				<span>{t("search")}</span>
			</NavLink>
			<ul className={styles.header_navigation}>
				<li>
					<NavLink activeClassName={styles.header_navigation_element_active} className={styles.header_navigation_element} to="/family-tree">
						<TreeIcon />
						<span>{t("family-tree")}</span>
					</NavLink>
				</li>
				<li>
					<NavLink activeClassName={styles.header_navigation_element_active} className={styles.header_navigation_element} to="/map">
						<MapIcon />
						<span>{t("map")}</span>
					</NavLink>
				</li>
				<li>
					<NavLink activeClassName={styles.header_navigation_element_active} className={styles.header_navigation_element} to="/connections">
						<FriendsIcon />
						<span>{t("connections")}</span>
					</NavLink>
				</li>
			</ul>
			<Popover
				title={(
					<div className={styles.notification_header}>
						<div>{t("notification")}</div>
						{currentUser.notifications && currentUser.notifications.length > 0
							? (
								<button
									type="button"
									className={styles.clear_all_btn}
									onClick={async () => {
										const response = await deleteNotification()
										if (response.status === "OK") {
											dispatch(updateUser({
												...currentUser,
												notifications: []
											}))
										}
									}}
								>
									{t("clear")}
									{" "}
									{t("all")}
								</button>
							)
							: null}
					</div>
				)}
				placement="bottomRight"
				content={
					(inviteSenders.length + currentUser.notifications.length) === 0
						? noContentMesage
						: popoverContent
				}
				trigger="click"
				visible={visibleNotification}
			>
				<button
					className={styles.notifications}
					type="button"
					onClick={async () => {
						setVisibleNotificatio(!visibleNotification)
						if (visibleNotification) {
							const userNotificationsResponse = await getUpdateUserNotifications()
							if (userNotificationsResponse?.status === "Updated successfully") {
								dispatch(updateUser({
									...currentUser,
									notifications: currentUser.notifications.map(n => {
										n.readed = true
										return n
									})
								}))
							}
						}
					}}
				>
					<div className={styles.notifications_container}>
						<NotificationsIcon />
						{(currentUser.invitesAsFriend.length > 0
							|| currentUser.invitesAsFamily.length > 0
							|| unreadedNotifications.length > 0)
							&& (
								<div className={styles.notifications_count}>
									{(currentUser.invitesAsFriend.length
										+ currentUser.invitesAsFamily.length
										+ unreadedNotifications.length) > 99 ? "99" : currentUser.invitesAsFriend.length
										+ currentUser.invitesAsFamily.length
									+ unreadedNotifications.length}
								</div>
							)}
					</div>
				</button>
			</Popover>
		</div>
	)
}
