/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react"
import {
	ComplexHierarchicalTree,
	DiagramComponent,
	Inject,
	SnapConstraints,
	LineDistribution,
	ChildArrangement,
	DiagramConstraints,
	LineRouting,
	SelectorConstraints,
	DiagramTools
} from "@syncfusion/ej2-react-diagrams"
import { store } from "app/redux/store"
import { message } from "antd"
import { useDispatch, useSelector } from "react-redux"
import {
	getNodes, getConnectors, selectPerson, removeChildCard, hideParents
} from "modules/family-tree/utils"
import { setZoom, setSelectedPersonId, setDragMode } from "modules/family-tree/ducks"
import { Person, AddPerson } from "modules/family-tree/components"
import { useTranslation } from "react-i18next"
import { saveUserTree } from "modules/family-tree/api"
import styles from "./diagram.module.css"

let first = true

const getTemplates = (props) => {
	if (props.addInfo.gender) {
		return <Person {...props} />
	}
	return <AddPerson {...props} />
}

export const Diagram = () => {
	const {
		people, zoom, dragMode, treeDirection, selectedPersonId, adaptiveZoom
	} = useSelector(s => s.familyTree)

	const token = localStorage.token ? localStorage.getItem("token") : sessionStorage.getItem("token")

	const [isUserInactive, setIsUserInactive] = useState(false)
	const dispatch = useDispatch()

	const { t } = useTranslation()

	if (!isUserInactive) {
		setTimeout(() => setIsUserInactive(true), 1000 * 60 * 30)
	}
	if (isUserInactive) {
		const uploadTree = async () => {
			dispatch(setSelectedPersonId(null))
			hideParents()
			removeChildCard()
			selectPerson(null)
			const { people } = store.getState().familyTree
			const result = await saveUserTree(people, token)
			if (result.status === "error") {
				message.error(t("something-went-wrong"))
			}
		}
		uploadTree()
	}

	useEffect(() => {
		window.diagramInstance.reset()
		window.diagramInstance.scrollSettings.minZoom = 0.01
		window.diagramInstance.fitToPage()
		dispatch(setZoom(1))
	}, [])

	return (
		<div
			className={selectedPersonId ? `${styles.diagram_active_panel}` : `${styles.diagram}`}
			role="button"
			tabIndex={0}
			onMouseMove={() => {
				setIsUserInactive(false)
			}}
			onMouseDown={() => {
				dispatch(setDragMode(DiagramTools.None))
			}}
			onMouseUp={() => {
				dispatch(setDragMode(DiagramTools.ZoomPan))
			}}
		>
			<DiagramComponent
				id="diagram"
				style={{ outline: 0 }}
				ref={(diagram) => {
					window.diagramInstance = diagram
					if (window.diagramInstance) {
						if (first) {
							first = false
							diagram.fitToPage()
						}
						diagram.doLayout()
					}
				}}
				constraints={DiagramConstraints.Default}
				scrollChange={args => {
					const { CurrentZoom } = args.newValue
					if (CurrentZoom !== zoom) {
						dispatch(setZoom(CurrentZoom))
					}
				}}
				tool={dragMode}
				nodes={getNodes(people)}
				nodeTemplate={getTemplates}
				connectors={getConnectors(people)}
				getConnectorDefaults={(connector) => {
					connector.style = {
						strokeColor: "#888888",
						fill: "#888888",
						strokeWidth: 5
					}
					connector.targetDecorator.shape = "None"
					connector.type = "Orthogonal"
					connector.cornerRadius = 1
					return connector
				}}
				click={async (args) => {
					if (adaptiveZoom) {
						window.diagramInstance.reset()
						window.diagramInstance.fitToPage()
					}
					if (args.element && args.element.diagramid) {
						dispatch(setSelectedPersonId(null))
						hideParents()
						removeChildCard()
						selectPerson(null)
						const { people } = store.getState().familyTree
						const result = await saveUserTree(people, token)
						if (result.status === "error") {
							message.error(t("something-went-wrong"))
						}
					}
				}}
				layout={{
					type: "ComplexHierarchicalTree",
					orientation: treeDirection,
					arrangement: ChildArrangement.Linear,
					margin: {
						left: 5, top: 0, right: 5, bottom: 0
					},
					bounds: {
						height: 100, width: 90, x: 0, y: 0
					},
					verticalSpacing: 50,
					horizontalSpacing: 0,
					horizontalAlignment: "Center",
					verticalAlignment: "Bottom",
					root: "person11"
				}}
				selectedItems={{
					constraints: SelectorConstraints.None
				}}
				snapSettings={{ constraints: SnapConstraints.None }}
			>
				<Inject services={[ComplexHierarchicalTree, LineDistribution, LineRouting]} />
			</DiagramComponent>
		</div>
	)
}
