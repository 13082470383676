import React, { useState } from "react"
import {
	Input, Button, message
} from "antd"
import {
	useLocation, useHistory, Redirect
} from "react-router-dom"
import { AppLogoIcon } from "shared/logo-icon"
import { CancelButton } from "shared/buttons"
import { useTranslation } from "react-i18next"
import { changeUserPassword } from "../api"
import "antd/dist/antd.css"
import styles from "./password-change-page.module.css"

export const PasswordChangePage = () => {
	const location = useLocation()
	const history = useHistory()
	const { email } = location
	const { t } = useTranslation()

	const [newPassword, setNewPassword] = useState("")
	const [newPasswordConfirm, setNewPasswordConfirm] = useState("")

	if (!email) {
		return <Redirect to="/login" />
	}

	return (
		<div className={styles.wrap}>
			<div className={styles.container}>
				<div className={styles.logo}><AppLogoIcon /></div>
				<div className={styles.form}>
					<div className={styles.form_header}>{t("enter-Your-New-Password")}</div>
					<div className={styles.form_message}>
						{t("passworde-notice")}
					</div>
					<Input
						placeholder={t("enter-your-new-password")}
						className={styles.auth_input}
						value={newPassword}
						onChange={(e) => setNewPassword(e.target.value)}
					/>
					<Input
						placeholder={t("enter-your-new-password-again")}
						className={styles.auth_input}
						value={newPasswordConfirm}
						onChange={(e) => setNewPasswordConfirm(e.target.value)}
					/>
					<div className={styles.form_finish_btns_container}>
						<CancelButton
							cancel={() => {
								history.push("/login")
							}}
							style={{ width: "74px", margin: "0px" }}
						/>
						<Button
							type="primary"
							disabled={newPassword.length < 8 && newPasswordConfirm.length < 8}
							className={newPassword.length >= 8 && newPasswordConfirm.length >= 8 ? styles.finish_form_button : `${styles.finish_form_button} ${styles.finish_form_button_disabled}`}
							onClick={async () => {
								if (newPassword.length >= 8 && newPasswordConfirm.length >= 8) {
									if (newPassword !== newPasswordConfirm) {
										message.error(`${t("please-enter-the-same-password-in-both-fields")}`)
									} else {
										const response = await changeUserPassword(newPassword, email)
										if (response.status === "OK") {
											history.push("/login")
										}
										if (response.status === "Not Allowed") {
											message.error(t("passworde-notice"))
										}
										if (response.status === "error") {
											message.error(response.message)
										}
									}
								}
							}}
						>
							{t("confirm")}
						</Button>
					</div>
				</div>
			</div>
			<div className={styles.auth_image} />
		</div>

	)
}