/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useEffect, useState } from "react"
import {
	Popover as APopover, Modal, Input, message
} from "antd"
import { EditIcon, DeleteIcon, MoreIcon } from "shared/icons"
import { useTranslation } from "react-i18next"
import { deleteVideo, renameVideo } from "modules/videos/api"
import styles from "./video.module.css"

const PopoverContent = ({
	video, albumId, setVisible, setVideos, fileName
}) => {
	const { t } = useTranslation()
	const [isShowRenameModal, setIsShowRenameModal] = useState(false)
	const [isShowConfirmModal, setIsShowConfirmModal] = useState(false)
	const [name, setName] = useState(fileName)
	useEffect(() => { setName(video.name) }, [video])

	const rename = async () => {
		setVisible(false)
		setIsShowRenameModal(false)
		if (!name || name === "") {
			message.error(t("please-enter-album-name"))
		} else {
			const res = await renameVideo(name, video.path, albumId)
			if (res.status === "successful") {
				setVideos(videos => videos.map(v => {
					if (v.path === video.path) {
						v.name = name
					}
					return v
				}))
			}
		}
	}

	const videoDelete = async () => {
		const res = await deleteVideo(video.path, albumId)
		setVisible(false)
		if (res.status === "successful") {
			setVideos(v => v.filter(v => v.path !== video.path))
		}
	}
	return (
		<>
			<div className={styles.popover_content}>
				<button
					type="button"
					className={styles.popover_btn}
					onClick={() => {
						setIsShowRenameModal(true)
						setVisible(false)
					}}
				>
					<EditIcon />
					<div className={styles.popover_btn_text}>{t("rename-video")}</div>
				</button>
				<button
					type="button"
					className={styles.popover_btn}
					onClick={() => {
						setIsShowConfirmModal(true)
						setVisible(false)
					}}
				>
					<DeleteIcon />
					<div className={styles.popover_btn_text}>
						{" "}
						{t("delete-video")}
					</div>
				</button>
			</div>
			<Modal title={t("rename-video")} visible={isShowRenameModal} onOk={rename} onCancel={() => setIsShowRenameModal(false)}>
				<Input placeholder={t("enter-video-name")} value={name} onChange={e => setName(e.target.value)} />
			</Modal>
			<Modal title={t("delete-video")} visible={isShowConfirmModal} onOk={videoDelete} onCancel={() => setIsShowConfirmModal(false)}>
				<p>{t("are-you-sure-that-you-want-to-delete-the-video")}</p>
			</Modal>
		</>
	)
}

export const Popover = ({
	video, isShowPopover, setIsShowPopover, setVideos, albumId
}) => (
	<APopover
		placement="bottomRight"
		content={() => (
			<PopoverContent
				setVideos={setVideos}
				albumId={albumId}
				video={video}
				setVisible={setIsShowPopover}
			/>
		)}
		trigger="click"
		onVisibleChange={setIsShowPopover}
		visible={isShowPopover}
	>
		<button
			className={styles.albumn_more_btn}
			style={{ fontSize: "24px" }}
			type="button"
		>
			<MoreIcon />
		</button>
	</APopover>
)
