import React from "react"

export const EmptySearchPeopleImage = () => (
	<svg width="172" height="120" viewBox="0 0 172 120" fill="none">
		<path d="M143.946 101.161H14.9902C23.1663 82.9971 48.8909 69.7388 79.468 69.7388C109.979 69.6725 135.77 82.9308 143.946 101.161Z" fill="#EAEEF9" />
		<path d="M157.439 101.161H171" stroke="#D5DAE8" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
		<path d="M1.69678 101.161H150.66" stroke="#D5DAE8" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
		<path d="M42.4434 109.978H155.645" stroke="#D5DAE8" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
		<path d="M16.5195 109.978H35.1981" stroke="#D5DAE8" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
		<path d="M142.55 25.9199C125.334 41.2996 115.363 59.7949 114.299 82.6655" stroke="#D5DAE8" strokeWidth="4" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
		<path d="M20.6981 36.2682C34.0687 47.7526 41.9552 61.7226 43.1079 79.1643" stroke="#D5DAE8" strokeWidth="4" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
		<path d="M143.547 25.9863C143.547 25.9863 138.628 20.4841 132.446 15.1145L133.377 12.8606L130.386 13.3909C128.325 11.6674 126.132 10.0101 123.938 8.68424C128.192 6.16516 131.848 6.43033 135.172 8.55166C141.221 12.264 142.816 19.4898 143.547 25.9863Z" fill="url(#paint0_linear)" />
		<path d="M19.9378 36.3334C19.9378 36.3334 23.6105 32.0606 28.2492 27.8703L27.5053 26.1636L29.7965 26.5241C31.3437 25.1779 32.9934 23.8804 34.648 22.8358C31.3632 20.976 28.5765 21.2326 26.0711 22.9011C21.5092 25.8245 20.3991 31.3637 19.9378 36.3334Z" fill="url(#paint1_linear)" />
		<path d="M141.486 24.9256C141.486 24.9256 135.371 23.3346 128.458 22.4065L128.059 20.3515L126.198 22.1414C123.871 21.8762 121.545 21.7436 119.417 21.8099C121.279 18.0313 123.938 16.5066 127.394 16.4403C133.31 16.2414 137.897 20.6829 141.486 24.9256Z" fill="url(#paint2_linear)" />
		<path d="M21.495 35.4934C21.495 35.4934 26.1393 34.1884 31.4024 33.3775L31.6762 31.8029L33.1235 33.1416C34.8955 32.9047 36.6693 32.769 38.294 32.7881C36.817 29.9314 34.7647 28.807 32.1253 28.8076C27.6065 28.7435 24.1717 32.2017 21.495 35.4934Z" fill="url(#paint3_linear)" />
		<path d="M142.816 27.1795C142.816 27.1795 142.683 19.3571 141.42 10.8718L143.747 9.74485L141.021 8.15386C140.556 5.36961 139.891 2.51908 139.094 0C144.212 0.928081 146.937 3.57974 148.2 7.62353C150.327 14.5841 146.605 21.4785 142.816 27.1795Z" fill="url(#paint4_linear)" />
		<path d="M20.5138 37.2334C20.5138 37.2334 20.4988 31.2605 21.3363 24.7648L19.5437 23.939L21.6003 22.6842C21.914 20.552 22.3789 18.3663 22.9502 16.4316C19.0571 17.2159 17.0163 19.2804 16.1125 22.3858C14.5926 27.7305 17.5368 32.9378 20.5138 37.2334Z" fill="url(#paint5_linear)" />
		<path d="M141.553 26.5829C141.553 26.5829 146.339 32.2177 150.859 39.0457L153.185 38.3828L152.255 41.2333C153.651 43.5535 154.98 45.94 156.044 48.2602C159.101 44.4153 159.367 40.7693 157.705 37.1232C154.847 30.8255 147.868 28.2402 141.553 26.5829Z" fill="url(#paint6_linear)" />
		<path d="M21.4688 36.7595C21.4688 36.7595 17.8995 41.1316 14.551 46.4105L12.7653 45.939L13.5181 48.1011C12.4871 49.8928 11.5079 51.7342 10.7307 53.521C8.33937 50.6314 8.08208 47.8523 9.29622 45.0446C11.3842 40.1951 16.6732 38.1182 21.4688 36.7595Z" fill="url(#paint7_linear)" />
		<path d="M141.752 25.7875C141.752 25.7875 149.463 26.9808 157.772 29.4336L159.235 27.3122L160.431 30.2291C163.156 31.0909 165.882 32.0852 168.275 33.2785C168.075 28.1077 165.815 24.992 161.96 23.2022C155.313 20.0202 147.934 22.7381 141.752 25.7875Z" fill="url(#paint8_linear)" />
		<path d="M21.3049 36.155C21.3049 36.155 15.4369 37.1802 9.13109 39.1756L7.98321 37.578L7.11338 39.8222C5.04592 40.5204 2.98043 41.3198 1.1716 42.2661C1.24677 38.3162 2.92546 35.9045 5.84166 34.4811C10.8681 31.9537 16.5407 33.919 21.3049 36.155Z" fill="url(#paint9_linear)" />
		<path d="M144.154 23.6623C145.309 22.5106 145.309 20.6434 144.154 19.4917C142.999 18.3401 141.127 18.3401 139.972 19.4917C138.817 20.6434 138.817 22.5106 139.972 23.6623C141.127 24.814 142.999 24.814 144.154 23.6623Z" fill="url(#paint10_linear)" />
		<path d="M19.4401 34.5685C18.5415 33.7065 18.5137 32.2812 19.378 31.385C20.2423 30.4888 21.6715 30.4611 22.5701 31.323C23.4688 32.185 23.4966 33.6103 22.6323 34.5065C21.7679 35.4027 20.3388 35.4305 19.4401 34.5685Z" fill="url(#paint11_linear)" />
		<path d="M148.347 28.4784C149.974 26.8556 149.974 24.2245 148.347 22.6017C146.72 20.9789 144.081 20.9789 142.454 22.6017C140.827 24.2245 140.827 26.8556 142.454 28.4784C144.081 30.1012 146.72 30.1012 148.347 28.4784Z" fill="url(#paint12_linear)" />
		<path d="M16.3114 38.307C15.0451 37.0924 15.0059 35.0841 16.2238 33.8212C17.4417 32.5584 19.4556 32.5193 20.7219 33.7339C21.9881 34.9485 22.0273 36.9568 20.8094 38.2197C19.5915 39.4825 17.5777 39.5216 16.3114 38.307Z" fill="url(#paint13_linear)" />
		<path d="M142.368 28.0161C143.418 26.9691 143.418 25.2716 142.368 24.2246C141.318 23.1777 139.616 23.1777 138.566 24.2246C137.516 25.2716 137.516 26.9691 138.566 28.0161C139.616 29.0631 141.318 29.0631 142.368 28.0161Z" fill="url(#paint14_linear)" />
		<path d="M20.8682 37.8654C20.0512 37.0818 20.026 35.7861 20.8117 34.9713C21.5974 34.1566 22.8967 34.1314 23.7136 34.915C24.5306 35.6986 24.5559 36.9943 23.7701 37.8091C22.9844 38.6238 21.6852 38.649 20.8682 37.8654Z" fill="url(#paint15_linear)" />
		<path opacity="0.4" d="M29.2153 94.9294C31.4089 94.4654 33.7354 95.1283 35.3972 96.5867" stroke="#D5DAE8" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
		<path opacity="0.4" d="M52.6802 87.7037C54.7408 86.9082 56.8014 86.2453 58.995 85.7149C60.3244 85.3835 61.7204 85.1846 63.0498 85.4498C64.3792 85.7149 65.6422 86.7093 65.9081 88.0351" stroke="#D5DAE8" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
		<path opacity="0.4" d="M82.6592 90.5541C85.3845 87.2396 90.3034 85.98 94.2918 87.5047C95.1559 87.8362 95.9536 88.3002 96.8842 88.2339C97.8812 88.1676 98.6789 87.5047 99.4101 86.8418C100.141 86.1789 100.806 85.4497 101.737 85.0519C102.667 84.7205 103.864 84.9194 104.329 85.7811" stroke="#D5DAE8" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
		<path opacity="0.4" d="M109.181 94.1339C112.571 91.8137 117.025 91.0845 121.013 92.0789" stroke="#D5DAE8" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
		<path opacity="0.4" d="M47.0967 80.478C47.2961 79.4173 48.5591 78.8207 49.6891 78.9533C50.8191 79.0196 51.8162 79.5499 52.9462 79.7488C54.4086 80.0139 55.9374 79.6162 57.1339 78.6881" stroke="#D5DAE8" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
		<path opacity="0.4" d="M32.606 90.4881C34.7995 90.6207 37.0596 89.8915 38.8543 88.632" stroke="#D5DAE8" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
		<path opacity="0.4" d="M89.439 79.6161C91.6325 79.2847 93.8926 79.6161 95.8203 80.6105" stroke="#D5DAE8" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
		<path opacity="0.6" d="M78.4712 77.4287C88.0529 77.4287 95.8204 76.2283 95.8204 74.7476C95.8204 73.2669 88.0529 72.0665 78.4712 72.0665C68.8895 72.0665 61.1221 73.2669 61.1221 74.7476C61.1221 76.2283 68.8895 77.4287 78.4712 77.4287Z" fill="#D5DAE8" />
		<path d="M60.9321 119H121.311" stroke="#D5DAE8" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
		<path d="M101.22 50.4532C101.22 56.143 99.1866 61.3183 95.7877 65.3738C91.5088 70.4885 85.0752 73.7269 77.8525 73.7269C70.9334 73.7269 64.7426 70.7307 60.4636 65.9791C56.7916 61.8631 54.5459 56.4154 54.5459 50.4532C54.5459 37.5907 64.9853 27.1796 77.8829 27.1796C90.7804 27.1796 101.22 37.5907 101.22 50.4532Z" fill="#DCE1EE" />
		<path d="M95.8183 65.3739C91.5393 70.4887 85.1057 73.727 77.8831 73.727C70.9639 73.727 64.7731 70.7308 60.4941 65.9792C61.2225 65.4042 62.1329 64.8897 63.2557 64.4357L64.1055 64.1028C65.7139 63.4672 67.8078 62.6803 70.6301 61.5C71.2674 61.2276 71.7833 60.7434 72.0564 60.1078C72.2082 59.7749 72.2992 59.3815 72.2992 58.988V53.7522C72.2082 53.6311 72.1171 53.5403 72.0564 53.4193C71.6012 52.7535 71.2977 51.9666 71.2067 51.1192L70.7515 50.9073C69.3859 51.2402 69.4769 49.7875 69.1431 47.0031C68.9914 45.8531 69.1734 45.611 69.6893 45.4294L70.1142 44.8543C67.5043 38.9527 68.8093 35.2604 71.3888 33.959C70.5391 32.0826 70.4784 31.4167 70.4784 31.4167C70.4784 31.4167 75.9712 32.3247 77.8831 31.9615C80.2805 31.4773 83.9829 32.0523 85.3485 35.2301C87.6245 36.1381 88.5349 37.5605 88.717 39.1343C89.0508 41.616 87.6245 44.3398 87.3514 45.3991C87.3817 45.4596 87.4121 45.4899 87.4121 45.5202C87.7763 45.7018 87.8976 46.0044 87.7763 47.0031C87.4424 49.6362 87.5335 51.2705 86.1679 50.9073L84.954 53.0258C84.8933 53.268 84.8933 53.3588 84.8629 53.5403C84.8022 53.9338 84.8326 54.7207 84.8326 59.0485C84.8326 59.563 84.9843 60.0473 85.2574 60.5013C85.5609 60.9552 85.9858 61.3487 86.532 61.5605C86.532 61.5605 86.5624 61.5605 86.5927 61.5908C89.7185 62.8922 91.9642 63.7396 93.6029 64.3752C94.4526 64.6475 95.181 65.0107 95.8183 65.3739Z" fill="white" />
		<path d="M87.412 45.3688C87.5334 44.703 87.2299 43.8859 86.9871 43.3108C86.9568 43.22 86.8961 43.1292 86.8657 43.0082C85.8036 40.8896 83.4668 40.0422 81.2212 39.9212C75.4855 39.6185 74.9696 40.7081 73.1792 39.0738C73.7254 39.9212 73.7861 41.2226 72.9364 42.8569C72.3294 44.0069 71.1762 44.5214 70.1748 44.8543C67.5649 38.9527 68.8699 35.2604 71.4494 33.959C70.5996 32.0826 70.5389 31.4167 70.5389 31.4167C70.5389 31.4167 76.0318 32.3247 77.9133 31.9615C80.3107 31.4773 84.0131 32.0523 85.3787 35.2301C87.6548 36.1381 88.5348 37.5605 88.7473 39.1343C89.0811 41.5857 87.6851 44.2793 87.412 45.3688Z" fill="url(#paint16_linear)" />
		<path d="M85.3179 60.4405V61.4696H72.0562V60.1379C72.2079 59.805 72.2989 59.4115 72.2989 59.0181V53.752C72.2079 53.631 72.1168 53.5402 72.0562 53.4191V53.1165C72.3293 53.6007 72.6934 54.0547 73.1487 54.4178L77.3062 57.293C78.2773 58.1102 79.6733 58.1404 80.6748 57.3535L84.5592 53.8731C84.6806 53.752 84.8323 53.631 84.9537 53.5099C84.893 53.9033 84.9234 54.6902 84.9234 59.0181C84.893 59.5326 85.0447 60.0471 85.3179 60.4405Z" fill="url(#paint17_linear)" />
		<defs>
			<linearGradient id="paint0_linear" x1="133.744" y1="7.54126" x2="133.744" y2="18.2669" gradientUnits="userSpaceOnUse">
				<stop stopColor="#EAEEF5" />
				<stop offset="1" stopColor="#D4DAE4" />
			</linearGradient>
			<linearGradient id="paint1_linear" x1="27.1458" y1="22.1087" x2="27.3048" y2="30.2958" gradientUnits="userSpaceOnUse">
				<stop stopColor="#EAEEF5" />
				<stop offset="1" stopColor="#D4DAE4" />
			</linearGradient>
			<linearGradient id="paint2_linear" x1="130.454" y1="16.7288" x2="130.454" y2="21.4952" gradientUnits="userSpaceOnUse">
				<stop stopColor="#EAEEF5" />
				<stop offset="1" stopColor="#D4DAE4" />
			</linearGradient>
			<linearGradient id="paint3_linear" x1="29.7942" y1="29.0732" x2="29.8649" y2="32.7114" gradientUnits="userSpaceOnUse">
				<stop stopColor="#EAEEF5" />
				<stop offset="1" stopColor="#D4DAE4" />
			</linearGradient>
			<linearGradient id="paint4_linear" x1="143.958" y1="0.944185" x2="143.958" y2="16.1998" gradientUnits="userSpaceOnUse">
				<stop stopColor="#EAEEF5" />
				<stop offset="1" stopColor="#D4DAE4" />
			</linearGradient>
			<linearGradient id="paint5_linear" x1="19.2512" y1="17.2245" x2="19.4773" y2="28.8693" gradientUnits="userSpaceOnUse">
				<stop stopColor="#EAEEF5" />
				<stop offset="1" stopColor="#D4DAE4" />
			</linearGradient>
			<linearGradient id="paint6_linear" x1="150.137" y1="27.3359" x2="150.137" y2="39.5032" gradientUnits="userSpaceOnUse">
				<stop stopColor="#EAEEF5" />
				<stop offset="1" stopColor="#D4DAE4" />
			</linearGradient>
			<linearGradient id="paint7_linear" x1="14.9273" y1="37.4616" x2="15.1076" y2="46.7491" gradientUnits="userSpaceOnUse">
				<stop stopColor="#EAEEF5" />
				<stop offset="1" stopColor="#D4DAE4" />
			</linearGradient>
			<linearGradient id="paint8_linear" x1="155.016" y1="22.1769" x2="155.016" y2="28.6323" gradientUnits="userSpaceOnUse">
				<stop stopColor="#EAEEF5" />
				<stop offset="1" stopColor="#D4DAE4" />
			</linearGradient>
			<linearGradient id="paint9_linear" x1="11.127" y1="33.5956" x2="11.2227" y2="38.5231" gradientUnits="userSpaceOnUse">
				<stop stopColor="#EAEEF5" />
				<stop offset="1" stopColor="#D4DAE4" />
			</linearGradient>
			<linearGradient id="paint10_linear" x1="140.117" y1="19.6363" x2="142.458" y2="21.9836" gradientUnits="userSpaceOnUse">
				<stop stopColor="#B0BACC" />
				<stop offset="1" stopColor="#969EAE" />
			</linearGradient>
			<linearGradient id="paint11_linear" x1="22.4611" y1="31.4355" x2="20.7093" y2="33.2619" gradientUnits="userSpaceOnUse">
				<stop stopColor="#B0BACC" />
				<stop offset="1" stopColor="#969EAE" />
			</linearGradient>
			<linearGradient id="paint12_linear" x1="142.659" y1="22.8054" x2="145.958" y2="26.1129" gradientUnits="userSpaceOnUse">
				<stop stopColor="#B0BACC" />
				<stop offset="1" stopColor="#969EAE" />
			</linearGradient>
			<linearGradient id="paint13_linear" x1="20.5683" y1="33.8924" x2="18.0997" y2="36.466" gradientUnits="userSpaceOnUse">
				<stop stopColor="#B0BACC" />
				<stop offset="1" stopColor="#969EAE" />
			</linearGradient>
			<linearGradient id="paint14_linear" x1="138.698" y1="24.356" x2="140.826" y2="26.4899" gradientUnits="userSpaceOnUse">
				<stop stopColor="#B0BACC" />
				<stop offset="1" stopColor="#969EAE" />
			</linearGradient>
			<linearGradient id="paint15_linear" x1="23.6146" y1="35.0173" x2="22.022" y2="36.6776" gradientUnits="userSpaceOnUse">
				<stop stopColor="#B0BACC" />
				<stop offset="1" stopColor="#969EAE" />
			</linearGradient>
			<linearGradient id="paint16_linear" x1="68.7413" y1="38.3943" x2="88.8022" y2="38.3943" gradientUnits="userSpaceOnUse">
				<stop stopColor="#B0BACC" />
				<stop offset="1" stopColor="#969EAE" />
			</linearGradient>
			<linearGradient id="paint17_linear" x1="78.691" y1="61.5204" x2="78.691" y2="56.9187" gradientUnits="userSpaceOnUse">
				<stop stopColor="white" />
				<stop offset="1" stopColor="#E2E5EC" />
			</linearGradient>
		</defs>
	</svg>
)