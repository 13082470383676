import { NodeConstraints, AnnotationConstraints } from "@syncfusion/ej2-react-diagrams"

const shape = { type: "HTML" }
const constraints = NodeConstraints.Default & ~NodeConstraints.Select
const annotations = [{ constraints: AnnotationConstraints.ReadOnly }]

export const getNode = (person) => ({
	addInfo: {
		name: person.name,
		isUser: person.isUser,
		gender: person.gender,
		parents: person.parents,
		partner: person.partner,
		hide: person.hide,
		withoutChild: person.withoutChild,
		lastGeneration: person.lastGeneration,
		from: person.from,
		livesIn: person.livesIn,
		birthDate: person.birthDate,
		deathDate: person.deathDate,
		avatar: person.avatar,
		inPending: person.inPending
	},
	id: person.positionId,
	width: person.hide ? 0 : 257,
	height: person.hide ? 0 : 252,
	shape,
	visible: person.visible,
	constraints,
	annotations
})

export const getNodes = (
	people
) => people.map(person => getNode(person))