import React from "react"
import { useSelector } from "react-redux"
import { useHistory } from "react-router-dom"
import { useTranslation } from "react-i18next"
import moment from "moment-timezone"
import { UserAvatar } from "shared/avatar"
import styles from "./post-header.module.css"

export const PostAuthor = ({
	postAuthor,
	post
}) => {
	const history = useHistory()
	const { t } = useTranslation()
	const { user } = useSelector(s => s)
	const { currentUser } = user
	const genderDetect = (gender = "neutral", type) => {
		let genderCHecked = gender
		if (gender !== "female" && gender !== "male" && gender !== "neutral") {
			genderCHecked = "neutral"
		}

		switch (`${genderCHecked}-${type}`) {
			case "female-photo":
				return t("added-photo-to-album-female")

			case "male-photo":
				return t("added-photo-to-album-male")

			case "neutral-photo":
				return t("added-photo-to-album-neutral")

			case "female-video":
				return t("added-video-to-album-female")

			case "male-video":
				return t("added-video-to-album-male")

			case "neutral-video":
				return t("added-video-to-album-neutral")

			default:
				return null
		}
	}
	return (
		<div
			className={styles.post_author}
			role="button"
			tabIndex={0}
			onClick={() => {
				if (postAuthor._id === currentUser._id) {
					history.push({ pathname: "/user-profile" })
				} else if (post.sharedCopyAuthorId) {
					history.push({ pathname: "/profile", userId: post.sharedCopyAuthorId })
				} else {
					history.push({ pathname: "/profile", userId: postAuthor._id })
				}
			}}
		>
			<UserAvatar user={postAuthor} />
			<div className={styles.post_author_info}>
				<div className={styles.post_author_name}>
					{postAuthor.firstName}
					{" "}
					{postAuthor.lastName}
					{" "}
					{postAuthor.maidenName && postAuthor.maidenName !== "" ? `(${postAuthor.maidenName})` : ""}
					{" "}
					{post.isUploadedPhoto ? (
						<>
							<span className={styles.post_image_published}>
								{genderDetect(postAuthor?.gender || "neutral", "photo")}
							</span>
						</>
					) : ""}
					{post.isUploadedVideo ? (
						<>
							<span className={styles.post_image_published}>
								{genderDetect(postAuthor?.gender || "neutral", "video")}
							</span>
						</>
					) : ""}
				</div>
				<div className={styles.post_publish_date}>
					{moment(post.creationDate).tz(`${moment.tz.guess()}`).fromNow()}
					{" "}
					{post.isEdited && "(edited)"}
				</div>
			</div>
		</div>
	)
}