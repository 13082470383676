import React from "react"

export const SearchPeopleStartScreenImage = () => (
	<svg width="199" height="151" viewBox="0 0 199 151" fill="none">
		<g filter="url(#filter0_d)">
			<path d="M124.243 97.1217H75.0564C72.9591 97.1217 71.2612 95.4261 71.2612 93.3316V25.9573C71.2612 23.8628 72.9591 22.1672 75.0564 22.1672H124.243C126.341 22.1672 128.038 23.8628 128.038 25.9573V93.3316C128.038 95.4261 126.341 97.1217 124.243 97.1217Z" fill="url(#paint0_linear)" />
		</g>
		<path d="M106.042 78.9441H93.2582C91.835 78.9441 90.6865 77.7971 90.6865 76.3758C90.6865 74.9545 91.835 73.8075 93.2582 73.8075H106.042C107.465 73.8075 108.614 74.9545 108.614 76.3758C108.614 77.7971 107.465 78.9441 106.042 78.9441Z" fill="#D5DDEA" />
		<path d="M111.909 84.5046H87.3654C86.6163 84.5046 86.0171 83.9062 86.0171 83.1581C86.0171 82.4101 86.6163 81.8116 87.3654 81.8116H111.909C112.658 81.8116 113.257 82.4101 113.257 83.1581C113.257 83.8812 112.658 84.5046 111.909 84.5046Z" fill="#D5DDEA" />
		<path d="M116.429 48.8975C116.429 53.9343 114.206 58.4476 110.686 61.5146C107.74 64.0829 103.87 65.6289 99.6501 65.6289C95.4305 65.6289 91.5604 64.0829 88.6142 61.4896C85.0937 58.4226 82.8716 53.9094 82.8716 48.8725C82.8716 39.6217 90.3869 32.1162 99.6501 32.1162C108.913 32.1162 116.429 39.6466 116.429 48.8975Z" fill="#D9DEEA" />
		<path d="M110.687 61.5146C107.741 64.0829 103.871 65.6288 99.6516 65.6288C95.432 65.6288 91.5619 64.0829 88.6157 61.4896C89.8392 61.0159 91.4371 60.3925 93.5844 59.5198C94.0837 59.3203 94.4582 58.9463 94.6829 58.4725C94.8078 58.1982 94.8577 57.9239 94.8577 57.6247V53.6102C94.7828 53.5354 94.7329 53.4356 94.6829 53.3608C94.3334 52.8372 94.1087 52.2388 94.0338 51.6154L93.6842 51.4408C92.6605 51.6902 92.7354 50.593 92.4608 48.4736C92.3609 47.6009 92.4858 47.4263 92.8852 47.2767L93.2098 46.8279C91.2124 42.3396 92.2111 39.5219 94.1836 38.5245C93.5344 37.1032 93.4845 36.5796 93.4845 36.5796C93.4845 36.5796 97.6791 37.2778 99.1023 37.0035C100.925 36.6295 103.746 37.0783 104.795 39.497C106.543 40.1702 107.217 41.2923 107.367 42.4642C107.516 43.5614 107.192 44.7583 106.867 45.6809C106.842 45.7557 106.817 45.8055 106.792 45.8554C106.792 45.8803 106.767 45.9053 106.767 45.9302C106.718 46.0549 106.693 46.1546 106.643 46.2544C106.618 46.3042 106.618 46.3541 106.593 46.404C106.568 46.5037 106.518 46.6035 106.493 46.6783C106.493 46.7032 106.493 46.7032 106.468 46.7281C106.443 46.8029 106.418 46.8777 106.393 46.9525C106.368 47.0523 106.343 47.1271 106.318 47.2019C106.343 47.2268 106.343 47.2767 106.368 47.3016C106.643 47.4263 106.742 47.6757 106.643 48.4486C106.393 50.4684 106.468 51.6902 105.419 51.4159L104.495 53.0117C104.47 53.1863 104.445 53.286 104.42 53.3858C104.395 53.685 104.395 54.2834 104.395 57.5748C104.395 57.9738 104.52 58.3478 104.72 58.672C104.945 59.0211 105.269 59.3203 105.669 59.4948C105.669 59.4948 105.694 59.4948 105.694 59.5198C107.866 60.4424 109.464 61.0408 110.687 61.5146Z" fill="white" />
		<path d="M106.342 47.2268C106.416 46.7281 106.192 46.0798 106.017 45.6559C105.992 45.5811 105.942 45.5063 105.917 45.4315C105.118 43.8357 103.32 43.1624 101.623 43.0627C97.2782 42.8383 96.8537 43.6611 95.5055 42.4144C95.9299 43.0378 95.9549 44.0601 95.3057 45.3068C94.8563 46.1796 93.9824 46.5785 93.1834 46.8279C91.186 42.3396 92.1847 39.5219 94.1572 38.5245C93.508 37.1032 93.4581 36.5796 93.4581 36.5796C93.4581 36.5796 97.6527 37.2778 99.0759 37.0035C100.899 36.6295 103.72 37.0783 104.769 39.497C106.516 40.1702 107.19 41.2923 107.34 42.4642C107.64 44.3344 106.541 46.404 106.342 47.2268Z" fill="url(#paint1_linear)" />
		<path d="M104.743 58.7219V59.4948H94.6562V58.4725C94.7811 58.1982 94.831 57.9239 94.831 57.6247V53.6102C94.7561 53.5354 94.7062 53.4356 94.6562 53.3608V53.1115C94.881 53.4855 95.1556 53.8097 95.4802 54.1089L98.6262 56.3281C99.3502 56.9515 100.424 56.9515 101.173 56.353L104.119 53.7099C104.219 53.6351 104.319 53.5354 104.419 53.4356C104.394 53.7349 104.394 54.3333 104.394 57.6247C104.444 58.0237 104.544 58.3977 104.743 58.7219Z" fill="url(#paint2_linear)" />
		<g filter="url(#filter1_d)">
			<path d="M57.4296 85.5269H25.7951C23.6978 85.5269 22 83.8313 22 81.7368V37.552C22 35.4575 23.6978 33.7619 25.7951 33.7619H57.4296C59.5269 33.7619 61.2247 35.4575 61.2247 37.552V81.7617C61.2247 83.8563 59.5269 85.5269 57.4296 85.5269Z" fill="url(#paint3_linear)" />
		</g>
		<path d="M46.0206 72.9846H37.1819C36.2082 72.9846 35.4092 72.1867 35.4092 71.2142C35.4092 70.2418 36.2082 69.4438 37.1819 69.4438H46.0206C46.9943 69.4438 47.7933 70.2418 47.7933 71.2142C47.7933 72.1867 46.9943 72.9846 46.0206 72.9846Z" fill="#D5DDEA" />
		<path d="M50.089 76.8246H33.1357C32.6114 76.8246 32.2119 76.4007 32.2119 75.902C32.2119 75.3784 32.6364 74.9794 33.1357 74.9794H50.089C50.6133 74.9794 51.0128 75.4033 51.0128 75.902C51.0128 76.4007 50.5884 76.8246 50.089 76.8246Z" fill="#D5DDEA" />
		<path d="M53.1869 52.2138C53.1869 55.7047 51.6389 58.8216 49.217 60.9411C47.1696 62.7114 44.523 63.7836 41.6017 63.7836C38.6805 63.7836 36.0089 62.7114 33.9865 60.9411C31.5646 58.8216 30.0166 55.7047 30.0166 52.2138C30.0166 45.8305 35.2099 40.644 41.6017 40.644C47.9935 40.644 53.1869 45.8305 53.1869 52.2138Z" fill="#D9DEEA" />
		<path d="M49.2139 60.9394C47.1665 62.7098 44.5199 63.782 41.5986 63.782C38.6774 63.782 36.0058 62.7098 33.9834 60.9394C34.8323 60.6153 35.9309 60.1914 37.404 59.568C37.7536 59.4184 38.0032 59.1691 38.1531 58.8449C38.228 58.6704 38.2779 58.4709 38.2779 58.2465V55.4787C38.228 55.4288 38.178 55.354 38.1531 55.3041C37.9034 54.9551 37.7536 54.5312 37.7036 54.1073L37.4539 53.9826C36.7548 54.1571 36.8048 53.3842 36.605 51.9379C36.5301 51.3395 36.63 51.2148 36.8797 51.1151L37.1044 50.8159C35.7312 47.699 36.4303 45.754 37.7785 45.0808C37.3291 44.1083 37.3041 43.7343 37.3041 43.7343C37.3041 43.7343 40.2004 44.2081 41.1991 44.0335C42.4725 43.7842 44.395 44.0834 45.1191 45.754C46.3176 46.2278 46.792 47.0008 46.8918 47.7987C46.9917 48.5717 46.767 49.3696 46.5673 50.0179C46.5423 50.0678 46.5423 50.0927 46.5173 50.1426C46.5173 50.1675 46.4924 50.1675 46.4924 50.1925C46.4674 50.2673 46.4424 50.3421 46.4175 50.4169C46.3925 50.4418 46.3925 50.4917 46.3675 50.5166C46.3425 50.5914 46.3176 50.6413 46.2926 50.7161V50.741C46.2676 50.7909 46.2427 50.8408 46.2427 50.8907C46.2177 50.9655 46.1927 51.0153 46.1927 51.0652C46.2177 51.0901 46.2177 51.1151 46.2177 51.14C46.4175 51.2397 46.4674 51.3894 46.3925 51.913C46.2177 53.3093 46.2676 54.1571 45.5436 53.9577L44.9194 55.0797C44.8944 55.2044 44.8694 55.2543 44.8694 55.354C44.8445 55.5535 44.8445 55.9774 44.8445 58.2465C44.8445 58.5208 44.9194 58.7701 45.0692 58.9945C45.219 59.2439 45.4437 59.4434 45.7183 59.5431H45.7433C47.2913 60.1914 48.3899 60.6153 49.2139 60.9394Z" fill="white" />
		<path d="M46.2443 51.0668C46.2942 50.7177 46.1444 50.2938 46.0196 49.9696C45.9946 49.9198 45.9696 49.8699 45.9447 49.82C45.3954 48.7229 44.147 48.2491 42.9735 48.1993C39.9773 48.0497 39.6777 48.6232 38.7539 47.7504C39.0535 48.1993 39.0785 48.8725 38.6291 49.7452C38.3045 50.3437 37.7052 50.618 37.1809 50.7925C35.8077 47.6756 36.5068 45.7307 37.855 45.0575C37.4056 44.085 37.3807 43.711 37.3807 43.711C37.3807 43.711 40.2769 44.1847 41.2757 44.0102C42.549 43.7608 44.4716 44.0601 45.1956 45.7307C46.3941 46.2045 46.8685 46.9775 46.9684 47.7754C47.1182 49.072 46.3691 50.4933 46.2443 51.0668Z" fill="url(#paint4_linear)" />
		<path d="M45.1458 59.0211V59.5696H38.1797V58.8465C38.2546 58.672 38.3045 58.4725 38.3045 58.2481V55.4803C38.2546 55.4304 38.2047 55.3556 38.1797 55.3058V55.1562C38.3295 55.4055 38.5292 55.6549 38.7539 55.8294L40.9262 57.3504C41.4255 57.7743 42.1746 57.7993 42.6989 57.3754L44.7463 55.5551C44.8212 55.5052 44.8961 55.4304 44.946 55.3556C44.921 55.5551 44.921 55.979 44.921 58.2481C44.921 58.5224 44.9959 58.7967 45.1458 59.0211Z" fill="url(#paint5_linear)" />
		<g filter="url(#filter2_d)">
			<path d="M172.757 85.5269H141.122C139.025 85.5269 137.327 83.8313 137.327 81.7368V37.552C137.327 35.4575 139.025 33.7619 141.122 33.7619H172.757C174.854 33.7619 176.552 35.4575 176.552 37.552V81.7617C176.552 83.8563 174.854 85.5269 172.757 85.5269Z" fill="url(#paint6_linear)" />
		</g>
		<path d="M161.347 72.9846H152.508C151.534 72.9846 150.735 72.1867 150.735 71.2142C150.735 70.2418 151.534 69.4438 152.508 69.4438H161.347C162.32 69.4438 163.119 70.2418 163.119 71.2142C163.144 72.1867 162.345 72.9846 161.347 72.9846Z" fill="#D5DDEA" />
		<path d="M165.416 76.8246H148.462C147.938 76.8246 147.539 76.4007 147.539 75.902C147.539 75.3784 147.963 74.9794 148.462 74.9794H165.416C165.94 74.9794 166.339 75.4033 166.339 75.902C166.339 76.4007 165.94 76.8246 165.416 76.8246Z" fill="#D5DDEA" />
		<path d="M168.537 52.2138C168.537 55.7047 166.989 58.8216 164.567 60.9411C162.52 62.7114 159.873 63.7836 156.952 63.7836C154.031 63.7836 151.359 62.7114 149.337 60.9411C146.915 58.8216 145.367 55.7047 145.367 52.2138C145.367 45.8305 150.56 40.644 156.952 40.644C163.344 40.644 168.537 45.8305 168.537 52.2138Z" fill="#D9DEEA" />
		<path d="M164.566 60.9394C162.519 62.7098 159.872 63.782 156.951 63.782C154.03 63.782 151.358 62.7098 149.336 60.9394C150.185 60.6153 151.283 60.1914 152.757 59.568C153.106 59.4184 153.356 59.1691 153.506 58.8449C153.58 58.6704 153.63 58.4709 153.63 58.2465V55.4787C153.58 55.4288 153.531 55.354 153.506 55.3041C153.256 54.9551 153.106 54.5312 153.056 54.1073L152.806 53.9826C152.107 54.1571 152.157 53.3842 151.958 51.9379C151.883 51.3395 151.983 51.2148 152.232 51.1151L152.457 50.8159C151.084 47.699 151.783 45.754 153.131 45.0808C152.682 44.1083 152.657 43.7343 152.657 43.7343C152.657 43.7343 155.553 44.2081 156.552 44.0335C157.825 43.7842 159.748 44.0834 160.472 45.754C161.67 46.2278 162.145 47.0008 162.244 47.7987C162.344 48.5717 162.12 49.3696 161.92 50.0179C161.895 50.0678 161.895 50.0927 161.87 50.1426C161.87 50.1675 161.845 50.1675 161.845 50.1925C161.82 50.2673 161.795 50.3421 161.77 50.4169C161.745 50.4418 161.745 50.4917 161.72 50.5166C161.695 50.5914 161.67 50.6413 161.645 50.7161V50.741C161.62 50.7909 161.595 50.8408 161.595 50.8907C161.57 50.9655 161.545 51.0153 161.545 51.0652C161.57 51.0901 161.57 51.1151 161.57 51.14C161.77 51.2397 161.82 51.3894 161.745 51.913C161.57 53.3093 161.62 54.1571 160.896 53.9577L160.272 55.0797C160.247 55.2044 160.222 55.2543 160.222 55.354C160.197 55.5535 160.197 55.9774 160.197 58.2465C160.197 58.5208 160.272 58.7701 160.422 58.9945C160.572 59.2439 160.796 59.4434 161.071 59.5431H161.096C162.619 60.1914 163.717 60.6153 164.566 60.9394Z" fill="white" />
		<path d="M161.571 51.0668C161.621 50.7177 161.472 50.2938 161.347 49.9696C161.322 49.9198 161.297 49.8699 161.272 49.82C160.723 48.7229 159.474 48.2491 158.301 48.1993C155.304 48.0497 155.005 48.6232 154.081 47.7504C154.381 48.1993 154.406 48.8725 153.956 49.7452C153.632 50.3437 153.032 50.618 152.508 50.7925C151.135 47.6756 151.834 45.7307 153.182 45.0575C152.733 44.085 152.708 43.711 152.708 43.711C152.708 43.711 155.604 44.1847 156.603 44.0102C157.876 43.7608 159.799 44.0601 160.523 45.7307C161.721 46.2045 162.196 46.9775 162.296 47.7754C162.445 49.072 161.721 50.4933 161.571 51.0668Z" fill="url(#paint7_linear)" />
		<path d="M160.473 59.0211V59.5696H153.507V58.8465C153.582 58.672 153.632 58.4725 153.632 58.2481V55.4803C153.582 55.4304 153.532 55.3556 153.507 55.3058V55.1562C153.657 55.4055 153.857 55.6549 154.082 55.8294L156.254 57.3504C156.753 57.7743 157.502 57.7993 158.027 57.3754L160.074 55.5551C160.149 55.5052 160.224 55.4304 160.274 55.3556C160.249 55.5551 160.249 55.979 160.249 58.2481C160.249 58.5224 160.324 58.7967 160.473 59.0211Z" fill="url(#paint8_linear)" />
		<path d="M166.839 133.078L135.254 101.56L142.495 94.3289L174.055 125.872L166.839 133.078Z" fill="#858B9B" />
		<path d="M182.569 148.912L158.35 124.725C156.328 122.705 156.328 119.414 158.35 117.394C160.373 115.374 163.668 115.374 165.691 117.394L189.91 141.581C191.932 143.6 191.932 146.892 189.91 148.912C187.887 150.931 184.592 150.931 182.569 148.912Z" fill="#858B9B" />
		<path d="M180.846 150.458L149.686 119.339C148.962 118.616 148.962 117.444 149.686 116.696L157.65 108.741C158.374 108.018 159.548 108.018 160.297 108.741L191.457 139.86C192.181 140.583 192.181 141.755 191.457 142.503L183.492 150.458C182.743 151.181 181.57 151.181 180.846 150.458Z" fill="url(#paint9_linear)" />
		<path d="M99.5011 0C66.4435 0 39.6528 26.7552 39.6528 59.7691C39.6528 92.7581 66.4435 119.538 99.4761 119.538C132.534 119.538 159.324 92.7831 159.324 59.7691C159.324 26.7552 132.534 0 99.5011 0ZM99.5011 108.467C72.9601 108.467 51.4627 86.649 51.4627 59.7442C51.4627 32.8394 72.9601 11.0462 99.5011 11.0462C126.042 11.0462 147.539 32.8643 147.539 59.7691C147.539 86.674 126.017 108.467 99.5011 108.467Z" fill="url(#paint10_linear)" />
		<defs>
			<filter id="filter0_d" x="49.2612" y="11.1672" width="100.777" height="118.955" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
				<feFlood floodOpacity="0" result="BackgroundImageFix" />
				<feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" />
				<feOffset dy="11" />
				<feGaussianBlur stdDeviation="11" />
				<feColorMatrix type="matrix" values="0 0 0 0 0.397708 0 0 0 0 0.47749 0 0 0 0 0.575 0 0 0 0.27 0" />
				<feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
				<feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
			</filter>
			<filter id="filter1_d" x="0" y="22.7619" width="83.2247" height="95.765" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
				<feFlood floodOpacity="0" result="BackgroundImageFix" />
				<feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" />
				<feOffset dy="11" />
				<feGaussianBlur stdDeviation="11" />
				<feColorMatrix type="matrix" values="0 0 0 0 0.397708 0 0 0 0 0.47749 0 0 0 0 0.575 0 0 0 0.27 0" />
				<feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
				<feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
			</filter>
			<filter id="filter2_d" x="115.327" y="22.7619" width="83.2247" height="95.765" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
				<feFlood floodOpacity="0" result="BackgroundImageFix" />
				<feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" />
				<feOffset dy="11" />
				<feGaussianBlur stdDeviation="11" />
				<feColorMatrix type="matrix" values="0 0 0 0 0.397708 0 0 0 0 0.47749 0 0 0 0 0.575 0 0 0 0.27 0" />
				<feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
				<feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
			</filter>
			<linearGradient id="paint0_linear" x1="99.6313" y1="20.4334" x2="99.6313" y2="97.9298" gradientUnits="userSpaceOnUse">
				<stop stopColor="#FDFEFF" />
				<stop offset="0.9964" stopColor="#ECF0F5" />
			</linearGradient>
			<linearGradient id="paint1_linear" x1="92.0863" y1="41.9044" x2="107.394" y2="41.9044" gradientUnits="userSpaceOnUse">
				<stop stopColor="#B0BACC" />
				<stop offset="1" stopColor="#969EAE" />
			</linearGradient>
			<linearGradient id="paint2_linear" x1="99.7028" y1="59.5337" x2="99.7028" y2="56.0171" gradientUnits="userSpaceOnUse">
				<stop stopColor="white" />
				<stop offset="1" stopColor="#E2E5EC" />
			</linearGradient>
			<linearGradient id="paint3_linear" x1="41.5996" y1="32.5645" x2="41.5996" y2="86.085" gradientUnits="userSpaceOnUse">
				<stop stopColor="#FDFEFF" />
				<stop offset="0.9964" stopColor="#ECF0F5" />
			</linearGradient>
			<linearGradient id="paint4_linear" x1="36.4302" y1="47.3897" x2="46.99" y2="47.3897" gradientUnits="userSpaceOnUse">
				<stop stopColor="#B0BACC" />
				<stop offset="1" stopColor="#969EAE" />
			</linearGradient>
			<linearGradient id="paint5_linear" x1="41.6648" y1="59.5965" x2="41.6648" y2="57.1651" gradientUnits="userSpaceOnUse">
				<stop stopColor="white" />
				<stop offset="1" stopColor="#E2E5EC" />
			</linearGradient>
			<linearGradient id="paint6_linear" x1="156.927" y1="32.5645" x2="156.927" y2="86.085" gradientUnits="userSpaceOnUse">
				<stop stopColor="#FDFEFF" />
				<stop offset="0.9964" stopColor="#ECF0F5" />
			</linearGradient>
			<linearGradient id="paint7_linear" x1="151.757" y1="47.3897" x2="162.318" y2="47.3897" gradientUnits="userSpaceOnUse">
				<stop stopColor="#B0BACC" />
				<stop offset="1" stopColor="#969EAE" />
			</linearGradient>
			<linearGradient id="paint8_linear" x1="156.992" y1="59.5965" x2="156.992" y2="57.1651" gradientUnits="userSpaceOnUse">
				<stop stopColor="white" />
				<stop offset="1" stopColor="#E2E5EC" />
			</linearGradient>
			<linearGradient id="paint9_linear" x1="149.123" y1="129.604" x2="192.008" y2="129.604" gradientUnits="userSpaceOnUse">
				<stop stopColor="#B0BACC" />
				<stop offset="1" stopColor="#969EAE" />
			</linearGradient>
			<linearGradient id="paint10_linear" x1="39.598" y1="59.7821" x2="159.346" y2="59.7821" gradientUnits="userSpaceOnUse">
				<stop stopColor="#B0BACC" />
				<stop offset="1" stopColor="#969EAE" />
			</linearGradient>
		</defs>
	</svg>
)