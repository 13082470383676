import React from "react"
import { Button } from "antd"
import { useTranslation } from "react-i18next"
import styles from "./buttons.module.css"

export const CancelButton = ({ style, cancel }) => {
	const { t } = useTranslation()
	return (
		<Button
			type="primary"
			className={`${styles.buttons} ${styles.cancel_button}`}
			style={style}
			onClick={() => { cancel() }}
		>
			{t("cancel")}
		</Button>
	)
}