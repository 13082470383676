import React, { useState, useEffect } from "react"
import { useSelector } from "react-redux"
import CustomScroll from "react-scrollbars-custom"
import { NavLink } from "react-router-dom"
import { v4 as uuidv4 } from "uuid"
import { EmptyRightPanelConnectionsImage } from "shared/empty-screen-images"
import { useTranslation } from "react-i18next"
import { RightPanelCard } from "./components"
import { getUserConnections } from "./api"
import styles from "./right-panel.module.css"

export const RightPanel = () => {
	const { user } = useSelector(s => s)
	const { currentUser } = user
	const { t } = useTranslation()
	const [friendsOrFamilySelected, setFriendsOrFamilySelected] = useState("family")
	const [userConnections, setUserConnections] = useState([])

	useEffect(() => {
		const getData = async () => {
			const result = await getUserConnections()
			setUserConnections([...result.result.family, ...result.result.friends])
		}
		getData()
	}, [currentUser.family, currentUser.friends])

	const rightPanelContent = () => {
		if (friendsOrFamilySelected === "family") {
			if (currentUser.family.length === 0) {
				return (
					<div
						className={styles.content_empty}
					>
						<div><EmptyRightPanelConnectionsImage /></div>
						<div>
							{t("looks-like-you-haven't-added-any-relatives-yet-Start-your-search")}
							{" "}
							<NavLink to="/search-people">{t("now")}</NavLink>
						</div>
					</div>
				)
			}
			return (
				<>
					{
						currentUser.family.map(parent => {
							const user = userConnections.find(p => p._id === parent)
							if (!user) {
								return null
							}
							return (
								<div key={uuidv4()} className={styles.card_container}>
									<RightPanelCard user={user} />
								</div>
							)
						})
					}
				</>
			)
		}
		if (friendsOrFamilySelected === "friends") {
			if (currentUser.friends.length === 0) {
				return (
					<div
						className={styles.content_empty}
					>
						<div><EmptyRightPanelConnectionsImage /></div>
						<div>
							{t("looks-like-you-haven't-added-any-relatives-yet-Start-your-search")}

							{" "}
							<NavLink to="/search-people">{t("now")}</NavLink>
						</div>
					</div>
				)
			}
			return (
				<>
					{currentUser.friends.map(friend => {
						const user = userConnections.find(p => p._id === friend)
						if (!user) {
							return null
						}
						return (
							<div key={uuidv4()} className={styles.card_container}>
								<RightPanelCard user={user} />
							</div>
						)
					})}
				</>
			)
		}

		return null
	}

	return (
		<div className={styles.wrap}>
			<div className={styles.header}>
				<button
					type="button"
					className={friendsOrFamilySelected === "family" ? `${styles.header_button} ${styles.button_selected}` : styles.header_button}
					onClick={() => {
						setFriendsOrFamilySelected("family")
					}}
				>
					{t("FAMILY")}
				</button>
				<button
					type="button"
					className={friendsOrFamilySelected === "friends" ? `${styles.header_button} ${styles.button_selected}` : styles.header_button}
					onClick={() => {
						setFriendsOrFamilySelected("friends")
					}}
				>
					{t("FRIENDS")}
				</button>
			</div>
			<CustomScroll>
				<div className={styles.main}>
					{rightPanelContent()}
				</div>
			</CustomScroll>
		</div>
	)
}