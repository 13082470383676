import React from "react"

export const EmptyNotificationsImage = () => (
	<svg width="172" height="161" viewBox="0 0 172 161" fill="none">
		<path d="M90.9482 154C127.172 154 156.54 124.53 156.54 88.0487C156.54 51.5678 127.043 22.0974 90.9482 22.0974C54.724 22.0974 25.3564 51.5678 25.3564 88.0487C25.3564 124.53 54.724 154 90.9482 154Z" fill="#EAEEF9" />
		<path d="M160.616 130.601C163.545 130.601 165.92 128.218 165.92 125.279C165.92 122.339 163.545 119.956 160.616 119.956C157.686 119.956 155.312 122.339 155.312 125.279C155.312 128.218 157.686 130.601 160.616 130.601Z" fill="#EAEEF9" />
		<path d="M168.378 109.829C170.379 109.829 172.001 108.202 172.001 106.194C172.001 104.187 170.379 102.559 168.378 102.559C166.378 102.559 164.756 104.187 164.756 106.194C164.756 108.202 166.378 109.829 168.378 109.829Z" fill="#EAEEF9" />
		<path d="M28.85 44.687C30.8506 44.687 32.4724 43.0595 32.4724 41.0519C32.4724 39.0443 30.8506 37.4168 28.85 37.4168C26.8494 37.4168 25.2275 39.0443 25.2275 41.0519C25.2275 43.0595 26.8494 44.687 28.85 44.687Z" fill="#EAEEF9" />
		<path d="M6.72736 114.793C10.4428 114.793 13.4547 111.77 13.4547 108.042C13.4547 104.313 10.4428 101.291 6.72736 101.291C3.01194 101.291 0 104.313 0 108.042C0 111.77 3.01194 114.793 6.72736 114.793Z" fill="#EAEEF9" />
		<path d="M99.1782 111.96H84.6016C81.2241 111.96 78.3799 115.087 78.3799 118.8V126.812C78.3799 135.019 84.4238 141.663 91.8899 141.663C99.356 141.663 105.4 135.019 105.4 126.812V118.8C105.4 114.891 102.556 111.96 99.1782 111.96Z" fill="white" />
		<g filter="url(#filter0_d)">
			<path fillRule="evenodd" clipRule="evenodd" d="M83.3088 35.1887V33.823C83.3088 29.7214 86.5086 26.5114 90.5971 26.5114C94.6857 26.5114 97.8855 29.7214 97.8855 33.823V35.189C112.148 38.5 122.772 51.318 122.772 66.636V70.7377C122.772 105.334 148.725 127.982 148.725 127.982H32.29C32.29 127.982 58.4213 105.334 58.4213 70.7377V66.636C58.4213 51.3175 69.0456 38.4992 83.3088 35.1887Z" fill="url(#paint0_linear)" />
		</g>
		<path d="M77.0875 84.8258C72.6435 84.8258 68.9104 81.9725 67.4883 77.8708C67.1328 76.9792 67.6661 76.0875 68.5549 75.7309C69.4437 75.3742 70.3325 75.9092 70.6881 76.8008C71.5769 79.4758 74.2433 81.4375 77.0875 81.4375C79.9318 81.4375 82.5982 79.6541 83.487 76.8008C83.8426 75.9092 84.7314 75.3742 85.6202 75.7309C86.509 76.0875 87.0423 76.9792 86.6868 77.8708C85.2647 82.1508 81.5316 84.8258 77.0875 84.8258Z" fill="#ADB6C8" />
		<path d="M103.929 84.8258C99.4852 84.8258 95.7522 81.9725 94.3301 77.8708C93.9746 76.9792 94.5079 76.0875 95.3967 75.7309C96.2855 75.3742 97.1743 75.9092 97.5299 76.8008C98.4187 79.4758 101.085 81.4375 103.929 81.4375C106.774 81.4375 109.44 79.6541 110.329 76.8008C110.684 75.9092 111.573 75.3742 112.462 75.7309C113.351 76.0875 113.884 76.9792 113.529 77.8708C112.106 82.1508 108.373 84.8258 103.929 84.8258Z" fill="#ADB6C8" />
		<path d="M90.5964 106.661C92.8544 106.661 94.6849 104.824 94.6849 102.559C94.6849 100.294 92.8544 98.4575 90.5964 98.4575C88.3383 98.4575 86.5078 100.294 86.5078 102.559C86.5078 104.824 88.3383 106.661 90.5964 106.661Z" fill="#ADB6C8" />
		<path d="M112.506 6.03645V0H135.754V6.03645L119.891 25.2434H136.028V31.2798H111.686V25.2434L127.549 6.03645H112.506Z" fill="url(#paint1_linear)" />
		<path d="M131.65 51.3098V47.4684H146.693V51.3098L136.573 63.6571H146.966V67.4985H131.103V63.6571L141.222 51.3098H131.65Z" fill="url(#paint2_linear)" />
		<defs>
			<filter id="filter0_d" x="10.29" y="15.5114" width="160.435" height="145.471" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
				<feFlood floodOpacity="0" result="BackgroundImageFix" />
				<feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" />
				<feOffset dy="11" />
				<feGaussianBlur stdDeviation="11" />
				<feColorMatrix type="matrix" values="0 0 0 0 0.397708 0 0 0 0 0.47749 0 0 0 0 0.575 0 0 0 0.27 0" />
				<feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
				<feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
			</filter>
			<linearGradient id="paint0_linear" x1="90.4696" y1="24.1643" x2="90.4696" y2="129.076" gradientUnits="userSpaceOnUse">
				<stop stopColor="#FDFEFF" />
				<stop offset="0.9964" stopColor="#ECF0F5" />
			</linearGradient>
			<linearGradient id="paint1_linear" x1="123.859" y1="1.08662" x2="123.859" y2="18.6437" gradientUnits="userSpaceOnUse">
				<stop stopColor="#B0BACC" />
				<stop offset="1" stopColor="#969EAE" />
			</linearGradient>
			<linearGradient id="paint2_linear" x1="139.036" y1="48.1643" x2="139.036" y2="59.4069" gradientUnits="userSpaceOnUse">
				<stop stopColor="#B0BACC" />
				<stop offset="1" stopColor="#969EAE" />
			</linearGradient>
		</defs>
	</svg>
)