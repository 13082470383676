/* eslint-disable no-nested-ternary */
import React from "react"
import { useSelector } from "react-redux"
import { socket } from "app/socket"
import { likeVideo, unlikeVideo } from "shared/video-modal/api"
import {
	ShareIcon, LikeIcon, MessageIcon
} from "shared/icons"
import { useTranslation } from "react-i18next"
import "emoji-mart/css/emoji-mart.css"
import {
	likePost, unlikePost,
	sharePost, unSharePost
} from "shared/feed-components/api"
import {
	likePhoto, unlikePhoto
} from "shared/full-screen-image/api"
import { message } from "antd"
import styles from "./post-actions.module.css"

export const PostActions = ({
	post,
	setIsShowComments,
	isShowComments,
	setIsShowSharesList,
	setIsShowLikeList,
	postLikes,
	setPostsLikes,
	postShares,
	setPostShares,
	postComments
}) => {
	const { t } = useTranslation()
	const { user } = useSelector(s => s)
	const { currentUser } = user

	return (
		<div className={styles.post_main_content_actions}>
			<div style={{ display: "flex" }}>
				<button
					type="button"
					className={postLikes.includes(currentUser._id) ? `${styles.post_main_content_action_btn} ${styles.post_main_content_action_btn_like} ${styles.liked}` : `${styles.post_main_content_action_btn} ${styles.post_main_content_action_btn_like}`}
					onClick={async () => {
						if (postLikes.includes(currentUser._id)) {
							if (post.isUploadedPhoto) {
								const response = await unlikePhoto(
									post.albumId,
									post.images[0],
									currentUser._id,
									post.userId
								)
								setPostsLikes(response.data.likes)
							} else if (post.isUploadedVideo) {
								const response = await unlikeVideo(
									post.albumId, post.videos[0].path,
									currentUser._id, post.userId
								)
								setPostsLikes(response.data.likes)
							} else {
								const response = await unlikePost(post._id, currentUser._id)
								setPostsLikes(response.data)
							}
						} else if (post.isUploadedPhoto) {
							const response = await likePhoto(
								post.albumId, post.images[0],
								currentUser._id, post.userId
							)
							setPostsLikes(response.data.likes)
							socket.emit("LIKE_POST", { userId: post.userId })
						} else if (post.isUploadedVideo) {
							const response = await likeVideo(
								post.albumId, post.videos[0].path,
								currentUser._id, post.userId
							)
							setPostsLikes(response.data.likes)
							socket.emit("LIKE_POST", { userId: post.userId })
						} else {
							const response = await likePost(post._id, currentUser._id)
							setPostsLikes(response.data)
							socket.emit("LIKE_POST", { userId: post.userId })
						}
					}}
				>
					<LikeIcon />
				</button>
				<button
					type="button"
					className={styles.btns_text}
					onClick={() => { if (postLikes.length > 0) { setIsShowLikeList(true) } }}
				>
					{postLikes.length < 10000 ? postLikes.length
						: `${postLikes.length.toString().slice(0, -3)}K`}
					{" "}
					<span className={styles.post_footer_labels}>{t("likes")}</span>
				</button>
			</div>
			<button
				type="button"
				className={isShowComments && postComments.length > 0 ? `${styles.post_main_content_action_btn} ${styles.post_main_content_action_btn_comments} ${styles.comments_opened}` : `${styles.post_main_content_action_btn} ${styles.post_main_content_action_btn_comments}`}
				onClick={() => {
					setIsShowComments(!isShowComments)
				}}
				disabled={postComments.length === 0}
			>
				<MessageIcon />
				<div>
					{postComments.length}
					{" "}
					<span className={styles.post_footer_labels}>{t("comments")}</span>
				</div>
			</button>
			<div style={{ display: "flex" }}>
				<button
					type="button"
					className={`${styles.post_main_content_action_btn} ${styles.post_main_content_action_btn_share} ${postShares.includes(currentUser._id) ? styles.post_main_content_action_btn_share_disabled : ""}`}
					onClick={async () => {
						if (post.userId !== currentUser._id) {
							if (!postShares.includes(currentUser._id)) {
								const response = await sharePost(post._id)
								if (response) { message.info(`${t("you-are-shared-the-post")}`) }
								setPostShares(response.data)
								socket.emit("SHARE_POST", { userId: post.userId })
							}
							if (postShares.includes(currentUser._id)) {
								const response = await unSharePost(post._id)
								setPostShares(response.data)
							}
						}
					}}
				>
					<ShareIcon />
				</button>
				<button
					type="button"
					className={styles.btns_text}
					onClick={() => { if (postShares.length > 0) { setIsShowSharesList(true) } }}
				>
					{postShares.length}
					{" "}
					<span className={styles.post_footer_labels}>{t("shares")}</span>
				</button>
			</div>
		</div>
	)
}