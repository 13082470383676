import React from "react"

export const MaleIcon = () => (
	<svg width="77" height="90" viewBox="0 0 77 80" fill="none">
		<path d="M74.8898 71.7726C66.0887 82.3213 52.856 89.0001 38.0003 89.0001C23.7688 89.0001 11.0354 82.8207 2.23438 73.021C3.73242 71.835 5.60499 70.7739 7.91448 69.8376L9.6622 69.151C12.9704 67.8402 17.2773 66.2173 23.0822 63.783C24.393 63.2212 25.4541 62.2225 26.0159 60.9117C26.328 60.2251 26.5153 59.4137 26.5153 58.6022V47.8038C26.328 47.5541 26.1407 47.3669 26.0159 47.1172C25.0796 45.744 24.4554 44.1211 24.2682 42.3734L23.3319 41.9364C20.5231 42.623 20.7103 39.6269 20.0237 33.8844C19.7116 31.5125 20.0861 31.0131 21.1472 30.6386L22.0211 29.4527C16.6531 17.281 19.3371 9.66595 24.6427 6.98194C22.895 3.11198 22.7701 1.73877 22.7701 1.73877C22.7701 1.73877 34.0679 3.61133 38.0003 2.86231C42.9314 1.86361 50.5465 3.04956 53.3553 9.60353C58.0367 11.4761 59.9093 14.4098 60.2838 17.6555C60.9704 22.7739 58.0367 28.3916 57.4749 30.5762C57.5374 30.7011 57.5998 30.7635 57.5998 30.8259C58.3488 31.2004 58.5985 31.8246 58.3488 33.8844C57.6622 39.3148 57.8495 42.6855 55.0406 41.9364L52.5439 46.3057C52.419 46.8051 52.419 46.9923 52.3566 47.3669C52.2318 48.1783 52.2942 49.8012 52.2942 58.7271C52.2942 59.7882 52.6063 60.7869 53.1681 61.7232C53.7922 62.6594 54.6661 63.4709 55.7896 63.9078C55.7896 63.9078 55.8521 63.9078 55.9145 63.9702C62.3436 66.6542 66.9626 68.402 70.3332 69.7128C72.0809 70.2745 73.579 71.0236 74.8898 71.7726Z" fill="white" />
		<path d="M57.6001 30.5136C57.8498 29.1403 57.2256 27.455 56.7263 26.2691C56.6639 26.0818 56.539 25.8946 56.4766 25.6449C54.292 21.2756 49.4857 19.5279 44.8667 19.2782C33.0696 18.654 32.0085 20.9011 28.3258 17.5305C29.4493 19.2782 29.5741 21.9622 27.8264 25.3328C26.578 27.7047 24.2061 28.7658 22.1463 29.4524C16.7783 17.2808 19.4623 9.6657 24.7679 6.9817C23.0202 3.11174 22.8953 1.73853 22.8953 1.73853C22.8953 1.73853 34.1931 3.61109 38.0631 2.86206C42.9942 1.86336 50.6092 3.04932 53.4181 9.60329C58.0995 11.4758 59.9096 14.4095 60.3466 17.6553C61.0332 22.7112 58.1619 28.2665 57.6001 30.5136Z" fill="#CECECE" />
		<path d="M74.8898 71.7726C66.0887 82.3213 52.856 89.0001 38.0003 89.0001C23.7688 89.0001 11.0354 82.8207 2.23438 73.0209C3.73242 71.835 5.60499 70.7739 7.91448 69.8376L9.6622 69.151C12.9704 67.8402 17.2773 66.2173 23.0822 63.783C24.393 63.2212 25.4541 62.2225 26.0159 60.9117C26.328 60.2251 26.5153 59.4137 26.5153 58.6022V47.8038C26.328 47.5541 26.1407 47.3669 26.0159 47.1172C25.0796 45.744 24.4554 44.1211 24.2682 42.3734L23.3319 41.9364C20.5231 42.623 20.7103 39.6269 20.0237 33.8844C19.7116 31.5125 20.0861 31.0132 21.1472 30.6386L22.0211 29.4527C16.6531 17.281 19.3371 9.66595 24.6427 6.98194C22.895 3.11198 22.7701 1.73877 22.7701 1.73877C22.7701 1.73877 34.0679 3.61133 38.0003 2.86231C42.9314 1.86361 50.5465 3.04956 53.3553 9.60353C58.0367 11.4761 59.9093 14.4098 60.2838 17.6555C60.9704 22.7739 58.0367 28.3916 57.4749 30.5762C57.5374 30.7011 57.5998 30.7635 57.5998 30.8259C58.3488 31.2004 58.5985 31.8246 58.3488 33.8844C57.6622 39.3148 57.8495 42.6855 55.0406 41.9364L52.5439 46.3057C52.419 46.8051 52.419 46.9923 52.3566 47.3669C52.2318 48.1783 52.2942 49.8012 52.2942 58.7271C52.2942 59.7882 52.6063 60.7869 53.1681 61.7232C53.7922 62.6594 54.6661 63.4709 55.7896 63.9078C55.7896 63.9078 55.8521 63.9078 55.9145 63.9702C62.3436 66.6542 66.9626 68.402 70.3332 69.7128C72.0809 70.2745 73.579 71.0236 74.8898 71.7726Z" stroke="#CECECE" strokeWidth="2" strokeMiterlimit="10" />
		<path d="M25.7129 46.6457C26.2805 47.6547 27.0648 48.4509 28.0107 49.2077L36.6504 55.1987C38.6684 56.9014 41.5694 56.9645 43.6505 55.3248L51.7226 48.0725C51.9748 47.8203 52.2773 47.2528 52.6131 46.2009" stroke="#CECECE" strokeWidth="2" strokeMiterlimit="10" />
	</svg>
)

export const FemaleIcon = () => (
	<svg width="76" height="90" viewBox="0 0 76 77" fill="none">
		<path d="M75 65C65.6992 76.1478 53.6643 83.2715 37.965 83.2715C22.9253 83.2715 11.3008 76.8563 2 66.5C3.58312 65.2467 3.72993 64.0102 6.17058 63.0207L8.01755 62.2951C11.5136 60.9099 17.3184 59.3927 23.453 56.8201C24.8382 56.2265 26.8034 56.6516 27.3971 55.2664C27.7269 54.5408 28.4539 52.4251 28.4539 51.5676L29.5106 43.1133C29.3128 42.8494 25.4319 39.2739 25.3 39.01C24.3105 37.5588 23.6509 35.8438 23.453 33.9968L22.3608 32.4555C19.5126 31.0315 19.6931 31.0944 18.9675 25.0258C18.6377 22.5192 19.0335 21.9914 20.1548 21.5957L21.0783 20.3424C21.0783 6.12565 28.6593 8.96208 34.2662 6.12565C42.57 2.44616 37.965 6.12565 37.965 6.12565C37.965 6.12565 34.8661 3.21869 39.0218 2.42713C44.2329 1.37171 45.5645 6.06861 48.5329 12.9948C53.4801 14.9737 52.3642 5.86591 52.76 9.29601C53.4856 14.705 58.6775 15.1084 58.5456 21.5297C58.6775 17.7503 58.6775 21.7276 58.6775 21.7936C59.469 22.1893 59.7329 22.849 59.469 25.0258C58.7434 30.7646 58.9413 34.3266 55.973 33.535L53.3344 38.1525C53.2025 38.6802 53.2025 38.8781 53.1366 39.2739C53.0046 40.1314 47.0443 41.9492 49.5896 51.5676C49.5896 52.689 51.1748 55.7948 52.76 56.3232C53.4196 57.3126 55.2714 56.9182 56.4588 57.3799C56.4588 57.3799 56.3928 57.8424 56.4588 57.9083C63.253 60.7448 68.572 61.5036 72.134 62.8888C73.981 63.4825 73.6148 64.2084 75 65Z" fill="white" />
		<path d="M75.5 64.5C74.1148 63.7084 72.5 63.0311 72.134 62.8888C68.572 61.5036 59.4636 59.6566 52.6693 56.8202C52.6034 56.7542 52.5374 56.7542 52.5374 56.7542C51.3501 56.2925 50.4266 55.435 49.7669 54.4455C49.1733 53.4561 48.8434 52.4006 48.8434 51.2793C48.8434 41.8465 48.8189 44.3919 48.9509 43.5344C49.0168 43.1386 52.6281 39.4139 52.76 38.8862L55.973 33.5351C59.6292 30.9603 60.1576 28.8467 60.1576 25.148C60.1576 23.5628 59.4691 23.0344 58.6775 21.7936C58.6775 21.7276 58.6115 21.6617 58.5456 21.5298C58.6775 17.2221 56 10.5 51 5.5L43.2489 4.01219L34.2662 2.427C34.2662 2.427 20.5279 4.54058 21.0783 20.3424L20.1548 21.5957C18.9675 23.0344 18.2733 23.9682 18.4144 25.148C19.14 31.2166 18.9675 29.9035 22.6415 33.0739L23.1699 35.1875C23.3678 37.0345 24.3105 37.5589 25.3 39.0101C25.4319 39.2739 28.8002 42.6422 28.9981 42.906V51.1473C28.9981 52.0049 28.8002 52.8624 28.4704 53.588C27.8767 54.9732 26.7553 56.0286 25.3701 56.6223C19.2355 59.1949 11.5136 60.9099 8.01756 62.2952L6.17059 63.0207C3.72994 64.0102 3.08312 64.7467 1.5 66" stroke="#CECECE" strokeWidth="2" strokeMiterlimit="10" />
		<path d="M24.9805 38.512C25.5803 39.5783 26.4091 40.4197 27.4088 41.2194L33.2094 46.2839C37.0759 49.5442 42.7721 49.5442 45.8908 46.2839L52.2658 39.5758C52.9368 38.8863 51.7911 40.0505 52.76 38.8863" stroke="#CECECE" strokeWidth="2" strokeMiterlimit="10" />
		<path d="M39.2514 65.0912C37.6171 67.543 34.1579 67.9342 31.2603 68.0848C34.0897 64.8567 31.5326 55.3427 20.8432 57.41L20.7469 57.4182C5.67812 55.9082 6.01918 40.5587 19.9868 34.8396L22.3294 33.8691C22.5302 33.9483 24.0962 34.2917 24.2088 34.4749C25.6173 37.3418 25.9088 39.4417 27.7173 41.311C22.258 46.6904 30.5751 47.5232 34.8419 50.9174C38.4805 52.6308 42.0873 60.8026 39.2514 65.0912Z" fill="#CECECE" />
		<path d="M59 18.282C55.4191 19.5458 51.5883 20.0513 47.7575 19.7143C41.7616 19.2088 35.9321 16.7656 31.4352 12.7216C29.9362 17.1026 26.1887 20.3883 21.9415 22.326C21.3586 22.5788 20.7757 22.8315 20.1927 23C20.1927 22.9158 20.1927 22.8315 20.1927 22.8315C19.7763 19.63 20.0262 16.4286 21.0255 13.3114C22.0248 10.1941 23.8569 7.41392 26.1887 5.22344C26.272 5.13919 26.3552 5.05495 26.5218 4.9707C26.9382 4.6337 27.3546 4.2967 27.771 3.95971C29.9362 2.35897 32.4345 1.17949 35.0161 0.505496C36.5151 0.1685 37.9308 0 39.4298 0C46.8415 0 53.4204 4.21245 56.7515 10.5311C58.0007 12.9744 58.5836 15.0806 58.8335 17.0183C59 17.4396 59 17.8608 59 18.282Z" fill="#CECECE" />
	</svg>
)

export const UnknownGenderIcon = () => (
	<svg width="77" height="88" viewBox="0 0 77 74" fill="none">
		<path d="M38.0003 83C52.856 83 66.0887 76.3212 74.8898 65.7724C73.579 65.0234 72.0809 64.2744 70.3332 63.7126C66.9626 62.4018 62.3436 60.6541 55.9145 57.9701C55.8521 57.9077 55.7896 57.9077 55.7896 57.9077C54.6661 57.4707 53.7922 56.6593 53.1681 55.723C52.6063 54.7867 52.2942 53.788 52.2942 52.7269C52.2942 43.801 52.2318 42.1782 52.3566 41.3667C52.419 40.9922 52.419 40.805 52.5439 40.3056L55.0406 35.9363C57.8495 36.6853 57.6622 33.3147 58.3488 27.8843C58.5985 25.8245 58.3488 25.2003 57.5998 24.8258C57.5998 24.8258 59.237 17.3182 57.5998 12.9999C55.5823 7.67873 52.2629 5.16563 47.0003 2.99991C41.4027 0.696292 36.999 0.469194 31.5003 2.99991C26.6122 5.2496 23.7376 7.90007 22.0211 12.9999C20.719 16.8687 22.0211 23.4525 22.0211 23.4525L21.1472 24.6385C20.0861 25.013 19.7116 25.5124 20.0237 27.8843C20.7103 33.6268 20.5231 36.6229 23.3319 35.9363L24.2682 36.3732C24.4554 38.1209 25.0796 39.7438 26.0159 41.117C26.1407 41.3667 26.328 41.554 26.5153 41.8036V52.6021C26.5153 53.4135 26.328 54.225 26.0159 54.9116C25.4541 56.2224 24.393 57.2211 23.0822 57.7828C17.2773 60.2172 12.9704 61.8401 9.6622 63.1509L7.91448 63.8375C5.60499 64.7737 3.73242 65.8349 2.23438 67.0208C11.0354 76.8206 23.7688 83 38.0003 83Z" fill="white" stroke="#CECECE" strokeWidth="2" strokeMiterlimit="10" />
		<path d="M25.7129 40.646C26.2805 41.655 27.0648 42.4511 28.0107 43.2079L34.9996 48.0002C39.4996 50.0002 39.9996 50.0002 44.9996 48.0002L51.7226 42.0728C51.9748 41.8205 52.2773 41.253 52.6131 40.2012" stroke="#CECECE" strokeWidth="2" strokeMiterlimit="10" />
	</svg>
)