import React from "react"
import { EmptyTreeImage } from "shared/empty-screen-images"
import { useTranslation } from "react-i18next"
import { UserFamilyTree } from "./profile-diagram"
import { ProfileTreeSidePanel } from "./profile-tree-side-panel"
import { ProfileTreeZoomPan } from "./profile-tree-zoom"
import styles from "./profile-tree.module.css"

export const ProfileTree = ({ profile }) => {
	const { t } = useTranslation()

	const treeContent = () => {
		if (!profile.userTree || profile.userTree.length === 0) {
			return (
				<div className={styles.content_e}>
					<div>
						<EmptyTreeImage />
					</div>
					<div>
						{t("looks-like")}
						{" "}
						{profile.firstName}
						{" "}
						{profile.lastName}
						{" "}
						{t("haven't-added-family-tree-yet")}
					</div>
				</div>
			)
		}
		if (profile.userTree && profile.userTree.length > 0) {
			return (
				<div className={styles.content}>
					<ProfileTreeZoomPan />
					<UserFamilyTree />
					<ProfileTreeSidePanel />
				</div>
			)
		}
	}

	return (

		<div className={styles.wrap}>
			<div className={styles.container}>
				{treeContent()}
			</div>
		</div>

	)
}