import React from "react"
import {
	Modal, Card, Avatar
} from "antd"
import { socket } from "app/socket"
import { UserOutlined } from "@ant-design/icons"
import { getImageUri } from "shared/utils"
import { useTranslation } from "react-i18next"

import styles from "./show-chat-members-modal.module.css"

const { Meta } = Card

export const ShowChatMembersModal = ({
	isShowParticipiantsModal, setIsShowParticipiantsModal, chatMembers, chat, currentUser
}) => {
	const { t } = useTranslation()

	return (
		<Modal
			footer={null}
			title={(
				<div>
					{t("members-list")}
				</div>
			)}
			visible={isShowParticipiantsModal}
			className={styles.modal}
			keyboard
			onCancel={() => {
				setIsShowParticipiantsModal(false)
			}}
		>
			<div>
				<div className={styles.list_friends_chat}>
					{chatMembers.map(user => (
						<div
							key={user._id}
							role="button"
							tabIndex={0}
						>
							<Card
								className={styles.list_friends_card}
								size="small"
								style={{
									margin: "4px 0",
									padding: "4px",
									width: "100%",
									borderRadius: "6px",
									background: "#FFFFFF"
								}}
							>
								<Meta
									avatar={(
										<Avatar
											style={{
												background: "rgba(136, 133, 133, 0.5)",
												display: "flex",
												alignItems: "center",
												justifyContent: "center"
											}}
											src={!user.avatar ? (
												<UserOutlined style={{
													fontSize: "28px", width: "100%", height: "100%", display: "flex", alignItems: "center", justifyContent: "center"
												}}
												/>
											) : getImageUri(user.avatar)}
										/>
									)}
									title={`${user.firstName} ${user.lastName}`}
									description={!user.location.name ? "" : user.location.name}
								/>
								{currentUser._id === chat.chatAdmin
									&& (
										<button
											type="button"
											className={styles.set_as_admin_btn}
											onClick={async () => {
												socket.emit("SET_NEW_ADMIN",
													{
														requesterId: currentUser._id,
														roomId: chat._id,
														userId: user._id
													})
											}}
										>
											{t("set-as-admin")}
										</button>
									)}
							</Card>
						</div>
					))}
				</div>
			</div>
		</Modal>
	)
}