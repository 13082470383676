import React from "react"
import { useTranslation } from "react-i18next"
import styles from "./mobile-modal-delete-note.module.css"

export const MobileDeleteNotification = ({ person }) => {
	const { t } = useTranslation()
	return (
		<div className={styles.delete_person_section}>
			{!person.isUser && (
				<div className={styles.delete_note}>
					{t("to-remove-this-person-first-remove-all-childrents-in-his-branch")}
				</div>
			)}
		</div>
	)
}