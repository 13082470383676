import React from "react"

export const TreeIcon = () => (
	<svg width="24" height="24" viewBox="0 0 24 24" fill="none">
		<path d="M12 13L10 11" stroke="#8F92A1" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
		<path d="M12 12L14 10" stroke="#8F92A1" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
		<path d="M12 22V8" stroke="#8F92A1" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
		<path d="M9.46149 15.9946C8.9511 15.967 8.45346 15.8365 8.00357 15.6123C7.55368 15.3881 7.16247 15.0756 6.85746 14.6968C6.55245 14.3179 6.34105 13.882 6.23812 13.4197C6.1352 12.9573 6.14325 12.4798 6.26171 12.0206C5.83836 11.6958 5.50406 11.2832 5.28602 10.8165C5.06797 10.3498 4.97234 9.84224 5.0069 9.335C5.04146 8.82777 5.20523 8.33522 5.4849 7.89745C5.76457 7.45968 6.15223 7.08904 6.61634 6.8157C6.20965 6.14869 6.07268 5.37005 6.22982 4.6185C6.38696 3.86695 6.82802 3.19132 7.47439 2.71198C8.12077 2.23265 8.93048 1.98074 9.75926 2.00115C10.588 2.02156 11.3821 2.31295 11.9998 2.82342C12.6178 2.31376 13.4115 2.02305 14.2398 2.00304C15.0682 1.98303 15.8773 2.23501 16.5233 2.71414C17.1692 3.19326 17.61 3.86844 17.7673 4.61951C17.9246 5.37059 17.7881 6.14882 17.3822 6.8157C17.8466 7.0888 18.2345 7.45931 18.5145 7.89704C18.7944 8.33477 18.9584 8.82736 18.9931 9.33468C19.0277 9.842 18.9321 10.3497 18.714 10.8165C18.4959 11.2832 18.1615 11.6958 17.738 12.0206C17.8604 12.4954 17.8648 12.9895 17.7508 13.466C17.6368 13.9425 17.4073 14.389 17.0797 14.7719C16.752 15.1548 16.3347 15.4643 15.8589 15.677C15.3832 15.8897 14.8615 16.0001 14.3329 16H14.3341H9.66797L9.46149 15.9946Z" stroke="#8F92A1" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
	</svg>
)

export const MapIcon = () => (
	<svg width="24" height="24" viewBox="0 0 24 24" fill="none">
		<path fillRule="evenodd" clipRule="evenodd" d="M12 21C16.9706 21 21 16.9706 21 12C21 7.02944 16.9706 3 12 3C7.02944 3 3 7.02944 3 12C3 16.9706 7.02944 21 12 21ZM14.8055 18.4151C17.1228 17.4003 18.7847 15.1667 18.9806 12.525C18.1577 12.9738 17.12 13.3418 15.9371 13.598C15.7882 15.4676 15.3827 17.1371 14.8055 18.4151ZM9.1945 5.58487C7.24725 6.43766 5.76275 8.15106 5.22208 10.244C5.4537 10.4638 5.84813 10.7341 6.44832 11.0008C6.89715 11.2003 7.42053 11.3798 8.00537 11.5297C8.05853 9.20582 8.50349 7.11489 9.1945 5.58487ZM10.1006 13.9108C10.2573 15.3675 10.5852 16.6202 10.9992 17.5517C11.2932 18.2133 11.5916 18.6248 11.8218 18.8439C11.9037 18.9219 11.9629 18.9634 12 18.9848C12.0371 18.9634 12.0963 18.9219 12.1782 18.8439C12.4084 18.6248 12.7068 18.2133 13.0008 17.5517C13.4148 16.6202 13.7427 15.3675 13.8994 13.9108C13.2871 13.9692 12.6516 14 12 14C11.3484 14 10.7129 13.9692 10.1006 13.9108ZM8.06286 13.598C8.21176 15.4676 8.61729 17.1371 9.1945 18.4151C6.8772 17.4003 5.21525 15.1666 5.01939 12.525C5.84231 12.9738 6.88001 13.3418 8.06286 13.598ZM13.9997 11.8896C13.369 11.9609 12.6993 12 12 12C11.3008 12 10.631 11.9609 10.0003 11.8896C10.0135 9.66408 10.4229 7.74504 10.9992 6.44832C11.2932 5.78673 11.5916 5.37516 11.8218 5.15605C11.9037 5.07812 11.9629 5.03659 12 5.01516C12.0371 5.03659 12.0963 5.07812 12.1782 5.15605C12.4084 5.37516 12.7068 5.78673 13.0008 6.44832C13.5771 7.74504 13.9865 9.66408 13.9997 11.8896ZM15.9946 11.5297C15.9415 9.20582 15.4965 7.11489 14.8055 5.58487C16.7528 6.43766 18.2373 8.15107 18.7779 10.244C18.5463 10.4638 18.1519 10.7341 17.5517 11.0008C17.1029 11.2003 16.5795 11.3798 15.9946 11.5297Z" fill="#8F92A1" />
	</svg>
)

export const FriendsIcon = () => (
	<svg width="24" height="24" viewBox="0 0 24 24" fill="none">
		<path fillRule="evenodd" clipRule="evenodd" d="M11 7C11 9.20914 9.2091 11 7 11C4.79086 11 3 9.20914 3 7C3 4.79086 4.79086 3 7 3C9.2091 3 11 4.79086 11 7ZM9 7C9 8.10457 8.1046 9 7 9C5.8954 9 5 8.10457 5 7C5 5.89543 5.8954 5 7 5C8.1046 5 9 5.89543 9 7ZM17 11C19.2091 11 21 9.20914 21 7C21 4.79086 19.2091 3 17 3C14.7909 3 13 4.79086 13 7C13 9.20914 14.7909 11 17 11ZM17 9C18.1046 9 19 8.10457 19 7C19 5.89543 18.1046 5 17 5C15.8954 5 15 5.89543 15 7C15 8.10457 15.8954 9 17 9ZM20 14C20.5523 14 21 14.4477 21 15V21H23V15C23 13.3431 21.6569 12 20 12H15L16 14H20ZM10 14C10.5523 14 11 14.4477 11 15V21H13V15C13 13.3431 11.6569 12 10 12H4C2.34315 12 1 13.3431 1 15V21H3V15C3 14.4477 3.44772 14 4 14H10Z" fill="#8F92A1" />
	</svg>
)

export const SearchIcon = () => (
	<svg width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
		<circle cx="11" cy="11" r="8" />
		<line x1="21" y1="21" x2="16.65" y2="16.65" />
	</svg>
)

export const NotificationsIcon = () => (
	<svg width="24" height="24" viewBox="0 0 24 24" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
		<path d="M18 8A6 6 0 0 0 6 8c0 7-3 9-3 9h18s-3-2-3-9" />
		<path d="M13.73 21a2 2 0 0 1-3.46 0" />
	</svg>
)