import React from "react"
import Upload from "antd/lib/upload"
import { useSelector } from "react-redux"
import ImgCrop from "antd-img-crop"
import { getAvatar } from "./get-avatar"
import { EditIcon, DeleteIcon } from "./personal-info-icons"
import styles from "../personal-info.module.css"

export const UserPreviewAvatar = (props) => {
	const {
		personalInfoAvatar,
		setPersonalInfoAvatar,
		avatarChange
	} = props

	const { user } = useSelector(s => s)
	const { currentUser } = user

	return (
		<div className={styles.user_preview}>
			<div className={styles.user_preview_avatar}>
				{getAvatar(avatarChange, personalInfoAvatar)}
				{personalInfoAvatar ? (
					<div className={styles.user_preview_avatar_btns}>
						<ImgCrop rotate>
							<Upload
								showUploadList={false}
								onChange={avatarChange}
							>
								<button type="button" className={styles.edit_avatar_btn}>
									<EditIcon />
								</button>
							</Upload>
						</ImgCrop>
						<button
							type="button"
							className={styles.delete_avatar_btn}
							onClick={() => setPersonalInfoAvatar("")}
						>
							<DeleteIcon />
						</button>
					</div>
				) : null}
			</div>
			<div className={styles.user_preview_info}>
				<div className={styles.user_preview_name}>
					{currentUser.firstName}
					{" "}
					{currentUser.lastName}
					{" "}
					{currentUser.maidenName && currentUser.maidenName !== "" ? `(${currentUser.maidenName})` : ""}
				</div>
				<div className={styles.user_preview_place}>
					{currentUser.location?.name}
				</div>
			</div>
		</div>
	)
}
