import {
  baseUri
} from "shared/constants"

export const getImageUri = img => {
  if (!img) {
    return null
  }
  if (!img.includes(".png")) {
    if (img.includes("data:image")) { return img }
    return `data:image/jpeg;base64,${img}`
  }
  return `${baseUri}${img}`
}