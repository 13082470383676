import notification from "antd/lib/notification"

export const CallEndedInfo = (t, personName) => {
    notification.open({
        message: `${t("VIDEO-CALL-CANCELED")}`,
        description:
            `${t("call-has-been-canceled-by")} ${personName}.`,
        duration: 10
    })
}

export const CallCanceledInfo = (t, personName) => {
    notification.open({
        message: `${t("VIDEO-CALL-CANCELED")}`,
        description:
            `${t("person")} ${personName} ${t("has-declined-connection")}.`,
        duration: 10
    })
}

export const CallDisconnectedInfo = (t) => {
    notification.open({
        message: `${t("User-initiated abort")}`,
        description:
            `${t("Call has been disconnected")}.`,
        duration: 10
    })
}

export const MediaDeviceError = (t) => {
    notification.error({
        message: `${t("Media device error")}`,
        description:
            `${t("Connect the microphone")}.`,
        duration: 10
    })
}

export const IsPublishSupported = () => {
    const isSupported = navigator.mediaDevices
        && navigator.mediaDevices.getUserMedia
        && navigator.mediaDevices.enumerateDevices
        && window.RTCPeerConnection
        && window.RTCIceCandidate
        && window.RTCSessionDescription

    if (!isSupported) {
        notification.error({
            message: "Media resource not supported!"
        })
    }
}

export const UnsupportedDebugInfo = () => {
    console.log(`Unsupported debug - navigator.mediaDevices: ${Boolean(navigator.mediaDevices)}`)
    console.log(`Unsupported debug - navigator.getUserMedia: ${(Boolean(navigator.mediaDevices) && Boolean(navigator.mediaDevices.getUserMedia))}`)
    console.log(`Unsupported debug - navigator.enumerateDevices: ${(Boolean(navigator.mediaDevices) && Boolean(navigator.mediaDevices.enumerateDevices))}`)
    console.log(`Unsupported debug - navigator.RTCPeerConnection: ${Boolean(window.RTCPeerConnection)}`)
    console.log(`Unsupported debug - navigator.RTCIceCandidate: ${Boolean(window.RTCIceCandidate)}`)
    console.log(`Unsupported debug - navigator.RTCSessionDescription: ${Boolean(window.RTCSessionDescription)}`)
    console.log("====================================================")
}