export const updatePersonalInfo = async ({
  firstName,
  lastName,
  avatar,
  birthDate,
  familyStatus,
  birthPlace,
  gender,
  maidenName,
  location
}) => {
  const token = localStorage.token ? localStorage.getItem("token") : sessionStorage.getItem("token")
  try {
    const userInfo = {
      firstName,
      lastName,
      avatar,
      birthDate,
      familyStatus: familyStatus || null,
      birthPlace,
      gender,
      maidenName: maidenName || "",
      location
    }
    const res = await fetch("/api/update/update-personal-info", {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Authorization: token
      },
      body: JSON.stringify(userInfo)
    })
    const json = await res.json()
    return json
  } catch (error) {
    const message = "Something went wrong"
    return message
  }
}