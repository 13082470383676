export const resendCodeToEmail = async (
  email
) => {
  try {
    const regInfo = {
      email
    }
    const res = await fetch("/api/auth/send-code-to-email", {
      method: "POST",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify(regInfo)
    })
    const json = await res.json()
    return json
  } catch (error) {
    const message = "Something went wrong"
    return message
  }
}