import React from "react"
import { Modal } from "antd"
import { ExclamationCircleOutlined } from "@ant-design/icons"
import { useDispatch } from "react-redux"
import { userLogOut } from "app/redux/user"
import { useTranslation } from "react-i18next"
import { deleteAccount } from "../api"
import styles from "../settings.module.css"

const { confirm } = Modal

export const SettingsFooter = () => {
	const dispatch = useDispatch()
	const { t } = useTranslation()

	const deleteAccountFunction = async () => {
		await deleteAccount()
	}

	const showConfirm = () => {
		confirm({
			title: `${t("are-you-sure-you-want-to-remove-your-account?-After-that,-all-your-data-including-feed-will-be-permanently-deleted-from-the-app.")}`,
			icon: <ExclamationCircleOutlined />,
			okText: `${t("yes")}`,
			okType: "danger",
			cancelText: `${t("no")}`,
			onOk() {
				deleteAccountFunction()
				localStorage.clear()
				sessionStorage.clear()
				dispatch(userLogOut())
				window.location.reload()
			}
		})
	}

	return (
		<div className={styles.footer}>
			<div>
				{t("you-can-delete-your-account-by-clicking-this")}
				{" "}
				{" "}
				<button
					type="button"
					onClick={showConfirm}
				>
					{t("button")}
				</button>
				.
			</div>
		</div>
	)
}