import React, { useState } from "react"
import { v4 as uuidv4 } from "uuid"
import { message } from "antd"
import ReactPlayer from "react-player"
import { baseUri } from "shared/constants"
import {
	DeleteIcon
} from "shared/icons"
import { useTranslation } from "react-i18next"
import "emoji-mart/css/emoji-mart.css"
import { VideoModal } from "shared/video-modal"
import styles from "../post-main-content.module.css"

export const PostVideoContent = ({
	isEditMode,
	post,
	images,
	videos,
	setVideos,
	mediaContentStyle,
	profile,
	videosToView
}) => {
	const { t } = useTranslation()

	const [hoverCurrentVideo, setHoverCurrentVideo] = useState(null)
	const [isShowVedeoPlayer, setIsShowVedeoPlayer] = useState(false)
	const [selectVideo, setSelectVideo] = useState(null)

	const getTime = (dur) => {
		const hours = Math.floor(dur / 3600)
		const minutes = Math.floor((dur % 3600) / 60)
		const seconds = Math.floor(dur - hours * 3600 - minutes * 60)
		return `${minutes}:${seconds}`
	}

	return (
		<div className={mediaContentStyle(post, "video")}>
			{videosToView.map(video => (
				<div
					className={styles.post_video_container}
					key={video.path}
				>
					{!profile && isEditMode && (
						<>
							<button
								key={uuidv4()}
								type="button"
								onClick={() => {
									if (post.videos.includes(video) && videos.includes(video)) {
										setVideos(videos.filter(v => v.path !== video.path))
									}
									if (post.videos.includes(video) && !videos.includes(video)) {
										setVideos([...videos, video])
									}
									if (!post.videos.includes(video)
										&& images.length && videos.length < 5) {
										setVideos([...videos, video])
									}
									if (!post.videos.includes(video)
										&& images.length && videos.length >= 5) {
										message.error(`${t("maximum-number-of-files-reached")}`)
									}
								}}
							>
								<DeleteIcon />
							</button>
							<div className={styles.post_main_content_image_delete_mark}>
								{!videos.includes(video) && (`${t("marked-for-deletion")}`)}
							</div>
						</>
					)}
					<div
						role="button"
						tabIndex={0}
						onClick={() => {
							if (!isEditMode) {
								setIsShowVedeoPlayer(true)
								setSelectVideo(video.path)
							}
						}}
						className={styles.video_palyer}
						onMouseEnter={() => setHoverCurrentVideo(video.path)}
						onMouseLeave={() => setHoverCurrentVideo(null)}
					>
						<div className={!videos.includes(video) ? styles.post_image_opacity : ""} />
						<ReactPlayer
							playing={(hoverCurrentVideo && !isShowVedeoPlayer)
								&& hoverCurrentVideo === video.path}
							width="100%"
							height="100%"
							url={`${baseUri}/${video.path}`}
						/>
						<div className={styles.duration_container}>
							<span className={styles.duration_text}>{getTime(video.duration)}</span>
						</div>
					</div>
					<VideoModal
						selectVideo={selectVideo}
						video={video}
						isShowVedeoPlayer={isShowVedeoPlayer}
						setIsShowVedeoPlayer={setIsShowVedeoPlayer}
					/>
				</div>
			))}
		</div>
	)
}