import React, { useState, useEffect } from "react"
import { store } from "app/redux/store"
import { useSelector, useDispatch } from "react-redux"
import { updateUser } from "app/redux/user"
import { useTranslation } from "react-i18next"
import { SettingElementDropdown } from "./components"
import { changePrivacySettings } from "../api"
import { dropdownElementsData } from "../data"
import styles from "../settings.module.css"

export const SettingsPrivacy = () => {
	const { user } = useSelector(s => s)
	const { currentUser } = user
	const { privacySettings } = currentUser
	const dispatch = useDispatch()
	const { t } = useTranslation()
	const [friendListSetting, setFriendListSetting] = useState(privacySettings.friendList)
	const [familyTreeSetting, setFamilyTreeSetting] = useState(privacySettings.familyTree)
	const [aboutInfoSetting, setAboutInfoSetting] = useState(privacySettings.aboutInfo)
	const [locationSetting, setLocationSetting] = useState(privacySettings.location)
	const [feedSetting, setFeedSetting] = useState(privacySettings.feed)
	const [photoSettings, setPhotoSetting] = useState(privacySettings.photos)
	const [videoSettings, setVideoSetting] = useState(privacySettings.videos)

	useEffect(() => {
		const getData = async () => {
			const { user } = store.getState()
			const response = await changePrivacySettings(
				friendListSetting,
				familyTreeSetting,
				aboutInfoSetting,
				locationSetting,
				feedSetting,
				photoSettings,
				videoSettings
			)
			dispatch(updateUser({
				...user.currentUser,
				privacySettings: response.settings
			}))
		}
		getData()
	}, [
		aboutInfoSetting,
		dispatch,
		familyTreeSetting,
		friendListSetting,
		locationSetting,
		feedSetting,
		photoSettings,
		videoSettings
	])

	const returnStateConstant = (listName) => {
		switch (listName) {
			case "friends-and-relatives-list":
				return [friendListSetting, setFriendListSetting, null]

			case "feed":
				return [feedSetting, setFeedSetting, null]

			case "family-tree":
				return [familyTreeSetting, setFamilyTreeSetting, null]

			case "about-information":
				return [aboutInfoSetting, setAboutInfoSetting, null]

			case "location":
				return [locationSetting, setLocationSetting, privacySettings]

			case "photos":
				return [photoSettings, setPhotoSetting, null]

			case "videos":
				return [videoSettings, setVideoSetting, null]

			default:
				break
		}
	}

	return (
		<div className={styles.section_content}>
			{dropdownElementsData.map(el => {
				const elementValues = returnStateConstant(el.labelText)
				return (
					<SettingElementDropdown
						labelText={t(el.labelText)}
						labelDescription={t(el.labelDescription)}
						value={elementValues[0]}
						setValue={elementValues[1]}
						privacySettings={elementValues[2]}
						key={el.labelText}
					/>
				)
			})}
		</div>
	)
}