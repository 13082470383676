import React, { useState, useEffect } from "react"
import { LeftCircleOutlined } from "@ant-design/icons"
import ReactPlayer from "react-player"
import { baseUri } from "shared/constants"
import { useSelector } from "react-redux"
import { VideoModal } from "shared/video-modal"
import { AppSpinner } from "shared/app-spinner/app-spinner"
import { getProfileVideos } from "../api"
import styles from "./profile-videos.module.css"

export const ProfileVideos = ({ albumId, currentProfileId, setCurrentId }) => {
	const [videos, setVideos] = useState([])
	const [albumName, setAlbumName] = useState(null)
	const [isLoading, setIsLoading] = useState(false)
	const [hoverCurrentVideo, setHoverCurrentVideo] = useState(null)
	const [isShowVedeoPlayer, setIsShowVedeoPlayer] = useState(false)
	const [selectVideo, setSelectVideo] = useState(null)
	const [duration, setDuration] = useState("")

	const hours = Math.floor(duration / 3600)
	const minutes = Math.floor((duration % 3600) / 60)
	const seconds = Math.floor(duration - hours * 3600 - minutes * 60)

	const { user } = useSelector(s => s)
	const { currentUser } = user
	useEffect(() => {
		const get = async () => {
			setIsLoading(true)
			const res = await getProfileVideos(albumId, currentProfileId)
			setAlbumName(res.data.name)
			setVideos(res.data.videos)
			setIsLoading(false)
		}
		get()
	}, [albumId, currentProfileId])
	return (
		<>
			<div
				className={styles.header}
			>
				<div
					style={{ paddingLeft: "24px" }}
				>
					<LeftCircleOutlined
						style={{ cursor: "pointer" }}
						onClick={() => setCurrentId(null)}
					/>
					<span style={{ paddingLeft: "24px" }}>{albumName}</span>
				</div>
			</div>
			<div className={styles.main_content_container}>
				{!isLoading ? (
					<>
						{
							videos.map(video => (
								<div key={video.path} className={styles.video_container}>
									<div
										role="button"
										tabIndex={0}
										onClick={() => {
											setIsShowVedeoPlayer(true)
											setSelectVideo(video.path)
										}}
										className={styles.video_content}
									>
										<div
											className={styles.video_palyer}
											onMouseEnter={() => setHoverCurrentVideo(video.path)}
											onMouseLeave={() => setHoverCurrentVideo(null)}
										>
											<ReactPlayer
												playing={(hoverCurrentVideo && !isShowVedeoPlayer)
													&& hoverCurrentVideo === video.path}
												width="100%"
												height="100%"
												url={`${baseUri}/${video.path}`}
												onDuration={(duration) => setDuration(duration)}
											/>
											<div className={styles.duration_container}>
												<span className={styles.duration_text}>{`${minutes}:${seconds}`}</span>
											</div>
										</div>
										<h3>{video.name}</h3>
									</div>
									<VideoModal
										selectVideo={selectVideo}
										video={video}
										setIsShowVedeoPlayer={setIsShowVedeoPlayer}
										isShowVedeoPlayer={isShowVedeoPlayer}
										isVideoWithComments
										albumId={albumId}
										ownerId={currentProfileId}
										userId={currentUser._id}
									/>
								</div>
							))
						}
					</>
				) : (
					<div
						style={{
							zIndex: "9999", position: "fixed", top: "0", left: "0", right: "0", bottom: "0", backgroundColor: "#F2F2F2", width: "100vw", height: "100vh", display: "flex", alignItems: "center", justifyContent: "center"
						}}
					>
						<AppSpinner
							style={{
								width: "100%", height: "100%"
							}}
						/>
					</div>
				)}

			</div>
		</>
	)
}