export const getVideoAlbumComments = async (
  ownerId, albumId
) => {
  const token = localStorage.token ? localStorage.getItem("token") : sessionStorage.getItem("token")
  try {
    const res = await fetch(`/api/video-gallery/comment-album?ownerId=${ownerId}&albumId=${albumId}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: token
      }
    })
    const json = await res.json()
    return json
  } catch (error) {
    console.error("Error:", error)
  }
}