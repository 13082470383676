import React, { useState } from "react"
import { SearchPeopleInput, SearchPeopleResult } from "./components"
import styles from "./search-people.module.css"

export const SearchPeoplePage = () => {
	const [searchPeopleResult, setSearchPeopleResult] = useState([])
	const [searchPeopleStatus, setSearchPeopleStatus] = useState("not-started")

	return (
		<div className={styles.wrap}>
			<SearchPeopleInput
				setSearchPeopleResult={setSearchPeopleResult}
				setSearchPeopleStatus={setSearchPeopleStatus}
			/>
			<SearchPeopleResult
				result={searchPeopleResult}
				searchPeopleStatus={searchPeopleStatus}
			/>
		</div>
	)
}