import React from "react"
import { useTranslation } from "react-i18next"
import { EditIcon, DeleteIcon } from "shared/icons"
import { deletePost } from "shared/feed-components/api"
import styles from "./popover-content.module.css"

export const PopoverContent = ({
	post, setIsEditMode, setInputValue, allPosts, setAllPosts
}) => {
	const { t } = useTranslation()
	return (
		<div className={styles.popover_content}>
			<button
				type="button"
				className={styles.popover_btn}
				onClick={async () => {
					setIsEditMode(true)
					setInputValue(post.text)
				}}
			>
				<EditIcon />
				<div className={styles.popover_btn_text}>{t("edit-post")}</div>
			</button>
			<button
				type="button"
				className={styles.popover_btn}
				onClick={async () => {
					await deletePost(post._id)
					setAllPosts(allPosts.filter(p => p._id !== post._id))
				}}
			>
				<DeleteIcon />
				<div className={styles.popover_btn_text}>
					{" "}
					{t("delete-post")}
				</div>
			</button>
		</div>
	)
}