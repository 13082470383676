export const cancelCrop = async (
  token
) => {
  try {
    const res = await fetch("/api/user-tree/cancel-crop", {
      method: "POST",
      headers: {
        Authorization: token
      }
    })
    const json = await res.json()
    return json
  } catch (error) {
    const message = "Something went wrong"
    return message
  }
}