export const SET_TREE_DIRECTION = "SET_TREE_DIRECTION"

export const setTreeDirection = direction => ({
  type: SET_TREE_DIRECTION, direction
})

export const treeDirection = (state = ("TopToBottom"), action) => {
  switch (action.type) {
    case SET_TREE_DIRECTION:
      return action.direction
    default:
      return state
  }
}