import React from "react"
import { v4 as uuidv4 } from "uuid"
import { message } from "antd"
import { getImageUri } from "shared/utils"
import {
	DeleteIcon
} from "shared/icons"
import { useTranslation } from "react-i18next"
import "emoji-mart/css/emoji-mart.css"
import styles from "../post-main-content.module.css"

export const PostImageContent = ({
	isEditMode,
	post,
	images,
	setSelectedPhoto,
	setIsActive,
	setAllPhotos,
	setOldImages,
	oldImages,
	setImageFiles,
	imageFiles,
	mediaContentStyle,
	profile
}) => {
	const { t } = useTranslation()

	return (
		<div className={mediaContentStyle(post, "image")}>
			{images.map(image => (
				<div
					key={uuidv4()}
					className={styles.image_container}
					role="button"
					tabIndex={0}
					onClick={() => {
						if (!isEditMode) {
							setIsActive(true)
							setSelectedPhoto(getImageUri(image))
							setAllPhotos(post.images)
						}
					}}
				>
					{!profile && isEditMode && (
						<>
							<button
								key={uuidv4()}
								type="button"
								onClick={(e) => {
									e.preventDefault()
									if (images.includes(image) && oldImages.includes(image)) {
										setOldImages(oldImages.filter(i => i !== image))
									}
									if (images.includes(image) && !oldImages.includes(image)) {
										setOldImages([...oldImages, image])
									}
									if (!images.includes(image) && images.length < 5) {
										setImageFiles([...imageFiles, image])
									}
									if (!images.includes(image) && images.length >= 5) {
										message.error(`${t("maximum-number-of-photos-reached")}`)
									}
								}}
							>
								<DeleteIcon />
							</button>
							<div className={styles.post_main_content_image_delete_mark}>
								{!oldImages.includes(image) && (`${t("marked-for-deletion")}`)}
							</div>
						</>
					)}
					<div className={!oldImages.includes(image) ? styles.post_image_opacity : ""} />
					<img
						className={styles.image}
						key={uuidv4()}
						src={getImageUri(image)}
						onError={({ currentTarget }) => {
							currentTarget.onerror = null
							currentTarget.className = null
							currentTarget.src = getImageUri("./img.png")
						}}
						alt="loading error"
					/>
				</div>
			))}
		</div>
	)
}