import React, { useState, useEffect } from "react"
import { Empty } from "antd"
import { EmptyPostImage } from "shared/empty-screen-images"
import { useTranslation } from "react-i18next"
import { FeedInputForm } from "shared/feed-components/feed-input-form"
import { FeedPost } from "shared/feed-components/feed-post"
import styles from "./user-personal-feed.module.css"

export const UserPersonalFeed = ({
	posts
}) => {
	const { t } = useTranslation()
	const [allPosts, setAllPosts] = useState(posts)
	useEffect(() => {
		setAllPosts(posts)
	}, [posts])
	return (
		<div className={styles.wrap}>
			<FeedInputForm
				feedType="personal"
				setAllPosts={setAllPosts}
				topPosition="55%"
				leftPosition="auto"
				rightPosition="52px"
				maxHeightValue="30vh"
			/>
			<div className={styles.posts_wrap}>
				{allPosts.length === 0
					? (
						<Empty
							image={<EmptyPostImage />}
							description={<span>{t("there-are-no-published-posts-yet")}</span>}
						/>
					)
					: (
						<>
							{allPosts.map(post => (
								<FeedPost
									id={post._id}
									personalFeedType
									post={post}
									key={post._id}
									profile={null}
									allPosts={allPosts}
									setAllPosts={setAllPosts}
								/>
							))}
						</>
					)}
			</div>
		</div>
	)
}