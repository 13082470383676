export const deleteNotification = async (
  id
) => {
  try {
    const token = localStorage.token ? localStorage.getItem("token") : sessionStorage.getItem("token")
    const notificationInfo = {
      id
    }
    const res = await fetch("/api/delete-notification", {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Authorization: token
      },
      body: JSON.stringify(notificationInfo)
    })
    const json = await res.json()
    return json
  } catch (error) {
    const message = "Something went wrong"
    return message
  }
}