export const sendReport = async (
  subject, contactEmail, subjectDescription
) => {
  const token = localStorage.token ? localStorage.getItem("token") : sessionStorage.getItem("token")
  try {
    const reportInfo = {
      subject, contactEmail, subjectDescription
    }
    const res = await fetch("/api/reports/send-report", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: token
      },
      body: JSON.stringify(reportInfo)
    })
    const json = await res.json()
    return json
  } catch (error) {
    const message = "Something went wrong"
    return message
  }
}