/* eslint-disable jsx-a11y/control-has-associated-label */
import React, { useState, useEffect } from "react"
import { Empty } from "antd"
import { useTranslation } from "react-i18next"
import { EmptyPostImage } from "shared/empty-screen-images/post-empty"
import CustomScroll from "react-scrollbars-custom"
import { AlbumComment } from "./album-comment"
import { AlbumCommentsFooter } from "./footer"
import { getAlbumComments, getVideoAlbumComments } from "./api"
import styles from "./album-comments.module.css"

export const AlbumComments = ({
	isAlbumCommentsActive, setIsAlbumCommentsActive, ownerId, album,
	setSelectedAlbum, userId, setIsVideoAlbum, isVideoAlbum
}) => {
	const { t } = useTranslation()
	const [albumComments, setAlbumComments] = useState([])
	useEffect(() => {
		if (isAlbumCommentsActive && album) {
			const getData = async () => {
				const data = isVideoAlbum
					? await getVideoAlbumComments(ownerId, album.id)
					: await getAlbumComments(ownerId, album.id)
				if (data.status === "successful") {
					setAlbumComments(data.data.comments.reverse())
				}
			}
			getData()
		}
	}, [album, isAlbumCommentsActive, isVideoAlbum, ownerId, setIsVideoAlbum])

	return (
		<div
			className={isAlbumCommentsActive ? styles.active_screen : styles.disabled}
		>
			<div
				className={isAlbumCommentsActive
					? styles.active_close_screen
					: styles.disabled_close_screen}
				role="button"
				tabIndex={0}
				onClick={() => {
					setIsAlbumCommentsActive(false)
					setSelectedAlbum(null)
				}}
			/>
			<div className={styles.content_wrapper}>
				<div className={styles.header}>{album ? album.name : "..."}</div>
				{isAlbumCommentsActive && (
					<div className={styles.main}>
						{!albumComments || albumComments.length === 0
							? (
								<Empty
									image={<EmptyPostImage />}
									description={<span>{t("there-are-no-comments-here-yet")}</span>}
								/>
							)
							: (
								<>
									<CustomScroll>
										{albumComments.map(comment => (
											<AlbumComment
												key={comment.commentId}
												setIsVideoAlbum={setIsVideoAlbum}
												isVideoAlbum={isVideoAlbum}
												comment={comment}
												userId={userId}
												ownerId={ownerId}
												albumId={album.id}
												setAlbumComments={setAlbumComments}
											/>
										))}
									</CustomScroll>
								</>
							)}
					</div>
				)}
				<div className={styles.footer}>
					<AlbumCommentsFooter
						setAlbumComments={setAlbumComments}
						ownerId={ownerId}
						albumId={album ? album.id : null}
						userId={userId}
						setIsVideoAlbum={setIsVideoAlbum}
						isVideoAlbum={isVideoAlbum}
						isAlbumCommentsActive={isAlbumCommentsActive}
					/>
				</div>
			</div>

		</div>
	)
}