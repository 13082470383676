import React from "react"
import moment from "moment"
import { useTranslation } from "react-i18next"
import styles from "./mobile-profile-tree-info-main.module.css"

export const MobileProfileTreeInfoMain = ({ person }) => {
	const { t } = useTranslation()

	return (
		<div className={styles.mobile_modal_main_wrap}>
			{person.name && (<span className={styles.side_panel_labels}>{t("name")}</span>)}
			{person.name && (
				<div
					className={styles.modal_main_inputs}
				>
					{person.name}
				</div>
			)}
			{person.from && (<span className={styles.side_panel_labels}>{t("from")}</span>)}
			{person.from && (
				<div
					className={styles.modal_main_inputs}
				>
					{person.from}
				</div>
			)}
			{person.livesIn && (<span className={styles.side_panel_labels}>{t("lives-in")}</span>)}
			{person.livesIn && (
				<div
					className={styles.modal_main_inputs}
				>
					{person.livesIn}
				</div>
			)}
			{person.birthDate && (<span className={styles.side_panel_labels}>{t("born in")}</span>)}
			{person.birthDate && (
				<div
					className={styles.modal_main_inputs}
				>
					{moment(person.birthDate, "DD/MM/YYYY").format("LL")}
				</div>
			)}
			{person.deathDate && (<span className={styles.side_panel_labels}>{t("death-date")}</span>)}
			{person.deathDate && (
				<div
					className={styles.modal_main_inputs}
				>
					{moment(person.deathDate, "DD/MM/YYYY").format("LL")}
				</div>
			)}
			{person.description && (<span className={styles.side_panel_labels}>{t("description")}</span>)}
			{person.description && (
				<div
					className={styles.modal_main_inputs}
				>
					{person.description}
				</div>
			)}
		</div>
	)
}