import React from "react"
import {
	PersonalInfoGeneral
} from "./components"
import styles from "./personal-info.module.css"

export const PersonalInfo = () => (
	<div className={styles.wrap}>
		<PersonalInfoGeneral />
	</div>
)