import React from "react"
import "./modal-video-call.css"
import { Modal } from "antd"
import { VideoContainer, NavBtnVideo, ModalFooter } from "./components"

export const ModalVideoCall = (props) => {
	const {
		personName,
		isShowModalVideoChat,
		receivingCall,
		name,
		answerCall,
		callAccepted,
		leaveCall,
		callEnded,
		selectedConnections,
		callBtnDisabled,
		callUser,
		currentUser,
		myVideo,
		userVideo,
		stream,
		loader,
		videoDisabled,
		videoTrecksEnabled,
		videoCameraUser,
		CameraBtnDisabled
	} = props

	return (
		<Modal
			className="modal"
			title={(
				<div>
					{personName}
				</div>
			)}
			visible={isShowModalVideoChat}
			footer={(
				<ModalFooter
					receivingCall={receivingCall}
					name={name}
					answerCall={answerCall}
					callAccepted={callAccepted}
					callEnded={callEnded}
					leaveCall={leaveCall}
					selectedConnections={selectedConnections}
					callBtnDisabled={callBtnDisabled}
					callUser={callUser}
					stream={stream}
					currentUser={currentUser}
				/>
			)}
		>
			<div className="video-container">
				<VideoContainer
					callAccepted={callAccepted}
					callEnded={callEnded}
					myVideo={myVideo}
					userVideo={userVideo}
					stream={stream}
					loader={loader}
					selectedConnections={selectedConnections}
					videoDisabled={videoDisabled}
					videoTrecksEnabled={videoTrecksEnabled}
					personName={personName}
					videoCameraUser={videoCameraUser}
				/>
				<NavBtnVideo
					CameraBtnDisabled={CameraBtnDisabled}
					videoTrecksEnabled={videoTrecksEnabled}
					videoDisabled={videoDisabled}
				/>
			</div>
		</Modal>
	)
}
