export const getUserTree = async () => {
  try {
    const token = localStorage.token ? localStorage.getItem("token") : sessionStorage.getItem("token")

    const res = await fetch("/api/family-tree/get-user-tree", {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: token
      }
    })
    const json = await res.json()
    if (json.status === "successful") {
      return json.result
    }
    return []
  } catch (error) {
    console.error("Error:", error)
  }
}