import React from "react"
import { store } from "app/redux/store"
import { Button, message } from "antd"
import { useTranslation } from "react-i18next"
import { useDispatch } from "react-redux"
import {
	selectPerson, removeChildCard, hideParents
} from "modules/family-tree/utils"
import { setSelectedPersonId } from "modules/family-tree/ducks"
import { saveUserTree } from "modules/family-tree/api"
import styles from "../side-panel.module.css"

export const SaveChangesButton = () => {
	const dispatch = useDispatch()
	const { t } = useTranslation()
	const token = localStorage.token ? localStorage.getItem("token") : sessionStorage.getItem("token")

	const saveChanges = async () => {
		dispatch(setSelectedPersonId(null))
		hideParents()
		removeChildCard()
		selectPerson(null)
		const { people } = store.getState().familyTree
		const result = await saveUserTree(people, token)
		if (result.status === "error") {
			message.error(t("something-went-wrong"))
		}
	}

	return (
		<div className={styles.side_panel_avatar}>
			<div className={styles.side_panel_avatar_btns}>
				<Button
					className={`${styles.side_panel_save_btn} ${styles.side_panel_avatar_btn}`}
					onClick={() => {
						saveChanges()
					}}
				>
					{t("save-changes")}
				</Button>
			</div>
		</div>
	)
}