export const commentVideo = async (
  albumId, videoPath, comment, ownerId, mentionedUser
) => {
  const token = localStorage.token ? localStorage.getItem("token") : sessionStorage.getItem("token")
  const commentInfo = {
    albumId, videoPath, comment, ownerId, mentionedUser
  }
  try {
    const res = await fetch("/api/video-gallery/comment-video", {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Authorization: token
      },
      body: JSON.stringify(commentInfo)
    })
    const json = await res.json()
    return json
  } catch (error) {
    console.error("Error:", error)
  }
}