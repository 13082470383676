import React from "react"
import CustomScroll from "react-scrollbars-custom"
import { NavLink } from "react-router-dom"
import { AppLogoIcon } from "shared/logo-icon"

import "antd/dist/antd.css"
import styles from "./terms-and-conditions.module.css"

export const TermsAndConditions = () => (
	<div className={styles.wrap}>
		<div className={styles.container}>
			<NavLink to="/registration" className={styles.logo}><AppLogoIcon /></NavLink>
			<div className={styles.container_header}>Terms of use</div>
			<CustomScroll>
				<div className={styles.container_content}>
					<div className={styles.content_text}>
						<p>
							Using the LifeTwig Products covered by these Terms,
							you agree that we can show your name, avatar to people
							discovering relatives. We use your personal data, such as
							information about your activity and interests, to show you
							ads that are more relevant to you.

						</p>
						<p>
							We collect and use your personal data in order
							to provide the services described above to you.
							You have controls over the types of information is shared.

						</p>
						<p>
							You agree not to engage in the conduct described below
							(or to facilitate or support others in doing so):

						</p>
						<p>You may not use our Products to do or share anything:</p>
						<p>That is unlawful, misleading, discriminatory or fraudulent.</p>
						<p>
							That infringes or violates someone else&apos;s rights,
							including their intellectual property rights.

						</p>
						<p>
							You may not upload viruses or malicious code or do anything that could disable,
							overburden, or impair the proper working or appearance of our Products.

						</p>
						<p>
							You may not access or collect data from our Products using automated means
							(without our prior permission) or attempt
							to access data you do not have permission to access.

						</p>
						<p>
							We can remove or restrict access to content
							that is in violation of these provisions.

						</p>
						<p>
							If we remove content that you have shared
							in violation of our Standards, we’ll determine
							if it is related to harming our community of users;
							compromising or interfering with the integrity or operation of any of our services,
							systems or Products; where we are restricted due to technical limitations;
							or where we are prohibited from doing so for legal reasons.

						</p>
						<p>
							We also can remove or restrict access to your content,
							services or information if we determine that doing so is reasonably necessary to avoid
							or mitigate adverse legal or regulatory impacts to LifeTwig.

						</p>
						<p>The permissions you give us:</p>
						<p>We need certain permissions from you to provide our services:</p>
						<p>
							Permission to use content you create and share:
							Some content that you share or upload, such
							as photos or videos, may be protected by intellectual property laws.

						</p>
						<p>
							You own the intellectual property rights.
							Nothing in these Terms takes away the rights
							you have to your own content. You are free
							to share your content with anyone else, wherever you want.

						</p>
						<p>
							However, we need you to give us some legal
							permissions to use this content on our platform.

						</p>
						<p>
							Specifically, when you share, post, or upload
							content that is covered by intellectual property
							rights on or in connection with LifeTwig Products
							you grant us a non-exclusive, transferable, sub-licensable,
							royalty-free, and worldwide license to host, use, distribute,
							modify, run, copy, publicly perform or display, translate,
							and create derivative works of your content.

						</p>
						<p>
							This means, for example, that if you share a photo on Lifetwig,
							you give us permission to store, copy, and share it with others
							(again, consistent with your settings) such as service providers that support
							our service or other Lifetwig Products you use.
							This license will end when your content
							is deleted from our systems.
						</p>
						<p>You can delete content individually or all at once by deleting your account.</p>
						<p>
							When you delete content, it’s no longer visible to other users,
							however it may continue to exist elsewhere on our systems where:

						</p>
						<p>
							- your content has been used by others in accordance with this
							license and they have not deleted it (in which case this license
							will continue to apply until that content is deleted)

						</p>
						<p>- where immediate deletion would restrict our ability to:</p>
						<p>
							- investigate or identify illegal activity or violations of
							our terms and policies (for example, to identify or
							investigate misuse of our Products or systems);

						</p>
						<p>- comply with a legal obligation, such as the preservation of evidence; or</p>
						<p>
							- comply with a request of a judicial or administrative authority,
							law enforcement or a government agency;

						</p>
						<p>
							You give us permission to use your name and profile picture
							and information about actions you have taken on LifeTwig

						</p>
						<p>
							If you use content covered by intellectual property rights
							that we have and make available in our Products
							( for example, images, designs, videos, or sounds we provide
							that you add to content you create or share on LifeTwig),
							we retain all rights to that content.  You can only use our
							copyrights or trademarks (or any similar marks) as expressly
							permitted with our prior written permission. You must obtain
							our written permission (or permission under an open source license)
							to modify, create derivative works of, decompile, or otherwise
							attempt to extract source code from us.

						</p>
						<p>
							We want Lifetwog to be a place where people feel
							welcome and safe to express themselves and share
							their thoughts and ideas.

						</p>
						<p>
							If we determine that you have clearly, seriously
							or repeatedly breached our Terms or Policies, including
							in particular our Community Standards, we may suspend or permanently
							disable access to your account. We may also suspend or disable your
							account if you repeatedly infringe other people’s intellectual property
							rights or where we are required to do so for legal reasons.

						</p>
					</div>
					<div className={styles.content_back_buttons}>
						<NavLink to="/registration" className={styles.back_link}>
							Go back
						</NavLink>
					</div>
				</div>
			</CustomScroll>
		</div>
		<div className={styles.auth_image} />
	</div>

)