export const uploadResizedImage = async (
  token, positionId, image
) => {
  const userInfo = {
    positionId,
    image
  }
  try {
    const res = await fetch("/api/user-tree/resized-image-upload", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: token
      },
      body: JSON.stringify(userInfo)
    })
    const json = await res.json()
    return json
  } catch (error) {
    const message = "Something went wrong"
    return message
  }
}
