import React, { useEffect, useRef, useState } from "react"
import {
	Space,
	DatePicker,
	Input,
	Checkbox,
	AutoComplete,
	Button
} from "antd"
import moment from "moment"
import { updatePerson } from "modules/family-tree/ducks"
import { useSelector } from "react-redux"
import { inviteAsFamily } from "modules/search-people/api"
import { updateUser } from "app/redux/user"
import { getPersonById } from "modules/family-tree/utils"
import { store } from "app/redux/store"
import { useTranslation } from "react-i18next"
import { findUsers, findUsersGlobal } from "modules/family-tree/api"
import { UserAvatar } from "shared/avatar"
import { UserProfileModal } from "./user-profile-modal"
import { SearchModal } from "./modal"
import styles from "../side-panel.module.css"

const { TextArea } = Input
const { Option } = AutoComplete

const disabledDate = (current) => current > moment().endOf("day")

export const MainSection = ({ id, person, isPersonAlive }) => {
	const nameInput = useRef()
	const { t } = useTranslation()
	const { user } = useSelector(s => s)
	const { people } = useSelector(s => s.familyTree)
	const { selectedPersonId } = useSelector(s => s.familyTree)
	const selectedPerson = getPersonById(selectedPersonId)
	const { currentUser } = user

	const token = localStorage.token ? localStorage.getItem("token") : sessionStorage.getItem("token")

	const translateName = () => {
		const splitedName = person.name.split(" ")
		const translatedSplitedName = splitedName.map((name, index) => {
			if (name === "Father") {
				if (index === 0) {
					name = `${t("father-one")}`
				} else {
					name = `${t("father-next")}`
				}
			}
			if (name === "Mother") {
				if (index === 0) {
					name = `${t("mother-one")}`
				} else {
					name = `${t("mother-next")}`
				}
			}
			if (name === "Child") {
				if (index === 0) {
					name = `${t("child-one")}`
				} else {
					name = `${t("child-next")}`
				}
			}
			if (name === "of") {
				name = `${t("of")}`
			}
			return name
		})
		const translatedName = translatedSplitedName.join(" ")
		return translatedName
	}

	const [isAlive, setIsAlive] = useState(true)
	const [personName, setPersonName] = useState(translateName())
	const [personFrom, setPersonFrom] = useState(person.from)
	const [personLivesIn, setPersonLivesIn] = useState(person.livesIn)
	const [personDescription, setPersonDescription] = useState(person.description)
	const [timer, setTimer] = useState(null)
	const [options, setOptions] = useState([])
	const [focustActive, setFocusActive] = useState({ name: false, livesIn: false })
	const [loading, setLoading] = useState(false)
	const [isAdvancedSearch, setAdvancedSearch] = useState(false)
	const [showGlobalUserProfileModal, setShowGlobalUserProfileModal] = useState(false)
	const [userToShowInModal, setUserToShowInModal] = useState(null)

	useEffect(() => {
		setPersonName(person.name)
		setPersonFrom(person.from)
		setPersonLivesIn(person.livesIn)
		setPersonDescription(person.description)
	}, [person.description, person.from, person.livesIn, person.name])

	const getModal = (visible, setVisible, header) => (
		<SearchModal visible={visible} setVisible={setVisible} header={header} token={token} />)

	useEffect(() => {
		if (id) {
			setTimeout(() => {
				if (nameInput && nameInput.current) { nameInput.current.focus() }
			}, 300)
		}
		if (!id) {
			nameInput.current.blur()
		}
		if (!isPersonAlive) {
			setIsAlive(false)
		}
		if (isPersonAlive) {
			setIsAlive(true)
		}
	}, [id, isPersonAlive])

	const loadUsers = async ({ name, livesIn }) => {
		if (personName !== "" && personName.length > 1) {
			const token = localStorage.token ? localStorage.getItem("token") : sessionStorage.getItem("token")
			const result = await findUsers(name || personName, livesIn || personLivesIn, token)
			const globalResult = await findUsersGlobal(
				name || personName,
				livesIn || personLivesIn,
				token
			)
			const peopleNotInTree = []
			result.forEach(person => {
				const personInTree = people.find(p => p.profileId === person._id)
				if (!personInTree) {
					peopleNotInTree.push(person)
				}
			})
			globalResult.forEach(person => {
				const personInTree = people.find(p => p.profileId === person._id)
				if (!personInTree) {
					person.isGlobalUser = true
					peopleNotInTree.push(person)
				}
			})
			setOptions(peopleNotInTree)
		}
	}

	const onPersonSelect = async (option) => {
		store.dispatch(updatePerson({
			...selectedPerson,
			name: `${option.firstName} ${option.lastName}`,
			from: option.birthPlace,
			livesIn: !option.location.name ? "" : option.location.name,
			birthDate: option.birthDate,
			deathDate: "",
			gender: !option.gender ? "unknown" : option.gender,
			description: "",
			avatar: option.avatar,
			profileId: option._id
		}))
		setOptions([])
		if (
			currentUser.friends.includes(option._id)
			&& option.friends.includes(currentUser._id)
		) {
			const resp = await inviteAsFamily(option._id)
			if (resp.status === "Updated successfully") {
				store.dispatch(updateUser({
					...currentUser,
					family: resp.family,
					friends: resp.friends
				}))
			}
		}
	}

	const searchHandler = async ({ name, livesIn }) => {
		if (timer) {
			clearTimeout(timer)
			setTimer(null)
		}
		setTimer(
			setTimeout(async () => {
				setLoading(true)
				await loadUsers({ name, livesIn })
				setLoading(false)
			}, 500)
		)
	}
	return (
		<>
			{showGlobalUserProfileModal
				&& userToShowInModal
				&& (
					<UserProfileModal
						showGlobalUserProfileModal={showGlobalUserProfileModal}
						setShowGlobalUserProfileModal={setShowGlobalUserProfileModal}
						setUserToShowInModal={setUserToShowInModal}
						userToShowInModal={userToShowInModal}
						selectedPerson={selectedPerson}
					/>
				)}
			<div className={styles.side_panel_main}>
				<div className={styles.side_panel_search_block}>
					<span className={styles.side_panel_labels}>{t("name")}</span>
					<AutoComplete
						className={`${styles.side_panel_inputs} ${styles.side_panel_autocomplete} ant-input ${focustActive.name ? styles.side_panel_inputs_focus : ""}`}
						ref={nameInput}
						placeholder={t("name")}
						filterOption={(inputValue, option) => option.value.toUpperCase()
							.indexOf(inputValue.toUpperCase()) !== -1}
						value={personName}
						onBlur={() => {
							setFocusActive({ ...focustActive, name: false })
							store.dispatch(updatePerson({ ...person, name: personName || `${t("relative")} ${t("of")} ${currentUser.firstName} ${currentUser.lastName}` }))
						}}
						disabled={person.isUser || person.profileId}
						onInput={async e => {
							await setPersonName(e.target.value)
							searchHandler({ name: e.target.value })
						}}
						onFocus={() => setFocusActive({ ...focustActive, name: true })}
						loading={focustActive.name && loading}
						style={(!person.isUser && !person.profileId) ? { marginBottom: 0 } : null}
						dropdownClassName={styles.autocomplete}
					>
						{options.map((option, i) => (
							<Option
								key={option._id}
								value={`${option.firstName} ${option.lastName}`}
								location={option.location.name}
							>
								<div
									role="button"
									tabIndex={0}
									onClick={() => {
										if (option.isGlobalUser) {
											setUserToShowInModal(option)
											setShowGlobalUserProfileModal(true)
										} else {
											onPersonSelect(option)
										}
									}}
									className={`${styles.side_panel_option} ${i < options.length - 1 && styles.option_border}`}
								>
									<UserAvatar user={option} />
									{option.firstName}
									{" "}
									{option.lastName}
									{" "}
									<span>
										{option.isGlobalUser && t("found-globally")}
									</span>
								</div>
							</Option>
						))}
					</AutoComplete>
					{(!person.isUser && !person.profileId) && (
						<Button
							type="link"
							block
							onClick={() => { setAdvancedSearch(true) }}
							className={styles.side_panel_search_btn}
						>
							{t("advanced-search")}
						</Button>
					)}

				</div>
				{!person.isUser && person.profileId !== "" && person.from === "" ? null : (
					<span className={styles.side_panel_labels}>
						{t("from")}
						:
					</span>
				)}
				{!person.isUser && person.profileId !== "" && person.from === "" ? null : (
					<Input
						className={styles.side_panel_inputs}
						placeholder={t("enter-place-name")}
						value={personFrom}
						onInput={e => setPersonFrom(e.target.value)}
						onBlur={() => store.dispatch(updatePerson({ ...person, from: personFrom }))}
						disabled={person.isUser || person.profileId}
					/>
				)}
				{!person.isUser && person.profileId !== "" && person.livesIn === "" ? null : (
					<span className={styles.side_panel_labels}>
						{t("lives-in")}
						:
					</span>
				)}
				{!person.isUser && person.profileId !== "" && person.from === "" ? null : (
					<Input
						className={styles.side_panel_inputs}
						placeholder={t("enter-place-name")}
						value={personLivesIn}
						onInput={e => setPersonLivesIn(e.target.value)}
						onBlur={() => store.dispatch(updatePerson({ ...person, livesIn: personLivesIn }))}
						disabled={person.isUser || person.profileId}
					/>
				)}
				{!person.isUser && person.profileId !== "" && person.birthDate === "" ? null : (
					<Space direction="horizontal">
						<div className={isAlive ? `${styles.birth_date_picker}` : `${styles.death_date_picker_active}`}>
							<span className={styles.side_panel_labels}>{t("born in")}</span>
							<DatePicker
								placeholder={t("select-birth-date")}
								disabledDate={disabledDate}
								className={styles.side_panel_inputs}
								format="DD/MM/YYYY"
								value={person.birthDate ? moment(person.birthDate, "DD/MM/YYYY") : ""}
								onChange={(date, dateString) => store.dispatch(
									updatePerson({ ...person, birthDate: dateString })
								)}
								disabled={person.isUser || person.profileId}
							/>
						</div>
						{!isAlive && (
							<div className={styles.death_date_picker_active}>
								<span className={styles.side_panel_labels}>{t("death-date")}</span>
								<DatePicker
									placeholder={t("select-death-date")}
									disabledDate={disabledDate}
									className={styles.side_panel_inputs}
									format="DD/MM/YYYY"
									value={person.deathDate ? moment(person.deathDate, "DD/MM/YYYY") : ""}
									onChange={(date, dateString) => store.dispatch(
										updatePerson({ ...person, deathDate: dateString })
									)}
									disabled={person.isUser || person.profileId}
								/>
							</div>
						)}
					</Space>
				)}
				{!person.isUser && !person.profileId && (
					<div>
						<Checkbox
							onChange={() => {
								setIsAlive(!isAlive)
								if (!isAlive) {
									store.dispatch(updatePerson({ ...person, deathDate: "" }))
								}
							}}
							checked={isAlive}
						>
							{person.gender === "female" ? `${t("alive-fem-ru")}` : `${t("alive")}`}
						</Checkbox>
					</div>
				)}
				{!person.isUser && (<span className={styles.side_panel_labels}>{t("description")}</span>)}
				{!person.isUser && (
					<TextArea
						className={styles.side_panel_inputs}
						rows={4}
						disabled={person.inPending}
						autoSize={{ minRows: 6, maxRows: 6 }}
						placeholder={person.isUser ? `${t("describe-yourself")}` : `${t("enter-person-description")}`}
						value={personDescription}
						onInput={e => setPersonDescription(e.target.value)}
						onBlur={() => store.dispatch(updatePerson(
							{ ...person, description: personDescription }
						))}
					/>
				)}
				{getModal(isAdvancedSearch, setAdvancedSearch, t("advanced-search"))}
			</div>
		</>
	)
}