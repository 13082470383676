import React from "react"
import {
	Modal, Card, Avatar
} from "antd"
import { UserOutlined } from "@ant-design/icons"
import { getImageUri } from "shared/utils"
import { useTranslation } from "react-i18next"
import { EmptyConnectionsImage } from "shared/empty-screen-images"
import { NavBtn } from "./components/nav-btn"
import styles from "./create-video-chat.module.css"

const { Meta } = Card

export const CreateVideoChat = ({
	isShowModal,
	setIsShowModal,
	currentUserConnections,
	setIsShowModalVideoChat,
	setSelectedConnections,
	setMode
}) => {
	const { t } = useTranslation()

	const CallUser = (data) => {
		const {
			userData,
			mode
		} = data
		setSelectedConnections(userData)
		setMode(mode)
		setIsShowModalVideoChat(true)
		setIsShowModal(false)
	}

	return (
		<Modal
			footer={(
				<div className={styles.footer}>
					<button
						className={styles.button_create_chat_true}
						type="button"
						onClick={() => {
							setIsShowModal(false)
							setSelectedConnections(null)
						}}
					>
						{t("cancel")}
					</button>
				</div>
			)}
			title={(
				<div>
					{" "}
					{t("please-choose-a-chat-participant")}
				</div>
			)}
			visible={isShowModal}
			className={styles.modal}
			keyboard
			onCancel={() => {
				setIsShowModal(false)
				setSelectedConnections(null)
			}}
		>
			{
				currentUserConnections.length === 0 ? (
					<div className={styles.background_no_participants}>
						<EmptyConnectionsImage />
					</div>
				) : (
					<div className={styles.list_friends_chat}>
						{currentUserConnections.map(user => (user.isOnline
							? (
								<div
									key={user._id}
									role="button"
									tabIndex={0}
								>
									<Card
										size="small"
										style={{
											margin: "4px 0",
											padding: "4px",
											width: "100%",
											borderRadius: "6px"
										}}
										bodyStyle={{
											display: "flex"
										}}
									>
										<Meta
											avatar={(
												<Avatar
													style={{
														background: "rgba(136, 133, 133, 0.5)",
														display: "flex",
														alignItems: "center",
														justifyContent: "center"
													}}
													src={
														!user.avatar ? (
															<UserOutlined
																style={{
																	fontSize: "28px",
																	width: "100%",
																	height: "100%",
																	display: "flex",
																	alignItems: "center",
																	justifyContent: "center"
																}}
															/>
														) : (
															getImageUri(user.avatar)
														)
													}
												/>
											)}
											title={`${user.firstName} ${user.lastName}`}
											description={!user.location.name ? "" : user.location.name}
										/>
										<NavBtn
											CallUser={CallUser}
											user={user}
										/>
									</Card>
								</div>
							) : null
						))}
					</div>
				)
			}
		</Modal>

	)
}
