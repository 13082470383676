import React from "react"
import { useSelector, useDispatch } from "react-redux"
import { selectPerson } from "modules/family-tree/utils"
import { setShowModal } from "modules/family-tree/ducks"
import { MaleIcon, FemaleIcon, UnknownGenderIcon } from "modules/family-tree/icons"
import { getImageUri, truncateText } from "shared/utils"
import { useTranslation } from "react-i18next"
import { MobEditIcon } from "./mob-person-icons"
import styles from "./person-mob-card.module.css"

export const PersonMobile = (props) => {
	const { addInfo, id } = props
	const { selectedPersonId } = useSelector(s => s.familyTree)
	const dispatch = useDispatch()
	const { t } = useTranslation()
	const isSelected = selectedPersonId === id
	const choseGender = () => {
		let gender = null
		if (addInfo.gender === "male") {
			gender = <MaleIcon />
		} else if (addInfo.gender === "female") {
			gender = <FemaleIcon />
		} else {
			gender = <UnknownGenderIcon />
		}
		return gender
	}

	const translateCard = () => {
		const splitedName = addInfo.name.split(" ")
		const translatedSplitedName = splitedName.map((name, index) => {
			if (name === "Father") {
				if (index === 0) {
					name = `${t("father-one")}`
				} else {
					name = `${t("father-next")}`
				}
			}
			if (name === "Mother") {
				if (index === 0) {
					name = `${t("mother-one")}`
				} else {
					name = `${t("mother-next")}`
				}
			}
			if (name === "Child") {
				if (index === 0) {
					name = `${t("child-one")}`
				} else {
					name = `${t("child-next")}`
				}
			}
			if (name === "of") {
				name = `${t("of")}`
			}
			return name
		})
		const translatedName = translatedSplitedName.join(" ")
		return translatedName
	}

	return (
		<div
			className={`${styles.wrap} ${isSelected && addInfo.gender === "male" ? styles.selected_male : ""} ${isSelected && addInfo.gender === "female" ? styles.selected_female : ""} ${addInfo.isUser ? styles.user_card : ""} ${addInfo.isUser && isSelected ? styles.selected_user_card : ""} ${isSelected && addInfo.gender === "unknown" ? styles.selected_gender_neutral : ""}`}
			role="button"
			tabIndex={0}
			style={{
				background: isSelected && addInfo.inPending ? "#FFF2E2" : "",
				borderColor: isSelected && addInfo.inPending ? "#FFF2E2" : "",
				boxShadow: isSelected && addInfo.inPending ? "inset 0px 8px 0px #FFF2E2" : ""
			}}
			onClick={() => selectPerson(addInfo, id)}
		>
			{!isSelected ? (
				""
			) : (
				<button
					type="button"
					className={styles.person_edit_button}
					onClick={(e) => {
						dispatch(setShowModal(true))
						e.stopPropagation()
					}}
				>
					<div className={styles.icon}>
						<MobEditIcon />
					</div>
				</button>
			)}
			<div className={styles.person_default_avatar}>
				{addInfo.avatar !== "" && !addInfo.inPending ? (<img src={getImageUri(addInfo.avatar)} alt="Something went wrong" className={styles.person_avatar} />) : choseGender()}
			</div>

			<div className={styles.person_description}>
				<div className={`${styles.person_name} ${styles.person_description_elements}`}>{!addInfo.name || addInfo.name === "" ? t("name") : translateCard()}</div>
				{!addInfo.inPending
					&& (
						<div className={`${styles.person_lives_in} ${styles.person_description_elements}`}>
							{!addInfo.livesIn || addInfo.livesIn === "" ? "" : truncateText(addInfo.livesIn, 30)}
						</div>
					)}
				{!addInfo.inPending
					&& (
						<div className={`${styles.person_lifespan} ${styles.person_description_elements}`}>
							{!addInfo.birthDate || addInfo.birthDate === "" ? "" : addInfo.birthDate}
							{" "}
							{addInfo.birthDate !== "" && addInfo.deathDate !== "" ? "-" : ""}
							{addInfo.birthDate === "" && addInfo.deathDate !== "" ? t("died-in") : ""}
							{" "}
							{!addInfo.deathDate || addInfo.deathDate === "" ? "" : addInfo.deathDate}
						</div>
					)}
			</div>
			{addInfo.inPending
				&& (
					<div className={styles.person_pending_lozenge}>{t("request-sent")}</div>
				)}
		</div>
	)
}