export const autoLogin = async (token, loadTree) => {
  try {
    const baseUrl = "/api/auth/autologin"
    const withTreeUrl = `${baseUrl}?loadTree=${loadTree}`

    const res = await fetch(loadTree ? withTreeUrl : baseUrl, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: token
      }
    })
    const json = await res.json()
    return json.user
  } catch (error) {
    console.error("Error:", error)
  }
}