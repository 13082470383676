import React from "react"

export const AddAlbumVideoImage = () => (
	<svg width="176" height="153" viewBox="0 0 176 153" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path d="M87.809 133.924C122.783 133.924 151.137 105.163 151.137 69.5597C151.137 33.9567 122.658 5.19556 87.809 5.19556C52.8347 5.19556 24.4805 33.9567 24.4805 69.5597C24.4805 105.163 52.8347 133.924 87.809 133.924Z" fill="#EAEEF9" />
		<path d="M147.015 27.3675C149.843 27.3675 152.135 25.0418 152.135 22.1728C152.135 19.3038 149.843 16.978 147.015 16.978C144.187 16.978 141.895 19.3038 141.895 22.1728C141.895 25.0418 144.187 27.3675 147.015 27.3675Z" fill="#E7EBF6" />
		<path d="M154.513 7.09644C156.444 7.09644 158.009 5.50785 158.009 3.54822C158.009 1.58859 156.444 0 154.513 0C152.581 0 151.016 1.58859 151.016 3.54822C151.016 5.50785 152.581 7.09644 154.513 7.09644Z" fill="#E7EBF6" />
		<path d="M27.8563 27.2418C29.7876 27.2418 31.3532 25.6532 31.3532 23.6936C31.3532 21.734 29.7876 20.1454 27.8563 20.1454C25.925 20.1454 24.3594 21.734 24.3594 23.6936C24.3594 25.6532 25.925 27.2418 27.8563 27.2418Z" fill="#E7EBF6" />
		<path d="M6.49485 95.6593C10.0819 95.6593 12.9897 92.7096 12.9897 89.0709C12.9897 85.4322 10.0819 82.4824 6.49485 82.4824C2.90784 82.4824 0 85.4322 0 89.0709C0 92.7096 2.90784 95.6593 6.49485 95.6593Z" fill="#E7EBF6" />
		<path d="M169.503 69.1769C173.09 69.1769 175.998 66.2272 175.998 62.5885C175.998 58.9498 173.09 56 169.503 56C165.916 56 163.008 58.9498 163.008 62.5885C163.008 66.2272 165.916 69.1769 169.503 69.1769Z" fill="#E7EBF6" />
		<g filter="url(#filter0_d)">
			<rect width="102.426" height="98.7534" rx="8" transform="matrix(0.999538 -2.51059e-05 -2.47108e-05 1.00046 36.7227 20.7399)" fill="url(#paint0_linear)" />
		</g>
		<rect width="78.0477" height="74.696" rx="4" transform="matrix(0.999538 -1.52666e-05 -1.49831e-05 1.00046 49.0078 32.7684)" fill="#EAEEF9" />
		<path d="M109.781 67.0172C110.024 62.9713 110.046 58.1001 108.94 53.9733C108.35 51.7723 107.109 49.8932 105.016 48.6848C102.923 47.4764 100.602 47.0664 97.9046 46.9045C93.3355 46.3595 89.1153 46.016 84.5929 46.1957C80.6208 46.228 76.0247 46.1326 72.146 47.6142C66.8648 49.9139 66.1184 54.8318 65.7946 60.2263C65.5518 64.2721 65.5301 69.1434 66.6359 73.2702C67.5205 76.5716 69.9084 78.8805 73.2026 79.6196C77.3689 80.8622 86.3867 80.9523 90.6339 80.8463C94.4316 80.7134 98.6519 81.0569 102.255 79.649C108.583 77.9535 109.605 72.9619 109.781 67.0172ZM82.8448 57.2795C82.9258 55.9309 83.7781 55.2602 85.0728 56.2402C88.6349 58.5294 92.1232 60.5434 95.5109 62.7318C96.9063 63.5374 96.8523 64.4365 95.6242 65.3553C92.168 67.3134 88.6112 69.446 85.0544 71.5785C83.7526 72.2222 82.8805 71.7187 82.8607 70.5445C82.6395 69.7192 82.8646 58.4537 82.8448 57.2795Z" fill="#B0B7C3" />
		<path d="M106.724 116.813C106.337 118.483 105.756 120.251 105.078 121.724C103.238 125.358 100.333 128.207 96.7507 130.073C93.0712 131.939 88.7139 132.725 84.3566 131.743C74.0927 129.582 67.5083 119.367 69.6385 108.956C71.7688 98.5446 81.7422 91.7675 92.006 94.0266C95.6855 94.8123 98.8809 96.6785 101.592 99.2322C106.143 103.848 108.08 110.527 106.724 116.813Z" fill="url(#paint1_linear)" />
		<path d="M94.2302 111.215H89.8732V106.795C89.8732 105.911 89.1954 105.125 88.2272 105.125C87.3558 105.125 86.5812 105.813 86.5812 106.795V111.215H82.2241C81.3527 111.215 80.5781 111.902 80.5781 112.885C80.5781 113.867 81.2559 114.554 82.2241 114.554H86.5812V118.974C86.5812 119.858 87.2589 120.644 88.2272 120.644C89.0986 120.644 89.8732 119.956 89.8732 118.974V114.554H94.2302C95.1016 114.554 95.8762 113.867 95.8762 112.885C95.8762 111.902 95.1016 111.215 94.2302 111.215Z" fill="white" />
		<defs>
			<filter id="filter0_d" x="14.7188" y="9.73755" width="146.38" height="142.801" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
				<feFlood floodOpacity="0" result="BackgroundImageFix" />
				<feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
				<feOffset dy="11" />
				<feGaussianBlur stdDeviation="11" />
				<feColorMatrix type="matrix" values="0 0 0 0 0.397708 0 0 0 0 0.47749 0 0 0 0 0.575 0 0 0 0.27 0" />
				<feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
				<feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
			</filter>
			<linearGradient id="paint0_linear" x1="51.1795" y1="-2.28426" x2="51.1795" y2="99.818" gradientUnits="userSpaceOnUse">
				<stop stopColor="#FDFEFF" />
				<stop offset="0.9964" stopColor="#ECF0F5" />
			</linearGradient>
			<linearGradient id="paint1_linear" x1="69.2209" y1="112.884" x2="107.156" y2="112.884" gradientUnits="userSpaceOnUse">
				<stop stopColor="#B0BACC" />
				<stop offset="1" stopColor="#969EAE" />
			</linearGradient>
		</defs>
	</svg>

)