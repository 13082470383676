/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react"
import { Empty } from "antd"
import { EmptyPostImage } from "shared/empty-screen-images"
import { useSelector } from "react-redux"
import { getPosts } from "shared/feed-components/api"
import { useTranslation } from "react-i18next"
import { FeedPost } from "shared/feed-components/feed-post"

import styles from "./profile-feed-content.module.css"

export const ProfileFeedContent = ({
	profile,
	page,
	setTotalPages,
	totalPages
}) => {
	const { user } = useSelector(s => s)
	const { currentUser } = user
	const { t } = useTranslation()
	const [posts, setPosts] = useState([])

	const feedDowload = async () => {
		if (page === 1) {
			const data = await getPosts({ userId: profile._id, page })
			setPosts(data.posts)
			setTotalPages(data.totalPages)
		}
		if (page > 1 && page <= totalPages) {
			const data = await getPosts({ userId: profile._id, page })
			setPosts([...posts, ...data.posts])
		}
	}

	useEffect(() => {
		const getData = async () => {
			if (profile.privacySettings.feed !== "only-me") {
				if (profile.privacySettings.feed === "public") {
					await feedDowload()
				}
				if (profile.privacySettings.feed === "relatives-only" && profile.family.includes(currentUser._id)) {
					await feedDowload()
				}
				if (profile.privacySettings.feed === "friends-only" && profile.friends.includes(currentUser._id)) {
					await feedDowload()
				}
				if (profile.privacySettings.feed === "friends-and-relatives" && (profile.friends.includes(currentUser._id) || profile.family.includes(currentUser._id))) {
					await feedDowload()
				}
			}
		}
		getData()
	}, [currentUser._id, profile, page])

	return (

		<div className={styles.wrap}>
			<div className={styles.posts_wrap}>
				{posts.length === 0
					? (
						<Empty
							image={<EmptyPostImage />}
							description={<span>{t("there-are-no-published-posts-yet")}</span>}
						/>
					)
					: (
						<>
							{posts.map(post => (
								<FeedPost
									id={post._id}
									personalFeedType
									post={post}
									key={post._id}
									profile={profile}
									allPosts={posts}
									setAllPosts={setPosts}
								/>
							))}
						</>
					)}
			</div>
		</div>

	)
}