import React from "react"
import { UserAvatar } from "shared/avatar"
import moment from "moment-timezone"
import { DeleteIcon } from "shared/icons"
import { deleteAlbumComment, deleteVideoAlbumComment } from "../api"
import styles from "./album-comment.module.css"

export const AlbumComment = ({
	comment, userId, ownerId, albumId,
	setAlbumComments, isVideoAlbum
}) => (
	<div
		className={styles.comment_card}
	>
		<div className={styles.comment_card_avatar}>
			<UserAvatar user={comment.user} />
		</div>
		<div className={styles.comment_card_info}>
			<div className={styles.comment_card_author}>
				{comment.user.firstName}
				{" "}
				{comment.user.lastName}
				{comment.commentAuthor === userId && (
					<button
						type="button"
						onClick={async () => {
							const comments = isVideoAlbum ? await deleteVideoAlbumComment(
								albumId, comment.commentId, ownerId
							)
								: await deleteAlbumComment(
									albumId, comment.commentId, ownerId
								)
							setAlbumComments(comments.data.comments.reverse())
						}}
					>
						<DeleteIcon />
					</button>
				)}
				{comment.commentAuthor !== userId && userId === ownerId && (
					<button
						type="button"
						onClick={async () => {
							const comments = await deleteAlbumComment(
								albumId, comment.commentId, ownerId
							)
							setAlbumComments(comments.data.comments.reverse())
						}}
					>
						<DeleteIcon />
					</button>
				)}
			</div>
			<div className={styles.comment_card_text}>{comment.commentText}</div>
			<div className={styles.comment_card_time}>{moment(comment.commentDate).tz(`${moment.tz.guess()}`).fromNow()}</div>
		</div>
	</div>
)
