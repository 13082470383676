import React from "react"
import { Button } from "antd"
import { useTranslation } from "react-i18next"
import styles from "./mob-save-button.module.css"

export const MobileSaveChangesButton = ({
	saveData
}) => {
	const { t } = useTranslation()

	return (
		<div className={styles.side_panel_avatar}>
			<div className={styles.side_panel_avatar_btns}>
				<Button
					className={`${styles.side_panel_save_btn} ${styles.side_panel_avatar_btn}`}
					onClick={() => {
						saveData()
					}}
				>
					{t("save-changes")}
				</Button>
			</div>
		</div>
	)
}