export const registerUser = async (inputFirstName, inputLastName, inputEmail, inputPassword) => {
  if (inputFirstName !== "" && inputLastName !== "" && inputEmail !== "" && inputPassword !== "") {
    const inputInfo = {
      email: inputEmail,
      password: inputPassword,
      firstName: inputFirstName,
      lastName: inputLastName
    }
    try {
      const res = await fetch("/api/auth/registration", {
        method: "POST",
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify(inputInfo)
      })
      const json = await res.json()
      return json
    } catch (error) {
      console.error("Error:", error)
    }
  }
  return true
}