import React, { useEffect, useState } from "react"
import {
	DatePicker,
	Input,
	Checkbox,
	AutoComplete,
	Button
} from "antd"
import { SearchModal } from "modules/family-tree/components/side-panel/sections/modal"
import { UserProfileModal } from "modules/family-tree/components/side-panel/sections/user-profile-modal"
import moment from "moment"
import { useTranslation } from "react-i18next"
import { updatePerson } from "modules/family-tree/ducks"
import { store } from "app/redux/store"
import { UserAvatar } from "shared/avatar"
import { useSelector } from "react-redux"
import { inviteAsFamily } from "modules/search-people/api"
import { updateUser } from "app/redux/user"
import { findUsers, findUsersGlobal } from "modules/family-tree/api"
import { getPersonById } from "modules/family-tree/utils"
import styles from "./mobile-modal-main.module.css"

const { Option } = AutoComplete
const { TextArea } = Input

const disabledDate = (current) => current > moment().endOf("day")

export const MobileMainSection = ({
	person,
	isPersonAlive,
	setNameInfo,
	setFromInfo,
	setLivesInInfo,
	setDescriptionInfo,
	nameInfo,
	fromInfo,
	livesInInfo,
	descriptionInfo,
	token
}) => {
	const [isAlive, setIsAlive] = useState(true)
	const [options, setOptions] = useState([])
	const [timer, setTimer] = useState(null)
	const [focustActive, setFocusActive] = useState({ name: false, livesIn: false })
	const [loading, setLoading] = useState(false)
	const [isAdvancedSearch, setAdvancedSearch] = useState(false)
	const [showGlobalUserProfileModal, setShowGlobalUserProfileModal] = useState(false)
	const [userToShowInModal, setUserToShowInModal] = useState(null)

	const { selectedPersonId } = useSelector(s => s.familyTree)
	const { people } = useSelector(s => s.familyTree)
	const { user } = useSelector(s => s)
	const { currentUser } = user
	const selectedPerson = getPersonById(selectedPersonId)
	const { t } = useTranslation()

	const getModal = (visible, setVisible, header) => (
		<SearchModal visible={visible} setVisible={setVisible} header={header} token={token} />)

	useEffect(() => {
		if (!isPersonAlive) {
			setIsAlive(false)
		}
		setNameInfo(person.name)
		setFromInfo(person.from)
		setLivesInInfo(person.livesIn)
		setDescriptionInfo(person.description)
	}, [isPersonAlive,
		person.description,
		person.from,
		person.livesIn,
		person.name,
		setDescriptionInfo,
		setFromInfo,
		setLivesInInfo,
		setNameInfo])

	const loadUsers = async ({ name, livesIn }) => {
		if (nameInfo !== "" && nameInfo.length > 1) {
			const result = await findUsers(name || nameInfo, livesIn || livesInInfo, token)
			const globalResult = await findUsersGlobal(
				name || nameInfo,
				livesIn || livesInInfo,
				token
			)
			const peopleNotInTree = []
			result.forEach(person => {
				const personInTree = people.find(p => p.profileId === person._id)
				if (!personInTree) {
					peopleNotInTree.push(person)
				}
			})
			globalResult.forEach(person => {
				const personInTree = people.find(p => p.profileId === person._id)
				if (!personInTree) {
					person.isGlobalUser = true
					peopleNotInTree.push(person)
				}
			})
			setOptions(peopleNotInTree)
		}
	}

	const onPersonSelect = async (option) => {
		store.dispatch(updatePerson({
			...selectedPerson,
			name: `${option.firstName} ${option.lastName}`,
			from: option.birthPlace,
			livesIn: !option.location.name ? "" : option.location.name,
			birthDate: option.birthDate,
			deathDate: "",
			gender: !option.gender ? "unknown" : option.gender,
			description: "",
			avatar: option.avatar,
			profileId: option._id
		}))
		setOptions([])
		if (
			currentUser.friends.includes(option._id)
			&& option.friends.includes(currentUser._id)
		) {
			const resp = await inviteAsFamily(option._id, token)
			if (resp.status === "Updated successfully") {
				store.dispatch(updateUser({
					...currentUser,
					family: resp.family,
					friends: resp.friends
				}))
			}
		}
	}

	const searchHandler = async ({ name, livesIn }) => {
		if (timer) {
			clearTimeout(timer)
			setTimer(null)
		}
		setTimer(
			setTimeout(async () => {
				setLoading(true)
				await loadUsers({ name, livesIn })
				setLoading(false)
			}, 500)
		)
	}

	return (
		<>
			{showGlobalUserProfileModal
				&& userToShowInModal
				&& (
					<UserProfileModal
						showGlobalUserProfileModal={showGlobalUserProfileModal}
						setShowGlobalUserProfileModal={setShowGlobalUserProfileModal}
						setUserToShowInModal={setUserToShowInModal}
						userToShowInModal={userToShowInModal}
						selectedPerson={selectedPerson}
						token={token}
					/>
				)}
			<div className={styles.mobile_modal_main_wrap}>
				<div>
					<AutoComplete
						className={`${styles.modal_main_inputs} ${styles.modal_main_autocomplete} ant-input ${focustActive.name ? styles.modal_main_inputs_focus : ""}`}
						placeholder={t("name")}
						filterOption={(inputValue, option) => option.value.toUpperCase()
							.indexOf(inputValue.toUpperCase()) !== -1}
						value={nameInfo}
						onBlur={() => {
							setFocusActive({ ...focustActive, name: false })
							store.dispatch(updatePerson({ ...person, name: nameInfo }))
						}}
						disabled={person.isUser || person.profileId}
						onInput={async e => {
							await setNameInfo(e.target.value)
							searchHandler({ name: e.target.value })
						}}
						onFocus={() => setFocusActive({ ...focustActive, name: true })}
						loading={focustActive.name && loading}
						style={(!person.isUser && !person.profileId) ? { marginBottom: 0 } : null}
						dropdownClassName={styles.autocomplete}
					>
						{options.map((option) => (
							<Option
								key={option._id}
								value={`${option.firstName} ${option.lastName}`}
								location={option.location.name}
							>
								<div
									role="button"
									tabIndex={0}
									onClick={() => {
										if (option.isGlobalUser) {
											setUserToShowInModal(option)
											setShowGlobalUserProfileModal(true)
										} else {
											onPersonSelect(option)
										}
									}}
									className={styles.side_panel_option}
								>
									<UserAvatar user={option} />
									{option.firstName}
									{" "}
									{option.lastName}
									{" "}
									<span>
										{option.isGlobalUser && t("found-globally")}
									</span>
								</div>
							</Option>
						))}
					</AutoComplete>
					{(!person.isUser && !person.profileId) && (
						<Button
							type="link"
							block
							onClick={() => { setAdvancedSearch(true) }}
							className={styles.modal_main_search_btn}
						>
							{t("advanced-search")}
						</Button>
					)}
				</div>
				{!person.isUser && person.profileId !== "" && person.from === "" ? null : (
					<Input
						className={styles.modal_main_inputs}
						placeholder={t("from")}
						value={fromInfo}
						onInput={e => setFromInfo(e.target.value)}
						disabled={person.isUser || person.profileId}
					/>
				)}
				{!person.isUser && person.profileId !== "" && person.livesIn === "" ? null : (
					<Input
						className={styles.modal_main_inputs}
						placeholder={t("enter-place-name")}
						value={livesInInfo}
						onInput={e => setLivesInInfo(e.target.value)}
						disabled={person.isUser || person.profileId}
					/>
				)}
				{!person.isUser && person.profileId !== "" && person.birthDate === "" ? null : (
					<DatePicker
						disabledDate={disabledDate}
						className={styles.modal_main_inputs}
						format="DD/MM/YYYY"
						value={person.birthDate ? moment(person.birthDate, "DD/MM/YYYY") : ""}
						placeholder={t("select-birth-date")}
						onChange={(
							date,
							dateString
						) => store.dispatch(updatePerson({ ...person, birthDate: dateString }))}
						disabled={person.isUser || person.profileId}
					/>
				)}
				{!isAlive && (
					<DatePicker
						disabledDate={disabledDate}
						className={styles.modal_main_inputs}
						format="DD/MM/YYYY"
						value={person.deathDate ? moment(person.deathDate, "DD/MM/YYYY") : ""}
						placeholder={t("select-death-date")}
						onChange={(
							date,
							dateString
						) => store.dispatch(updatePerson({ ...person, deathDate: dateString }))}
						disabled={person.isUser || person.profileId}
					/>
				)}
				{!person.isUser && !person.profileId && (
					<div className={styles.modal_main_checkbox}>
						<Checkbox
							onChange={() => {
								setIsAlive(!isAlive)
								if (!isAlive) {
									store.dispatch(updatePerson({ ...person, deathDate: "" }))
								}
							}}
							checked={isAlive}
						>
							{person.gender === "female" ? `${t("alive-fem-ru")}` : `${t("alive")}`}
						</Checkbox>
					</div>
				)}
				<TextArea
					className={styles.modal_main_inputs}
					rows={4}
					autoSize={{ minRows: 6, maxRows: 6 }}
					disabled={person.inPending}
					placeholder={person.isUser ? `${t("describe-yourself")}` : `${t("enter-person-description")}`}
					value={descriptionInfo}
					onInput={e => setDescriptionInfo(e.target.value)}
				/>
				{getModal(isAdvancedSearch, setAdvancedSearch, t("advanced-search"))}
			</div>
		</>
	)
}