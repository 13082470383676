import React from "react"

export const AppLogoIcon = () => (
	<svg width="100%" height="100%" viewBox="0 0 496 496" fill="none">
		<path d="M378.825 302.113C381.729 290.148 388.344 279.794 397.215 273.328C406.087 266.862 416.488 264.814 426.13 267.635L444.308 272.952L442.484 280.471C439.58 292.435 432.965 302.79 424.093 309.255C415.222 315.721 404.821 317.769 395.179 314.949L377.001 309.632" fill="white" />
		<path d="M117.4 296.192C113.328 285.798 106.119 277.304 97.3576 272.58C88.5966 267.855 79.0016 267.287 70.6835 271.001L55.0018 278.001L57.5607 284.533C61.6326 294.927 68.8422 303.421 77.6032 308.145C86.3643 312.869 95.9593 313.437 104.277 309.724L119.959 302.724" fill="white" />
		<path d="M301.153 263.527C302.594 252.33 307.551 242.026 314.935 234.883C322.319 227.74 331.523 224.342 340.524 225.438L357.494 227.502L356.588 234.539C355.147 245.736 350.189 256.039 342.806 263.182C335.422 270.325 326.217 273.723 317.216 272.628L300.247 270.563" fill="white" />
		<path d="M194.34 263.527C192.899 252.33 187.941 242.026 180.557 234.883C173.174 227.74 163.969 224.342 154.968 225.438L137.999 227.502L138.904 234.539C140.345 245.736 145.303 256.039 152.686 263.182C160.07 270.325 169.275 273.723 178.276 272.628L195.245 270.563" fill="white" />
		<path d="M263.569 214.72C260.797 202.596 262.006 189.94 266.929 179.538C271.853 169.136 280.088 161.839 289.822 159.252L308.175 154.375L309.917 161.994C312.689 174.118 311.481 186.774 306.557 197.176C301.634 207.578 293.399 214.875 283.664 217.462L265.311 222.339" fill="white" />
		<path d="M232.958 214.72C235.73 202.596 234.521 189.94 229.598 179.538C224.675 169.136 216.44 161.839 206.705 159.252L188.352 154.375L186.61 161.994C183.838 174.118 185.047 186.774 189.97 197.176C194.894 207.578 203.128 214.875 212.863 217.462L231.216 222.339" fill="white" />
		<path d="M253.28 133.689C262.683 125.077 268.954 113.333 270.714 101.039C272.474 88.7458 269.578 76.9098 262.664 68.1353L249.629 51.5931L243.72 57.0046C234.317 65.6159 228.046 77.3601 226.286 89.6538C224.526 101.947 227.421 113.783 234.336 122.558L247.371 139.1" fill="white" />
		<path d="M341.324 167.41C341.191 155.005 345.021 143.06 351.97 134.202C358.918 125.343 368.417 120.297 378.377 120.173L397.153 119.94L397.237 127.735C397.369 140.139 393.54 152.085 386.591 160.943C379.642 169.802 370.143 174.848 360.184 174.971L341.407 175.205" fill="white" />
		<path d="M387.315 226.165C388.975 214.403 394.32 203.646 402.173 196.26C410.027 188.874 419.745 185.464 429.192 186.781L447.001 189.263L445.957 196.654C444.297 208.416 438.953 219.173 431.099 226.559C423.246 233.945 413.527 237.355 404.081 236.038L386.272 233.556" fill="white" />
		<path d="M108.318 226.165C106.658 214.403 101.313 203.646 93.4597 196.26C85.6063 188.874 75.8874 185.464 66.4411 186.781L48.6323 189.263L49.6755 196.654C51.3355 208.416 56.6801 219.173 64.5335 226.559C72.3869 233.945 82.1058 237.355 91.5521 236.038L109.361 233.556" fill="white" />
		<path d="M297.43 124.022C293.795 113.688 293.606 102.431 296.903 92.7258C300.2 83.0209 306.714 75.6635 315.011 72.2722L330.653 65.8787L332.937 72.3725C336.571 82.7061 336.76 93.9636 333.463 103.669C330.166 113.373 323.653 120.731 315.355 124.122L299.713 130.516" fill="white" />
		<path d="M198.188 124.022C201.822 113.688 202.011 102.431 198.714 92.7258C195.417 83.0209 188.904 75.6635 180.606 72.2722L164.964 65.8787L162.681 72.3725C159.046 82.7061 158.857 93.9636 162.154 103.669C165.451 113.373 171.965 120.731 180.262 124.122L195.904 130.516" fill="white" />
		<path d="M153.938 167.41C154.07 155.005 150.241 143.06 143.292 134.202C136.343 125.343 126.844 120.297 116.885 120.173L98.1083 119.94L98.0251 127.735C97.8925 140.139 101.722 152.085 108.671 160.943C115.619 169.802 125.118 174.848 135.078 174.971L153.854 175.205" fill="white" />
		<circle cx="248.5" cy="306.5" r="36.5" fill="white" />
		<path fillRule="evenodd" clipRule="evenodd" d="M137.615 309.502C135.878 310.433 134.54 314.243 135.15 316.522C136.642 322.093 141.601 327.081 156.305 337.805C166.522 345.256 186.45 362.332 197.014 372.686C212.725 388.086 224.141 404.453 228.683 418.09C231.322 426.011 231.417 432.193 229.21 451.979C228.121 461.751 226.89 473.743 226.475 478.629C225.714 487.601 225.428 508.428 226.059 508.976C226.247 509.139 231.963 508.466 238.761 507.48C245.56 506.494 256.179 505.178 262.36 504.556L273.598 503.423L272.977 496.784C272.058 486.96 272.284 464.164 273.378 456.256C280.01 408.321 308.057 362.932 346.699 337.599C358.561 329.822 361.196 325.891 359.126 321.26C357 316.5 350.396 318 348.5 318C346.604 318 337.667 320.444 330.976 323.321C303.818 334.992 280.838 359.213 261.976 396.046L259.28 401.31L257.856 399.081C254.247 393.433 243.115 379.517 236.478 372.357C227.864 363.062 207.275 344.956 196.521 337.218C171.924 319.52 153.017 309.455 143.572 309.031C140.995 308.916 138.314 309.128 137.615 309.502Z" fill="white" />
	</svg>
)