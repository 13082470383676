/* eslint-disable no-nested-ternary */
import React from "react"
import { useTranslation } from "react-i18next"
import moment from "moment-timezone"
import { UserAvatar } from "shared/avatar"
import styles from "./post-header.module.css"

export const SharedPostAuthor = ({
	postAuthor,
	post,
	profile
}) => {
	const { t } = useTranslation()

	return (
		<div className={styles.post_author}>
			<UserAvatar user={profile._id === postAuthor._id ? postAuthor : profile} />
			<div className={styles.post_author_info}>
				<div className={styles.post_author_name}>
					{profile._id === postAuthor._id ? postAuthor.firstName : profile.firstName}
					{" "}
					{profile._id === postAuthor._id ? postAuthor.lastName : profile.lastName}
					{" "}
					{profile._id !== postAuthor._id ? (
						<>
							<span className={styles.post_shared}>
								{profile.gender === "female" ? t("shared-female") : t("shared-male")}
								{" "}
								{post.isUploadedPhoto
									? t("new-photo-of")
									: post.isUploadedVideo
										? t("new-video-of")
										: t("post-of")}
								{" "}
							</span>
							<span>
								{postAuthor.firstName}
								{" "}
								{postAuthor.lastName}
							</span>
						</>
					) : ""}
				</div>
				<div className={styles.post_publish_date}>
					{moment(post.creationDate).tz(`${moment.tz.guess()}`).fromNow()}
					{" "}
					{post.isEdited && "(edited)"}
				</div>
			</div>
		</div>
	)
}