import React, { useState, useEffect } from "react"
import { Modal } from "antd"
import { useTranslation } from "react-i18next"
import { useSelector } from "react-redux"
import { UserAvatar } from "shared/avatar"
import { getUsersByIds } from "shared/utils"
import { useHistory } from "react-router-dom"
import { v4 as uuidv4 } from "uuid"
import CustomScroll from "react-scrollbars-custom"

export const UsersListModal = ({
	isShowList,
	setIsShowList,
	usersIds,
	titleName
}) => {
	const history = useHistory()
	const [users, setUsers] = useState(null)

	const { user } = useSelector(s => s)
	const { currentUser } = user
	useEffect(() => {
		const getUser = async () => {
			const usersResponse = await getUsersByIds(usersIds)
			if (usersResponse.status === "successful") {
				setUsers(usersResponse.users)
			}
		}
		getUser()
	}, [usersIds])
	const { t } = useTranslation()
	return (
		<CustomScroll>
			<Modal
				title={t(titleName)}
				visible={isShowList && usersIds.length > 0}
				onCancel={() => setIsShowList(false)}
				footer={false}
			>
				<div style={{ height: "300px", cursor: "pointer" }}>
					{users?.map(user => (
						<div
							key={uuidv4()}
							role="button"
							tabIndex={0}
							onClick={() => {
								if (currentUser._id === user._id) {
									history.push({ pathname: "/user-profile" })
								} else {
									history.push({ pathname: "/profile", userId: user._id })
								}
							}}
							style={{ display: "flex" }}
						>
							<UserAvatar user={user} />
							<h2>{`${user.firstName} ${user.lastName}`}</h2>
						</div>
					))}
				</div>
			</Modal>
		</CustomScroll>
	)
}