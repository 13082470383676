/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useState } from "react"
import { Input, message, Upload } from "antd"
import { socket } from "app/socket"
import { UserAvatar } from "shared/avatar"
import { useTranslation } from "react-i18next"
import "emoji-mart/css/emoji-mart.css"
import { Picker } from "emoji-mart"
import { IMAGE_MAXIMUM_SIZE } from "shared/constants"
import { EmojiIcon, ImageClipIcon, AllowClear } from "shared/icons"
import ImgCrop from "antd-img-crop"
import styles from "./chat-input.module.css"

const { TextArea } = Input

export const ChatInputForm = ({
	chatId, currentUser, setVisiblePopover, chat
}) => {
	const [inputValue, setInputValue] = useState("")
	const [toggle, setToggle] = useState(false)
	const [visible, setVisible] = useState(false)
	const [activeInput, setActiveInput] = useState(false)
	const chatCompanion = chat.users.find(u => u._id !== currentUser._id)

	const addEmoji = (emoji) => {
		setInputValue(`${inputValue}${emoji}`)
	}
	const { t } = useTranslation()

	const getBase64 = file => new Promise((resolve, reject) => {
		const reader = new FileReader()
		reader.readAsDataURL(file)
		reader.onload = () => resolve(reader.result)
		reader.onerror = error => reject(error)
	})

	const selectPhotos = async ({ file }) => {
		if (file.status !== "uploading") {
			if (file.size > IMAGE_MAXIMUM_SIZE) {
				message.error(t("photo-size-exceeded"))
			} else {
				const base64Image = await getBase64(file.originFileObj)
				const newImage = base64Image.split(",")
				socket.emit("ROOM:NEW_MESSAGE", {
					userId: currentUser._id, chatId, text: inputValue, image: newImage[1]
				})
				setInputValue("")
			}
		}
	}

	return (
		<div
			className={styles.input_form_wrap}
			onClick={() => {
				setVisiblePopover(false)
			}}
		>
			{toggle && (
				<Picker
					showPreview={false}
					useButton={false}
					title="Emoji"
					style={{
						position: "absolute", bottom: "140px", right: "52px", overflow: "hidden", maxHeight: "40vh", zIndex: 10, width: "300px", background: "#f2f2f2"
					}}
					onSelect={el => {
						setToggle(false)
						addEmoji(el.native)
					}}
				/>
			)}
			<div className={styles.input_form}>
				<div className={styles.input_form_input_block}>
					<div className={styles.input_form_input_block_avatar}>
						<UserAvatar user={currentUser} />
					</div>
					<div
						className={`${styles.input_block} ${activeInput && styles.active}`}
					>
						<TextArea
							bordered={false}
							className={styles.input_field}
							placeholder={t("type-a-message")}
							autoSize={{ minRows: 1, maxRows: 6 }}
							value={inputValue}
							onKeyDown={(e) => {
								if (e.key === "Enter" && !e.shiftKey) {
									if (inputValue && inputValue !== "") {
										socket.emit("ROOM:NEW_MESSAGE", {
											userId: currentUser._id, chatId, text: inputValue, image: null
										})
										e.preventDefault()
										setVisible(false)
										setInputValue("")
									} else {
										message.error(`${t("no-message-to-send")}`)
									}
								}
							}}
							onClick={() => setActiveInput(true)}
							onChange={(e) => {
								if (e.target.value !== "") {
									setVisible(true)
								}
								setInputValue(e.target.value)
								setToggle(false)
							}}
							onBlur={() => {
								setActiveInput(false)
							}}
							onFocus={() => {
								setToggle(false)
							}}
						/>
						<ImgCrop
							quality={0.1}
							rotate
						>
							<Upload
								onChange={selectPhotos}
								showUploadList={false}
							>
								<button type="button" className={styles.btns}>
									<ImageClipIcon />
								</button>
							</Upload>
						</ImgCrop>

						<button
							type="button"
							className={`${styles.btns} ${styles.emoji_picker_disabled}`}
							onClick={() => setToggle(!toggle)}
						>
							<EmojiIcon />
						</button>

						<button
							type="button"
							className={`${styles.btns} ${!visible ? styles.btns_none : styles.btns_visible}`}
							onClick={() => {
								setInputValue("")
								setActiveInput(false)
								setVisible(false)
							}}
						>
							<AllowClear />
						</button>

					</div>
					<button
						type="button"
						className={styles.confirm_button}
						onClick={() => {
							if (!chat.roomName && chatCompanion
								&& currentUser.blacklist?.includes(chatCompanion._id)) {
								message.error(`${t("This-user-has-added-you-to-the-blacklist")}`)
							} else if (inputValue && inputValue !== "") {
								socket.emit("ROOM:NEW_MESSAGE", {
									userId: currentUser._id, chatId, text: inputValue, image: null
								})
								setVisible(false)
								setInputValue("")
							} else {
								message.error(`${t("no-message-to-send")}`)
							}
						}}
					>
						{t("send")}
					</button>
				</div>
			</div>
		</div>
	)
}