export const SET_DRAG_MODE = "SET_DRAG_MODE"

export const setDragMode = drag => ({
  type: SET_DRAG_MODE, drag
})

export const dragMode = (state = (""), action) => {
  switch (action.type) {
    case SET_DRAG_MODE:
      return action.drag
    default:
      return state
  }
}