import React, { useState, useCallback } from "react"
import { Modal, Slider } from "antd"
import Cropper from "react-easy-crop"
import { store } from "app/redux/store"
import { updatePerson } from "modules/family-tree/ducks"
import { getCroppedImg } from "./utils/get-cropped-image"
import { uploadResizedImage, cancelCrop } from "./api"
import styles from "./image-cropper.module.css"

const formatter = (value) => `${value}°`

export const ImageCropper = ({
	token, person, resizedImage, setResizedImage
}) => {
	const [crop, setCrop] = useState({ x: 0, y: 0 })
	const [zoom, setZoom] = useState(1)
	const [rotation, setRotation] = useState(0)
	const [confirmLoading, setConfirmLoading] = useState(false)
	const [croppedAreaPixels, setCroppedAreaPixels] = useState(null)

	const handleCancel = () => {
		setResizedImage("")
		cancelCrop(token)
	}

	const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
		setCroppedAreaPixels(croppedAreaPixels)
	}, [])

	const onCrop = async () => {
		setConfirmLoading(true)
		const base64image = await getCroppedImg(resizedImage, croppedAreaPixels, rotation)
		const imageToUpload = base64image.split(",")
		const response = await uploadResizedImage(token, person.positionId, imageToUpload[1])
		if (response.status === "successful") {
			store.dispatch(updatePerson({ ...person, avatar: response.data.path }))
			setResizedImage("")
		}
		setConfirmLoading(false)
	}

	return (
		<Modal
			title=""
			visible={resizedImage}
			onOk={onCrop}
			centered
			className={styles.modal}
			confirmLoading={confirmLoading}
			onCancel={handleCancel}
		>
			<div className={styles.crop}>
				<Cropper
					image={resizedImage}
					crop={crop}
					zoom={zoom}
					aspect={4 / 4}
					cropShape="round"
					onCropChange={setCrop}
					onCropComplete={onCropComplete}
					onZoomChange={setZoom}
					className={styles.crop_inner}
					rotation={rotation}
				/>
			</div>
			<Slider
				defaultValue={rotation}
				value={rotation}
				onChange={(value) => setRotation(value)}
				max={360}
				min={0}
				tipFormatter={formatter}
			/>
		</Modal>
	)
}