export const findUsers = async (name) => {
  const token = localStorage.token ? localStorage.getItem("token") : sessionStorage.getItem("token")
  try {
    const res = await fetch("/api/finde-connections", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: token
      },
      body: JSON.stringify({ name })
    })
    const json = await res.json()
    return json.result
  } catch (error) {
    console.error("Error:", error)
  }
}