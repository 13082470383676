export const deleteVideo = async (
  videoPath, albumId
) => {
  const token = localStorage.token ? localStorage.getItem("token") : sessionStorage.getItem("token")
  try {
    const reportInfo = {
      videoPath
    }
    const res = await fetch(`/api/video-gallery/video/${albumId}`, {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        Authorization: token
      },
      body: JSON.stringify(reportInfo)
    })
    const json = await res.json()
    return json
  } catch (error) {
    const message = "Something went wrong"
    return message
  }
}