import React from "react"

export const EmptyChatImage = () => (
	<svg width="163" height="146" viewBox="0 0 163 146" fill="none">
		<path d="M81.8425 126C116.642 126 144.855 97.8484 144.855 63C144.855 28.1516 116.642 0 81.8425 0C47.0427 0 18.8301 28.1516 18.8301 63C18.8301 97.8484 47.0427 126 81.8425 126Z" fill="#EAEEF9" />
		<path d="M152.063 43.2814C154.877 43.2814 157.159 41.005 157.159 38.1968C157.159 35.3886 154.877 33.1122 152.063 33.1122C149.249 33.1122 146.968 35.3886 146.968 38.1968C146.968 41.005 149.249 43.2814 152.063 43.2814Z" fill="#EAEEF9" />
		<path d="M159.52 23.3149C161.442 23.3149 163 21.7602 163 19.8425C163 17.9247 161.442 16.37 159.52 16.37C157.598 16.37 156.04 17.9247 156.04 19.8425C156.04 21.7602 157.598 23.3149 159.52 23.3149Z" fill="#EAEEF9" />
		<path d="M14.48 46.8779C16.4019 46.8779 17.9599 45.3233 17.9599 43.4055C17.9599 41.4877 16.4019 39.933 14.48 39.933C12.558 39.933 11 41.4877 11 43.4055C11 45.3233 12.558 46.8779 14.48 46.8779Z" fill="#EAEEF9" />
		<path d="M103.468 26.5928C124.923 26.5928 142.23 42.1623 142.23 61.3027C142.23 72.3014 136.509 82.1573 127.498 88.585V103.012C127.498 105.012 125.209 106.011 123.779 104.869L111.478 95.2985C108.904 95.727 106.329 96.0127 103.611 96.0127C82.1565 96.0127 64.8496 80.4432 64.8496 61.3027C64.8496 42.1623 82.1565 26.5928 103.468 26.5928Z" fill="#CBD4E0" />
		<g filter="url(#filter0_d)">
			<path d="M65.5645 24.8787C41.678 24.8787 22.3687 42.1623 22.3687 63.4453C22.3687 75.7295 28.8051 86.7281 38.8174 93.7273V109.725C38.8174 111.868 41.2489 113.153 42.9653 111.725L56.6964 101.155C59.5571 101.726 62.4177 102.012 65.4214 102.012C89.3078 102.012 108.617 84.7284 108.617 63.4453C108.617 42.1623 89.3078 24.8787 65.5645 24.8787Z" fill="url(#paint0_linear)" />
		</g>
		<path d="M51.4759 65.0166C54.0828 65.0166 56.196 62.9062 56.196 60.3029C56.196 57.6996 54.0828 55.5892 51.4759 55.5892C48.8691 55.5892 46.7559 57.6996 46.7559 60.3029C46.7559 62.9062 48.8691 65.0166 51.4759 65.0166Z" fill="#989FB0" />
		<path d="M78.2232 65.0166C80.8301 65.0166 82.9433 62.9062 82.9433 60.3029C82.9433 57.6996 80.8301 55.5892 78.2232 55.5892C75.6164 55.5892 73.5032 57.6996 73.5032 60.3029C73.5032 62.9062 75.6164 65.0166 78.2232 65.0166Z" fill="#989FB0" />
		<path d="M70.7855 80.0148C70.3564 80.0148 69.9273 79.8719 69.6412 79.4434C68.497 77.8722 66.7806 77.0152 64.7781 77.0152C62.9187 77.0152 61.0593 77.8722 60.0581 79.4434C59.629 80.1576 58.7708 80.3005 58.0556 79.7291C57.3404 79.3006 57.1974 78.4435 57.7695 77.7293C59.3429 75.4439 62.0605 74.0155 64.9212 74.0155C67.7818 74.0155 70.3564 75.4439 72.0728 77.7293C72.5019 78.4435 72.3588 79.3006 71.7867 79.7291C71.3576 80.0148 71.0715 80.0148 70.7855 80.0148Z" fill="#989FB0" />
		<path d="M54.9249 21.2537C54.7524 21.2537 54.58 21.2537 54.4075 21.2537C53.5452 20.9092 53.0278 20.0481 53.2003 19.187L56.1321 9.71445C56.477 8.85332 57.3393 8.33663 58.2016 8.50886C59.0639 8.85331 59.5813 9.71445 59.4089 10.5756L56.6495 20.0481C56.477 20.737 55.7872 21.2537 54.9249 21.2537Z" fill="#B5BCCE" />
		<path d="M47.6819 23.3204C46.992 23.3204 46.3022 22.8038 45.9573 22.1149L41.4733 8.50887C41.1284 7.64773 41.6457 6.61437 42.508 6.26991C43.3703 5.92546 44.4051 6.44214 44.75 7.30328L49.234 20.9093C49.5789 21.7704 49.0615 22.8038 48.1992 23.1482C48.0268 23.3204 47.8543 23.3204 47.6819 23.3204Z" fill="#B5BCCE" />
		<path d="M40.9553 26.5928C40.4379 26.5928 40.093 26.4206 39.748 26.0761L34.2293 20.737C33.5395 20.0481 33.5395 19.0148 34.2293 18.3259C34.9191 17.6369 35.9539 17.6369 36.6437 18.3259L42.1625 23.6649C42.8523 24.3538 42.8523 25.3872 42.1625 26.0761C41.8176 26.4206 41.3002 26.5928 40.9553 26.5928Z" fill="#B5BCCE" />
		<defs>
			<filter id="filter0_d" x="0.368652" y="13.8787" width="130.249" height="131.45" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
				<feFlood floodOpacity="0" result="BackgroundImageFix" />
				<feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" />
				<feOffset dy="11" />
				<feGaussianBlur stdDeviation="11" />
				<feColorMatrix type="matrix" values="0 0 0 0 0.397708 0 0 0 0 0.47749 0 0 0 0 0.575 0 0 0 0.27 0" />
				<feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
				<feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
			</filter>
			<linearGradient id="paint0_linear" x1="65.4648" y1="22.8559" x2="65.4648" y2="113.271" gradientUnits="userSpaceOnUse">
				<stop stopColor="#FDFEFF" />
				<stop offset="0.9964" stopColor="#ECF0F5" />
			</linearGradient>
		</defs>
	</svg>
)