import React from "react"
import { message } from "antd"
import { useTranslation } from "react-i18next"
import { socket } from "app/socket"
import { getUsersByIds } from "shared/utils"
import styles from "./more-buttons.module.css"

export const MoreButtons = ({
	chat,
	currentUser,
	setCurrentUserConnections,
	setIsShowModal,
	setVisiblePopover,
	setIsShowDeleteModal,
	setSelectedChat,
	setIsShowParticipiantsModal
}) => {
	const { t } = useTranslation()

	return (
		<div className={styles.popover}>
			<button
				className={styles.add_human_button}
				type="button"
				onClick={async () => {
					const newUserCha = []
					const usersToSelect = [...currentUser.friends, ...currentUser.family]
					usersToSelect
						.filter((item, index) => usersToSelect.indexOf(item) === index)
						.forEach((user) => {
							if (
								typeof chat.users.find((u) => u._id === user) === "undefined"
							) {
								newUserCha.push(user)
							}
						})
					const usersResponse = await getUsersByIds([...newUserCha])
					if (usersResponse.status === "successful") {
						setCurrentUserConnections(usersResponse.users)
						setIsShowModal(true)
					}
					setVisiblePopover(false)
				}}
			>
				{t("add-members")}
			</button>
			{chat.roomName && chat.users.length > 1 && (
				<button
					type="button"
					className={`${styles.remove_human_button} ${styles.show_members}`}
					onClick={() => {
						setIsShowParticipiantsModal(true)
						setVisiblePopover(false)
					}}
				>
					{t("show-members")}
				</button>
			)}
			<button
				type="button"
				className={styles.remove_human_button}
				onClick={async () => {
					socket.emit("LEAVE_CHAT", {
						roomId: chat._id,
						userId: currentUser._id,
						text: "LEAVED_CHAT"
					})
					setVisiblePopover(false)
					setSelectedChat(null)
				}}
			>
				{t("leave-chat")}
			</button>
			{chat.roomName && chat.users.length > 1 && (
				<button
					type="button"
					className={styles.remove_human_button}
					onClick={() => {
						if (chat?.chatAdmin && chat?.chatAdmin === currentUser._id) {
							setIsShowDeleteModal(true)
							setVisiblePopover(false)
						} else {
							message.error(t("only-chat-admin-can-delete"))
						}
					}}
				>
					{t("delete-member")}
				</button>
			)}
		</div>
	)
}