import React, { useState, useEffect } from "react"
import { store } from "app/redux/store"
import { useSelector, useDispatch } from "react-redux"
import { updateUser } from "app/redux/user"
import { useTranslation } from "react-i18next"
import { changeNotificationsSettings } from "../api"
import { switchElementsData } from "../data"
import { SettingElement } from "./components"
import styles from "../settings.module.css"

export const SettingsNotifications = () => {
	const { user } = useSelector(s => s)
	const { currentUser } = user
	const { notificationsSettings } = currentUser
	const dispatch = useDispatch()
	const { t } = useTranslation()

	const [passwordChangeSetting, setPasswordChangeSetting] = useState(
		notificationsSettings.passwordChange
	)
	const [connectionsAcceptSetting, setConnectionsAcceptSetting] = useState(
		notificationsSettings.connectionsAccept
	)
	const [birthdaysSetting, setBirthdaysSetting] = useState(
		notificationsSettings.birthdays
	)
	const [likesAndSharesSetting, setLikesAndSharesSetting] = useState(
		notificationsSettings.likesCommentsAndShares
	)

	useEffect(() => {
		const { user } = store.getState()
		const getData = async () => {
			const response = await changeNotificationsSettings(
				passwordChangeSetting,
				connectionsAcceptSetting,
				birthdaysSetting,
				likesAndSharesSetting
			)
			dispatch(updateUser({
				...user.currentUser,
				notificationsSettings: response.settings
			}))
		}
		getData()
	}, [passwordChangeSetting,
		connectionsAcceptSetting,
		birthdaysSetting, dispatch,
		likesAndSharesSetting])

	const returnStateConstant = (switchName) => {
		switch (switchName) {
			case "password-change":
				return [passwordChangeSetting, setPasswordChangeSetting]

			case "connection-accepted":
				return [connectionsAcceptSetting, setConnectionsAcceptSetting]

			case "birthdays":
				return [birthdaysSetting, setBirthdaysSetting]

			case "likes-comments-and-shares":
				return [likesAndSharesSetting, setLikesAndSharesSetting]

			default:
				break
		}
	}

	return (
		<div className={styles.section_content}>
			{switchElementsData.map(el => {
				const elementValues = returnStateConstant(el.labelText)
				return (
					<SettingElement
						labelText={t(el.labelText)}
						labelDescription={t(el.labelDescription)}
						value={elementValues[0]}
						setValue={elementValues[1]}
						key={el.labelText}
					/>
				)
			})}
		</div>
	)
}