import { v4 as uuidv4 } from "uuid"
import { store } from "app/redux/store"
import { addRightBranchUncle } from "modules/family-tree/ducks"

export const addRightBranchUncleNode = (firstParentId, chosedParentId) => {
  store.dispatch(addRightBranchUncle({
    positionId: `person${uuidv4()}`,
    name: "Add child",
    from: "",
    livesIn: "",
    birthDate: "",
    deathDate: "",
    gender: "",
    parents: [firstParentId, chosedParentId],
    description: "",
    avatar: "",
    profileId: ""
  }, chosedParentId))
}