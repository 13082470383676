import React from "react"

export const EmptyRightPanelConnectionsImage = () => (
	<svg width="132" height="111" viewBox="0 0 132 111" fill="none">
		<path d="M66.7 102C94.7 102 117.4 79.2106 117.4 51C117.4 22.7894 94.7 0 66.7 0C38.7 0 16 22.7894 16 51C16 79.2106 38.7 102 66.7 102Z" fill="#EAEEF9" />
		<path d="M123.2 30.0177C125.464 30.0177 127.3 28.1748 127.3 25.9015C127.3 23.6283 125.464 21.7854 123.2 21.7854C120.936 21.7854 119.1 23.6283 119.1 25.9015C119.1 28.1748 120.936 30.0177 123.2 30.0177Z" fill="#EAEEF9" />
		<path d="M129.2 13.8543C130.746 13.8543 132 12.5957 132 11.0433C132 9.49078 130.746 8.23224 129.2 8.23224C127.654 8.23224 126.4 9.49078 126.4 11.0433C126.4 12.5957 127.654 13.8543 129.2 13.8543Z" fill="#EAEEF9" />
		<path d="M12.5 32.9291C14.0463 32.9291 15.3 31.6705 15.3 30.1181C15.3 28.5656 14.0463 27.307 12.5 27.307C10.9536 27.307 9.69995 28.5656 9.69995 30.1181C9.69995 31.6705 10.9536 32.9291 12.5 32.9291Z" fill="#EAEEF9" />
		<path d="M17.7 94.0689C20.5719 94.0689 22.9 91.7316 22.9 88.8485C22.9 85.9653 20.5719 83.628 17.7 83.628C14.8281 83.628 12.5 85.9653 12.5 88.8485C12.5 91.7316 14.8281 94.0689 17.7 94.0689Z" fill="#EAEEF9" />
		<g filter="url(#filter0_d)">
			<path d="M113.7 30.62H27.0001C25.1001 30.62 23.6001 29.1141 23.6001 27.2066V7.62988C23.6001 5.7224 25.1001 4.21649 27.0001 4.21649H113.6C115.5 4.21649 117 5.7224 117 7.62988V27.2066C117.1 29.1141 115.5 30.62 113.7 30.62Z" fill="white" />
		</g>
		<path d="M44.6001 17.3681C44.6001 19.5767 43.6001 21.5846 42.1001 22.9901C40.8001 24.1948 39.0001 24.8976 37.1001 24.8976C35.2001 24.8976 33.4001 24.1948 32.1001 22.9901C30.5001 21.5846 29.6001 19.5767 29.6001 17.3681C29.6001 13.2519 33.0001 9.83853 37.1001 9.83853C41.2001 9.83853 44.6001 13.2519 44.6001 17.3681Z" fill="#DCE1EE" />
		<path d="M42.1001 22.9901C40.8001 24.1948 39.0001 24.8976 37.1001 24.8976C35.2001 24.8976 33.4001 24.1948 32.1001 22.9901C32.7001 22.7893 33.4001 22.4881 34.3001 22.0865C34.5001 21.9861 34.7001 21.7854 34.8001 21.5846C34.9001 21.4842 34.9001 21.2834 34.9001 21.183V19.2755L34.8001 19.1751C34.6001 18.9743 34.5001 18.6732 34.5001 18.372L34.3001 18.2716C33.8001 18.372 33.9001 17.87 33.7001 16.8661C33.7001 16.4645 33.7001 16.3641 33.9001 16.2637L34.1001 16.0629C33.2001 13.9546 33.6001 12.6495 34.6001 12.1476C34.3001 11.4448 34.3001 11.244 34.3001 11.244C34.3001 11.244 36.3001 11.5452 36.9001 11.4448C37.8001 11.244 39.1001 11.4448 39.6001 12.6495C40.4001 12.9507 40.7001 13.4527 40.8001 14.055C40.9001 14.9586 40.4001 15.9625 40.3001 16.2637C40.4001 16.3641 40.5001 16.4645 40.4001 16.7657C40.3001 17.6692 40.3001 18.2716 39.8001 18.1712L39.4001 19.0747C39.4001 19.1751 39.4001 19.1751 39.4001 19.2755C39.4001 19.3759 39.4001 19.6771 39.4001 21.2834C39.4001 21.4842 39.5001 21.685 39.5001 21.7854C39.6001 21.9861 39.8001 22.0865 39.9001 22.1869C40.8001 22.4881 41.5001 22.7893 42.1001 22.9901Z" fill="white" />
		<path d="M40.3265 16.3641C40.3265 16.1551 40.2224 15.8417 40.1183 15.6327V15.5282C39.702 14.6922 38.8693 14.3788 38.0367 14.3788C35.955 14.2743 35.7469 14.6922 35.0183 14.0653C35.2265 14.3788 35.2265 14.9012 34.9142 15.5282C34.7061 15.9461 34.2897 16.1551 33.8734 16.2596C32.9367 14.0653 33.353 12.7069 34.3938 12.1844C34.0816 11.453 34.0816 11.244 34.0816 11.244C34.0816 11.244 36.1632 11.5575 36.7877 11.453C37.7244 11.244 39.0775 11.453 39.5979 12.7069C40.4306 13.0204 40.7428 13.5428 40.8469 14.1698C40.951 14.9012 40.4306 15.9461 40.3265 16.3641Z" fill="#A2ABBC" />
		<path d="M39.5 21.7853V22.1869H34.8V21.685C34.9 21.5846 34.9 21.3838 34.9 21.2834V19.3759L34.8 19.2755V19.1751C34.9 19.3759 35.0001 19.4763 35.2001 19.6771L36.7001 20.681C37.0001 20.9822 37.5 20.9822 37.9 20.681L39.3 19.4763L39.4 19.3759C39.4 19.4763 39.4 19.7775 39.4 21.3838C39.3 21.4842 39.4 21.5846 39.5 21.7853Z" fill="url(#paint0_linear)" />
		<path d="M69.2002 15.1594H50.0002C49.5002 15.1594 49.2002 14.7578 49.2002 14.3563V11.8464C49.2002 11.3445 49.6002 11.0433 50.0002 11.0433H69.2002C69.7002 11.0433 70.0002 11.4448 70.0002 11.8464V14.3563C70.0002 14.8582 69.7002 15.1594 69.2002 15.1594Z" fill="#CED6E2" />
		<path d="M92.0002 23.6929H50.0002C49.5002 23.6929 49.2002 23.2913 49.2002 22.8897V20.3799C49.2002 19.8779 49.6002 19.5768 50.0002 19.5768H92.0002C92.5002 19.5768 92.8002 19.9783 92.8002 20.3799V22.8897C92.8002 23.3917 92.4002 23.6929 92.0002 23.6929Z" fill="#CED6E2" />
		<g filter="url(#filter1_d)">
			<path d="M113.7 96.4784H27.0001C25.1001 96.4784 23.6001 94.9725 23.6001 93.065V73.4882C23.6001 71.5808 25.1001 70.0749 27.0001 70.0749H113.6C115.5 70.0749 117 71.5808 117 73.4882V93.065C117.1 94.9725 115.5 96.4784 113.7 96.4784Z" fill="white" />
		</g>
		<path d="M44.6001 83.2264C44.6001 85.4351 43.6001 87.443 42.1001 88.8485C40.8001 90.0532 39.0001 90.756 37.1001 90.756C35.2001 90.756 33.4001 90.0532 32.1001 88.8485C30.5001 87.443 29.6001 85.4351 29.6001 83.2264C29.6001 79.1103 33.0001 75.6969 37.1001 75.6969C41.2001 75.6969 44.6001 79.1103 44.6001 83.2264Z" fill="#DCE1EE" />
		<path d="M42.1001 88.8485C40.8001 90.0532 39.0001 90.756 37.1001 90.756C35.2001 90.756 33.4001 90.0532 32.1001 88.8485C32.7001 88.6477 33.4001 88.3465 34.3001 87.9449C34.5001 87.8445 34.7001 87.6438 34.8001 87.443C34.9001 87.3426 34.9001 87.1418 34.9001 87.0414V85.1339L34.8001 85.0335C34.6001 84.8327 34.5001 84.5315 34.5001 84.2304L34.3001 84.13C33.8001 84.2304 33.9001 83.7284 33.7001 82.7245C33.7001 82.3229 33.7001 82.2225 33.9001 82.1221L34.1001 81.9213C33.2001 79.813 33.6001 78.5079 34.6001 78.006C34.3001 77.3032 34.3001 77.1024 34.3001 77.1024C34.3001 77.1024 36.3001 77.4036 36.9001 77.3032C37.8001 77.1024 39.1001 77.3032 39.6001 78.5079C40.4001 78.8091 40.7001 79.3111 40.8001 79.9134C40.9001 80.817 40.4001 81.8209 40.3001 82.1221C40.4001 82.2225 40.5001 82.3229 40.4001 82.6241C40.3001 83.5276 40.3001 84.13 39.8001 84.0296L39.4001 84.8327C39.4001 84.9331 39.4001 84.9331 39.4001 85.0335C39.4001 85.1339 39.4001 85.4351 39.4001 87.0414C39.4001 87.2422 39.5001 87.443 39.5001 87.5434C39.6001 87.7441 39.8001 87.8445 39.9001 87.9449C40.8001 88.4469 41.5001 88.6477 42.1001 88.8485Z" fill="white" />
		<path d="M40.3265 82.2225C40.3265 82.0135 40.2224 81.7 40.1183 81.4911V81.3866C39.702 80.5506 38.8693 80.2372 38.0367 80.2372C35.955 80.1327 35.7469 80.5506 35.0183 79.9237C35.2265 80.2372 35.2265 80.7596 34.9142 81.3866C34.7061 81.8045 34.2897 82.0135 33.8734 82.118C32.9367 79.9237 33.353 78.5653 34.3938 78.0428C34.0816 77.3114 34.0816 77.1024 34.0816 77.1024C34.0816 77.1024 36.1632 77.4159 36.7877 77.3114C37.7244 77.1024 39.0775 77.3114 39.5979 78.5653C40.4306 78.8788 40.7428 79.4012 40.8469 80.0282C40.951 80.8641 40.4306 81.8045 40.3265 82.2225Z" fill="#A2ABBC" />
		<path d="M39.5 87.6438V88.0453H34.8V87.5434C34.9 87.443 34.9 87.2422 34.9 87.1418V85.2343L34.8 85.1339V85.0335C34.9 85.2343 35.0001 85.3347 35.2001 85.5355L36.7001 86.5394C37.0001 86.8406 37.5 86.8406 37.9 86.5394L39.3 85.3347L39.4 85.2343C39.4 85.3347 39.4 85.6359 39.4 87.2422C39.3 87.3426 39.4 87.5434 39.5 87.6438Z" fill="url(#paint1_linear)" />
		<path d="M69.2002 81.1182H50.0002C49.5002 81.1182 49.2002 80.7166 49.2002 80.315V77.8052C49.2002 77.3032 49.6002 77.002 50.0002 77.002H69.2002C69.7002 77.002 70.0002 77.4036 70.0002 77.8052V80.315C70.0002 80.7166 69.7002 81.1182 69.2002 81.1182Z" fill="#CED6E2" />
		<path d="M92.0002 89.6516H50.0002C49.5002 89.6516 49.2002 89.2501 49.2002 88.8485V86.3386C49.2002 85.8367 49.6002 85.5355 50.0002 85.5355H92.0002C92.5002 85.5355 92.8002 85.9371 92.8002 86.3386V88.8485C92.8002 89.2501 92.4002 89.6516 92.0002 89.6516Z" fill="#CED6E2" />
		<g filter="url(#filter2_d)">
			<path d="M99 63.5492H12.4C10.5 63.5492 9 62.0433 9 60.1358V40.559C9 38.6516 10.5 37.1457 12.4 37.1457H99C100.9 37.1457 102.4 38.6516 102.4 40.559V60.1358C102.5 62.0433 100.9 63.5492 99 63.5492Z" fill="white" />
		</g>
		<path d="M54.6001 48.0886H35.4001C34.9001 48.0886 34.6001 47.687 34.6001 47.2854V44.7756C34.6001 44.2736 35.0001 43.9724 35.4001 43.9724H54.6001C55.1001 43.9724 55.4001 44.374 55.4001 44.7756V47.2854C55.4001 47.7874 55.1001 48.0886 54.6001 48.0886Z" fill="#CED6E2" />
		<path d="M77.4001 56.7224H35.4001C34.9001 56.7224 34.6001 56.3209 34.6001 55.9193V53.4094C34.6001 52.9075 35.0001 52.6063 35.4001 52.6063H77.3001C77.8001 52.6063 78.1001 53.0079 78.1001 53.4094V55.9193C78.2001 56.3209 77.8001 56.7224 77.4001 56.7224Z" fill="#CED6E2" />
		<path d="M30.1 50.2973C30.1 52.4055 29.2 54.313 27.8 55.7185C27.7 55.8189 27.7 55.8189 27.6 55.9193C26.3 57.124 24.5 57.8268 22.6 57.8268C20.7 57.8268 19 57.124 17.7 56.0197C17.7 56.0197 17.7 56.0197 17.6 56.0197C16 54.6142 15 52.6063 15 50.2973C15 46.1811 18.4 42.7677 22.5 42.7677C26.7 42.7677 30.1 46.1811 30.1 50.2973Z" fill="#DCE1EE" />
		<path d="M27.7707 55.8714C27.4665 55.1587 26.9594 54.5479 26.1482 54.1406C25.7425 53.937 25.4383 53.8352 24.9312 53.8352H24.627C23.9171 53.8352 23.8157 53.7334 23.8157 53.7334V52.5117C24.627 51.9008 25.2355 51.0864 25.4383 50.1701C25.9453 50.0683 26.2496 49.661 26.1482 49.152C26.1482 48.9484 25.9453 48.7447 25.9453 48.5411C25.9453 48.4393 25.9453 48.3375 25.9453 48.2357C25.9453 48.1339 25.9453 48.1339 25.9453 48.0321C25.9453 47.9303 25.9453 47.9303 25.9453 47.8285C25.9453 47.4212 25.8439 47.1158 25.5397 46.7086C24.9312 45.5887 23.7143 44.876 22.396 44.876C22.0918 44.876 21.8889 44.876 21.5847 44.9778C21.0777 45.0796 20.672 45.2832 20.2664 45.5887C20.165 45.6905 20.165 45.6905 20.0636 45.7923C20.0636 45.7923 20.0636 45.7923 19.9622 45.8941C19.5565 46.3013 19.2523 46.8104 19.0495 47.3194C18.8466 47.8285 18.8466 48.4393 18.8466 49.0502C18.8466 49.0502 18.8466 49.0502 18.8466 49.152C18.8466 49.2538 18.8466 49.2538 18.8466 49.3556C18.8466 49.3556 18.8466 49.4574 18.7452 49.4574C18.9481 49.2538 18.8466 49.5592 18.9481 49.7628C19.1509 50.2719 19.4551 50.1701 19.8607 50.4755C19.8607 50.4755 19.8607 50.4755 19.7593 50.4755L19.3537 50.5773C17.1227 51.29 16.9199 53.6316 19.1509 54.1406C18.9481 54.2424 18.8466 54.3442 18.7452 54.3442C18.1368 54.8533 17.7311 55.4641 17.5283 56.075C18.8466 57.1949 20.5706 57.9076 22.4974 57.9076C24.4242 57.9076 26.2496 57.1949 27.5679 55.9732C27.6693 56.075 27.7707 55.9732 27.7707 55.8714C27.8721 55.8714 27.8721 55.8714 27.7707 55.8714ZM21.4833 53.428C21.3819 53.3262 21.2805 53.2243 21.2805 53.2243C21.1791 53.1225 21.0777 53.1225 20.9763 53.0207C20.9763 53.0207 20.8748 53.0207 20.8748 52.9189C20.5706 52.7153 20.2664 52.5117 20.165 52.2063C20.165 52.1044 20.2664 51.9008 20.4692 51.6972C20.5706 51.799 20.672 51.9008 20.7734 52.1044C20.8748 52.2063 20.9763 52.3081 21.0777 52.4099C21.1791 52.4099 21.2805 52.5117 21.2805 52.6135C21.2805 52.6135 21.2805 52.6135 21.3819 52.6135C21.3819 52.6135 21.3819 52.6135 21.4833 52.6135V53.428Z" fill="white" />
		<path d="M24.7499 53.777C24.6499 54.1786 24.4499 54.4797 24.4499 54.4797L20.6499 53.8774L20.8499 52.8734C20.8499 52.8734 20.9499 52.8734 20.9499 52.9738C21.0499 53.0742 21.1499 53.0742 21.2499 53.1746C21.3499 53.1746 21.3499 53.275 21.4499 53.3754V52.6727C21.4499 52.6727 21.4499 52.6727 21.3499 52.6727C21.9499 52.9738 22.7499 53.1746 23.8499 52.4719V53.6766C23.9499 53.6766 24.0499 53.777 24.7499 53.777Z" fill="url(#paint2_linear)" />
		<path d="M23.9598 48.1775C24.6732 48.1775 25.3866 48.0751 25.9981 47.8705C25.9981 47.8705 25.9981 47.7682 26.1 47.6659C25.9981 47.3589 25.8961 46.9497 25.6923 46.5404C25.0808 45.4149 23.8578 44.6987 22.5329 44.6987C22.2272 44.6987 21.9339 44.7206 21.525 44.8229C20.9808 44.9899 20.7281 45.1366 20.5992 45.2151C20.5992 45.2151 20.3013 45.4162 20.087 45.6195C19.8727 45.8229 19.3736 46.5404 19.1697 47.052C18.9659 47.6659 18.8015 48.1877 18.9034 48.8016C19.0053 48.8016 19.1697 48.689 19.2717 48.689C20.087 48.2798 20.6985 47.7682 21.0042 46.9497C21.8195 47.6659 22.8387 48.0751 23.9598 48.1775Z" fill="#A2ABBC" />
		<path d="M20.5 51.8032C20.6 51.9036 20.7 52.004 20.8 52.1043C20.7 52.004 20.6 51.9036 20.5 51.8032Z" fill="#3973F4" />
		<path d="M21.9001 55.5177C21.6001 55.9193 21.1001 55.9193 20.6001 55.9193C21.1001 55.4173 20.8001 53.9114 19.2001 54.1122C16.9001 53.7106 17.2001 51.3012 19.4001 50.5984L19.8001 50.498C19.8001 50.498 19.8001 50.498 19.9001 50.5984C20.1001 51.1004 20.3001 51.4016 20.6001 51.8032C19.7001 52.6063 21.0001 52.8071 21.6001 53.4095C22.0001 53.6103 22.4001 54.9154 21.9001 55.5177Z" fill="#A2ABBC" />
		<defs>
			<filter id="filter0_d" x="14.6001" y="0.216492" width="111.404" height="44.4035" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
				<feFlood floodOpacity="0" result="BackgroundImageFix" />
				<feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" />
				<feOffset dy="5" />
				<feGaussianBlur stdDeviation="4.5" />
				<feColorMatrix type="matrix" values="0 0 0 0 0.397708 0 0 0 0 0.47749 0 0 0 0 0.575 0 0 0 0.17 0" />
				<feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
				<feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
			</filter>
			<filter id="filter1_d" x="14.6001" y="66.0749" width="111.404" height="44.4035" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
				<feFlood floodOpacity="0" result="BackgroundImageFix" />
				<feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" />
				<feOffset dy="5" />
				<feGaussianBlur stdDeviation="4.5" />
				<feColorMatrix type="matrix" values="0 0 0 0 0.397708 0 0 0 0 0.47749 0 0 0 0 0.575 0 0 0 0.17 0" />
				<feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
				<feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
			</filter>
			<filter id="filter2_d" x="0" y="33.1457" width="111.404" height="44.4035" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
				<feFlood floodOpacity="0" result="BackgroundImageFix" />
				<feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" />
				<feOffset dy="5" />
				<feGaussianBlur stdDeviation="4.5" />
				<feColorMatrix type="matrix" values="0 0 0 0 0.397708 0 0 0 0 0.47749 0 0 0 0 0.575 0 0 0 0.17 0" />
				<feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
				<feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
			</filter>
			<linearGradient id="paint0_linear" x1="37.1515" y1="22.2053" x2="37.1515" y2="20.5461" gradientUnits="userSpaceOnUse">
				<stop stopColor="white" />
				<stop offset="1" stopColor="#E2E5EC" />
			</linearGradient>
			<linearGradient id="paint1_linear" x1="37.1515" y1="88.0637" x2="37.1515" y2="86.4045" gradientUnits="userSpaceOnUse">
				<stop stopColor="white" />
				<stop offset="1" stopColor="#E2E5EC" />
			</linearGradient>
			<linearGradient id="paint2_linear" x1="22.7011" y1="54.492" x2="22.7011" y2="53.3858" gradientUnits="userSpaceOnUse">
				<stop stopColor="white" />
				<stop offset="1" stopColor="#E2E5EC" />
			</linearGradient>
		</defs>
	</svg>
)