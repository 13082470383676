import React, { useState } from "react"
import {
	Input, Button, message
} from "antd"
import {
	useLocation, useHistory, Redirect
} from "react-router-dom"
import { AppLogoIcon } from "shared/logo-icon"
import { CancelButton } from "shared/buttons"
import { useTranslation } from "react-i18next"
import { registrationConfirm, resendCodeToEmail, sendMessageToEmail } from "../api"
import "antd/dist/antd.css"
import styles from "./registration-confirm.module.css"

export const RegistrationConfirm = () => {
	const location = useLocation()
	const history = useHistory()
	const { email } = location
	const { t } = useTranslation()

	const [inputValue, setInputValue] = useState("")

	if (!email) {
		return <Redirect to="/login" />
	}

	return (
		<div className={styles.wrap}>
			<div className={styles.container}>
				<div className={styles.logo}><AppLogoIcon /></div>
				<div className={styles.form}>
					<div className={styles.form_header}>{t("enter-security-code")}</div>
					<div className={styles.form_message}>
						{t("please-check-your-email-for-a-message-with-your-security code.")}
						{t("if-message-has-not-arrived-check-the-spamtab")}
					</div>
					<div className={styles.input_container}>
						<Input
							placeholder={t("enter-code")}
							className={styles.auth_input}
							value={inputValue}
							onChange={(e) => setInputValue(e.target.value)}
						/>
						<div title={email} className={styles.input_message}>
							<div>{t("we-sent-your-code-to")}</div>
							{" "}
							<div className={styles.input_message_email}>{email}</div>
						</div>
					</div>
					<div className={styles.resend_code_btn_container}>
						<button
							type="button"
							className={styles.resend_code_btn}
							onClick={async () => {
								const result = await resendCodeToEmail(email)
								if (result.status === "OK") {
									message.success(`${t("code-sended")}`)
								}
							}}
						>
							{t("didn't-get-a-code?")}
						</button>
					</div>
					<div className={styles.form_finish_btns_container}>
						<CancelButton
							cancel={() => {
								history.push("/login")
							}}
							style={{ width: "74px" }}
						/>
						<Button
							type="primary"
							className={styles.finish_form_button}
							onClick={async () => {
								const response = await registrationConfirm(inputValue, email)
								if (response.status === "OK") {
									await sendMessageToEmail(email)
									history.push("/login")
								} else {
									message.error(response.message === "Invalid verification code" ? t("invalid-verification-code") : response.message)
								}
							}}
						>
							{t("continue")}
						</Button>
					</div>
				</div>
			</div>
			<div className={styles.auth_image} />
		</div>

	)
}