import React from "react"
import { SearchPeopleStartScreenImage } from "shared/empty-screen-images"
import { useTranslation } from "react-i18next"
import { EmailInvite } from "./email-invite"
import styles from "./search-people-components.module.css"

export const SearchPeopleStartScreen = () => {
	const { t } = useTranslation()
	return (
		<div className={styles.results_content_empty_wrap}>
			<div>
				<SearchPeopleStartScreenImage />
			</div>
			<span>{t("enter-persons-first-name-and-last-name-to-search")}</span>
			<p />
			<EmailInvite />
		</div>
	)
}