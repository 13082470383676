/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react"
import {
	Route, Switch, Redirect, useHistory, useLocation
} from "react-router-dom"
import { socket } from "app/socket"
import { useSelector } from "react-redux"
import { updateUser } from "app/redux/user"
import {
	setMessages, updateMessages, updateNameChat, setMessagesReaded, addNewChat,
	updateChat, sortChats, leaveChat, updateUsers, updateChatAvatar, updateChatAdmin
} from "app/redux/messages"
import { setUserOffline } from "shared/left-panel/api"
import { setUserOnline } from "modules/auth/api"
import { getUsersByIds } from "shared/utils"
import { Header } from "shared/header"
import { LeftPanel } from "shared/left-panel"
import { RightPanel } from "shared/right-panel"
import { FamilyTree } from "modules/family-tree"
import { Friends } from "modules/friends"
import { PersonalInfo } from "modules/personal-info"
import { Settings } from "modules/settings"
import { Feed } from "modules/feed"
import { Messages } from "modules/chat"
import { ContactUsPage } from "modules/contact-us"
import { ConnectionProfileCard } from "modules/connection-profile-card"
import { UserProfileCard } from "modules/user-profile"
import { CalendarPage } from "modules/calendar"
import { VideoChat } from "modules/video-chat"
import { VideosPage } from "modules/videos"
import { PhotosPage } from "modules/photos"
import { MapPage } from "modules/map"
import { SearchPeoplePage } from "modules/search-people"
import Watermark from "react-awesome-watermark"
import { Button, notification } from "antd"
import { useTranslation } from "react-i18next"
import { ModalVideoCall } from "modules/video-chat/components/modal-video-call"
import { store } from "../redux/store"
import styles from "../app.module.css"

export const CALL_ENDED = "CALL_ENDED"
export const CALL_CANCELED = "CALL_CANCELED"

export const MainTab = () => {
	const { user } = useSelector(s => s)
	const [isUserInactive, setIsUserInactive] = useState(false)
	const [isBurgerActive, setIsBurgerActive] = useState(false)
	const [isPrintCLicked, setIsPrintClicked] = useState(false)
	const [windowHeight, setWindowHeight] = useState(false)
	const [windowWidth, setWindowWidth] = useState(false)
	const [modeData, setModeData] = useState()

	const history = useHistory()
	const location = useLocation()
	const { t } = useTranslation()
	const [isShowModalVideoChat, setIsShowModalVideoChat] = useState(false)
	const [selectedConnections, setSelectedConnections] = useState(null)

	const close = (id) => {
		socket.emit("CALL_CANCELED_ENDED", [CALL_CANCELED, id])
		socket.emit("USER_ALREADY_ANSWERED", { _id: user.currentUser._id })
		setSelectedConnections(null)
	}
	const openNotification = (id) => {
		const key = `open${Date.now()}`
		const btn = (
			<Button
				type="primary"
				size="small"
				onClick={() => {
					notification.close(key)
					setIsShowModalVideoChat(true)
					socket.emit("USER_ALREADY_ANSWERED", { _id: user.currentUser._id })
				}}
			>
				{t("answer")}
			</Button>
		)
		notification.open({
			message: `${t("VIDEO-CALL")}`,
			description:
				`${t("start-chating-with-your-connections")}`,
			btn,
			duration: 10,
			key,
			onClose: () => close(id)
		})
	}

	useEffect(() => {
		const { user } = store.getState()
		const { currentUser } = user
		if (currentUser._id) {
			store.dispatch(updateUser({
				...currentUser,
				userSocketId: socket.id
			}))
			socket.emit("USER_LOGIN", currentUser._id)
			socket.on("USER_CHATS", (userChats) => {
				store.dispatch(setMessages(userChats))
			})
		}
		socket.on("RENAME_CHAT", (roomId, newChatName) => {
			store.dispatch(updateNameChat(roomId, newChatName))
		})
		socket.on("USER_ALREADY_ANSWERED", () => {
			notification.destroy()
		})
		socket.on("CALL_CANCELED_ENDED", () => {
			if (!isShowModalVideoChat) {
				notification.destroy()
			}
		})
		socket.on("CHANGE_CHAT_AVATAR", (roomId, avatar) => {
			store.dispatch(updateChatAvatar(roomId, avatar))
		})
		socket.on("ROOM:NEW_MESSAGE", (chatId, message, chatDate) => {
			store.dispatch(updateMessages(chatId, message, chatDate))
			store.dispatch(sortChats())
		})
		socket.on("ROOM:DELETE_MESSAGE", (chatId, messages) => {
			store.dispatch(updateChat(chatId, messages))
			store.dispatch(sortChats())
		})
		socket.on("ROOM:DELETE_MESSAGE_FOR_SENDER", (chatId, messages) => {
			store.dispatch(updateChat(chatId, messages))
			store.dispatch(sortChats())
		})
		socket.on("ROOM:READ_MESSAGES", (chatId, messages) => {
			store.dispatch(setMessagesReaded(chatId, messages))
		})
		socket.on("START_CHAT", (chat) => {
			store.dispatch(addNewChat(chat))
			store.dispatch(sortChats())
			socket.emit("JOIN_NEW_ROOM", chat._id)
		})
		socket.on("LEAVE_CHAT", (chatId) => {
			store.dispatch(leaveChat(chatId))
			store.dispatch(sortChats())
		})
		socket.on("UPDATE_CHAT_INFO", ({
			chatUsersIds,
			roomId,
			chatUsers,
			systemMessage,
			chatDate,
			newAdmin
		}) => {
			store.dispatch(updateUsers(roomId, chatUsersIds, chatUsers))
			if (systemMessage && chatDate) {
				store.dispatch(updateMessages(roomId, systemMessage, chatDate))
			}
			if (newAdmin) {
				store.dispatch(updateChatAdmin(roomId, newAdmin))
			}
		})
		socket.on("UPDATE_CHAT_ADMIN", ({
			roomId,
			chatAdmin,
			systemMessage,
			chatDate
		}) => {
			store.dispatch(updateChatAdmin(roomId, chatAdmin))
			if (systemMessage && chatDate) {
				store.dispatch(updateMessages(roomId, systemMessage, chatDate))
			}
		})
		socket.on("USER_INVITES_AS_FRIEND", (friendRequests) => {
			const { user } = store.getState()
			const { currentUser } = user
			store.dispatch(updateUser({
				...currentUser,
				invitesAsFriend: friendRequests
			}))
		})
		socket.on("USER_INVITES_AS_FAMILY", (familyRequests) => {
			const { user } = store.getState()
			const { currentUser } = user
			store.dispatch(updateUser({
				...currentUser,
				invitesAsFamily: familyRequests
			}))
		})
		socket.on("USER_NOTIFICATIONS", (notifications) => {
			const { user } = store.getState()
			const { currentUser } = user
			store.dispatch(updateUser({
				...currentUser,
				notifications
			}))
		})
		socket.on("MENTIONED_USER_NOTIFICATIONS", (notifications) => {
			const { user } = store.getState()
			const { currentUser } = user
			store.dispatch(updateUser({
				...currentUser,
				notifications
			}))
		})
		socket.on("CHANGE_BLACKLIST", (blacklist) => {
			const { user } = store.getState()
			const { currentUser } = user
			store.dispatch(updateUser({
				...currentUser,
				blacklist
			}))
		})
		socket.on("NEW_TREE_ELEMENT_NOTIFICATION", (newNotification) => {
			const { user } = store.getState()
			const { currentUser } = user
			if (newNotification.userId !== currentUser._id) {
				store.dispatch(updateUser({
					...currentUser,
					notifications: [newNotification, ...currentUser.notifications]
				}))
			}
		})
	}, [history])

	const printClick = () => {
		setIsPrintClicked(true)
	}

	const printClose = () => {
		setIsPrintClicked(false)
	}

	const onResize = (e) => {
		setWindowWidth(e.target.innerWidth)
		setWindowHeight(e.target.innerHeight)
	}

	useEffect(() => {
		socket.on("CALL_USER", async (data) => {
			const { user } = store.getState()
			const { currentUser } = user
			const userResponse = await getUsersByIds(data.from)
			const callUser = { ...data, avatar: userResponse.users[0].avatar }
			store.dispatch(updateUser({
				...currentUser, callUser
			}))
			setSelectedConnections(callUser)
			setModeData(callUser.mode)
			if (!callUser.callBack) {
				openNotification(callUser.from)
			}
		})

		setWindowHeight(window.innerHeight)
		setWindowWidth(window.innerWidth)

		return () => {
			socket.removeListener("CALL_USER")
		}
	}, [])

	useEffect(() => {
		window.addEventListener("beforeprint", printClick)
		window.addEventListener("afterprint", printClose)
		window.addEventListener("resize", onResize)
		return () => {
			window.removeEventListener("beforeprint", printClick)
			window.removeEventListener("afterprint", printClose)
			window.removeEventListener("resize", onResize)
		}
	}, [])

	if (!isUserInactive) {
		setTimeout(() => setIsUserInactive(true), 1000 * 60 * 30)
	}
	if (isUserInactive) {
		const setOffline = async () => {
			const token = localStorage.token ? localStorage.getItem("token") : sessionStorage.getItem("token")
			await setUserOffline(token)
		}
		setOffline()
	}

	if (!user.isAuth || !user.currentUser) {
		return <Redirect to="/login" />
	}

	if (user.isAuth && user.currentUser && location.pathname === "/") {
		setTimeout(() => history.push("/feed"), 1000)
	}

	return (
		<Watermark
			text={process.env.REACT_APP_WATERMARK_TEXT}
			style={{
				width: windowWidth,
				height: windowHeight,
				opacity: isPrintCLicked ? 0.5 : 0,
				fontSize: 24
			}}
			multiple
			className="watermark"
		>
			<div
				onMouseMove={async () => {
					if (isUserInactive) {
						window.location.reload()
						await setUserOnline(socket.id)
					}
					setIsUserInactive(false)
				}}
			>
				{(isShowModalVideoChat) && (
					<ModalVideoCall
						isShowModalVideoChat={isShowModalVideoChat}
						setIsShowModalVideoChat={setIsShowModalVideoChat}
						selectedConnections={selectedConnections}
						mode={modeData}
					/>
				)}
				<Header isBurgerActive={isBurgerActive} setIsBurgerActive={setIsBurgerActive} />
				<LeftPanel isBurgerActive={isBurgerActive} setIsBurgerActive={setIsBurgerActive} />
				<div className={styles.middle_container}>
					<Switch>
						<Route exact path="/family-tree" component={FamilyTree} />
						<Route exact path="/connections" component={Friends} />
						<Route path="/profile">
							<ConnectionProfileCard />
						</Route>
						<Route path="/user-profile">
							<UserProfileCard />
							<Redirect to="/user-profile/feed" />
						</Route>
						<Route path="/feed" component={Feed} />
						<Route path="/messages" component={Messages} />
						<Route path="/map" component={MapPage} />
						<Route path="/personal-info" component={PersonalInfo} />
						<Route path="/search-people" component={SearchPeoplePage} />
						<Route path="/photo" component={PhotosPage} />
						<Route path="/calendar" component={CalendarPage} />
						<Route path="/videochat" component={VideoChat} />
						<Route path="/video-gallery" component={VideosPage} />
						<Route path="/settings">
							<Settings />
							<Redirect to="/settings/privacy" />
						</Route>
						<Route path="/contact-us" component={ContactUsPage} />
						<Route path="*">
							<Redirect to="/feed" />
						</Route>
					</Switch>
				</div>
				<RightPanel />
			</div>
		</Watermark>
	)
}