import React, {
	useState, useEffect, useRef
} from "react"
import {
	Modal, message, Empty
} from "antd"
import { CloseCircleOutlined } from "@ant-design/icons"
import { baseUri } from "shared/constants"
import ReactPlayer from "react-player"
import {
	SendIcon, EmojiIcon, AllowClear, LikeIcon
} from "shared/icons"
import { EmptyPostImage } from "shared/empty-screen-images/post-empty"
import { Picker } from "emoji-mart"
import { useTranslation } from "react-i18next"
import CustomScroll from "react-scrollbars-custom"
import { v4 as uuidv4 } from "uuid"
import { MentionUser } from "shared/mentions/mention"
import { socket } from "app/socket"
import styles from "./video-modal.module.css"
import { VideoComment } from "./comment"
import { LikesList } from "./likes-list/likes-list"
import {
	commentVideo, getVideoComments, unlikeVideo, likeVideo
} from "./api"

export const VideoModal = ({
	video, setIsShowVedeoPlayer, isShowVedeoPlayer, selectVideo,
	isVideoWithComments,
	albumId,
	ownerId,
	userId
}) => {
	const videoModal = useRef()
	const [inputValue, setInputValue] = useState("")
	const [toggle, setToggle] = useState(false)
	const [visible, setVisible] = useState(false)
	const [activeInput, setActiveInput] = useState(false)
	const [videoComments, setVideoComments] = useState([])
	const [isShowLikeList, setIsShowLikeList] = useState(false)
	const [currentVideoLikes, setCurrentVideoLikes] = useState(video.likes)
	const [mentionedUsers, setMentionedUsers] = useState([])

	const addEmoji = (emoji) => {
		setInputValue(`${inputValue}${emoji}`)
	}
	const { t } = useTranslation()
	useEffect(() => {
		if (isShowVedeoPlayer && isVideoWithComments) {
			const getData = async () => {
				const data = await getVideoComments(ownerId, albumId, video.path)
				if (data.status === "successful") {
					setVideoComments(data.data.comments.reverse())
				}
			}
			getData()
		}
	}, [albumId, isShowVedeoPlayer, isVideoWithComments, ownerId, video.path])
	return (
		<>
			<Modal
				ref={videoModal.current}
				footer={null}
				className={styles.ant_modal_body}
				title={(
					<div className={styles.video_player_header}>
						<span>
							{video.name}
						</span>
						<CloseCircleOutlined onClick={() => {
							setIsShowVedeoPlayer(false)
						}}
						/>
					</div>
				)}
				visible={isShowVedeoPlayer && selectVideo === video.path}
				width="100%"
				keyboard
				onCancel={() => {
					setIsShowVedeoPlayer(false)
				}}
			>

				{
					isVideoWithComments ? (
						<div className={styles.content_wrapper}>
							<div className={styles.video_wrapper}>
								<ReactPlayer
									controls
									width="100%"
									height="100%"
									url={`${baseUri}/${video.path}`}
								/>
								{" "}
							</div>
							<div className={styles.video_comments}>
								<div className={styles.action_on_video}>
									<div style={{ display: "flex" }}>
										<button
											type="button"
											className={currentVideoLikes?.includes(userId) ? `${styles.post_main_content_action_btn} ${styles.post_main_content_action_btn_like} ${styles.liked}` : `${styles.post_main_content_action_btn} ${styles.post_main_content_action_btn_like}`}
											onClick={async () => {
												if (currentVideoLikes.includes(userId)) {
													const res = await unlikeVideo(albumId, video.path, userId, ownerId)
													setCurrentVideoLikes(res.data.likes)
												} else {
													const res = await likeVideo(albumId, video.path, userId, ownerId)
													setCurrentVideoLikes(res.data.likes)
												}
											}}
										>
											<LikeIcon />
										</button>
										<button
											type="button"
											className={styles.btns_text}
											onClick={() => setIsShowLikeList(true)}
										>
											{currentVideoLikes?.length < 10000 ? currentVideoLikes?.length
												: `${currentVideoLikes?.length.toString().slice(0, -3)}K`}
											{" "}
											<span className={styles.post_footer_labels}>{t("likes")}</span>
										</button>
									</div>
								</div>
								<div className={styles.comments_zone}>
									{!videoComments || videoComments.length === 0
										? (
											<Empty
												image={<EmptyPostImage />}
												description={<span>{t("there-are-no-comments-here-yet")}</span>}
											/>
										)
										: (
											<>
												<CustomScroll>
													{videoComments.map(comment => (
														<VideoComment
															key={comment.commentId}
															comment={comment}
															userId={userId}
															ownerId={ownerId}
															videoPath={video.path}
															albumId={albumId}
															setVideoComments={setVideoComments}
														/>

													))}
												</CustomScroll>
											</>
										)}
								</div>
								<div className={styles.video_comments_input}>
									<div className={`${styles.comment_input_field} ${activeInput && styles.active}`}>
										<MentionUser
											setMentionedUsers={setMentionedUsers}
											setVisible={setVisible}
											setToggle={setToggle}
											inputValue={inputValue}
											setInputValue={setInputValue}
										/>
										<button
											type="button"
											className={`${styles.btns}`}
											onClick={() => setToggle(!toggle)}
										>
											<EmojiIcon />
										</button>
										<button
											type="button"
											className={`${styles.btns} ${!visible ? styles.btns_none : styles.btns_visible}`}
											onClick={() => {
												setInputValue("")
												setActiveInput(false)
												setVisible(!visible)
											}}
										>
											<AllowClear />
										</button>
									</div>
									{toggle && (
										<Picker
											showPreview={false}
											useButton={false}
											title="Emoji"
											style={{
												position: "absolute", bottom: "114px", right: "42px", overflow: "hidden", maxHeight: "40vh", zIndex: 11, width: "240px", background: "#f2f2f2"
											}}
											onSelect={el => {
												setToggle(false)
												addEmoji(el.native)
											}}
										/>
									)}
									<button
										type="button"
										className={styles.confirm_button}
										onClick={async () => {
											setActiveInput(false)
											setVisible(false)
											setMentionedUsers([])
											if (inputValue.length === 0) {
												message.error(`${t("no-data-to-post")}`)
											} else {
												const commentId = `comment_${uuidv4()}`
												const mentionedUser = mentionedUsers.includes(ownerId)
												const comment = {
													commentText: inputValue, commentAuthor: userId, commentId
												}
												const response = await commentVideo(
													albumId, video.path, comment, ownerId, mentionedUser
												)
												setInputValue("")
												setVideoComments(response.data.comments.reverse())
												socket.emit("COMMENT_ALBUM", { userId: ownerId })
												if (!mentionedUser) {
													socket.emit("MENTIONED_USERS", {
														usersId: mentionedUsers,
														id: userId,
														videoAlbum: albumId,
														videoPath: video.path,
														ownerId
													})
												}
											}
										}}
									>
										<SendIcon />
									</button>
								</div>
							</div>
						</div>
					) : (
						<ReactPlayer
							controls
							width="100%"
							height="70vh"
							url={`${baseUri}/${video.path}`}
						/>
					)
				}
			</Modal>
			{isShowLikeList
				&& currentVideoLikes
				&& currentVideoLikes.length > 0
				&& (
					<LikesList
						isShowLikeList={isShowLikeList}
						setIsShowLikeList={setIsShowLikeList}
						file={video}
						fileAuthor={ownerId}
					/>
				)}
		</>
	)
}
