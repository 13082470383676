import React, { useState, useEffect } from "react"
import { store } from "app/redux/store"
import {
	setUserInTree, setPeople, setDragMode, setDefaultState
} from "modules/family-tree/ducks"
import { autoLogin } from "app/api"
import {
	ComplexHierarchicalTree,
	DiagramComponent,
	Inject,
	SnapConstraints,
	LineDistribution,
	ChildArrangement,
	DiagramConstraints,
	LineRouting,
	SelectorConstraints,
	DiagramTools
} from "@syncfusion/ej2-react-diagrams"
import { useLocation } from "react-router-dom"
import { Result } from "antd"
import { useDispatch, useSelector } from "react-redux"
import { getNodes, getConnectors } from "modules/family-tree/utils"
import { useTranslation } from "react-i18next"
import { AddPersonMobile } from "modules/family-tree/components/mobile-diagram/add-person-mob-card"
import { PersonMobile } from "modules/family-tree/components/mobile-diagram/person-mob-card"
import { MobileEditModal } from "modules/family-tree/components/mobile-diagram/modal-window"
import { AppSpinner } from "shared/app-spinner/app-spinner"
import styles from "./mobile-diagram.module.css"

let first = true
export const MobileDiagram = () => {
	const {
		people, dragMode, treeDirection
	} = useSelector(s => s.familyTree)

	const [loadingError, setLoadingError] = useState(false)
	const [isLoad, setIsLoad] = useState(false)
	const useQuery = () => new URLSearchParams(useLocation().search)
	const query = useQuery()
	const incomeId = query.get("user")
	const lang = query.get("lang")

	const dispatch = useDispatch()

	const { i18n } = useTranslation()

	const token = Buffer.from(incomeId, "base64").toString("ascii")

	useEffect(() => {
		const getData = async () => {
			if (token) {
				const loadedData = await autoLogin(token, true)
				i18n.changeLanguage(lang)
				const { people } = store.getState().familyTree
				const userInTree = await people.find(p => p.isUser)
				if (!loadedData || !loadedData.userTree) {
					setLoadingError(true)
					return null
				}
				if (loadedData.userTree) {
					if (loadedData.userTree.length > 0) {
						const persons = loadedData.userTree
						store.dispatch(setPeople(persons))
						store.dispatch(setUserInTree({
							...userInTree,
							name: !loadedData.firstName && !loadedData.lastName ? "User" : `${loadedData.firstName} ${loadedData.lastName}`,
							from: !loadedData.birthPlace ? "" : `${loadedData.birthPlace}`,
							livesIn: !loadedData.location?.name ? "" : `${loadedData.location.name}`,
							birthDate: !loadedData.birthDate ? "" : `${loadedData.birthDate}`,
							avatar: !loadedData.avatar ? "" : `${loadedData.avatar}`
						}))
					}
					if (loadedData.userTree.length === 0) {
						store.dispatch(setDefaultState())
						setIsLoad(true)
						store.dispatch(setUserInTree({
							...userInTree,
							name: !loadedData.firstName && !loadedData.lastName ? "User" : `${loadedData.firstName} ${loadedData.lastName}`,
							from: !loadedData.birthPlace ? "" : `${loadedData.birthPlace}`,
							livesIn: !loadedData.location.name ? "" : `${loadedData.location.name}`,
							birthDate: !loadedData.birthDate ? "" : `${loadedData.birthDate}`,
							avatar: !loadedData.avatar ? "" : `${loadedData.avatar}`,
							profileId: loadedData._id,
							gender: loadedData.gender ? loadedData.gender : "unknown"
						}))
					}
				}
			}
			setIsLoad(true)
			if (!loadingError) {
				window.diagramInstance.reset()
				window.diagramInstance.scrollSettings.minZoom = 0.01
				window.diagramInstance.fitToPage()
				window.diagramInstance.zoom(1)
			}
		}
		getData()
	}, [i18n, loadingError, token])

	if (!isLoad) {
		return (
			<AppSpinner />
		)
	}

	if (loadingError) {
		return (
			<Result
				status="warning"
				title="Something went wrong"
				style={{
					width: "100vw", height: "100vh", padding: 0, display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center"
				}}
			/>
		)
	}

	const getTemplates = (props) => {
		props.addInfo.token = token
		if (props.addInfo.gender) {
			return <PersonMobile {...props} />
		}
		return <AddPersonMobile {...props} />
	}

	return (
		<div className={styles.wrap}>
			<div className={styles.diagram}>
				<DiagramComponent
					id="diagram"
					style={{ outline: 0 }}
					ref={(diagram) => {
						window.diagramInstance = diagram
						if (window.diagramInstance) {
							if (first) {
								first = false
								diagram.fitToPage()
								window.diagramInstance.zoomTo({ type: "ZoomOut", zoomFactor: 0.65 })
							}
							diagram.doLayout()
						}
					}}
					onMouseDown={() => {
						dispatch(setDragMode(DiagramTools.None))
					}}
					onMouseUp={() => {
						dispatch(setDragMode(DiagramTools.ZoomPan))
					}}
					constraints={DiagramConstraints.Default}
					tool={dragMode}
					nodes={getNodes(people)}
					nodeTemplate={getTemplates}
					connectors={getConnectors(people)}
					getConnectorDefaults={(connector) => {
						connector.style = {
							strokeColor: "#e0e0e0",
							strokeWidth: 5
						}
						connector.targetDecorator.shape = "None"
						connector.type = "Orthogonal"
						connector.cornerRadius = 3
						return connector
					}}
					layout={{
						type: "ComplexHierarchicalTree",
						orientation: treeDirection,
						arrangement: ChildArrangement.Linear,
						margin: {
							left: 5, top: 0, right: 5, bottom: 0
						},
						bounds: {
							height: 100, width: 90, x: 0, y: 0
						},
						verticalSpacing: 50,
						horizontalSpacing: 0,
						horizontalAlignment: "Center",
						verticalAlignment: "Bottom",
						root: "person11"
					}}
					selectedItems={{
						constraints: SelectorConstraints.None
					}}
					snapSettings={{ constraints: SnapConstraints.None }}
				>
					<Inject services={[ComplexHierarchicalTree, LineDistribution, LineRouting]} />
				</DiagramComponent>
			</div>
			<MobileEditModal token={token} />
		</div>
	)
}