import React from "react"
import { NavLink } from "react-router-dom"
import { useSelector } from "react-redux"
import { useTranslation } from "react-i18next"
import { linksPaths } from "../data"
import styles from "../settings.module.css"

export const SettingsHeader = () => {
	const { t } = useTranslation()
	const { user } = useSelector(s => s)
	const { currentUser } = user

	return (
		<div className={styles.header_container}>
			<ul className={styles.header}>
				{linksPaths.map(l => {
					if (l.label === "security" && currentUser.appleId) {
						return null
					}
					return (
						<li key={l.path}>
							<NavLink
								activeClassName={styles.header_element_active}
								className={styles.header_element_inner}
								to={l.path}
							>
								{t(l.label)}
							</NavLink>
						</li>
					)
				})}
			</ul>
		</div>

	)
}