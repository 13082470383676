import React from "react"

export const EmptyConnectionsImage = () => (
	<svg width="169" height="140" viewBox="0 0 169 140" fill="none">
		<path d="M84.0567 133C120.48 133 150.008 103.284 150.008 66.5C150.008 29.7155 120.48 0 84.0567 0C47.634 0 18.1055 29.7155 18.1055 66.5C18.1055 103.284 47.634 133 84.0567 133Z" fill="#EAEEF9" />
		<path d="M157.553 39.1407C160.499 39.1407 162.886 36.7378 162.886 33.7736C162.886 30.8094 160.499 28.4065 157.553 28.4065C154.608 28.4065 152.22 30.8094 152.22 33.7736C152.22 36.7378 154.608 39.1407 157.553 39.1407Z" fill="#EAEEF9" />
		<path d="M165.358 18.0648C167.37 18.0648 169 16.4238 169 14.3995C169 12.3752 167.37 10.7341 165.358 10.7341C163.347 10.7341 161.716 12.3752 161.716 14.3995C161.716 16.4238 163.347 18.0648 165.358 18.0648Z" fill="#EAEEF9" />
		<path d="M13.5529 42.9369C15.5645 42.9369 17.1952 41.2959 17.1952 39.2716C17.1952 37.2472 15.5645 35.6062 13.5529 35.6062C11.5413 35.6062 9.91064 37.2472 9.91064 39.2716C9.91064 41.2959 11.5413 42.9369 13.5529 42.9369Z" fill="#EAEEF9" />
		<path d="M20.3175 122.659C24.0532 122.659 27.0817 119.611 27.0817 115.852C27.0817 112.092 24.0532 109.044 20.3175 109.044C16.5817 109.044 13.5532 112.092 13.5532 115.852C13.5532 119.611 16.5817 122.659 20.3175 122.659Z" fill="#EAEEF9" />
		<g filter="url(#filter0_d)">
			<path d="M145.196 39.9261H32.415C29.9434 39.9261 27.9922 37.9625 27.9922 35.4753V9.94871C27.9922 7.46151 29.9434 5.49792 32.415 5.49792H145.065C147.537 5.49792 149.488 7.46151 149.488 9.94871V35.4753C149.618 37.9625 147.537 39.9261 145.196 39.9261Z" fill="white" />
		</g>
		<path d="M55.3091 22.6465C55.3091 25.5264 54.0083 28.1446 52.057 29.9772C50.366 31.5481 48.0245 32.4644 45.553 32.4644C43.0814 32.4644 40.74 31.5481 39.0489 29.9772C36.9676 28.1446 35.7969 25.5264 35.7969 22.6465C35.7969 17.2794 40.2196 12.8286 45.553 12.8286C50.8863 12.8286 55.3091 17.2794 55.3091 22.6465Z" fill="#DCE1EE" />
		<path d="M52.057 29.9773C50.3659 31.5482 48.0244 32.4645 45.5529 32.4645C43.0814 32.4645 40.7399 31.5482 39.0488 29.9773C39.8293 29.7155 40.7399 29.3228 41.9106 28.7992C42.1708 28.6683 42.4309 28.4065 42.561 28.1446C42.6911 28.0137 42.6911 27.7519 42.6911 27.621V25.1338L42.561 25.0029C42.3009 24.7411 42.1708 24.3484 42.1708 23.9557L41.9106 23.8248C41.2602 23.9557 41.3903 23.3011 41.1301 21.9921C41.1301 21.4685 41.1301 21.3376 41.3903 21.2067L41.6505 20.9448C40.4797 18.1958 41 16.4941 42.3009 15.8395C41.9106 14.9232 41.9106 14.6614 41.9106 14.6614C41.9106 14.6614 44.5122 15.0541 45.2927 14.9232C46.4635 14.6614 48.1545 14.9232 48.8049 16.4941C49.8456 16.8868 50.2358 17.5413 50.3659 18.3267C50.496 19.5049 49.8456 20.8139 49.7155 21.2067C49.8456 21.3376 49.9757 21.4685 49.8456 21.8612C49.7155 23.0393 49.7155 23.8248 49.0651 23.6939L48.5448 24.872C48.5448 25.0029 48.5448 25.0029 48.5448 25.1338C48.5448 25.2647 48.5448 25.6574 48.5448 27.7519C48.5448 28.0137 48.6749 28.2756 48.6749 28.4065C48.8049 28.6683 49.0651 28.7992 49.1952 28.9301C50.3659 29.3228 51.2765 29.7155 52.057 29.9773Z" fill="white" />
		<path d="M49.7503 21.3376C49.7503 21.0651 49.6149 20.6563 49.4795 20.3838V20.2476C48.938 19.1576 47.8548 18.7488 46.7717 18.7488C44.0639 18.6126 43.7931 19.1576 42.8454 18.3401C43.1161 18.7488 43.1161 19.4301 42.71 20.2476C42.4392 20.7926 41.8976 21.0651 41.3561 21.2013C40.1375 18.3401 40.6791 16.5689 42.033 15.8876C41.6268 14.9339 41.6268 14.6614 41.6268 14.6614C41.6268 14.6614 44.3347 15.0701 45.147 14.9339C46.3655 14.6614 48.1256 14.9339 48.8026 16.5689C49.8857 16.9776 50.2919 17.6589 50.4273 18.4763C50.5627 19.4301 49.8857 20.7926 49.7503 21.3376Z" fill="#A2ABBC" />
		<path d="M48.6749 28.4065V28.9301H42.561V28.2756C42.6911 28.1447 42.6911 27.8829 42.6911 27.7519V25.2647L42.561 25.1338V25.0029C42.6911 25.2647 42.8212 25.3956 43.0814 25.6575L45.0326 26.9665C45.4228 27.3592 46.0732 27.3592 46.5936 26.9665L48.4147 25.3956L48.5448 25.2647C48.5448 25.3956 48.5448 25.7884 48.5448 27.8828C48.4147 28.0138 48.5448 28.1447 48.6749 28.4065Z" fill="url(#paint0_linear)" />
		<path d="M87.3097 19.7667H62.3341C61.6837 19.7667 61.2935 19.243 61.2935 18.7194V15.4468C61.2935 14.7923 61.8138 14.3995 62.3341 14.3995H87.3097C87.9601 14.3995 88.3504 14.9232 88.3504 15.4468V18.7194C88.3504 19.3739 87.9601 19.7667 87.3097 19.7667Z" fill="#CED6E2" />
		<path d="M116.968 30.8936H62.3341C61.6837 30.8936 61.2935 30.37 61.2935 29.8464V26.5737C61.2935 25.9192 61.8138 25.5265 62.3341 25.5265H116.968C117.619 25.5265 118.009 26.0501 118.009 26.5737V29.8464C118.009 30.5009 117.489 30.8936 116.968 30.8936Z" fill="#CED6E2" />
		<g filter="url(#filter1_d)">
			<path d="M145.196 125.8H32.415C29.9434 125.8 27.9922 123.837 27.9922 121.349V95.8229C27.9922 93.3356 29.9434 91.3721 32.415 91.3721H145.065C147.537 91.3721 149.488 93.3356 149.488 95.8229V121.349C149.618 123.837 147.537 125.8 145.196 125.8Z" fill="white" />
		</g>
		<path d="M55.3091 108.521C55.3091 111.401 54.0083 114.019 52.057 115.852C50.366 117.422 48.0245 118.339 45.553 118.339C43.0814 118.339 40.74 117.422 39.0489 115.852C36.9676 114.019 35.7969 111.401 35.7969 108.521C35.7969 103.154 40.2196 98.7029 45.553 98.7029C50.8863 98.7029 55.3091 103.154 55.3091 108.521Z" fill="#DCE1EE" />
		<path d="M52.057 115.851C50.3659 117.422 48.0244 118.339 45.5529 118.339C43.0814 118.339 40.7399 117.422 39.0488 115.851C39.8293 115.59 40.7399 115.197 41.9106 114.673C42.1708 114.542 42.4309 114.281 42.561 114.019C42.6911 113.888 42.6911 113.626 42.6911 113.495V111.008L42.561 110.877C42.3009 110.615 42.1708 110.223 42.1708 109.83L41.9106 109.699C41.2602 109.83 41.3903 109.175 41.1301 107.866C41.1301 107.343 41.1301 107.212 41.3903 107.081L41.6505 106.819C40.4797 104.07 41 102.368 42.3009 101.714C41.9106 100.797 41.9106 100.536 41.9106 100.536C41.9106 100.536 44.5122 100.928 45.2927 100.797C46.4635 100.536 48.1545 100.797 48.8049 102.368C49.8456 102.761 50.2358 103.415 50.3659 104.201C50.496 105.379 49.8456 106.688 49.7155 107.081C49.8456 107.212 49.9757 107.343 49.8456 107.735C49.7155 108.913 49.7155 109.699 49.0651 109.568L48.5448 110.615C48.5448 110.746 48.5448 110.746 48.5448 110.877C48.5448 111.008 48.5448 111.401 48.5448 113.495C48.5448 113.757 48.6749 114.019 48.6749 114.15C48.8049 114.411 49.0651 114.542 49.1952 114.673C50.3659 115.328 51.2765 115.59 52.057 115.851Z" fill="white" />
		<path d="M49.7503 107.212C49.7503 106.939 49.6149 106.53 49.4795 106.258V106.122C48.938 105.032 47.8548 104.623 46.7717 104.623C44.0639 104.487 43.7931 105.032 42.8454 104.214C43.1161 104.623 43.1161 105.304 42.71 106.122C42.4392 106.667 41.8976 106.939 41.3561 107.075C40.1375 104.214 40.6791 102.443 42.033 101.762C41.6268 100.808 41.6268 100.536 41.6268 100.536C41.6268 100.536 44.3347 100.944 45.147 100.808C46.3655 100.536 48.1256 100.808 48.8026 102.443C49.8857 102.852 50.2919 103.533 50.4273 104.35C50.5627 105.44 49.8857 106.667 49.7503 107.212Z" fill="#A2ABBC" />
		<path d="M48.6749 114.281V114.804H42.561V114.15C42.6911 114.019 42.6911 113.757 42.6911 113.626V111.139L42.561 111.008V110.877C42.6911 111.139 42.8212 111.27 43.0814 111.531L45.0326 112.841C45.4228 113.233 46.0732 113.233 46.5936 112.841L48.4147 111.27L48.5448 111.139C48.5448 111.27 48.5448 111.662 48.5448 113.757C48.4147 113.888 48.5448 114.15 48.6749 114.281Z" fill="url(#paint1_linear)" />
		<path d="M87.3097 105.772H62.3341C61.6837 105.772 61.2935 105.248 61.2935 104.724V101.452C61.2935 100.797 61.8138 100.405 62.3341 100.405H87.3097C87.9601 100.405 88.3504 100.928 88.3504 101.452V104.724C88.3504 105.248 87.9601 105.772 87.3097 105.772Z" fill="#CED6E2" />
		<path d="M116.968 116.899H62.3341C61.6837 116.899 61.2935 116.375 61.2935 115.851V112.579C61.2935 111.924 61.8138 111.531 62.3341 111.531H116.968C117.619 111.531 118.009 112.055 118.009 112.579V115.851C118.009 116.375 117.489 116.899 116.968 116.899Z" fill="#CED6E2" />
		<g filter="url(#filter2_d)">
			<path d="M126.073 82.8632H13.4228C10.9512 82.8632 9 80.8996 9 78.4124V52.8858C9 50.3986 10.9512 48.4351 13.4228 48.4351H126.073C128.545 48.4351 130.496 50.3986 130.496 52.8858V78.4124C130.626 80.8996 128.545 82.8632 126.073 82.8632Z" fill="white" />
		</g>
		<path d="M68.318 62.7038H43.3424C42.692 62.7038 42.3018 62.1802 42.3018 61.6566V58.3839C42.3018 57.7294 42.8221 57.3367 43.3424 57.3367H68.318C68.9684 57.3367 69.3587 57.8603 69.3587 58.3839V61.6566C69.3587 62.3111 68.9684 62.7038 68.318 62.7038Z" fill="#CED6E2" />
		<path d="M97.9756 73.9616H43.3414C42.691 73.9616 42.3008 73.438 42.3008 72.9144V69.6417C42.3008 68.9872 42.8211 68.5945 43.3414 68.5945H97.8455C98.496 68.5945 98.8862 69.1181 98.8862 69.6417V72.9144C99.0163 73.438 98.496 73.9616 97.9756 73.9616Z" fill="#CED6E2" />
		<path d="M36.447 65.5838C36.447 68.3328 35.2762 70.82 33.4551 72.6527C33.325 72.7836 33.325 72.7836 33.1949 72.9145C31.5039 74.4854 29.1624 75.4017 26.6909 75.4017C24.2193 75.4017 22.0079 74.4854 20.3169 73.0454C20.3169 73.0454 20.3169 73.0454 20.1868 73.0454C18.1055 71.2127 16.8047 68.5946 16.8047 65.5838C16.8047 60.2167 21.2275 55.7659 26.5608 55.7659C32.0242 55.7659 36.447 60.2167 36.447 65.5838Z" fill="#DCE1EE" />
		<path d="M33.4172 72.852C33.0214 71.9227 32.3619 71.1262 31.3065 70.5952C30.7789 70.3297 30.3831 70.197 29.7236 70.197H29.3278C28.4044 70.197 28.2725 70.0642 28.2725 70.0642V68.4712C29.3278 67.6747 30.1193 66.6127 30.3831 65.4179C31.0427 65.2852 31.4385 64.7542 31.3065 64.0904C31.3065 63.8249 31.0427 63.5594 31.0427 63.2939C31.0427 63.1612 31.0427 63.0284 31.0427 62.8957C31.0427 62.7629 31.0427 62.7629 31.0427 62.6302C31.0427 62.4974 31.0427 62.4974 31.0427 62.3647C31.0427 61.8337 30.9108 61.4354 30.5151 60.9044C29.7236 59.4441 28.1406 58.5149 26.4257 58.5149C26.0299 58.5149 25.7661 58.5149 25.3704 58.6476C24.7108 58.7804 24.1831 59.0459 23.6555 59.4442C23.5235 59.5769 23.5235 59.5769 23.3916 59.7096C23.3916 59.7096 23.3916 59.7097 23.2597 59.8424C22.7321 60.3734 22.3363 61.0372 22.0725 61.7009C21.8086 62.3647 21.8086 63.1612 21.8086 63.9577C21.8086 63.9577 21.8086 63.9577 21.8086 64.0904C21.8086 64.2232 21.8086 64.2232 21.8086 64.3559C21.8086 64.3559 21.8086 64.4887 21.6767 64.4887C21.9406 64.2232 21.8086 64.6214 21.9406 64.8869C22.2044 65.5507 22.6001 65.4179 23.1278 65.8162C23.1278 65.8162 23.1278 65.8162 22.9959 65.8162L22.4682 65.9489C19.5661 66.8782 19.3023 69.9315 22.2044 70.5952C21.9406 70.728 21.8086 70.8607 21.6767 70.8607C20.8852 71.5245 20.3576 72.321 20.0938 73.1175C21.8086 74.5778 24.0512 75.507 26.5576 75.507C29.064 75.507 31.4385 74.5778 33.1534 72.9847C33.2853 73.1175 33.4172 72.9848 33.4172 72.852C33.5491 72.852 33.5491 72.852 33.4172 72.852ZM25.2384 69.666C25.1065 69.5332 24.9746 69.4005 24.9746 69.4005C24.8427 69.2677 24.7108 69.2677 24.5789 69.135C24.5789 69.135 24.447 69.135 24.447 69.0022C24.0512 68.7367 23.6555 68.4712 23.5235 68.073C23.5235 67.9402 23.6555 67.6747 23.9193 67.4092C24.0512 67.542 24.1831 67.6747 24.315 67.9402C24.447 68.073 24.5789 68.2057 24.7108 68.3385C24.8427 68.3385 24.9746 68.4712 24.9746 68.604C24.9746 68.604 24.9746 68.604 25.1065 68.604C25.1065 68.604 25.1065 68.604 25.2384 68.604V69.666Z" fill="white" />
		<path d="M29.4876 70.1211C29.3576 70.6447 29.0974 71.0374 29.0974 71.0374L24.1543 70.252L24.4145 68.9429C24.4145 68.9429 24.5445 68.9429 24.5445 69.0738C24.6746 69.2047 24.8047 69.2047 24.9348 69.3357C25.0649 69.3357 25.0649 69.4666 25.1949 69.5975V68.6811C25.1949 68.6811 25.1949 68.6811 25.0649 68.6811C25.8454 69.0738 26.886 69.3357 28.3169 68.4193V69.9902C28.447 69.9902 28.5771 70.1211 29.4876 70.1211Z" fill="url(#paint2_linear)" />
		<path d="M28.4596 62.8197C29.3876 62.8197 30.3156 62.6863 31.1111 62.4195C31.1111 62.4195 31.1111 62.2861 31.2436 62.1527C31.1111 61.7524 30.9785 61.2188 30.7133 60.6851C29.9179 59.2176 28.327 58.2837 26.6036 58.2837C26.2059 58.2837 25.8244 58.3122 25.2924 58.4456C24.5845 58.6634 24.2559 58.8547 24.0882 58.957C24.0882 58.957 23.7007 59.2192 23.4219 59.4844C23.143 59.7496 22.4938 60.6851 22.2287 61.3522C21.9636 62.1527 21.7496 62.833 21.8822 63.6335C22.0148 63.6335 22.2287 63.4868 22.3613 63.4868C23.4219 62.9531 24.2173 62.2861 24.615 61.2188C25.6756 62.1527 27.0013 62.6863 28.4596 62.8197Z" fill="#A2ABBC" />
		<path d="M23.9595 67.5474C24.0896 67.678 24.2196 67.8086 24.3497 67.9391C24.2196 67.8086 24.0896 67.678 23.9595 67.5474Z" fill="#3973F4" />
		<path d="M25.7808 72.391C25.3905 72.9146 24.7401 72.9146 24.0897 72.9146C24.7401 72.2601 24.3499 70.2965 22.2686 70.5583C19.2767 70.0347 19.6669 66.893 22.5287 65.9766L23.0491 65.8457C23.0491 65.8457 23.0491 65.8457 23.1791 65.9766C23.4393 66.6311 23.6995 67.0239 24.0897 67.5475C22.919 68.5947 24.61 68.8565 25.3905 69.642C25.9109 69.9038 26.4312 71.6055 25.7808 72.391Z" fill="#A2ABBC" />
		<defs>
			<filter id="filter0_d" x="18.9922" y="1.49792" width="139.502" height="52.4282" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
				<feFlood floodOpacity="0" result="BackgroundImageFix" />
				<feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" />
				<feOffset dy="5" />
				<feGaussianBlur stdDeviation="4.5" />
				<feColorMatrix type="matrix" values="0 0 0 0 0.397708 0 0 0 0 0.47749 0 0 0 0 0.575 0 0 0 0.17 0" />
				<feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
				<feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
			</filter>
			<filter id="filter1_d" x="18.9922" y="87.3721" width="139.502" height="52.4281" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
				<feFlood floodOpacity="0" result="BackgroundImageFix" />
				<feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" />
				<feOffset dy="5" />
				<feGaussianBlur stdDeviation="4.5" />
				<feColorMatrix type="matrix" values="0 0 0 0 0.397708 0 0 0 0 0.47749 0 0 0 0 0.575 0 0 0 0.17 0" />
				<feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
				<feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
			</filter>
			<filter id="filter2_d" x="0" y="44.4351" width="139.502" height="52.4282" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
				<feFlood floodOpacity="0" result="BackgroundImageFix" />
				<feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" />
				<feOffset dy="5" />
				<feGaussianBlur stdDeviation="4.5" />
				<feColorMatrix type="matrix" values="0 0 0 0 0.397708 0 0 0 0 0.47749 0 0 0 0 0.575 0 0 0 0.17 0" />
				<feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
				<feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
			</filter>
			<linearGradient id="paint0_linear" x1="45.6198" y1="28.954" x2="45.6198" y2="26.7905" gradientUnits="userSpaceOnUse">
				<stop stopColor="white" />
				<stop offset="1" stopColor="#E2E5EC" />
			</linearGradient>
			<linearGradient id="paint1_linear" x1="45.6198" y1="114.828" x2="45.6198" y2="112.665" gradientUnits="userSpaceOnUse">
				<stop stopColor="white" />
				<stop offset="1" stopColor="#E2E5EC" />
			</linearGradient>
			<linearGradient id="paint2_linear" x1="26.8226" y1="71.0534" x2="26.8226" y2="69.6111" gradientUnits="userSpaceOnUse">
				<stop stopColor="white" />
				<stop offset="1" stopColor="#E2E5EC" />
			</linearGradient>
		</defs>
	</svg>
)