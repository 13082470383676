import React, { useState, useEffect } from "react"
import { v4 as uuidv4 } from "uuid"
import { useSelector } from "react-redux"
import { EmptyConnectionsImage } from "shared/empty-screen-images"
import { useTranslation } from "react-i18next"
import { getProfileConnections } from "./api"
import { ProfileConnectionCard } from "./profile-connections-card"
import styles from "./profile-connections-content.module.css"

export const ProfileConnectionsContent = ({ profile }) => {
	const { user } = useSelector(s => s)
	const { currentUser } = user
	const { t } = useTranslation()
	const [friendsOrFamilySelected, setFriendsOrFamilySelected] = useState("family")
	const [userConnections, setUserConnections] = useState([])

	useEffect(() => {
		const getData = async () => {
			if (profile.privacySettings.friendList !== "only-me") {
				if (profile.privacySettings.friendList === "public") {
					const result = await getProfileConnections(profile._id)
					setUserConnections([...result.result.family, ...result.result.friends])
				}
				if (profile.privacySettings.friendList === "relatives-only" && profile.family.includes(currentUser._id)) {
					const result = await getProfileConnections(profile._id)
					setUserConnections([...result.result.family, ...result.result.friends])
				}
				if (profile.privacySettings.friendList === "friends-only" && profile.friends.includes(currentUser._id)) {
					const result = await getProfileConnections(profile._id)
					setUserConnections([...result.result.family, ...result.result.friends])
				}
				if (profile.privacySettings.friendList === "friends-and-relatives" && (profile.friends.includes(currentUser._id) || profile.family.includes(currentUser._id))) {
					const result = await getProfileConnections(profile._id)
					setUserConnections([...result.result.family, ...result.result.friends])
				}
			}
		}
		getData()
	}, [currentUser._id, profile])
	const friendsContent = () => {
		if (friendsOrFamilySelected === "family") {
			if (profile.family.length === 0) {
				return (
					<div className={styles.content_e}>
						<div>
							<EmptyConnectionsImage />
						</div>
						<div>
							{t("looks-like")}
							{" "}
							{profile.firstName}
							{" "}
							{profile.lastName}
							{" "}
							{t("haven't-added-any-relatives-yet")}
						</div>
					</div>
				)
			}
			return (
				<div className={styles.content}>
					{
						profile.family.map(parent => {
							const user = userConnections.find(p => p._id === parent)
							if (!user) {
								return null
							}
							return (
								<ProfileConnectionCard
									key={uuidv4()}
									user={user}
								/>
							)
						})
					}
				</div>
			)
		}
		if (friendsOrFamilySelected === "friends") {
			if (profile.friends.length === 0) {
				return (
					<div className={styles.content_e}>
						<div>
							<EmptyConnectionsImage />
						</div>
						<div>
							{t("looks-like")}
							{" "}
							{profile.firstName}
							{" "}
							{profile.lastName}
							{" "}
							{t("haven't-added-any-friends-yet")}
						</div>
					</div>
				)
			}
			return (
				<div className={styles.content}>
					{profile.friends.map(friend => {
						const user = userConnections.find(p => p._id === friend)
						if (!user) {
							return null
						}
						return (
							<ProfileConnectionCard
								key={uuidv4()}
								user={user}
							/>
						)
					})}
				</div>
			)
		}

		return null
	}

	return (

		<div className={styles.wrap}>
			<div className={styles.container}>
				<div className={styles.results_header}>
					<button
						type="button"
						className={friendsOrFamilySelected === "family" ? `${styles.header_button} ${styles.button_selected}` : styles.header_button}
						onClick={() => {
							setFriendsOrFamilySelected("family")
						}}
					>
						{t("family")}
						<div>{profile.family.length}</div>
					</button>
					<button
						type="button"
						className={friendsOrFamilySelected === "friends" ? `${styles.header_button} ${styles.button_selected}` : styles.header_button}
						onClick={() => {
							setFriendsOrFamilySelected("friends")
						}}
					>
						{t("friends")}
						<div>{profile.friends.length}</div>
					</button>
				</div>
				{friendsContent()}
			</div>
		</div>

	)
}