/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useState, useEffect } from "react"
import { v4 as uuidv4 } from "uuid"
import { LeftCircleOutlined } from "@ant-design/icons"
import { useSelector } from "react-redux"
import { getImageUri } from "shared/utils"
import { getProfilePhotos } from "modules/connection-profile-card/components/profile-photo/api"
import { AddPhotoImage } from "shared/empty-screen-images"
import { FullScreenImage } from "shared/full-screen-image"
import { AppSpinner } from "shared/app-spinner/app-spinner"
import styles from "./connection-profile-album.module.css"

export const ProfileAlbum = ({ albumId, curProfileId, setCurId }) => {
	const { user } = useSelector(s => s)
	const { currentUser } = user

	const [responseDate, setResponseDate] = useState([])
	const [albumName, setAlbumName] = useState(null)
	const [isLoading, setIsLoading] = useState(false)
	const [isActive, setIsActive] = useState(false)
	const [selectedPhoto, setSelectedPhoto] = useState(null)
	const [allPhotos, setAllPhotos] = useState([])
	const [isChangePhoto, setIsChangePhoto] = useState(false)

	useEffect(() => {
		const get = async () => {
			setIsLoading(true)
			const res = await getProfilePhotos(albumId, curProfileId)
			setAlbumName(res.data.name)
			setResponseDate(res.data.user)
			setIsLoading(false)
			return res
		}
		get()
	}, [albumId, curProfileId, isChangePhoto])

	return (
		<>
			<div
				className={styles.header}
			>
				<div
					style={{ paddingLeft: "24px" }}
				>
					<LeftCircleOutlined
						style={{ cursor: "pointer" }}
						onClick={() => {
							setCurId(null)
						}}
					/>
					<span style={{ paddingLeft: "24px" }}>{albumName}</span>
				</div>
			</div>

			<div className={styles.main_content_container}>
				{!isLoading ? (
					<>
						{responseDate.length === 0
							&& (
								<div className={styles.main_no_albums}>
									<AddPhotoImage />
								</div>
							)}
						{responseDate?.length > 0 && responseDate.map(photo => (
							<div key={uuidv4()} className={styles.photo_container}>
								<div className={styles.image_content}>
									<div
										style={{
											position: "absolute",
											top: "0",
											left: "0",
											width: "100%",
											height: "100%",
											border: "1px solid #EAEEF9",
											borderRadius: "8px",
											display: "flex",
											alignItems: "center",
											justifyContent: "center",
											cursor: "pointer",
											backgroundImage: `url(${getImageUri(photo.path)})`,
											backgroundPosition: "center center",
											backgroundSize: "cover"
										}}
										onClick={() => {
											setIsActive(true)
											setSelectedPhoto(getImageUri(photo.path))
											setAllPhotos(responseDate.map(photo => (photo.path)))
										}}
									/>
								</div>
							</div>
						))}
					</>
				) : (
					<div
						style={{
							zIndex: "8", position: "fixed", top: "0", left: "0", right: "0", bottom: "0", backgroundColor: "#F2F2F2", width: "100vw", height: "100vh", display: "flex", alignItems: "center", justifyContent: "center"
						}}
					>
						<AppSpinner />
					</div>
				)}
			</div>
			<FullScreenImage
				isActive={isActive}
				selectedPhoto={selectedPhoto}
				allPhotos={allPhotos}
				setIsActive={setIsActive}
				setSelectedPhoto={setSelectedPhoto}
				isPhotoWithComments
				albumId={albumId}
				userId={currentUser._id}
				ownerId={curProfileId}
				photoFiles={responseDate}
				setIsChangePhoto={setIsChangePhoto}
			/>
		</>
	)
}