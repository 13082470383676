import { v4 as uuidv4 } from "uuid"
import { store } from "app/redux/store"
import { addLeftBranchUncle } from "modules/family-tree/ducks"

export const addLeftBranchUncleNode = (firstParentId, chosedParentId) => {
  store.dispatch(addLeftBranchUncle({
    name: "Add child",
    lastAdded: true,
    positionId: `person${uuidv4()}`,
    from: "",
    livesIn: "",
    birthDate: "",
    deathDate: "",
    gender: "",
    parents: [firstParentId, chosedParentId],
    description: "",
    avatar: "",
    profileId: ""
  }, chosedParentId))
}