export const SET_USER = "SET_USER"
export const USER_LOG_OUT = "USER_LOG_OUT"
export const UPDATE_USER = "UPDATE_USER"

export const setUser = (user) => ({
  type: SET_USER,
  user
})

export const userLogOut = () => ({
  type: USER_LOG_OUT
})

export const updateUser = value => ({
  type: UPDATE_USER,
  value
})

const initialState = {
  currentUser: {},
  isAuth: false
}

export const user = (state = initialState, action) => {
  switch (action.type) {
    case SET_USER:
      return {
        ...state,
        currentUser: action.user,
        isAuth: true
      }
    case USER_LOG_OUT:
      return {
        ...state,
        currentUser: {},
        isAuth: false
      }
    case UPDATE_USER:
      return {
        ...state,
        currentUser: action.value
      }
    default:
      return state
  }
}
