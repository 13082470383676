/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useRef } from "react"
import { useSelector } from "react-redux"
import { Route, Switch } from "react-router-dom"
import CustomScroll from "react-scrollbars-custom"
import { BackToTopButton } from "shared/back-to-top-btn"
import { getUserPostsLength } from "modules/connection-profile-card/api"
import { getPosts } from "shared/feed-components/api"
import { UserProfileHeaderCard } from "./components/header-card"
import { UserPersonalFeed } from "./components/feed"
import { UserPersonalConnections } from "./components/connections"

import styles from "./user-profile-card.module.css"

export const UserProfileCard = () => {
	const { user } = useSelector(s => s)
	const { currentUser } = user

	const [posts, setPosts] = useState([])
	const [postsLength, setPostsLength] = useState(0)
	const [scrollPosition, setScrollPosition] = useState(false)
	const [page, setPage] = useState(1)
	const [totalPages, setTotalPages] = useState(1)

	useEffect(() => {
		const getData = async () => {
			if (page === 1) {
				const postsLengthData = await getUserPostsLength(currentUser._id)
				setPostsLength(postsLengthData.postsLength)
				const data = await getPosts({ userId: currentUser._id, page })
				setPosts(data.posts)
				setTotalPages(data.totalPages)
			}
			if (page > 1 && page <= totalPages) {
				const data = await getPosts({ userId: currentUser._id, page })
				setPosts([...posts, ...data.posts])
			}
		}
		getData()
	}, [currentUser._id, page])

	const scrollbar = useRef()

	return (
		<div className={styles.wrap}>
			<CustomScroll
				ref={scrollbar}
				onScroll={(values) => {
					if ((values.scrollHeight - values.scrollTop) === values.clientHeight) {
						if (page >= 1 && page < totalPages) {
							setPage(page + 1)
						}
					}
					if (values.scrollTop > 300) {
						setScrollPosition(true)
					} else {
						setScrollPosition(false)
					}
				}}
			>
				<div className={styles.content_wrap}>
					<UserProfileHeaderCard currentUser={currentUser} posts={postsLength} />
					<Switch>
						<Route exact path="/user-profile/feed">
							<UserPersonalFeed
								posts={posts}
							/>
						</Route>
						<Route exact path="/user-profile/connections">
							<UserPersonalConnections />
						</Route>
					</Switch>
				</div>
				<BackToTopButton scrollPosition={scrollPosition} scrollbar={scrollbar} />
			</CustomScroll>
		</div>
	)
}
