import React from "react"
import { useDispatch } from "react-redux"
import { store } from "app/redux/store"
import { message } from "antd"
import { useTranslation } from "react-i18next"
import { saveUserTree } from "modules/family-tree/api"
import { socket } from "app/socket"
import {
	selectPerson, removeChildCard, hideParents
} from "modules/family-tree/utils"
import { setPerson, setSelectedPersonId } from "modules/family-tree/ducks"
import { PlusIcon } from "modules/family-tree/components/add-person"
import styles from "./add-person-mob-card.module.css"

export const AddPersonMobile = ({ addInfo, id }) => {
	const dispatch = useDispatch()

	const { t } = useTranslation()

	const findPartner = (id) => {
		const { people } = store.getState().familyTree
		const personPartner = people.find(p => p.partner === id)
		return personPartner
	}

	const translateCard = () => {
		if (addInfo.name.includes("parent")) {
			addInfo.name = addInfo.name.replace("Add parent", `${t("add-parent")}`)
		}
		if (addInfo.name.includes("child")) {
			addInfo.name = addInfo.name.replace("Add child", `${t("add-child")}`)
		}
		return addInfo.name
	}

	if (addInfo.hide) { return null }
	return (
		<div
			className={styles.wrap}
			role="button"
			tabIndex={0}
			onClick={async () => {
				if (id === "person21") {
					const partner = findPartner(id)
					const isPartnerUnsetted = partner.gender === "" && partner.positionId === "person22" && partner.name === "Add parent"
					if (isPartnerUnsetted) {
						dispatch(setPerson(partner.positionId))
					} else {
						dispatch(setPerson(id))
					}
				} else {
					dispatch(setPerson(id))
				}
				dispatch(setSelectedPersonId(null))
				hideParents()
				removeChildCard()
				selectPerson(null)
				const { token } = addInfo
				const { people } = store.getState().familyTree
				const result = await saveUserTree(people, token)
				if (result.status === "error") {
					message.error(t("something-went-wrong"))
				} else {
					socket.emit("NEW_TREE_ELEMENT_NOTIFICATION", { userId: result.userId })
				}
			}}
		>
			<div
				className={styles.plus_element}
			>
				<PlusIcon />
			</div>
			<div
				className={styles.card_name}
			>
				{translateCard()}
			</div>
		</div>
	)
}