/* eslint-disable no-nested-ternary */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react"
import moment from "moment"
import { useSelector } from "react-redux"
import { DeleteOutlined } from "@ant-design/icons"
import { Popconfirm } from "antd"
import { socket } from "app/socket"
import { useTranslation } from "react-i18next"
import { ChatAvatar } from "../chat-avatar"
import styles from "./chat-list-card.module.css"

export const ChatListCard = ({
	id, friendFirstName, friendLastName, friendAvatar,
	chatMessages, online, selectedChat, setSelectedChat,
	roomName, setVisible, chatAvatar
}) => {
	const { user } = useSelector(s => s)
	const [chatId, setChatId] = useState("")
	const { currentUser } = user
	const { t } = useTranslation()

	const lastMessageText = (chatMessages) => {
		if (chatMessages && chatMessages.length > 0) {
			if (chatMessages[chatMessages.length - 1].text === "") {
				return `${t("image")}`
			}
			if (chatMessages[chatMessages.length - 1].text === "JOINED_CHAT") {
				return `${t("user")} ${t("joined-chat")}`
			}
			if (chatMessages[chatMessages.length - 1].text === "KICKED_FROM_CHAT") {
				return `${t("user")} ${t("was-kicked-from-chat")}`
			}
			if (chatMessages[chatMessages.length - 1].text === "LEAVED_CHAT") {
				return `${t("user")} ${t("leaved-chat")}`
			}
			if (chatMessages[chatMessages.length - 1].text === "NEW_CHAT_ADMIN") {
				return `${t("user")} ${t("new-chat-admin")}`
			}
			return chatMessages[chatMessages.length - 1].text
		}
		return `${t("there-are-no-messages-yet")}`
	}

	const renderGroupChatavatar = () => {
		if (chatAvatar) {
			return <ChatAvatar friendAvatar={chatAvatar} online={null} />
		}
		return (
			<div className={styles.avatar_groupchat}>
				{roomName[0].toUpperCase()}
			</div>
		)
	}

	useEffect(() => {
		if (chatId !== "") { setSelectedChat("") }
	}, [chatId])

	return (
		<div
			className={selectedChat === id ? `${styles.chat_list_card} ${styles.chat_list_card_selected}` : `${styles.chat_list_card}`}
			role="button"
			tabIndex={0}
			onClick={() => {
				setVisible(false)
				setSelectedChat(id)
			}}
		>
			<div
				className={selectedChat === id
					? styles.card_select_indicator_selected
					: styles.card_select_indicator}
			/>
			{roomName
				? renderGroupChatavatar()
				: (<ChatAvatar friendAvatar={friendAvatar} online={online} />)}
			<div className={styles.chat_list_card_middle_container}>
				{roomName
					? (
						<div className={styles.chat_list_card_author}>
							{roomName}
						</div>
					)
					: (
						<div className={styles.chat_list_card_author}>
							{friendFirstName}
							{" "}
							{friendLastName}
						</div>
					)}
				<div
					className={chatMessages && chatMessages.length - chatMessages
						.filter(message => message.whoRead
							.find(id => id === currentUser._id)).length > 0
						? `${styles.chat_list_card_message_text} ${styles.chat_list_card_message_unreaded_text}`
						: styles.chat_list_card_message_text}
				>
					{lastMessageText(chatMessages)}
				</div>
			</div>
			<div className={styles.chat_list_card_right_container}>
				<div className={styles.chat_list_card_time}>{chatMessages && chatMessages.length > 0 ? moment(chatMessages[chatMessages.length - 1].date).fromNow() : ""}</div>
				<div
					className={styles.chat_list_delete_card}
				>
					<Popconfirm
						className={styles.chat_list_delete}
						title={t("are-you-sure-that-you-want-to-delete-the-chat")}
						placement={window.innerWidth > "1040" ? "right" : "bottomRight"}
						onConfirm={() => {
							socket.emit("LEAVE_CHAT", {
								roomId: id,
								userId: currentUser._id
							})
							setChatId(id)
						}}
						okText={t("yes")}
						cancelText={t("no")}
					>
						<DeleteOutlined
							onClick={(e) => { e.stopPropagation() }}
						/>
					</Popconfirm>
				</div>
				{chatMessages && chatMessages.length - chatMessages
					.filter(message => message.whoRead
						.find(id => id === currentUser._id)).length > 0
					? (
						<div className={styles.chat_list_card_unreaded_messages}>
							{chatMessages.length - chatMessages
								.filter(message => message.whoRead
									.find(id => id === currentUser._id)).length}
						</div>
					)
					: null}
			</div>
		</div>
	)
}