export const acceptAsFamilyMember = async (
  newFamilyMemberId
) => {
  try {
    const token = localStorage.token ? localStorage.getItem("token") : sessionStorage.getItem("token")
    const userInfo = {
      newFamilyMemberId
    }
    const res = await fetch("/api/set-to-family", {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Authorization: token
      },
      body: JSON.stringify(userInfo)
    })
    const json = await res.json()
    return json
  } catch (error) {
    const message = "Something went wrong"
    return message
  }
}