import React, { useEffect, useState } from "react"
import { store } from "app/redux/store"
import { setPeople } from "modules/family-tree/ducks"
import {
	ComplexHierarchicalTree,
	DiagramComponent,
	Inject,
	SnapConstraints,
	LineDistribution,
	ChildArrangement,
	DiagramConstraints,
	LineRouting,
	SelectorConstraints
} from "@syncfusion/ej2-react-diagrams"
import { getProfile } from "modules/connection-profile-card/api"
import { useSelector } from "react-redux"
import { getNodes, getConnectors } from "modules/family-tree/utils"
import { useLocation } from "react-router-dom"
import { useTranslation } from "react-i18next"
import { AppSpinner } from "shared/app-spinner/app-spinner"
import { MobileProfileTreePerson } from "./mobile-profile-tree-person"
import { MobileProfileTreeInfo } from "./mobile-profile-tree-info"
import styles from "./mobile-profile-tree.module.css"

let first = true
export const MobileProfileTree = () => {
	const {
		people, dragMode, treeDirection
	} = useSelector(s => s.familyTree)
	const useQuery = () => new URLSearchParams(useLocation().search)
	const query = useQuery()
	const incomeId = query.get("user")
	const lang = query.get("lang")

	const { i18n } = useTranslation()

	const personId = Buffer.from(incomeId, "base64").toString("ascii")

	const [isLoad, setIsLoad] = useState(false)

	useEffect(() => {
		const getData = async () => {
			const result = await getProfile(personId, true)
			i18n.changeLanguage(lang)
			if (result.profile.userTree && result.profile.userTree.length > 0) {
				store.dispatch(setPeople(result.profile.userTree))
				setIsLoad(true)
			}
		}
		getData()
		if (isLoad) {
			window.diagramInstance.reset()
			window.diagramInstance.scrollSettings.minZoom = 0.01
			window.diagramInstance.fitToPage()
		}
	}, [i18n, personId])

	const getTemplates = (props) => {
		if (props.addInfo.gender) {
			return <MobileProfileTreePerson {...props} />
		}
		return null
	}

	if (!isLoad) {
		return (
			<AppSpinner />
		)
	}

	return (
		<div className={styles.wrap}>
			<div className={styles.diagram}>
				<DiagramComponent
					id="diagram"
					style={{ outline: 0 }}
					ref={(diagram) => {
						window.diagramInstance = diagram
						if (window.diagramInstance) {
							if (first) {
								first = false
								diagram.reset()
								diagram.scrollSettings.minZoom = 0.01
								diagram.fitToPage()
							}
							diagram.doLayout()
						}
					}}
					constraints={DiagramConstraints.Default & ~(DiagramConstraints.UserInteraction)}
					tool={dragMode}
					nodes={getNodes(people)}
					nodeTemplate={getTemplates}
					connectors={getConnectors(people)}
					getConnectorDefaults={(connector) => {
						connector.style = {
							strokeColor: "#e0e0e0",
							strokeWidth: 5
						}
						connector.targetDecorator.shape = "None"
						connector.type = "Orthogonal"
						connector.cornerRadius = 3
						return connector
					}}
					layout={{
						type: "ComplexHierarchicalTree",
						orientation: treeDirection,
						arrangement: ChildArrangement.Linear,
						margin: {
							left: 5, top: 0, right: 5, bottom: 0
						},
						bounds: {
							height: 100, width: 90, x: 0, y: 0
						},
						verticalSpacing: 50,
						horizontalSpacing: 0,
						horizontalAlignment: "Center",
						verticalAlignment: "Bottom",
						root: "person11"
					}}
					selectedItems={{
						constraints: SelectorConstraints.None
					}}
					snapSettings={{ constraints: SnapConstraints.None }}
				>
					<Inject services={[ComplexHierarchicalTree, LineDistribution, LineRouting]} />
				</DiagramComponent>
			</div>
			<MobileProfileTreeInfo />
		</div>
	)
}