export const publishPost = async (
  formData
) => {
  const token = localStorage.token ? localStorage.getItem("token") : sessionStorage.getItem("token")
  try {
    const res = await fetch("/api/posts/pusblished-posts", {
      method: "POST",
      headers: {
        Authorization: token
      },
      body: formData
    })
    const json = await res.json()
    return json
  } catch (error) {
    const message = "Something went wrong"
    return message
  }
}