/* eslint-disable react/jsx-no-bind */
import React, { useState, useEffect, useRef } from "react"
import { browserName } from "react-device-detect"
import Calendar from "react-calendar"
import { v4 as uuidv4 } from "uuid"
import { useSelector } from "react-redux"
import moment from "moment"
import mobile from "is-mobile"
import { getUsersByIds, getUserTree } from "shared/utils"
import "react-calendar/dist/Calendar.css"
import { EventCard } from "./components/event-card"

import styles from "./calendar.module.css"

export const CalendarPage = () => {
	const { user } = useSelector(s => s)
	const { currentUser } = user
	const [value, onChange] = useState(new Date())
	const [users, setUsers] = useState([])
	const [birthdayUsers, setBirthdayUsers] = useState([])
	const [userLanguage, setUserLanguage] = useState("en")
	const isMobile = mobile()
	const lang = localStorage.getItem("i18nextLng")
	const datesToAddClassTo = [...users.map(u => new Date(moment(u.birthDate, "DD/MM/YYYY").format("YYYY-MM-DD")))]

	const calendarRef = useRef()

	function tileClassName({ date, view }) {
		if (view === "month") {
			if (datesToAddClassTo.find(dDate => dDate.getMonth() === date.getMonth()
				&& dDate.getDate() === date.getDate())) {
				return styles.event
			}
		}
		return true
	}

	function filterUserBirthday(value, users) {
		const usersCorrect = users.map(u => ({
			...u,
			birthDate: new Date(moment(u.birthDate, "DD/MM/YYYY").format("YYYY-MM-DD"))
		}))
		const usersList = usersCorrect.filter(user => user.birthDate.getMonth() === value.getMonth()
			&& user.birthDate.getDate() === value.getDate())
		setBirthdayUsers([...usersList])
	}

	useEffect(() => {
		const getData = async () => {
			const usersResponse = await getUsersByIds([...currentUser.friends, ...currentUser.family])
			const userTree = await getUserTree()
			if (usersResponse.status === "successful") {
				setUsers([...usersResponse.users, ...userTree.filter(p => p.birthDate && !p.isUser)])
			}
		}
		getData()
	}, [currentUser.family, currentUser.friends])

	useEffect(() => {
		filterUserBirthday(value, users)
	}, [value, users])

	useEffect(() => {
		setUserLanguage(lang)
		if (lang === "bel") {
			if (browserName.toLowerCase() === "chrome") {
				setUserLanguage("ru")
			}
		}
	}, [lang])

	return (
		<>
			<div
				className={styles.wrap}
			>
				<div className={styles.container} id="user-calendar">
					<Calendar
						ref={calendarRef}
						className={styles.calendar}
						onChange={onChange}
						value={value}
						tileClassName={tileClassName}
						showDoubleView={!isMobile && window.innerWidth >= 1400}
						minDetail="month"
						locale={userLanguage || "en"}
					/>
					<div className={styles.event_list}>
						{!!birthdayUsers.length && birthdayUsers.map(bUser => (
							<div className={styles.event_wrap} key={uuidv4()}>
								<EventCard user={bUser} key={uuidv4()} />
							</div>
						))}
					</div>
				</div>
			</div>
		</>
	)
}