export const getProfileConnections = async id => {
  const token = localStorage.token ? localStorage.getItem("token") : sessionStorage.getItem("token")
  const userInfo = {
    id
  }
  try {
    const res = await fetch("/api/profile/connections", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: token
      },
      body: JSON.stringify(userInfo)
    })
    const json = await res.json()
    return json
  } catch (error) {
    const message = "Something went wrong"
    return message
  }
}