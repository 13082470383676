import React from "react"
import { useSelector } from "react-redux"
import { Popover, message } from "antd"
import { useTranslation } from "react-i18next"
import {
	MoreIcon
} from "shared/icons"
import { updatePost } from "shared/feed-components/api"
import { PopoverContent } from "../popover"
import { PostAuthor } from "./post-author"
import { SharedPostAuthor } from "./shared-post-author"
import styles from "./post-header.module.css"

export const PostHeader = ({
	postAuthor,
	post,
	isEditMode,
	setInputValue,
	setImages,
	setNewImages,
	setFiles,
	setIsEditMode,
	setVisiblePopover,
	visiblePopover,
	inputVideo,
	setVideoFiles,
	setFormData,
	setFileName,
	images,
	inputValue,
	files,
	videos,
	videoFiles,
	fileName,
	formData,
	setOldImages,
	oldImages,
	setImageFiles,
	imageFiles,
	profile,
	allPosts,
	setAllPosts,
	setVideos,
	setVideosToView,
	setPostText
}) => {
	const { t } = useTranslation()
	const { user } = useSelector(s => s)
	const { currentUser } = user

	const returnHeader = () => {
		if (!profile) {
			if (post.sharedCopyAuthorId) {
				return (
					<SharedPostAuthor
						postAuthor={postAuthor}
						post={post}
						profile={post.sharedCopyFullAuthor}
					/>
				)
			}
			return <PostAuthor postAuthor={postAuthor} post={post} />
		}
		return <SharedPostAuthor postAuthor={postAuthor} post={post} profile={profile} />
	}

	return (
		<div className={styles.post_header}>
			{returnHeader()}
			{!profile && currentUser._id === post.userId && !isEditMode && (
				<Popover
					placement="bottomRight"
					content={() => (
						<PopoverContent
							post={post}
							setIsEditMode={setIsEditMode}
							setInputValue={setInputValue}
							allPosts={allPosts}
							setAllPosts={setAllPosts}
						/>
					)}
					trigger="click"
					visible={visiblePopover}
				>
					<button
						className={styles.post_more_btn}
						style={{ fontSize: "24px" }}
						type="button"
						onClick={() => {
							setVisiblePopover(true)
						}}
					>
						<MoreIcon />
					</button>
				</Popover>
			)}
			{!profile && currentUser._id === post.userId && isEditMode && (
				<div>
					<button
						className={`${styles.post_edit_btn} ${styles.post_confirm_edit_btn}`}
						type="button"
						onClick={async () => {
							if (!inputValue && images.length === 0
								&& files.length === 0 && fileName.length === 0
								&& videos.length === 0 && post.text) {
								message.error(`${t("no-data-to-post")}`)
							} else {
								videoFiles.forEach(v => formData.append("files", v))
								imageFiles.forEach(i => formData.append("images", i))
								formData.append("text", inputValue)
								formData.append("postId", post._id)
								formData.append("oldImages", JSON.stringify(oldImages))
								formData.append("postVideos", JSON.stringify(videos))
								const response = await updatePost(formData)
								setImageFiles(response.data.images)
								setVideoFiles(response.data.videos)
								setInputValue(response.data.text)
								setImages(response.data.images)
								setOldImages(response.data.images)
								setVideos(response.data.videos)
								setVideosToView(response.data.videos)
								setPostText(response.data.text)
								setFiles([])
								setNewImages([])
								setIsEditMode(false)
								setVisiblePopover(false)
								setFormData(new FormData())
								setFileName([])
								inputVideo.current.value = ""
							}
						}}
					>
						{t("save-changes")}
						{" "}
					</button>
					<button
						className={`${styles.post_edit_btn} ${styles.post_cancel_edit_btn}`}
						type="button"
						onClick={() => {
							setInputValue(post.text)
							setImages(post.images)
							setOldImages(post.images)
							setImageFiles(post.images)
							setNewImages([])
							setVideoFiles(post.videos)
							setVideos(post.videos)
							setFiles(post.files)
							setIsEditMode(false)
							setVisiblePopover(false)
						}}
					>
						{t("cancel")}
					</button>
				</div>
			)}
		</div>
	)
}
