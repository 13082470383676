import React from "react"

export const AddVideo = () => (
	<svg width="217" height="156" viewBox="0 0 217 156" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path d="M107.505 142C142.756 142 171.334 113.45 171.334 78.1084C171.334 42.7668 142.63 14.2168 107.505 14.2168C72.2542 14.2168 43.6758 42.7668 43.6758 78.1084C43.6758 113.45 72.2542 142 107.505 142Z" fill="#EAEEF9" />
		<path d="M167.177 36.2268C170.028 36.2268 172.339 33.9181 172.339 31.0702C172.339 28.2223 170.028 25.9136 167.177 25.9136C164.327 25.9136 162.016 28.2223 162.016 31.0702C162.016 33.9181 164.327 36.2268 167.177 36.2268Z" fill="#E7EBF6" />
		<path d="M174.732 16.1034C176.679 16.1034 178.257 14.5267 178.257 12.5818C178.257 10.6368 176.679 9.06018 174.732 9.06018C172.785 9.06018 171.207 10.6368 171.207 12.5818C171.207 14.5267 172.785 16.1034 174.732 16.1034Z" fill="#E7EBF6" />
		<path d="M47.072 36.1009C49.0188 36.1009 50.5971 34.5242 50.5971 32.5793C50.5971 30.6344 49.0188 29.0577 47.072 29.0577C45.1251 29.0577 43.5469 30.6344 43.5469 32.5793C43.5469 34.5242 45.1251 36.1009 47.072 36.1009Z" fill="#E7EBF6" />
		<path d="M25.5466 104.017C29.1622 104.017 32.0932 101.089 32.0932 97.4771C32.0932 93.8651 29.1622 90.937 25.5466 90.937C21.931 90.937 19 93.8651 19 97.4771C19 101.089 21.931 104.017 25.5466 104.017Z" fill="#E7EBF6" />
		<g filter="url(#filter0_d)">
			<path d="M102.156 92.0798L59.4275 121.744C56.9558 123.443 53.5883 122.856 51.8581 120.384L23.4962 79.5036C21.797 77.0316 22.384 73.6636 24.8556 71.9332L67.5838 42.2695C70.0555 40.57 73.423 41.1571 75.1532 43.629L103.546 84.5094C105.245 86.9813 104.627 90.3803 102.156 92.0798Z" fill="url(#paint0_linear)" />
		</g>
		<path d="M94.6794 87.0531L57.1107 113.132C56.0603 113.874 54.6082 113.596 53.8667 112.545L30.4172 78.7411C29.6757 77.6905 29.9538 76.2382 31.0042 75.4966L68.5729 49.4173C69.6233 48.6757 71.0754 48.9538 71.8169 50.0043L95.2664 83.8086C96.0079 84.8592 95.7298 86.3115 94.6794 87.0531Z" fill="#EAEEF9" />
		<path d="M74.263 75.8861C73.08 73.9151 71.5289 71.6286 69.6888 70.0519C68.7075 69.211 67.5246 68.7293 66.1579 68.8346C64.7912 68.94 63.5735 69.4921 62.2594 70.2808C59.9467 71.4902 57.8617 72.682 55.8031 74.2155C53.9547 75.5037 51.7733 76.9321 50.4332 78.8685C48.6989 81.6373 49.9258 84.1778 51.5032 86.8059C52.6863 88.777 54.2373 91.0634 56.0774 92.6402C57.5495 93.9015 59.4069 94.2167 61.1853 93.5067C63.5332 92.7529 67.7819 89.9049 69.7354 88.4941C71.4699 87.2147 73.5549 86.0229 74.7899 84.2091C77.2076 81.3877 76.0858 78.7245 74.263 75.8861ZM58.5374 79.9624C58.143 79.3054 58.3269 78.7184 59.2469 78.762C61.6474 78.6916 63.9253 78.516 66.2119 78.4544C67.1231 78.3841 67.386 78.8222 67.1057 79.6457C66.116 81.6697 65.1351 83.8076 64.1542 85.9454C63.7513 86.6639 63.1818 86.7078 62.7962 86.1647C62.4282 85.8493 58.923 80.5056 58.5374 79.9624Z" fill="#B0B7C3" />
		<g filter="url(#filter1_d)">
			<path d="M153.905 117.325L114.204 83.7057C111.918 81.759 111.64 78.3291 113.556 76.0426L145.718 38.0668C147.664 35.7802 151.093 35.5021 153.38 37.4179L193.08 71.0368C195.366 72.9835 195.644 76.4134 193.729 78.6999L161.567 116.676C159.62 118.962 156.191 119.24 153.905 117.325Z" fill="url(#paint1_linear)" />
		</g>
		<path d="M156.532 109.785L121.62 80.2449C120.631 79.4106 120.508 77.9583 121.342 76.9695L147.912 45.5754C148.746 44.5866 150.198 44.463 151.187 45.2973L186.098 74.8374C187.087 75.6717 187.211 77.124 186.376 78.1128L159.806 109.507C158.972 110.496 157.52 110.619 156.532 109.785Z" fill="#EAEEF9" />
		<path d="M164.67 81.3561C166.235 79.7033 168.002 77.6156 169.015 75.4413C169.556 74.2817 169.7 73.0256 169.236 71.7505C168.772 70.4753 167.921 69.4613 166.819 68.4183C165.05 66.5351 163.359 64.8644 161.348 63.3097C159.628 61.8902 157.685 60.1907 155.482 59.4284C152.38 58.5119 150.285 60.3582 148.199 62.562C146.634 64.2148 144.867 66.3025 143.854 68.4768C143.043 70.2162 143.237 72.0711 144.387 73.5778C145.731 75.6158 149.578 78.9087 151.443 80.3958C153.125 81.709 154.816 83.3797 156.875 84.0745C160.209 85.6285 162.449 83.8498 164.67 81.3561ZM156.597 67.447C157.118 66.8961 157.727 66.9151 157.93 67.8039C158.637 70.074 159.41 72.1992 160.078 74.3631C160.388 75.2132 160.04 75.5805 159.18 75.5326C156.987 75.1278 154.687 74.7617 152.387 74.3955C151.595 74.2027 151.402 73.6714 151.817 73.1591C152.02 72.7242 156.182 67.9592 156.597 67.447Z" fill="#B0B7C3" />
		<g filter="url(#filter2_d)">
			<path d="M135.926 82.8678L84.8214 92.7015C81.8701 93.2611 79.0205 91.3278 78.4376 88.4036L68.9746 39.5658C68.411 36.6175 70.3434 33.7737 73.2707 33.1947L124.376 23.3611C127.327 22.8015 130.176 24.7347 130.759 27.659L140.222 76.4967C140.786 79.445 138.854 82.2889 135.926 82.8678Z" fill="url(#paint2_linear)" />
		</g>
		<path d="M131.695 76.0981L86.7733 84.7603C85.5029 85.007 84.2927 84.193 84.0442 82.9239L76.21 42.5643C75.9616 41.2953 76.7753 40.0874 78.0457 39.8407L122.968 31.1785C124.238 30.9318 125.448 31.7458 125.697 33.0149L133.531 73.3745C133.779 74.6436 132.966 75.8514 131.695 76.0981Z" fill="#EAEEF9" />
		<path d="M117.425 54.5058C117.086 52.2219 116.528 49.5038 115.429 47.3327C114.842 46.1747 113.93 45.2724 112.622 44.8438C111.314 44.4152 109.972 44.4584 108.449 44.684C105.838 44.9152 103.445 45.2178 100.945 45.8475C98.7345 46.3305 96.1611 46.8155 94.1723 48.0956C91.4974 49.996 91.6571 52.825 92.1082 55.8702C92.4465 58.1541 93.0048 60.8723 94.1044 63.0434C94.9841 64.7803 96.5856 65.7879 98.5086 65.8142C100.977 66.0191 106.015 65.0135 108.37 64.4572C110.471 63.9384 112.864 63.6358 114.708 62.429C118.038 60.7429 118.023 57.8405 117.425 54.5058ZM101.268 52.2311C101.156 51.4698 101.552 50.9961 102.389 51.3909C104.642 52.25 106.823 52.9643 108.968 53.7877C109.84 54.0734 109.915 54.5809 109.338 55.237C107.641 56.7332 105.907 58.3385 104.174 59.9438C103.524 60.4551 102.979 60.2765 102.83 59.6242C102.61 59.19 101.417 52.8834 101.268 52.2311Z" fill="#B0B7C3" />
		<path d="M136.622 113.538C136.021 116.088 135.12 118.787 134.069 121.037C131.216 126.587 126.712 130.937 121.157 133.787C115.451 136.637 108.695 137.837 101.938 136.337C86.0233 133.037 75.8136 117.438 79.1167 101.538C82.4199 85.6388 97.8846 75.2893 113.8 78.7391C119.505 79.9391 124.46 82.789 128.664 86.6888C135.721 93.7385 138.724 103.938 136.622 113.538Z" fill="url(#paint3_linear)" />
		<path d="M117.256 104.988H110.5V98.2381C110.5 96.8882 109.449 95.6882 107.947 95.6882C106.596 95.6882 105.395 96.7382 105.395 98.2381V104.988H98.6384C97.2871 104.988 96.0859 106.038 96.0859 107.538C96.0859 109.038 97.1369 110.088 98.6384 110.088H105.395V116.837C105.395 118.187 106.446 119.387 107.947 119.387C109.299 119.387 110.5 118.337 110.5 116.837V110.088H117.256C118.607 110.088 119.809 109.038 119.809 107.538C119.809 106.038 118.607 104.988 117.256 104.988Z" fill="white" />
		<defs>
			<filter id="filter0_d" x="0.527344" y="30.3004" width="125.977" height="125.412" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
				<feFlood floodOpacity="0" result="BackgroundImageFix" />
				<feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
				<feOffset dy="11" />
				<feGaussianBlur stdDeviation="11" />
				<feColorMatrix type="matrix" values="0 0 0 0 0.397708 0 0 0 0 0.47749 0 0 0 0 0.575 0 0 0 0.27 0" />
				<feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
				<feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
			</filter>
			<filter id="filter1_d" x="90.2852" y="25.1473" width="126.714" height="126.448" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
				<feFlood floodOpacity="0" result="BackgroundImageFix" />
				<feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
				<feOffset dy="11" />
				<feGaussianBlur stdDeviation="11" />
				<feColorMatrix type="matrix" values="0 0 0 0 0.397708 0 0 0 0 0.47749 0 0 0 0 0.575 0 0 0 0.27 0" />
				<feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
				<feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
			</filter>
			<filter id="filter2_d" x="46.875" y="12.2646" width="115.443" height="113.533" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
				<feFlood floodOpacity="0" result="BackgroundImageFix" />
				<feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
				<feOffset dy="11" />
				<feGaussianBlur stdDeviation="11" />
				<feColorMatrix type="matrix" values="0 0 0 0 0.397708 0 0 0 0 0.47749 0 0 0 0 0.575 0 0 0 0.27 0" />
				<feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
				<feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
			</filter>
			<linearGradient id="paint0_linear" x1="63.4893" y1="39.4173" x2="63.4893" y2="123.59" gradientUnits="userSpaceOnUse">
				<stop stopColor="#FDFEFF" />
				<stop offset="0.9964" stopColor="#ECF0F5" />
			</linearGradient>
			<linearGradient id="paint1_linear" x1="153.615" y1="34.2402" x2="153.615" y2="119.484" gradientUnits="userSpaceOnUse">
				<stop stopColor="#FDFEFF" />
				<stop offset="0.9964" stopColor="#ECF0F5" />
			</linearGradient>
			<linearGradient id="paint2_linear" x1="70.9398" y1="31.0196" x2="137.377" y2="84.5112" gradientUnits="userSpaceOnUse">
				<stop stopColor="#FDFEFF" />
				<stop offset="0.9964" stopColor="#ECF0F5" />
			</linearGradient>
			<linearGradient id="paint3_linear" x1="78.4692" y1="107.537" x2="137.291" y2="107.537" gradientUnits="userSpaceOnUse">
				<stop stopColor="#B0BACC" />
				<stop offset="1" stopColor="#969EAE" />
			</linearGradient>
		</defs>
	</svg>
)