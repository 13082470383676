/* eslint-disable jsx-a11y/control-has-associated-label */
import React, { useState, useEffect } from "react"
import {
	ArrowIcon, SendIcon, EmojiIcon, AllowClear, LikeIcon
} from "shared/icons"
import { CloseCircleOutlined } from "@ant-design/icons"
import { EmptyPostImage } from "shared/empty-screen-images/post-empty"
import { LikesList } from "shared/video-modal/likes-list/likes-list"
import { Picker } from "emoji-mart"
import {
	message, Empty
} from "antd"
import { useTranslation } from "react-i18next"
import { getImageUri } from "shared/utils"
import CustomScroll from "react-scrollbars-custom"
import { v4 as uuidv4 } from "uuid"
import {
	commentGalleryPhoto, getPhotoComments, likePhoto, unlikePhoto
} from "shared/full-screen-image/api"
import { MentionUser } from "shared/mentions/mention"
import { socket } from "app/socket"
import { ImageComment } from "./comment"
import styles from "./full-screen-image.module.css"

export const FullScreenImage = ({
	albumName,
	isActive,
	selectedPhoto,
	allPhotos,
	setIsActive,
	setSelectedPhoto,
	isPhotoWithComments,
	albumId,
	ownerId,
	userId,
	photoFiles,
	setIsChangePhoto
}) => {
	const [selectedPhotoIndex, setSelectedPhotoIndex] = useState(null)
	const [inputValue, setInputValue] = useState("")
	const [visible, setVisible] = useState(false)
	const [toggle, setToggle] = useState(false)
	const [activeInput, setActiveInput] = useState(false)
	const [photoComments, setPhotoComments] = useState([])
	const [currntPhoto, setCurrentPhoto] = useState(null)
	const [isShowLikeList, setIsShowLikeList] = useState(false)
	const [mentionedUsers, setMentionedUsers] = useState([])
	const addEmoji = (emoji) => {
		setInputValue(`${inputValue}${emoji}`)
	}
	const { t } = useTranslation()
	useEffect(() => {
		allPhotos.map((photo, index) => {
			if (selectedPhoto === getImageUri(photo)) {
				setSelectedPhotoIndex(index)
				setCurrentPhoto(photoFiles?.find(p => p.path === photo))
			}
			return null
		})
		if (isActive && isPhotoWithComments) {
			const getData = async () => {
				const data = await getPhotoComments(ownerId, albumId, `users${selectedPhoto.split("users")[1]}`)
				if (data.status === "successful") {
					setPhotoComments(data.data.comments.reverse())
				}
			}
			getData()
		}
	}, [albumId, allPhotos, isActive, isPhotoWithComments, ownerId, photoFiles, selectedPhoto])
	return (
		<>
			<div
				className={isActive ? styles.active_screen : styles.disabled}
			>
				<div
					className={isActive ? styles.active_close_screen : styles.disabled_close_screen}
					role="button"
					tabIndex={0}
					onClick={() => {
						setIsActive(false)
						setSelectedPhoto(null)
						setSelectedPhotoIndex(null)
						setInputValue("")
						setToggle(false)
						setPhotoComments([])
					}}
				/>
				{
					isPhotoWithComments ? (
						<div className={styles.content_wrapper}>
							<div className={styles.content_btn}>
								<div className={styles.content}>
									<span>
										{albumName}
									</span>
									<CloseCircleOutlined
										className={styles.btn_close}
										onClick={() => {
											setIsActive(false)
											setSelectedPhoto(null)
											setSelectedPhotoIndex(null)
											setInputValue("")
											setToggle(false)
											setPhotoComments([])
										}}
									/>
								</div>
							</div>
							<div className={styles.content_basic}>
								<div className={styles.image_wrapper}>
									<img
										src={selectedPhoto}
										alt="loading error"
										onError={({ currentTarget }) => {
											currentTarget.onerror = null
											currentTarget.className = null
											currentTarget.src = getImageUri("./img.png")
										}}
										className={styles.image_content}
									/>
								</div>
								<div className={styles.image_comments}>
									<div className={styles.action_on_img}>
										<div style={{ display: "flex" }}>
											<button
												type="button"
												className={currntPhoto?.likes?.includes(userId) ? `${styles.post_main_content_action_btn} ${styles.post_main_content_action_btn_like} ${styles.liked}` : `${styles.post_main_content_action_btn} ${styles.post_main_content_action_btn_like}`}
												onClick={async () => {
													setIsChangePhoto(true)
													if (currntPhoto.likes.includes(userId)) {
														await unlikePhoto(albumId, currntPhoto.path, userId, ownerId)
													} else {
														await likePhoto(albumId, currntPhoto.path, userId, ownerId)
													}
													setIsChangePhoto(false)
												}}
											>
												<LikeIcon />
											</button>
											<button
												type="button"
												className={styles.btns_text}
												onClick={() => setIsShowLikeList(true)}
											>
												{!!currntPhoto?.likes?.length < 10000 ? currntPhoto?.likes?.length || 0
													: `${currntPhoto?.likes?.length.toString().slice(0, -3)}K`}
												{" "}
												<span className={styles.post_footer_labels}>{t("likes")}</span>
											</button>
										</div>
									</div>
									<div className={styles.comments_zone}>
										{!photoComments || photoComments.length === 0
											? (
												<Empty
													image={<EmptyPostImage />}
													description={<span>{t("there-are-no-comments-here-yet")}</span>}
												/>
											)
											: (
												<>
													<CustomScroll>
														{photoComments.map(comment => (
															<ImageComment
																key={comment.commentId}
																comment={comment}
																userId={userId}
																ownerId={ownerId}
																photoPath={`users${selectedPhoto.split("users")[1]}`}
																albumId={albumId}
																setPhotoComments={setPhotoComments}
															/>
														))}
													</CustomScroll>
												</>
											)}
									</div>
									<div className={styles.image_comments_input}>
										<div className={`${styles.comment_input_field} ${activeInput && styles.active}`}>
											<MentionUser
												setMentionedUsers={setMentionedUsers}
												setVisible={setVisible}
												setToggle={setToggle}
												inputValue={inputValue}
												setInputValue={setInputValue}
											/>
											<button
												type="button"
												className={`${styles.btns}`}
												onClick={() => setToggle(!toggle)}
											>
												<EmojiIcon />
											</button>
											<button
												type="button"
												className={`${styles.btns} ${!visible ? styles.btns_none : styles.btns_visible}`}
												onClick={() => {
													setInputValue("")
													setActiveInput(false)
													setVisible(!visible)
												}}
											>
												<AllowClear />
											</button>
										</div>
										{toggle && (
											<Picker
												showPreview={false}
												useButton={false}
												title="Emoji"
												style={{
													position: "absolute", bottom: "114px", right: "42px", overflow: "hidden", maxHeight: "40vh", zIndex: 11, width: "240px", background: "#f2f2f2"
												}}
												onSelect={el => {
													setToggle(false)
													addEmoji(el.native)
												}}
											/>
										)}
										<button
											type="button"
											className={styles.confirm_button}
											onClick={async () => {
												setVisible(false)
												setMentionedUsers([])
												if (inputValue.length === 0) {
													message.error(`${t("no-data-to-post")}`)
												} else {
													const commentId = `comment_${uuidv4()}`
													const mentionedUser = mentionedUsers.includes(ownerId)
													const comment = {
														commentText: inputValue, commentAuthor: userId, commentId
													}
													const sendedPhoto = `users${selectedPhoto.split("users")[1]}`
													const response = await commentGalleryPhoto(
														albumId, sendedPhoto, comment, ownerId, mentionedUser
													)
													setInputValue("")
													setPhotoComments(response.data.comments.reverse())
													socket.emit("COMMENT_MEDIA", { userId: ownerId })
													if (!mentionedUser) {
														socket.emit("MENTIONED_USERS", {
															usersId: mentionedUsers,
															id: userId,
															photoAlbum: albumId,
															photoPath: currntPhoto.path,
															ownerId
														})
													}
												}
											}}
										>
											<SendIcon />
										</button>
									</div>
								</div>
							</div>
						</div>
					) : <img src={selectedPhoto} alt="loading error" className={styles.image_content} />
				}

			</div>
			{
				selectedPhotoIndex > 0 && (
					<button
						className={selectedPhotoIndex !== null ? `${styles.image_btn} ${styles.left_btn}` : styles.btn_disabled}
						type="button"
						onClick={() => {
							const prevPhoto = allPhotos.find(
								(photo, index) => index === selectedPhotoIndex - 1
							)
							setSelectedPhoto(getImageUri(prevPhoto))
						}}
					>
						<ArrowIcon />
					</button>
				)
			}
			{
				selectedPhotoIndex < (allPhotos.length - 1) && (
					<button
						className={selectedPhotoIndex !== null ? `${styles.image_btn} ${styles.right_btn}` : styles.btn_disabled}
						type="button"
						onClick={() => {
							const nextPhoto = allPhotos.find(
								(photo, index) => index === selectedPhotoIndex + 1 || index === allPhotos.length - 1
							)
							setSelectedPhoto(getImageUri(nextPhoto))
						}}
					>
						<ArrowIcon />
					</button>
				)
			}
			{isShowLikeList
				&& currntPhoto.likes
				&& currntPhoto.likes.length > 0
				&& (
					<LikesList
						isShowLikeList={isShowLikeList}
						setIsShowLikeList={setIsShowLikeList}
						file={currntPhoto}
						fileAuthor={ownerId}
					/>
				)}
		</>
	)
}