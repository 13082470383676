import React from "react"
import { Route, Switch } from "react-router-dom"
import { useSelector } from "react-redux"
import CustomScroll from "react-scrollbars-custom"
import {
	SettingsHeader,
	SettingsFooter,
	SettingsPrivacy,
	SettingsSecurity,
	SettingsNotifications
} from "./components"
import styles from "./settings.module.css"

export const Settings = () => {
	const { user } = useSelector(s => s)
	const { currentUser } = user
	return (
		<div className={styles.wrap}>
			<div className={styles.container}>
				<SettingsHeader />
				<div className={styles.main_content_container}>
					<CustomScroll>
						<Switch>
							<Route exact path="/settings/privacy" component={SettingsPrivacy} />
							{!currentUser.appleId && (<Route exact path="/settings/security" component={SettingsSecurity} />)}
							<Route exact path="/settings/notifications" component={SettingsNotifications} />
						</Switch>
					</CustomScroll>
				</div>
				<SettingsFooter />
			</div>
		</div>
	)
}