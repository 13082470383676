/* eslint-disable jsx-a11y/control-has-associated-label */
import React from "react"
import { setShowModal } from "modules/family-tree/ducks"
import { useSelector, useDispatch } from "react-redux"
import { getPersonById } from "modules/family-tree/utils"
import { MobileProfileTreeInfoHeader } from "./mobile-profile-tree-info-header"
import { MobileProfileTreeInfoMain } from "./mobile-profile-tree-info-main"
import styles from "./mobile-profile-tree-info.module.css"

export const MobileProfileTreeInfo = () => {
	const { selectedPersonId, isShowModal } = useSelector(s => s.familyTree)
	const dispatch = useDispatch()
	const selectedPerson = getPersonById(selectedPersonId)
	const personPartner = getPersonById("person21")

	const getContent = (id, selectedPerson) => {
		if (id) {
			return (
				<>
					<MobileProfileTreeInfoHeader person={selectedPerson} />
					<MobileProfileTreeInfoMain person={selectedPerson} />
				</>
			)
		}
		return null
	}

	return (
		<div
			className={selectedPersonId && isShowModal ? `${styles.wrap}` : `${styles.wrap} ${styles.disabled}`}

		>
			<div
				className={styles.close_wrap}
				role="button"
				tabIndex={0}
				onClick={async () => {
					dispatch(setShowModal(false))
				}}
			/>
			<div className={styles.container_top} />
			<div className={styles.container_wrap}>
				<div className={styles.container}>
					{getContent(selectedPersonId, selectedPerson, personPartner)}
				</div>
			</div>
			<div className={styles.container_bottom} />
		</div>
	)
}