import React from "react"
import { Spin } from "antd"
import { LoadingOutlined } from "@ant-design/icons"
import styles from "./app-spinner.module.css"

export const AppSpinner = ({
	loadingOutlinedStyle, size, style, tip
}) => (
	<Spin
		indicator={<LoadingOutlined style={loadingOutlinedStyle || { fontSize: 64 }} spin />}
		className={styles.app_loader}
		size={size || "large"}
		style={style || null}
		tip={tip || null}
	/>
)