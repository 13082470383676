import React from "react"
import { useTranslation } from "react-i18next"
import {
	LocationIcon,
	BirthDateIcon,
	CircleIcon
} from "shared/icons"
import moment from "moment-timezone"
import styles from "../side-panel.module.css"

export const UserProfileAbout = ({ userToShowInModal }) => {
	const { t } = useTranslation()

	return (
		<div className={styles.profile_about_container}>
			{
				userToShowInModal.birthDate && (
					<div className={styles.profile_about}>
						<BirthDateIcon />
						<span>
							{t("born in")}
							{" "}
							<b>{moment(userToShowInModal.birthDate, "DD/MM/YYYY").format("YYYY")}</b>
						</span>
					</div>
				)
			}
			{
				userToShowInModal.birthPlace && (
					<div className={styles.profile_about}>
						<LocationIcon />
						<span>
							{t("from")}
							{" "}
							<b>{userToShowInModal.birthPlace}</b>
						</span>
					</div>
				)
			}
			{
				userToShowInModal.gender && (
					<div className={styles.profile_about}>
						<CircleIcon />
						<span>
							{t("gender")}
							{" "}
							<b>{t(userToShowInModal.gender === "unknown" ? "gender-neutral" : userToShowInModal.gender)}</b>
						</span>
					</div>
				)
			}
		</div>
	)
}