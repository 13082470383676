export const renameAlbum = async (
  name, albumId
) => {
  const token = localStorage.token ? localStorage.getItem("token") : sessionStorage.getItem("token")
  try {
    const reportInfo = {
      name, albumId
    }
    const res = await fetch("/api/video-gallery", {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Authorization: token
      },
      body: JSON.stringify(reportInfo)
    })
    const json = await res.json()
    return json
  } catch (error) {
    const message = "Something went wrong"
    return message
  }
}