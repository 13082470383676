import React from "react"
import { Route, Switch } from "react-router-dom"
import styles from "./photos-page.module.css"
import { Album } from "./components/album"
import { AlbumCard } from "./components/albums-card/albums-card"

export const PhotosPage = () => (
	<div className={styles.wrap}>
		<Switch>
			<Route exact path="/photo" component={AlbumCard} />
			<Route exact path="/photo/album/:albumId" component={Album} />
		</Switch>
	</div>
)