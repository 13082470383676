import React, { useEffect } from "react"
import {
	ComplexHierarchicalTree,
	DiagramComponent,
	Inject,
	SnapConstraints,
	LineDistribution,
	ChildArrangement,
	DiagramConstraints,
	LineRouting,
	SelectorConstraints,
	DiagramTools
} from "@syncfusion/ej2-react-diagrams"
import { useDispatch, useSelector } from "react-redux"
import { getNodes, getConnectors } from "modules/family-tree/utils"
import { setZoom, setSelectedPersonId } from "modules/family-tree/ducks"
import { ProfileTreePerson } from "modules/connection-profile-card/components/profile-tree/profile-tree-person"
import styles from "./user-family-tree.module.css"

let first = true
export const UserFamilyTree = () => {
	const {
		people, treeDirection, zoom
	} = useSelector(s => s.familyTree)

	const dispatch = useDispatch()

	const getTemplates = (props) => {
		if (props.addInfo.gender) {
			return <ProfileTreePerson {...props} />
		}
		return null
	}

	useEffect(() => {
		window.diagramInstance.reset()
		window.diagramInstance.scrollSettings.minZoom = 0.01
		window.diagramInstance.fitToPage()
		dispatch(setZoom(1))
	}, [dispatch])

	return (
		<div className={styles.wrap}>
			<div className={styles.diagram}>
				<DiagramComponent
					id="diagram"
					style={{ outline: 0 }}
					ref={(diagram) => {
						window.diagramInstance = diagram
						if (window.diagramInstance) {
							if (first) {
								first = false
								diagram.fitToPage()
							}
							diagram.doLayout()
						}
					}}
					constraints={DiagramConstraints.Default}
					scrollChange={args => {
						const { CurrentZoom } = args.newValue
						if (CurrentZoom !== zoom) {
							dispatch(setZoom(CurrentZoom))
						}
					}}
					tool={DiagramTools.ZoomPan}
					nodes={getNodes(people)}
					nodeTemplate={getTemplates}
					connectors={getConnectors(people)}
					getConnectorDefaults={(connector) => {
						connector.style = {
							strokeColor: "#888888",
							fill: "#888888",
							strokeWidth: 5
						}
						connector.targetDecorator.shape = "None"
						connector.type = "Orthogonal"
						connector.cornerRadius = 1
						return connector
					}}
					click={async (args) => {
						if (args.element && args.element.diagramid) {
							dispatch(setSelectedPersonId(null))
						}
					}}
					layout={{
						type: "ComplexHierarchicalTree",
						orientation: treeDirection,
						arrangement: ChildArrangement.Linear,
						margin: {
							left: 5, top: 0, right: 5, bottom: 0
						},
						bounds: {
							height: 100, width: 90, x: 0, y: 0
						},
						verticalSpacing: 50,
						horizontalSpacing: 0,
						horizontalAlignment: "Center",
						verticalAlignment: "Bottom",
						root: "person11"
					}}
					selectedItems={{
						constraints: SelectorConstraints.None
					}}
					snapSettings={{ constraints: SnapConstraints.None }}
				>
					<Inject services={[ComplexHierarchicalTree, LineDistribution, LineRouting]} />
				</DiagramComponent>
			</div>
		</div>
	)
}