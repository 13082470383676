export const getProfileVideos = async (albumId, currentProfileId) => {
  try {
    const token = localStorage.token ? localStorage.getItem("token") : sessionStorage.getItem("token")
    const res = await fetch(`/api/video-gallery/profile/videos?albumId=${albumId}&currentProfileId=${currentProfileId}`, {
      method: "GET",
      headers: {
        Authorization: token
      }
    })
    const json = await res.json()
    return json
  } catch (error) {
    const message = "Something went wrong"
    return message
  }
}