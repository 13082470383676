import React from "react"
import { useTranslation } from "react-i18next"
import { AddAlbumVideoImage } from "shared/empty-screen-images/add-video-album"
import { Button } from "antd"
import styles from "./empty-album.module.css"

export const EmptyAlbum = ({ setVisible }) => {
	const { t } = useTranslation()
	return (
		<div className={styles.main_no_albums}>
			<AddAlbumVideoImage />
			<div className={styles.no_albums_text}>{t("create-your-first-video-album")}</div>
			<Button
				onClick={() => setVisible(true)}
				className={styles.confirm_button}
			>
				{t("create-video-albums")}
			</Button>
		</div>
	)
}