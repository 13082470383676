/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useState, useRef } from "react"
import { useSelector } from "react-redux"
import { v4 as uuidv4 } from "uuid"
import {
	Upload,
	Input,
	Button,
	message,
	Image
} from "antd"
import { publishPost, getPosts } from "shared/feed-components/api"
import { UserAvatar } from "shared/avatar"
import { getImageUri } from "shared/utils"
import {
	DeleteIcon, EmojiIcon, ImageClipIcon, SendIcon, AllowClear
} from "shared/icons"
import "emoji-mart/css/emoji-mart.css"
import { Picker } from "emoji-mart"
import { useTranslation } from "react-i18next"
import { VideoCameraAddOutlined } from "@ant-design/icons"
import { IMAGE_MAXIMUM_SIZE, VIDEO_MAXIMUM_SIZE } from "shared/constants"
import styles from "./feed-input-form.module.css"

const { TextArea } = Input

export const FeedInputForm = ({
	feedType,
	topPosition,
	leftPosition,
	rightPosition,
	maxHeightValue,
	setAllPosts
}) => {
	const videoInputRef = useRef()
	const { user } = useSelector(s => s)
	const { currentUser } = user
	const { t } = useTranslation()
	const [toggle, setToggle] = useState(false)
	const [inputValue, setInputValue] = useState("")
	const [images, setImages] = useState([])
	const [visible, setVisible] = useState(false)
	const [activeInput, setActiveInput] = useState(false)
	const [fileName, setFileName] = useState([])
	const [formData, setFormData] = useState(new FormData())
	const [videoFiles, setVideoFiles] = useState([])
	const [imageFiles, setImageFiles] = useState([])

	const getBase64 = file => new Promise((resolve, reject) => {
		const reader = new FileReader()
		reader.readAsDataURL(file)
		reader.onload = () => resolve(reader.result)
		reader.onerror = error => reject(error)
	})
	const addEmoji = (emoji) => {
		setInputValue(`${inputValue}${emoji}`)
	}
	const getImage = async ({ file }) => {
		if (file) {
			if (file.size > IMAGE_MAXIMUM_SIZE) {
				message.error(t("photo-size-exceeded"))
			} else {
				const base64Image = await getBase64(file.originFileObj)
				const image = base64Image.split(",")
				if (images.length + fileName.length === 5) {
					message.error(`${t("maximum-number-of-files-reached")}`)
				} else if (images.includes(image[1])) {
					message.error(`${t("image-already-added")}`)
				} else {
					if (!image[1] || !image[0].includes("data:image")) {
						message.error(`${t("choose-correct-format")}`)
					}
					if (image[1] && image[0].includes("data:image")) {
						setImageFiles([...imageFiles, file.originFileObj])
						setImages([...images, image[1]])
					}
				}
			}
		}
	}
	const getVideoDuration = (file) => new Promise(resolve => {
		const video = document.createElement("video")
		video.onloadedmetadata = () => resolve(video.duration)
		video.src = URL?.createObjectURL(file)
	})

	const onChange = async (e) => {
		if (e.target.files[0].size > VIDEO_MAXIMUM_SIZE) {
			message.error(t("video-size-exceeded"))
		} else if (images.length + fileName.length === 5) {
			message.error(`${t("maximum-number-of-files-reached")}`)
		} else if (fileName.includes(e.target.files[0]?.name)) {
			message.error(`${t("video-already-added")}`)
		} else if (e.target.files.length) {
			const duration = await getVideoDuration(e.target.files[0])
			setFileName([...fileName, e.target.files[0].name])
			setVideoFiles([...videoFiles, e.target.files[0]])
			formData.append(`duration${e.target.files[0].name}`, duration)
		}
	}

	return (
		<div
			onMouseLeave={() => setToggle(false)}
			className={`${styles.input_form} ${feedType === "personal" ? styles.personal_feed_input : styles.full_feed_input}`}
		>
			{images.length > 0 && (
				<div className={styles.input_form_image_block}>
					{images.map((image, i) => (
						<div key={uuidv4()} className={styles.input_form_image}>
							<Image
								width={90}
								height={90}
								src={getImageUri(image)}
							/>
							<button
								className={styles.input_form_image_delete}
								type="button"
								onClick={async () => {
									imageFiles.splice(i, 1)
									setVideoFiles(imageFiles)
									setImages(images.filter(img => img !== image))
								}}
							>
								<DeleteIcon />
							</button>
						</div>
					))}
				</div>
			)}
			{fileName.length > 0 && (
				<div className={styles.input_form_video_block}>
					{fileName.map((file, index) => (
						<div key={uuidv4()} className={styles.input_form_video}>
							<button
								className={styles.input_form_delete_video_button}
								type="button"
								onClick={async () => {
									videoFiles.splice(index, 1)
									setVideoFiles(videoFiles)
									formData.delete(`duration${file}`)
									setFileName(fileName.filter(f => f !== file))
								}}
							>
								<DeleteIcon />
							</button>
							<p className={styles.input_form_file_name}>{file}</p>
						</div>
					))}
				</div>
			)}
			<div className={styles.input_form_input_block}>
				<div className={styles.input_form_input_block_avatar}>
					<UserAvatar user={currentUser} />
				</div>
				<div className={`${styles.input_block} ${activeInput && styles.active}`}>
					<TextArea
						bordered={false}
						className={styles.input_field}
						onBlur={() => {
							setActiveInput(false)
						}}
						onClick={() => setActiveInput(true)}
						onChange={(e) => {
							if (e.target.value === "") {
								setVisible(false)
							} else {
								setVisible(true)
							}
							setInputValue(e.target.value)
							setToggle(false)
						}}
						autoSize
						maxLength={300}
						value={inputValue}
					/>
					{inputValue.length === 0
						&& (
							<div className={styles.input_block_placeholder}>
								<div>{`${t("what's-on-your-mind")}, ${currentUser.firstName}?`}</div>
							</div>
						)}
					<div className={styles.btns_wrap}>
						<button
							type="button"
							className={`${styles.btns}  ${styles.emoji_picker_disabled}`}
							onClick={() => setToggle(!toggle)}
						>
							<EmojiIcon />
						</button>
						<Upload
							showUploadList={false}
							onChange={getImage}
							customRequest={() => null}
							accept="image/*"
						>
							<button type="button" className={styles.btns}>
								<ImageClipIcon />
								{images.length >= 1 && (
									<div className={styles.picked_files_count}>{images.length}</div>
								)}
							</button>
						</Upload>
						<button type="button" className={styles.btns}>
							<form>
								<div style={{ position: "relative" }}>
									<input
										type="file"
										name="file"
										id="file"
										accept="video/*"
										className={styles.input_field}
										onChange={onChange}
										ref={videoInputRef}
									/>
									<label htmlFor="file" className={styles.file_icon}>
										<VideoCameraAddOutlined className={styles.add_video_button} />
									</label>
								</div>
							</form>
						</button>
						<button
							type="button"
							className={`${styles.btns} ${!visible ? styles.btns_none : styles.btns_visible}`}
							onClick={() => {
								setInputValue("")
								setActiveInput(false)
								setVisible(!visible)
							}}
						>
							<AllowClear />
						</button>
					</div>
				</div>
				<Button
					className={styles.confirm_button}
					onClick={async () => {
						setVisible(false)
						if (inputValue.length === 0 && images.length === 0
							&& fileName.length === 0) {
							message.error(`${t("no-data-to-post")}`)
						} else {
							videoFiles.forEach(v => formData.append("files", v))
							imageFiles.forEach(i => formData.append("images", i))
							formData.append("text", inputValue)
							const response = await publishPost(formData)
							if (!response.status) {
								message.error(`${t("something-went-wrong")}`)
							} else {
								setVideoFiles([])
								videoInputRef.current.value = ""
								setFormData(new FormData())
								setImageFiles([])
								setInputValue("")
								setImages([])
								setFileName([])
								const response = await getPosts({ page: 1 })
								setAllPosts(response.posts)
								message.success(`${t("successfully-posted")}`)
							}
						}
					}}
				>
					<SendIcon />
				</Button>
			</div>
			{toggle && (
				<Picker
					showPreview={false}
					useButton={false}
					title="Emoji"
					style={{
						position: "absolute", top: topPosition, left: leftPosition, right: rightPosition, overflow: "hidden", maxHeight: maxHeightValue, zIndex: 10, width: "300px", background: "#f2f2f2"
					}}
					onSelect={el => addEmoji(el.native)}
				/>
			)}
		</div>
	)
}