import React, { useState } from "react"
import { Popover, Button } from "antd"
import { EditOutlined } from "@ant-design/icons"
import { updatePerson } from "modules/family-tree/ducks"
import { useTranslation } from "react-i18next"
import { socket } from "app/socket"
import { store } from "app/redux/store"
import { SearchModal } from "modules/family-tree/components/side-panel/sections/modal"
import styles from "./mobile-modal-header.module.css"

export const MobileHeader = ({ person, token }) => {
	const [visible, setVisible] = useState(false)
	const [visiblePopover, setVisiblePopover] = useState(false)
	const { t } = useTranslation()

	const getModal = (visible, setVisible) => (
		<SearchModal visible={visible} setVisible={setVisible} token={token} />
	)

	const contentPopover = (
		<>
			<p>{t("you-want-to-change-selected-user-profile-link-or-clear-it?")}</p>
			<div className={styles.buttons_popover}>
				<Button size="small" onClick={() => setVisiblePopover(false)} type="button">{t("close")}</Button>
				<Button
					size="small"
					onClick={() => {
						setVisiblePopover(false)
						setVisible(true)
					}}
					type="button"
				>
					{t("change")}
				</Button>
				<Button
					size="small"
					onClick={() => store.dispatch(updatePerson({
						...person,
						profileId: "",
						from: "",
						livesIn: "",
						birthDate: "",
						deathDate: "",
						gender: "unknown",
						description: "",
						avatar: "",
						inPending: false
					}))}
					type="button"
				>
					{t("clear")}
				</Button>
			</div>
		</>
	)

	const handleVisibleChange = (visible) => {
		setVisiblePopover(visible)
	}

	return (
		<div className={styles.mobile_modal_header}>
			<span>{person.name}</span>
			{person.profileId !== "" && !person.isUser && (
				<>
					<button
						type="button"
						className={styles.mobile_modal_link_btn}
						onClick={() => {
							socket.emit("GET_USER'S_LINK", { userId: person.profileId })
						}}
					>
						{t("view-profile")}
					</button>
					<div className={styles.mobile_modal_link}>
						<Popover
							overlayStyle={{ width: "300px" }}
							placement="topLeft"
							trigger="click"
							content={contentPopover}
							arrowPointAtCenter
							visible={visiblePopover}
							onVisibleChange={handleVisibleChange}
						>
							<button
								className={styles.modal_edit_link}
								type="button"
							>
								<EditOutlined />
							</button>
						</Popover>
						{getModal(visible, setVisible)}
					</div>
				</>
			)}
		</div>
	)
}