import React from "react"

export const AddPhotoImage = () => (
	<svg width="217" height="156" viewBox="0 0 217 156" fill="none">
		<path d="M107.505 142C142.756 142 171.334 113.45 171.334 78.1084C171.334 42.7668 142.63 14.2168 107.505 14.2168C72.2542 14.2168 43.6758 42.7668 43.6758 78.1084C43.6758 113.45 72.2542 142 107.505 142Z" fill="#EAEEF9" />
		<path d="M167.177 36.2268C170.028 36.2268 172.339 33.9181 172.339 31.0702C172.339 28.2223 170.028 25.9136 167.177 25.9136C164.327 25.9136 162.016 28.2223 162.016 31.0702C162.016 33.9181 164.327 36.2268 167.177 36.2268Z" fill="#E7EBF6" />
		<path d="M174.732 16.1034C176.679 16.1034 178.257 14.5267 178.257 12.5818C178.257 10.6368 176.679 9.06018 174.732 9.06018C172.785 9.06018 171.207 10.6368 171.207 12.5818C171.207 14.5267 172.785 16.1034 174.732 16.1034Z" fill="#E7EBF6" />
		<path d="M47.072 36.1009C49.0188 36.1009 50.5971 34.5242 50.5971 32.5793C50.5971 30.6344 49.0188 29.0577 47.072 29.0577C45.1251 29.0577 43.5469 30.6344 43.5469 32.5793C43.5469 34.5242 45.1251 36.1009 47.072 36.1009Z" fill="#E7EBF6" />
		<path d="M25.5466 104.017C29.1622 104.017 32.0932 101.089 32.0932 97.4771C32.0932 93.8651 29.1622 90.937 25.5466 90.937C21.931 90.937 19 93.8651 19 97.4771C19 101.089 21.931 104.017 25.5466 104.017Z" fill="#E7EBF6" />
		<g filter="url(#filter0_d)">
			<path d="M102.156 92.0798L59.4275 121.744C56.9558 123.443 53.5883 122.856 51.8581 120.384L23.4962 79.5036C21.797 77.0316 22.384 73.6636 24.8556 71.9332L67.5838 42.2695C70.0555 40.57 73.423 41.1571 75.1532 43.629L103.546 84.5094C105.245 86.9813 104.627 90.3803 102.156 92.0798Z" fill="url(#paint0_linear)" />
		</g>
		<path d="M95.5466 87.5991L57.9779 113.678C56.9275 114.42 55.4754 114.142 54.7339 113.091L31.2844 79.2871C30.5429 78.2365 30.821 76.7842 31.8714 76.0426L69.4401 49.9633C70.4905 49.2217 71.9426 49.4998 72.6841 50.5504L96.1336 84.3547C96.8751 85.4053 96.597 86.8575 95.5466 87.5991Z" fill="#EAEEF9" />
		<path d="M68.6316 87.3213L54.9141 96.8384L56.3661 84.324L68.6316 87.3213Z" fill="#B0B7C4" />
		<path d="M79.9726 79.442L60.2305 93.1615L62.3313 75.147L79.9726 79.442Z" fill="#CED6E2" />
		<path d="M50.9326 83.2115C52.673 83.2115 54.0839 81.8004 54.0839 80.0597C54.0839 78.3191 52.673 76.908 50.9326 76.908C49.1921 76.908 47.7812 78.3191 47.7812 80.0597C47.7812 81.8004 49.1921 83.2115 50.9326 83.2115Z" fill="#B0B7C4" />
		<g filter="url(#filter1_d)">
			<path d="M153.905 117.325L114.204 83.7057C111.918 81.759 111.64 78.3291 113.556 76.0426L145.718 38.0668C147.664 35.7802 151.093 35.5021 153.38 37.4179L193.08 71.0368C195.366 72.9835 195.644 76.4134 193.729 78.6999L161.567 116.676C159.62 118.962 156.191 119.24 153.905 117.325Z" fill="url(#paint1_linear)" />
		</g>
		<path d="M156.532 109.785L121.62 80.2449C120.631 79.4106 120.508 77.9583 121.342 76.9695L147.912 45.5754C148.746 44.5866 150.198 44.463 151.187 45.2973L186.098 74.8374C187.087 75.6717 187.211 77.124 186.376 78.1128L159.806 109.507C158.972 110.496 157.52 110.619 156.532 109.785Z" fill="#EAEEF9" />
		<path d="M149.827 83.6748L137.098 72.8908L149.579 71.0677L149.827 83.6748Z" fill="#B0B7C4" />
		<path d="M160.391 92.6051L142.039 77.0625L159.989 74.4669L160.391 92.6051Z" fill="#CED6E2" />
		<path d="M152.3 67.8853C154.04 67.8853 155.451 66.4742 155.451 64.7336C155.451 62.9929 154.04 61.5818 152.3 61.5818C150.559 61.5818 149.148 62.9929 149.148 64.7336C149.148 66.4742 150.559 67.8853 152.3 67.8853Z" fill="#B0B7C4" />
		<g filter="url(#filter2_d)">
			<path d="M135.926 82.8678L84.8214 92.7015C81.8701 93.2611 79.0205 91.3278 78.4376 88.4036L68.9746 39.5658C68.411 36.6175 70.3434 33.7737 73.2707 33.1947L124.376 23.3611C127.327 22.8015 130.176 24.7347 130.759 27.659L140.222 76.4967C140.786 79.445 138.854 82.2889 135.926 82.8678Z" fill="url(#paint2_linear)" />
		</g>
		<path d="M131.695 76.0981L86.7733 84.7603C85.5029 85.007 84.2927 84.193 84.0442 82.9239L76.21 42.5643C75.9616 41.2953 76.7753 40.0874 78.0457 39.8407L122.968 31.1785C124.238 30.9318 125.448 31.7458 125.697 33.0149L133.531 73.3745C133.779 74.6436 132.966 75.8514 131.695 76.0981Z" fill="#EAEEF9" />
		<path d="M107.128 64.952L90.7384 68.1017L97.1394 57.2428L107.128 64.952Z" fill="#B0B7C4" />
		<path d="M120.712 62.3238L97.087 66.8682L106.313 51.2683L120.712 62.3238Z" fill="#CED6E2" />
		<path d="M96.3606 53.1321C97.4514 51.7772 97.235 49.7945 95.8773 48.7036C94.5196 47.6126 92.5347 47.8265 91.4439 49.1813C90.353 50.5361 90.5694 52.5188 91.9271 53.6098C93.2848 54.7008 95.2697 54.4869 96.3606 53.1321Z" fill="#B0B7C4" />
		<path d="M136.622 113.538C136.021 116.088 135.12 118.787 134.069 121.037C131.216 126.587 126.712 130.937 121.157 133.787C115.451 136.637 108.695 137.837 101.938 136.337C86.0233 133.037 75.8136 117.438 79.1167 101.538C82.4199 85.6388 97.8846 75.2893 113.8 78.7391C119.505 79.9391 124.46 82.789 128.664 86.6888C135.721 93.7385 138.724 103.938 136.622 113.538Z" fill="url(#paint3_linear)" />
		<path d="M117.256 104.988H110.5V98.2381C110.5 96.8882 109.449 95.6882 107.947 95.6882C106.596 95.6882 105.395 96.7382 105.395 98.2381V104.988H98.6384C97.2871 104.988 96.0859 106.038 96.0859 107.538C96.0859 109.038 97.1369 110.088 98.6384 110.088H105.395V116.837C105.395 118.187 106.446 119.387 107.947 119.387C109.299 119.387 110.5 118.337 110.5 116.837V110.088H117.256C118.607 110.088 119.809 109.038 119.809 107.538C119.809 106.038 118.607 104.988 117.256 104.988Z" fill="white" />
		<defs>
			<filter id="filter0_d" x="0.527344" y="30.3004" width="125.977" height="125.412" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
				<feFlood floodOpacity="0" result="BackgroundImageFix" />
				<feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" />
				<feOffset dy="11" />
				<feGaussianBlur stdDeviation="11" />
				<feColorMatrix type="matrix" values="0 0 0 0 0.397708 0 0 0 0 0.47749 0 0 0 0 0.575 0 0 0 0.27 0" />
				<feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
				<feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
			</filter>
			<filter id="filter1_d" x="90.2852" y="25.1473" width="126.714" height="126.448" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
				<feFlood floodOpacity="0" result="BackgroundImageFix" />
				<feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" />
				<feOffset dy="11" />
				<feGaussianBlur stdDeviation="11" />
				<feColorMatrix type="matrix" values="0 0 0 0 0.397708 0 0 0 0 0.47749 0 0 0 0 0.575 0 0 0 0.27 0" />
				<feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
				<feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
			</filter>
			<filter id="filter2_d" x="46.875" y="12.2646" width="115.443" height="113.533" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
				<feFlood floodOpacity="0" result="BackgroundImageFix" />
				<feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" />
				<feOffset dy="11" />
				<feGaussianBlur stdDeviation="11" />
				<feColorMatrix type="matrix" values="0 0 0 0 0.397708 0 0 0 0 0.47749 0 0 0 0 0.575 0 0 0 0.27 0" />
				<feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
				<feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
			</filter>
			<linearGradient id="paint0_linear" x1="63.4893" y1="39.4173" x2="63.4893" y2="123.59" gradientUnits="userSpaceOnUse">
				<stop stopColor="#FDFEFF" />
				<stop offset="0.9964" stopColor="#ECF0F5" />
			</linearGradient>
			<linearGradient id="paint1_linear" x1="153.615" y1="34.2402" x2="153.615" y2="119.484" gradientUnits="userSpaceOnUse">
				<stop stopColor="#FDFEFF" />
				<stop offset="0.9964" stopColor="#ECF0F5" />
			</linearGradient>
			<linearGradient id="paint2_linear" x1="70.9398" y1="31.0196" x2="137.377" y2="84.5112" gradientUnits="userSpaceOnUse">
				<stop stopColor="#FDFEFF" />
				<stop offset="0.9964" stopColor="#ECF0F5" />
			</linearGradient>
			<linearGradient id="paint3_linear" x1="78.4692" y1="107.537" x2="137.291" y2="107.537" gradientUnits="userSpaceOnUse">
				<stop stopColor="#B0BACC" />
				<stop offset="1" stopColor="#969EAE" />
			</linearGradient>
		</defs>
	</svg>
)