import React, { useEffect, useState } from "react"
import { store } from "app/redux/store"
import { useDispatch, useSelector } from "react-redux"
import CustomScroll from "react-scrollbars-custom"
import { updateUser, setUser } from "app/redux/user"
import { CancelButton } from "shared/buttons"
import moment from "moment"
import { autoLogin } from "app/api"
import {
	Form, Button, message
} from "antd"
import { updatePersonalInfo } from "modules/personal-info/api"
import { useTranslation } from "react-i18next"
import { AppSpinner } from "shared/app-spinner/app-spinner"
import { UserPreview } from "./user-preview"
import styles from "../personal-info.module.css"

export const PersonalInfoGeneral = () => {
	const [form] = Form.useForm()
	const { user } = useSelector(s => s)
	const { currentUser } = user
	const dispatch = useDispatch()
	const { t } = useTranslation()
	const [personalInfoAvatar, setPersonalInfoAvatar] = useState("")
	const [isLoading, setIsLoading] = useState(false)
	const [currentUserLocation, setCurrentUserLocation] = useState(currentUser.location)

	useEffect(() => {
		setPersonalInfoAvatar(currentUser.avatar)
	}, [currentUser])

	const getBase64 = file => new Promise((resolve, reject) => {
		const reader = new FileReader()
		reader.readAsDataURL(file)
		reader.onload = () => resolve(reader.result)
		reader.onerror = error => reject(error)
	})

	const avatarChange = async ({ file }) => {
		if (file) {
			const base64Avatar = await getBase64(file.originFileObj)
			const avatar = base64Avatar.split(",")
			setPersonalInfoAvatar(avatar[1])
		}
	}

	const saveChanges = async (fieldsValue) => {
		const values = {
			...fieldsValue,
			birthDate: !fieldsValue.birthDate ? "" : fieldsValue.birthDate.format("DD/MM/YYYY")
		}

		const {
			firstName, lastName, birthDate, birthPlace, gender, familyStatus, maidenName
		} = values
		const avatar = personalInfoAvatar
		setIsLoading(true)

		const response = await updatePersonalInfo({
			firstName,
			lastName,
			avatar,
			birthDate,
			familyStatus,
			birthPlace,
			gender,
			maidenName,
			location: currentUserLocation
		})
		const success = (response) => {
			message.success(response)
		}
		const error = (response) => {
			message.error(response)
		}
		if (response.status) {
			success(t("updated-successfully"))
		} else {
			error(t("something-went-wrong"))
		}

		store.dispatch(updateUser({
			...currentUser,
			firstName,
			lastName,
			avatar: response.avatar,
			birthDate,
			birthPlace,
			familyStatus,
			gender,
			maidenName,
			coordinates: response.coordinates,
			location: currentUserLocation
		}))

		setIsLoading(false)
	}

	const cancelChange = async () => {
		const token = localStorage.token ? localStorage.getItem("token") : sessionStorage.getItem("token")
		const loadedData = await autoLogin(token)
		setPersonalInfoAvatar(loadedData.avatar)
		dispatch(setUser(loadedData))
		console.log(dispatch(setUser(loadedData)))
		form.setFieldsValue({
			firstName: loadedData.firstName,
			lastName: loadedData.lastName,
			birthPlace: loadedData.birthPlace,
			gender: loadedData.gender,
			maidenName: loadedData.maidenName,
			city: loadedData.location?.name ? loadedData.location.name : "",
			birthDate: !loadedData.birthDate ? "" : moment(loadedData.birthDate, "DD/MM/YYYY"),
			familyStatus: loadedData.familyStatus ? loadedData.familyStatus : null
		})
		message.info(`${t("all-changes-cancelled")}`)
	}

	return (
		<Form
			name="personal-info"
			className={styles.container}
			initialValues={{
				firstName: currentUser.firstName,
				lastName: currentUser.lastName,
				city: currentUser.location?.name ? currentUser.location.name : "",
				birthDate: !currentUser.birthDate ? "" : moment(currentUser.birthDate, "DD/MM/YYYY"),
				birthPlace: currentUser.birthPlace,
				gender: currentUser.gender,
				maidenName: currentUser.maidenName,
				familyStatus: currentUser.familyStatus ? currentUser.familyStatus : null
			}}
			form={form}
			onFinish={saveChanges}
		>
			<div className={styles.header}>{t("personal-info")}</div>
			<div className={styles.main_content_container}>
				<div className={styles.content}>
					<CustomScroll style={{ position: "relative" }}>
						{isLoading ? (
							<AppSpinner
								loadingOutlinedStyle={{ fontSize: 64, marginRight: "10px" }}
								style={{
									position: "absolute", width: "100%", height: "100%", top: 0, bottom: 0
								}}
								tip="Saving changes..."
							/>
						) : (
							<UserPreview
								form={form}
								setCurrentUserLocation={setCurrentUserLocation}
								avatarChange={avatarChange}
								personalInfoAvatar={personalInfoAvatar}
								setPersonalInfoAvatar={setPersonalInfoAvatar}
							/>
						)}
					</CustomScroll>
				</div>
			</div>
			<div className={styles.footer}>
				<CancelButton cancel={cancelChange} />
				<Form.Item style={{ margin: "0px" }}>
					<Button type="primary" htmlType="submit" loading={isLoading} className={styles.confirm_button}>
						{t("save")}
					</Button>
				</Form.Item>
			</div>
		</Form>
	)
}
