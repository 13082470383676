import React, { useState } from "react"
import { ToolbarAdvancedOprions } from "shared/icons"
import { Zoom } from "./zoom"
import { AdaptiveZoom } from "./adaptive-zoom"
import { TreeDirection } from "./tree-direction"
import styles from "./toolbar.module.css"

export const Toolbar = () => {
	const [isAdvancedOptionsOpened, setIsAdvancedOptionsOpened] = useState(false)

	return (
		<div className={styles.toolbar}>
			<Zoom />
			<button
				title="advanced options"
				type="button"
				className={!isAdvancedOptionsOpened ? `${styles.toolbar_adavanced_options_btn}` : `${styles.toolbar_adavanced_options_btn} ${styles.toolbar_adavanced_options_btn_active}`}
				onClick={() => setIsAdvancedOptionsOpened(!isAdvancedOptionsOpened)}
			>
				<ToolbarAdvancedOprions />
			</button>
			<div className={!isAdvancedOptionsOpened ? `${styles.toolbar_adavanced_options_container}` : `${styles.toolbar_adavanced_options_container} ${styles.toolbar_adavanced_options_container_opened}`}>
				<AdaptiveZoom />
				<TreeDirection />
			</div>
		</div>
	)
}