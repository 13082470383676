import React, { useRef, useEffect, useState } from "react"
import { socket } from "app/socket"
import { useSelector } from "react-redux"
import { FullScreenImage } from "shared/full-screen-image"
import { DeleteChatMemberModal } from "./modals/delete-chat-member"
import { ShowChatMembersModal } from "./modals/show-chat-members"
import { ChatInputForm } from "../chat-input"
import { CreateGroupChat } from "../create-group-chat"
import styles from "./chat-correspondence.module.css"
import { MessagesHeader } from "./messages-header/messages-header"
import { DeleteMessage } from "./delete-message/delete-massage"
import { RenderingMessages } from "./rendering-messages/rendering-messages"

export const ChatCorrespondence = ({
	chatlist, selectedChat, setSelectedChat, userId
}) => {
	const { user } = useSelector(s => s)
	const { currentUser } = user

	const chatScrollbar = useRef()
	const [selectedMessages, setSelectedMessages] = useState([])
	const [isActive, setIsActive] = useState(false)
	const [selectedPhoto, setSelectedPhoto] = useState(null)
	const [allPhotos, setAllPhotos] = useState([])
	const [isShowModal, setIsShowModal] = useState(false)
	const [currentUserConnections, setCurrentUserConnections] = useState([])
	const [isShowDeleteModal, setIsShowDeleteModal] = useState(false)
	const [isShowParticipiantsModal, setIsShowParticipiantsModal] = useState(false)
	const [visiblePopover, setVisiblePopover] = useState(false)

	useEffect(() => {
		if (chatScrollbar.current) {
			setSelectedMessages([])
			chatScrollbar.current.scrollToBottom()
		}
	}, [chatlist, selectedChat])

	return (
		< >
			{
				chatlist.map(chat => {
					if (selectedChat === chat._id) {
						chat.messages.find(message => !message.whoRead.includes(currentUser._id))
						if (chat.messages.find(message => !message.whoRead.includes(currentUser._id))) {
							socket.emit("ROOM:READ_MESSAGES", selectedChat, currentUser._id)
						}
						const chatCompanion = chat.users.find(user => user._id !== currentUser._id)

						return (
							<div
								onMouseLeave={() => {
									setVisiblePopover(false)
								}}
								key={chat._id}
								className={styles.messages_wrap}
							>
								<ShowChatMembersModal
									isShowParticipiantsModal={isShowParticipiantsModal}
									setIsShowParticipiantsModal={setIsShowParticipiantsModal}
									chatMembers={chat.users.filter(user => user._id !== currentUser._id)}
									chat={chat}
									currentUser={currentUser}
								/>
								<DeleteChatMemberModal
									isShowDeleteModal={isShowDeleteModal}
									setIsShowDeleteModal={setIsShowDeleteModal}
									chatMembers={chat.users.filter(user => user._id !== currentUser._id)}
									roomId={chat._id}
								/>
								<CreateGroupChat
									isShowModal={isShowModal}
									setIsShowModal={setIsShowModal}
									currentUserConnections={currentUserConnections}
									isAddUser
									roomId={selectedChat}
									chatMembers={chat.users.filter(user => user._id !== currentUser._id)}
								/>
								<MessagesHeader
									setVisiblePopover={setVisiblePopover}
									visiblePopover={visiblePopover}
									setIsShowModal={setIsShowModal}
									setSelectedChat={setSelectedChat}
									selectedChat={selectedChat}
									currentUser={currentUser}
									setCurrentUserConnections={setCurrentUserConnections}
									userId={userId}
									setIsShowDeleteModal={setIsShowDeleteModal}
									setIsShowParticipiantsModal={setIsShowParticipiantsModal}
									chat={chat}
									chatCompanion={chatCompanion}
								/>
								<DeleteMessage
									selectedMessages={selectedMessages}
									setSelectedMessages={setSelectedMessages}
									chatId={chat._id}
								/>
								<RenderingMessages
									chat={chat}
									setVisiblePopover={setVisiblePopover}
									chatScrollbar={chatScrollbar}
									currentUser={currentUser}
									selectedMessages={selectedMessages}
									setIsActive={setIsActive}
									setSelectedPhoto={setSelectedPhoto}
									setSelectedMessages={setSelectedMessages}
									setAllPhotos={setAllPhotos}
								/>
								<ChatInputForm
									chat={chat}
									chatId={selectedChat}
									currentUser={currentUser}
									setVisiblePopover={setVisiblePopover}
								/>
							</div>
						)
					}
					return null
				})
			}
			<FullScreenImage
				isActive={isActive}
				selectedPhoto={selectedPhoto}
				allPhotos={allPhotos}
				setIsActive={setIsActive}
				setSelectedPhoto={setSelectedPhoto}
				isPhotoWithComments={false}
			/>
		</>
	)
}
