export const setUserOnline = async (socketId) => {
  const token = localStorage.token ? localStorage.getItem("token") : sessionStorage.getItem("token")
  const userInfo = {
    socketId
  }
  try {
    const res = await fetch("/api/auth/login", {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Authorization: token
      },
      body: JSON.stringify(userInfo)
    })
    const json = await res.json()
    return json
  } catch (error) {
    console.error("Error:", error)
  }
}