import React, { useState, useEffect } from "react"
import { Empty } from "antd"
import { useLocation } from "react-router-dom"
import { MessagesStartScreenImage } from "shared/empty-screen-images"
import { useSelector } from "react-redux"
import { ChatList } from "./components/chat-list"
import { ChatCorrespondence } from "./components/chat-correspondence"
import { CreateGroupChat } from "./components/create-group-chat"
import { CreateChat } from "./components/create-chat/create-chat"
import { EmptyMessagesButtons } from "./components/empty-messages-buttons"
import styles from "./messages.module.css"

export const Messages = () => {
	const { messages } = useSelector(s => s)
	const { user } = useSelector(s => s)
	const { currentUser } = user

	const location = useLocation()
	const { userId } = location
	const [isShowModal, setIsShowModal] = useState(false)
	const [isShowModalChat, setIsShowModalChat] = useState(false)
	const [currentUserConnections, setCurrentUserConnections] = useState([])
	const [selectedChat, setSelectedChat] = useState("")
	const [chatlist, setChatList] = useState([])

	useEffect(() => {
		if (userId && selectedChat === "") {
			const selected = messages.find(message => message.users.length === 2
				&& message.users.find(u => u._id === userId)
				&& message.users.find(u => u._id === currentUser._id))
			setSelectedChat(selected._id)
		}
		setChatList([...messages])
	}, [currentUser._id, messages, selectedChat, setSelectedChat, userId])

	return (
		<>
			<CreateGroupChat
				isShowModal={isShowModal}
				setIsShowModal={setIsShowModal}
				currentUserConnections={currentUserConnections}
				isAddUser={false}
				chatMembers={[]}
			/>
			<CreateChat
				isShowModal={isShowModalChat}
				setIsShowModal={setIsShowModalChat}
				currentUserConnections={currentUserConnections}
			/>
			<div className={styles.wrap}>
				<div className={styles.container}>
					{messages && messages.length > 0 && (
						<ChatList
							selectedChat={selectedChat}
							setSelectedChat={setSelectedChat}
							userId={userId}
						/>
					)}
					{selectedChat ? (
						<ChatCorrespondence
							chatlist={messages}
							selectedChat={selectedChat}
							setSelectedChat={setSelectedChat}
							userId={userId}
						/>
					) : (
						<Empty
							className={messages && messages.length > 0
								? styles.empty_wrap
								: styles.empty_wrap_fullsize}
							description={(
								<EmptyMessagesButtons
									setCurrentUserConnections={setCurrentUserConnections}
									setIsShowModal={setIsShowModal}
									chatlist={chatlist}
									setIsShowModalChat={setIsShowModalChat}
								/>
							)}
							image={(
								<MessagesStartScreenImage />
							)}
						/>
					)}
				</div>
			</div>
		</>
	)
}