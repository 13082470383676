import React from "react"
import { Switch } from "antd"
import styles from "modules/settings/settings.module.css"

export const SettingElement = ({
	labelText, labelDescription, value, setValue
}) => (
	<div className={styles.setting_container}>
		<div>
			<p className={styles.setting_container_label}>{labelText}</p>
			<p className={styles.setting_container_label_description}>
				{labelDescription}
			</p>
		</div>
		<Switch
			checked={value}
			onChange={(e) => {
				setValue(e)
			}}
		/>
	</div>
)
