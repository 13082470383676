import i18n from "i18next"
import Backend from "i18next-xhr-backend"
import LanguageDetector from "i18next-browser-languagedetector"
import { initReactI18next } from "react-i18next"
import en from "./en.json"
import ru from "./ru.json"
import bel from "./bel.json"
import ro from "./ro.json"

const resources = {
  en: {
    translation: en
  },
  ru: {
    translation: ru
  },
  bel: {
    translation: bel
  },
  ro: {
    translation: ro
  }
}

const options = {

  order: ["navigator", "htmlTag", "path", "subdomain"],

  lookupQuerystring: "lng",
  lookupCookie: "i18next",
  lookupLocalStorage: "i18nextLng",
  lookupFromPathIndex: 0,
  lookupFromSubdomainIndex: 0,

  caches: ["localStorage", "cookie"],
  excludeCacheFor: ["cimode"],

  cookieMinutes: 10,
  cookieDomain: "myDomain",

  htmlTag: document.documentElement,

  checkWhitelist: true
}

const fallbackLng = ["en"]
const availableLanguages = ["en", "ru", "bel", "ro"]

export const i18next = i18n
  .use(Backend)
  .use(LanguageDetector)
  .use(initReactI18next)

  .init({
    resources,
    fallbackLng,
    debug: true,
    whitelist: availableLanguages,
    detection: options,

    interpolation: {
      escapeValue: false
    }
  })
