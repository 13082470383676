import React from "react"
import { Select } from "antd"
import { dropDownOptions } from "modules/settings/data"
import styles from "modules/settings/settings.module.css"
import { useTranslation } from "react-i18next"

const { Option } = Select

export const SettingElementDropdown = ({
	labelText, labelDescription, value, setValue, privacySettings
}) => {
	const { t } = useTranslation()

	return (
		<div className={styles.setting_container}>
			<div>
				<p className={styles.setting_container_label}>{labelText}</p>
				<p className={styles.setting_container_label_description}>
					{labelDescription}
				</p>
			</div>
			<Select
				value={value}
				dropdownClassName={styles.dropdown_container}
				className={styles.select_small_screen}
				bordered={false}
				onSelect={(e) => {
					setValue(e)
				}}
			>
				{dropDownOptions.map(opt => {
					if (opt === "public") {
						if (!privacySettings) {
							return <Option key={opt} title={t(opt)} value={opt}>{t(opt)}</Option>
						}
						return null
					}
					return (
						<Option key={opt} title={t(opt)} value={opt}>{t(opt)}</Option>
					)
				})}
			</Select>
		</div>
	)
}