import React from "react"

export const EditIcon = () => (
	<svg width="24" height="24" viewBox="0 0 24 24">
		<path d="M21.1937 2.44922L19.5609 0.816406C19.0453 0.300781 18.3148 0 17.6273 0C16.9398 0 16.2093 0.300781 15.6937 0.816406L0.525746 15.9844L0.0101207 20.8828C-0.0758168 21.4844 0.396839 22 0.998402 22C1.04137 22 1.08434 22 1.12731 22L6.02575 21.4844L21.1937 6.31641C22.2679 5.24219 22.2679 3.52344 21.1937 2.44922ZM5.08043 19.5078L2.20153 19.8086L2.50231 16.9297L12.4281 6.96094L13.7601 5.62891L16.3812 8.25L15.0492 9.58203L5.08043 19.5078ZM19.7328 4.85547L17.8422 6.78906L15.2211 4.16797L17.1547 2.27734C17.3265 2.10547 17.5414 2.0625 17.6273 2.0625C17.7132 2.0625 17.9281 2.10547 18.1 2.27734L19.7328 3.91016C19.9906 4.16797 19.9906 4.59766 19.7328 4.85547Z" />
	</svg>
)
export const DeleteIcon = () => (
	<svg width="24" height="24" viewBox="0 0 24 24" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
		<polyline points="3 6 5 6 21 6" />
		<path d="M19 6v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6m3 0V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2" />
	</svg>
)

export const AddAvatarIcon = () => (
	<svg width="54" height="54" viewBox="0 0 24 24" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
		<path d="M23 19a2 2 0 0 1-2 2H3a2 2 0 0 1-2-2V8a2 2 0 0 1 2-2h4l2-3h6l2 3h4a2 2 0 0 1 2 2z" />
		<circle cx="12" cy="13" r="4" />
	</svg>
)

export const PlusIcon = () => (
	<svg width="36" height="36" viewBox="0 0 24 24" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
		<line x1="12" y1="5" x2="12" y2="19" />
		<line x1="5" y1="12" x2="19" y2="12" />
	</svg>
)
