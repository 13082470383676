import React, { useState } from "react"
import { useSelector } from "react-redux"
import { getPersonById, findChildren, findParents } from "modules/family-tree/utils"
import CustomScroll from "react-scrollbars-custom"
import styles from "./side-panel.module.css"
import {
	Header,
	MainSection,
	SelectGender,
	AvatarUpload,
	DeletePerson,
	DeleteNotification,
	SaveChangesButton
} from "./sections"

const isShowDeleteButton = (id, selectedPerson, personPartner) => {
	const personChildren = id === "person21" ? findChildren("person22") : findChildren(id)
	const childGenders = personChildren.map(p => p.gender)
	const personParents = findParents(id)
	const parentGenders = personParents.map(p => p.gender)
	const addChildToParentCard = childGenders.find(gender => gender === "")

	if (selectedPerson.parents.length !== 0 && parentGenders.every(p => p === "") && personChildren.length === 1 && childGenders !== "") {
		return (
			<DeletePerson
				person={selectedPerson}
				personChildren={personChildren}
				childGenders={childGenders}
				parentGenders={parentGenders}
				addChildToParentCard={addChildToParentCard}
			/>
		)
	}

	if (selectedPerson.parents.length !== 0 && parentGenders.every(p => p === "") && addChildToParentCard === "") {
		const unhiddenChilds = personChildren.filter(child => !child.hide)
		if (personChildren.length <= 2) {
			return (
				<DeletePerson
					person={selectedPerson}
					personChildren={personChildren}
					childGenders={childGenders}
					parentGenders={parentGenders}
					addChildToParentCard={addChildToParentCard}
					selectedPersonPartner={personPartner}
				/>
			)
		}
		if (personChildren.length >= 2
			&& unhiddenChilds.length === 2
			&& unhiddenChilds.find(child => child.name === "Add child ")
			&& personChildren.find(child => child.hide)) {
			return (
				<DeletePerson
					person={selectedPerson}
					personChildren={personChildren}
					childGenders={childGenders}
					parentGenders={parentGenders}
					addChildToParentCard={addChildToParentCard}
				/>
			)
		}
	}

	if (selectedPerson.parents.length !== 0 && personChildren.length === 1 && addChildToParentCard === "") {
		return (
			<DeletePerson
				person={selectedPerson}
				personChildren={personChildren}
				childGenders={childGenders}
				parentGenders={parentGenders}
				addChildToParentCard={addChildToParentCard}
			/>
		)
	}
	return (<DeleteNotification person={selectedPerson} />)
}

const getContent = (id, selectedPerson, personPartner) => {
	if (id) {
		return (
			<>
				<Header person={selectedPerson} />
				<CustomScroll>
					<AvatarUpload
						person={selectedPerson}
						withoutBtns={selectedPerson.isUser || selectedPerson.profileId}
					/>
					<MainSection
						person={selectedPerson}
						id={id}
						isPersonAlive={selectedPerson.deathDate === "" || !selectedPerson.deathDate}
					/>
					{!selectedPerson.isUser && selectedPerson.gender !== "" && selectedPerson.gender === "" ? null : (<SelectGender person={selectedPerson} />)}
					<SaveChangesButton />
					{isShowDeleteButton(id, selectedPerson, personPartner)}
				</CustomScroll>
			</>
		)
	}
	return null
}

export const SidePanel = () => {
	const { selectedPersonId } = useSelector(s => s.familyTree)
	const selectedPerson = getPersonById(selectedPersonId)
	const personPartner = getPersonById("person21")
	const [isFocusedInput, setIsFocusedInput] = useState(false)

	return (

		<div
			role="button"
			tabIndex={0}
			onMouseDown={() => setIsFocusedInput(true)}
			onMouseUp={() => setIsFocusedInput(false)}
			className={selectedPersonId ? `${styles.side_panel}` : `${styles.side_panel} ${styles.disabled}`}
		>
			<div className={isFocusedInput ? `${styles.side_panel_border}` : `${styles.side_panel_border_disabled}`} />
			{getContent(selectedPersonId, selectedPerson, personPartner)}
		</div>
	)
}