import React from "react"
import { useTranslation } from "react-i18next"
import { socket } from "app/socket"
import { CALL_ENDED } from "app/navigation/main"

export const ModalFooter = (props) => {
	const {
		receivingCall,
		name,
		answerCall,
		callAccepted,
		callEnded,
		leaveCall,
		selectedConnections,
		callBtnDisabled,
		callUser,
		currentUser,
		stream
	} = props

	const id = selectedConnections.from || selectedConnections._id

	const { t } = useTranslation()

	return (
		(
			<div>
				<div className="myId">
					<div className="call-button">
						<div>
							{receivingCall && !callAccepted ? (
								<div className="caller">
									<h3>
										{name}
										{" "}
										{t("is-calling")}
									</h3>
									<button
										type="button"
										variant="contained"
										color="primary"
										disabled={!stream}
										onClick={answerCall}
									>
										{t("answer")}
									</button>
								</div>
							) : null}
						</div>
						{callAccepted && !callEnded ? null : (
							<button
								type="button"
								color="primary"
								aria-label="call"
								onClick={() => {
									leaveCall()
									socket.emit("CALL_CANCELED_ENDED", [CALL_ENDED, id])
								}}
							>
								{t("cancel")}
							</button>
						)}

						{callAccepted && !callEnded ? (
							<button
								type="button"
								variant="contained"
								color="secondary"
								onClick={() => {
									leaveCall()
									socket.emit("CALL_CANCELED_ENDED", [CALL_ENDED, id])
								}}
							>
								{t("end-call")}
							</button>
						) : (
							selectedConnections?.firstName && (
								<button
									type="button"
									className="callButton"
									aria-label="call"
									disabled={callBtnDisabled || !stream}
									onClick={callUser}
								>
									{!callBtnDisabled ? `${t("call")} ${selectedConnections?.firstName
										|| currentUser.callUser.name
										}` : `${t("calling")}...`}
								</button>
							)
						)}
					</div>
				</div>
			</div>
		)
	)
}