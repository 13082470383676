import React from "react"
import { NavLink } from "react-router-dom"
import { Popover } from "antd"
import { AddVideo } from "shared/empty-screen-images"
import { useTranslation } from "react-i18next"
import { MoreIcon } from "shared/icons"
import ReactPlayer from "react-player"
import { baseUri } from "shared/constants"
import styles from "./album-list.module.css"

export const AldumList = ({
	albums, setVisiblePopover, PopoverContent, setIsEditMode,
	setVisible, setCurrentKey, visiblePopover, setAlbums, currentKey,
	setIsAlbumCommentsActive, setSelectedAlbum, setIsVideoAlbum,
	setCurrentAlbum
}) => {
	const { t } = useTranslation()
	return (
		<>
			{
				albums.map(album => (
					<div
						key={album.id}
						className={styles.album_card}
						onMouseLeave={() => {
							setVisiblePopover(false)
						}}
					>
						<Popover
							placement="bottomRight"
							content={() => (
								<PopoverContent
									setCurrentAlbum={setCurrentAlbum}
									setIsVideoAlbum={setIsVideoAlbum}
									fullAlbume={album}
									setIsEditMode={setIsEditMode}
									album={album.id}
									setVisible={setVisible}
									setVisiblePopover={setVisiblePopover}
									setAlbums={setAlbums}
									setIsAlbumCommentsActive={setIsAlbumCommentsActive}
									setSelectedAlbum={setSelectedAlbum}
								/>
							)}
							trigger="click"
							visible={currentKey === album.id && visiblePopover}
						>
							<button
								className={styles.albumn_more_btn}
								style={{ fontSize: "24px" }}
								type="button"
								onClick={() => {
									setCurrentKey(album.id)
									setVisiblePopover(!visiblePopover)
								}}
							>
								<MoreIcon />
							</button>
						</Popover>
						<NavLink to={`/video-gallery/video/${album.id}`}>
							{" "}
							<div className={styles.album_card_content}>
								{album.videos.length > 0 ? (
									<div
										style={{ cursor: "pointer" }}
									>
										<div className={styles.album_card_video}>
											{" "}
											<ReactPlayer
												width="100%"
												height="100%"
												url={`${baseUri}/${album.videos[album.videos.length - 1].path}`}
											/>
										</div>
										<h3 className={styles.title_album}>{album.name}</h3>
										<h4 className={styles.counter_items}>
											{album.videos.length}
											{" "}
											{t("videos")}
										</h4>
									</div>
								) : (
									<>
										<div className={styles.img}>
											<AddVideo />
										</div>
										<h3 className={styles.title_album}>{album.name}</h3>
										<h4 className={styles.counter_items}>
											{album.videos.length}
											{" "}
											{t("videos")}
										</h4>
									</>
								)}
							</div>
						</NavLink>

					</div>
				))
			}
		</>
	)
}