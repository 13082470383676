import { v4 as uuidv4 } from "uuid"
import { store } from "app/redux/store"
import { addParent } from "modules/family-tree/ducks"

export const addParentNode = childId => {
  store.dispatch(addParent({
    positionId: `person${uuidv4()}`,
    name: "Add parent ",
    from: "",
    livesIn: "",
    birthDate: "",
    deathDate: "",
    gender: "",
    parents: [],
    description: "",
    avatar: "",
    withoutChild: true,
    lastGeneration: true,
    hide: true,
    profileId: ""
  },
    {
      positionId: `person${uuidv4()}`,
      name: "Add parent ",
      from: "",
      livesIn: "",
      birthDate: "",
      deathDate: "",
      gender: "",
      parents: [],
      description: "",
      avatar: "",
      withoutChild: true,
      lastGeneration: true,
      hide: true,
      profileId: ""
    },
    childId))
}