export const restorePasswordConfirm = async (
  code, email
) => {
  try {
    const regInfo = {
      code, email
    }
    const res = await fetch("/api/auth/confirm-restore-password", {
      method: "PUT",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify(regInfo)
    })
    const json = await res.json()
    return json
  } catch (error) {
    const message = "Something went wrong"
    return message
  }
}