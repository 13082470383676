import React, { useState } from "react"
import { Button } from "antd"
import { store } from "app/redux/store"
import { updatePerson } from "modules/family-tree/ducks"
import { AppSpinner } from "shared/app-spinner/app-spinner"
import { ImageCropper } from "shared/image-cropper"
import {
	deleteTreeAvatar, resizeImage
} from "modules/family-tree/api"
import { useTranslation } from "react-i18next"
import { MaleIcon, FemaleIcon, UnknownGenderIcon } from "modules/family-tree/icons"
import { getImageUri } from "shared/utils"
import {
	baseUri
} from "shared/constants"
import { UploadIcon, ClearIcon } from "../side-panel-icons"
import styles from "../side-panel.module.css"

export const AvatarUpload = ({ person, withoutBtns }) => {
	const [resizedImage, setResizedImage] = useState("")
	const [imageProcessing, setImageProcessing] = useState(false)

	const beforeCropResize = async (file) => {
		setImageProcessing(true)
		const formData = new FormData()
		formData.append("image", file)
		const token = localStorage.token
			? localStorage.getItem("token") : sessionStorage.getItem("token")
		const response = await resizeImage(
			token,
			formData
		)
		const { imageURL } = response
		setResizedImage(`${baseUri}${imageURL}`)
		setImageProcessing(false)
	}

	const choseGender = () => {
		let gender = null
		if (person.gender === "male") {
			gender = <MaleIcon />
		} else if (person.gender === "female") {
			gender = <FemaleIcon />
		} else {
			gender = <UnknownGenderIcon />
		}
		return gender
	}

	const { t } = useTranslation()
	const webtoken = localStorage.token
		? localStorage.getItem("token") : sessionStorage.getItem("token")
	return (
		<>
			<div className={styles.side_panel_avatar}>
				<div className={styles.person_default_avatar}>
					{person.avatar !== "" ? (<img src={getImageUri(person.avatar)} alt="Something went wrong" className={styles.person_avatar} />) : <div>{choseGender()}</div>}
				</div>
				{!withoutBtns && (
					<div className={styles.side_panel_avatar_btns}>
						<ImageCropper
							token={webtoken}
							person={person}
							resizedImage={resizedImage}
							setResizedImage={setResizedImage}
						/>
						<div style={{ position: "relative", cursor: "pointer", height: "40px" }}>
							<input
								type="file"
								name="file"
								id="file"
								accept="image/*"
								style={{
									position: "absolute",
									width: "100%",
									height: "100%",
									outline: "none",
									opacity: 0,
									zIndex: 9,
									cursor: "pointer"
								}}
								onChange={(e) => beforeCropResize(e.target.files[0])}
							/>
							<Button
								className={!person.avatar ? `${styles.side_panel_avatar_confirm} ${styles.side_panel_avatar_btn}` : `${styles.side_panel_avatar_confirm} ${styles.side_panel_avatar_btn} ${styles.side_panel_avatar_confirm_uploaded}`}
								icon={<UploadIcon />}
							>
								{!person.avatar ? (`${t("upload-avatar")}`) : (`${t("change")}`)}
							</Button>
						</div>
						{
							person.avatar !== "" && (
								<Button
									className={`${styles.side_panel_avatar_clear} ${styles.side_panel_avatar_btn}`}
									icon={<ClearIcon />}
									onClick={() => {
										const token = localStorage.token ? localStorage.getItem("token") : sessionStorage.getItem("token")
										deleteTreeAvatar(token, person.positionId)
										store.dispatch(updatePerson({ ...person, avatar: "" }))
									}}
								>
									&nbsp;
									{t("clear")}
								</Button>
							)
						}
					</div>
				)}
			</div>
			{imageProcessing && (
				<AppSpinner
					loadingOutlinedStyle={{ fontSize: 64, marginRight: "10px" }}
					style={{
						position: "fixed", width: "100%", height: "100%", top: 0, left: 0, zIndex: "9999"
					}}
					tip={t("processing")}
				/>
			)}
		</>
	)
}
