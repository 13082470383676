import React from "react"
import { UserAvatar } from "shared/avatar"
import moment from "moment-timezone"
import { DeleteIcon } from "shared/icons"
import { deletePhotoComment } from "shared/full-screen-image/api"
import { deleteVideoComment } from "shared/video-modal/api"

import { deleteComment } from "shared/feed-components/api"
import styles from "./feed-comments.module.css"

export const FeedComments = ({
	post, user, postComments, setPostComments
}) => (
	<div className={styles.comments}>
		{postComments.map(comment => {
			if (comment) {
				return (
					<div
						className={styles.comment_card}
						key={comment.commentId}
					>
						<div className={styles.comment_card_avatar}>
							<UserAvatar user={comment.user} />
						</div>
						<div className={styles.comment_card_info}>
							<div className={styles.comment_card_author}>
								{comment.user.firstName}
								{" "}
								{comment.user.lastName}
								{comment.commentAuthor === user._id && (
									<button
										type="button"
										onClick={async () => {
											if (post.isUploadedPhoto) {
												await deletePhotoComment(
													post.albumId, post.images[0], comment.commentId, post.userId
												)
												setPostComments(postComments.filter(
													c => c.commentId !== comment.commentId
												))
											} else if (post.isUploadedVideo) {
												await deleteVideoComment(
													post.albumId, post.videos[0].path, comment.commentId, post.userId
												)
												setPostComments(postComments.filter(
													c => c.commentId !== comment.commentId
												))
											}
											if (!post.isUploadedPhoto) {
												await deleteComment(
													post._id,
													comment.commentId
												)
												setPostComments(postComments.filter(
													c => c.commentId !== comment.commentId
												))
											}
										}}
									>
										<DeleteIcon />
									</button>
								)}
								{comment.commentAuthor !== user._id && user._id === post.userId && (
									<button
										type="button"
										onClick={async () => {
											if (post.isUploadedPhoto) {
												await deletePhotoComment(
													post.albumId, post.images[0], comment.commentId, post.userId
												)
											} else if (post.isUploadedVideo) {
												await deleteVideoComment(
													post.albumId, post.videos[0].path, comment.commentId, post.userId
												)
												setPostComments(postComments.filter(
													c => c.commentId !== comment.commentId
												))
											}
											if (!post.isUploadedPhoto) {
												await deleteComment(
													post._id,
													comment.commentId
												)
												setPostComments(postComments.filter(
													comment => comment.commentId !== post._id
												))
											}
										}}
									>
										<DeleteIcon />
									</button>
								)}
							</div>
							<div className={styles.comment_card_text}>{comment.commentText}</div>
							<div className={styles.comment_card_time}>{moment(comment.commentDate).tz(`${moment.tz.guess()}`).fromNow()}</div>
						</div>
					</div>

				)
			}
			return null
		})}
	</div>
)