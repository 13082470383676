export const changeUserPassword = async (
  userPassword, email
) => {
  try {
    const regInfo = {
      userPassword, email
    }
    const res = await fetch("/api/auth/change-user-password", {
      method: "PUT",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify(regInfo)
    })
    const json = await res.json()
    return json
  } catch (error) {
    const message = "Something went wrong"
    return message
  }
}