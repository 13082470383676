import React, { useState } from "react"
import { useTranslation } from "react-i18next"
import {
	Button, Modal, Input, message
} from "antd"
import {
	LeftCircleOutlined, VerticalAlignBottomOutlined
} from "@ant-design/icons"
import { NavLink } from "react-router-dom"
import { VIDEO_MAXIMUM_SIZE } from "shared/constants"
import styles from "./videos-header.module.css"

export const VideosHeader = ({
	albumName, isEditMode, visible, file,
	videoName, albumId,
	setVideos, setVideoName, setFilename,
	setFile, setVisible, setIsEditMode,
	videos
}) => {
	const [duration, setDuration] = useState(null)
	const { t } = useTranslation()
	const handleCancel = () => {
		setVideoName("")
		setFilename("New video")
		setVisible(false)
		setIsEditMode(false)
	}

	const getVideoDuration = (file) => new Promise(resolve => {
		const video = document.createElement("video")
		video.onloadedmetadata = () => resolve(video.duration)
		video.src = URL.createObjectURL(file)
	})
	const onChange = async (e) => {
		if (e.target.files[0].size > VIDEO_MAXIMUM_SIZE) {
			message.error(t("video-size-exceeded"))
		} else {
			setFile(e.target.files[0])
			setFilename(e.target.files[0].name)
			const duration = await getVideoDuration(e.target.files[0])
			setDuration(duration)
		}
	}
	const onSubmit = async e => {
		e.preventDefault()
		const formData = new FormData()
		formData.append("file", file)
		formData.append("videoName", !videoName ? `Video${videos.length + 1}` : videoName)
		formData.append("albumId", albumId)
		formData.append("duration", duration)

		try {
			const token = localStorage.token ? localStorage.getItem("token") : sessionStorage.getItem("token")
			if (!file) {
				message.error(t("choose-video"))
			} else {
				const res = await fetch("/api/video-gallery/video", {
					body: formData,
					method: "POST",
					headers: {
						Authorization: token
					}
				})
				if (res.status === 200) {
					const json = await res.json()
					const { videos } = json.data
					setVideos(videos)
				}
			}
		} catch (err) {
			console.log(err)
		}
		setVideoName("")
		setFilename("New video")
		setVisible(false)
		setIsEditMode(false)
	}
	return (
		<div
			className={styles.header}
		>
			<div
				style={{ paddingLeft: "32px" }}
			>
				<NavLink
					to="/video-gallery"
					className={styles.header_back_btn}
					activeStyle={{
						color: "#626262"
					}}
				>
					<LeftCircleOutlined style={{ cursor: "pointer" }} />
				</NavLink>
				<span style={{ paddingLeft: "24px" }}>{albumName}</span>
			</div>
			<Modal
				title={t("upload-videos")}
				visible={visible}
				onOk={onSubmit}
				onCancel={handleCancel}
				keyboard
			>
				<form onSubmit={onSubmit}>
					<Input
						placeholder={t("enter-video-name")}
						value={videoName}
						onChange={(e) => setVideoName(e.target.value)}
					/>
					<div>
						<input
							type="file"
							name="file"
							id="file"
							accept="video/*"
							className={styles.input_field}
							onChange={onChange}
						/>
						{!isEditMode && (
							<label htmlFor="file" className={styles.file_label}>
								<VerticalAlignBottomOutlined />
								{t("choose-video")}
							</label>
						)}
					</div>
				</form>
			</Modal>
			{videos.length > 0 && (
				<Button
					onClick={() => {
						setVisible(true)
					}}
					className={styles.confirm_button}
				>
					{t("upload-videos")}
				</Button>
			)}
		</div>
	)
}