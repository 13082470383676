import React from "react"
import { useSelector } from "react-redux"
import CustomScroll from "react-scrollbars-custom"
import { useTranslation } from "react-i18next"
import { SearchPeopleStartScreen } from "./empty-container"
import { SearchPeopleNoResultScreen } from "./no-result"
import { SearchPeopleUserCard } from "./user-card"
import styles from "./search-people-components.module.css"

export const SearchPeopleResult = ({ result, searchPeopleStatus }) => {
	const { user } = useSelector(s => s)
	const { currentUser } = user
	const { t } = useTranslation()

	return (
		<div className={styles.container}>
			{!result || result.length === 0
				? (
					<>
						{searchPeopleStatus === "not-started" ? <SearchPeopleStartScreen /> : <SearchPeopleNoResultScreen />}

					</>
				)
				: (
					<>
						<div className={styles.results_header}>{t("result")}</div>
						<CustomScroll>
							<div
								className={styles.results_content}
							>
								{
									result.map(user => {
										if (user._id !== currentUser._id) {
											return (
												<SearchPeopleUserCard
													key={user._id}
													user={user}
													currentUser={currentUser}
												/>
											)
										}
										return null
									})
								}
							</div>
						</CustomScroll>
					</>
				)}
		</div>

	)
}