import React, { useEffect, useState } from "react"
import { Select } from "antd"
import { updatePerson } from "modules/family-tree/ducks"
import { store } from "app/redux/store"
import { useTranslation } from "react-i18next"
import styles from "./mobile-modal-gender.module.css"

export const MobileSelectGender = ({ person }) => {
	const { Option } = Select
	const [personGender, setPersonGender] = useState(person.gender)

	const { t } = useTranslation()

	useEffect(() => {
		if (person) {
			setPersonGender(person.gender)
		}
	}, [person, personGender])

	return (
		<div className={styles.select_gender_wrap}>
			<Select
				className={styles.gender_selector}
				placeholder={t("select-gender")}
				bordered={false}
				onSelect={value => store.dispatch(updatePerson({ ...person, gender: value }))}
				value={personGender}
				disabled={person.isUser || person.profileId}
			>
				<Option value="male"><span className={styles.gender_selector_value}>{t("male")}</span></Option>
				<Option value="female"><span className={styles.gender_selector_value}>{t("female")}</span></Option>
				<Option value="unknown"><span className={styles.gender_selector_value}>{t("gender-neutral")}</span></Option>
			</Select>
		</div>
	)
}