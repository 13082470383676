import React, { useState } from "react"
import { Empty } from "antd"
import { MessagesStartScreenImage } from "shared/empty-screen-images"
import { ModalVideoCall } from "./components/modal-video-call"
import { CreateVideoChat } from "./components/create-video-chat/create-video-chat"
import { EmptyMessagesButtons } from "./components/empty-messages-buttons"
import styles from "./video-chat.module.css"

export const VideoChat = () => {
	const [isShowModalChat, setIsShowModalChat] = useState(false)
	const [isShowModalVideoChat, setIsShowModalVideoChat] = useState(false)
	const [currentUserConnections, setCurrentUserConnections] = useState([])
	const [selectedConnections, setSelectedConnections] = useState(null)
	const [mode, setMode] = useState()

	return (
		<>
			<CreateVideoChat
				isShowModal={isShowModalChat}
				setIsShowModal={setIsShowModalChat}
				setIsShowModalVideoChat={setIsShowModalVideoChat}
				currentUserConnections={currentUserConnections}
				selectedConnections={selectedConnections}
				setSelectedConnections={setSelectedConnections}
				setMode={setMode}
			/>
			<div className={styles.wrap}>
				<div className={styles.container}>
					<Empty
						className={styles.empty_wrap_fullsize}
						description={(
							<EmptyMessagesButtons
								setCurrentUserConnections={setCurrentUserConnections}
								setIsShowModalChat={setIsShowModalChat}
							/>
						)}
						onClick={() => setSelectedConnections(null)}
						image={<MessagesStartScreenImage />}
					/>

					{(isShowModalVideoChat) && (
						<ModalVideoCall
							isShowModalVideoChat={isShowModalVideoChat}
							setIsShowModalVideoChat={setIsShowModalVideoChat}
							selectedConnections={selectedConnections}
							mode={mode}
						/>
					)}
				</div>
			</div>
		</>
	)
}
