import React, { useState } from "react"
import {
	Modal, Card, Avatar, message
} from "antd"
import { UserOutlined } from "@ant-design/icons"
import { getImageUri } from "shared/utils"
import { useTranslation } from "react-i18next"
import { socket } from "app/socket"
import { useSelector } from "react-redux"
import { EmptyConnectionsImage } from "shared/empty-screen-images"
import styles from "./create-chat.module.css"

const { Meta } = Card

export const CreateChat = ({
	isShowModal, setIsShowModal, currentUserConnections
}) => {
	const [selectedConnections, setSelectedConnections] = useState(null)

	const { user } = useSelector(s => s)
	const { currentUser } = user

	const { t } = useTranslation()

	return (
		<Modal
			footer={
				(
					<div className={styles.footer}>
						<button
							className={selectedConnections !== null ? `${styles.button_create_chat_true}` : `${styles.button_create_chat_error}`}
							type="button"
							onClick={() => {
								if (selectedConnections === null) {
									message.error(t("please-choose-a-member"))
									return
								}
								socket.emit("START_CHAT",
									{
										recipients: [selectedConnections],
										userId: currentUser._id
									})
								setIsShowModal(false)
								setSelectedConnections(null)
							}}
						>
							{t("create")}
						</button>
					</div>
				)
			}
			title={(
				<div>
					{t("please-choose-a-chat-participant")}
				</div>
			)}
			visible={isShowModal}
			className={styles.modal}
			keyboard
			onCancel={() => {
				setIsShowModal(false)
				setSelectedConnections(null)
			}}
		>

			{currentUserConnections.length === 0
				? (
					<div className={styles.background_no_participants}>
						<EmptyConnectionsImage />
					</div>
				) : (
					<div className={styles.list_friends_chat}>
						{currentUserConnections.map(user => (
							<div
								key={user._id}
								role="button"
								tabIndex={0}
								onClick={() => {
									if (selectedConnections === user._id) {
										setSelectedConnections(null)
									} else {
										setSelectedConnections(user._id)
									}
								}}
							>
								<Card
									size="small"
									style={{
										margin: "4px 0",
										padding: "4px",
										width: "100%",
										borderRadius: "6px",
										background: selectedConnections === user._id ? "#F2F2F2" : "#FFFFFF"
									}}
								>
									<Meta
										avatar={(
											<Avatar
												style={{
													background: "rgba(136, 133, 133, 0.5)",
													display: "flex",
													alignItems: "center",
													justifyContent: "center"
												}}
												src={!user.avatar ? (
													<UserOutlined style={{
														fontSize: "28px", width: "100%", height: "100%", display: "flex", alignItems: "center", justifyContent: "center"
													}}
													/>
												) : getImageUri(user.avatar)}
											/>
										)}
										title={`${user.firstName} ${user.lastName}`}
										description={!user.location.name ? "" : user.location.name}
									/>
								</Card>
							</div>
						))}
					</div>
				)}
		</Modal>
	)
}
