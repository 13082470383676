import React, { useState } from "react"
import { Input } from "antd"
import { EmojiIcon, AllowClear } from "shared/icons"
import "emoji-mart/css/emoji-mart.css"
import { Picker } from "emoji-mart"
import styles from "../post-main-content.module.css"

const { TextArea } = Input

export const PostTextEditComponent = ({
	setInputValue,
	inputValue
}) => {
	const [visible, setVisible] = useState(false)
	const [toggleEditPost, setToggleEditPost] = useState(false)

	const addEmojiEditPost = (emoji) => {
		setInputValue(`${inputValue}${emoji}`)
	}

	return (
		<>
			<TextArea
				bordered={false}
				className={styles.post_main_content_text}
				onChange={(e) => {
					if (e.target.value === "") {
						setVisible(false)
					} else {
						setVisible(true)
					}
					setInputValue(e.target.value)
				}}
				autoSize
				maxLength={300}
				value={inputValue}
			/>
			{inputValue && (
				<button
					type="button"
					className={styles.btns_edit}
					onClick={() => { setToggleEditPost(!toggleEditPost) }}
				>
					<EmojiIcon />
				</button>
			)}
			{toggleEditPost && (
				<Picker
					showPreview={false}
					useButton={false}
					title="Emoji"
					style={{
						position: "absolute", bottom: "-280px", overflow: "hidden", maxHeight: "30vh", right: "0", zIndex: 10, width: "300px", background: "#f2f2f2"
					}}
					onSelect={el => addEmojiEditPost(el.native)}
				/>
			)}
			<button
				type="button"
				className={`${styles.btns} ${!visible ? styles.btns_none : styles.btns_visible}`}
				onClick={() => {
					setInputValue("")
					setVisible(!visible)
				}}
			>
				<AllowClear />
			</button>
		</>
	)
}