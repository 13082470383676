import React from "react"
import { Modal, Button, message } from "antd"
import { DeleteOutlined, ExclamationCircleOutlined } from "@ant-design/icons"
import { store } from "app/redux/store"
import { saveUserTree } from "modules/family-tree/api"
import { removeChildCard, hideParents, deleteHiddenLastGeneration } from "modules/family-tree/utils"
import { useTranslation } from "react-i18next"
import { updatePerson, setSelectedPersonId } from "modules/family-tree/ducks"
import styles from "./mobile-modal-delete-person.module.css"

const { confirm } = Modal

const deletePerson = (
	person, personChildren, childGenders, parentGenders, addChildToParentCard, selectedPersonPartner
) => {
	if (person.parents.length === 2 && personChildren.length === 1 && childGenders !== "" && person.withoutChild) {
		hideParents()
		store.dispatch(updatePerson({
			...person,
			positionId: person.positionId,
			name: "Add parent",
			from: "",
			livesIn: "",
			birthDate: "",
			deathDate: "",
			gender: "",
			parents: person.parents,
			description: "",
			avatar: "",
			hide: true,
			profileId: ""
		}))
		store.dispatch(setSelectedPersonId(null))
		deleteHiddenLastGeneration()
	}

	if (person.parents.length !== 0 && personChildren.length === 1 && childGenders !== "" && personChildren[0].name.includes("Add child") && !person.withoutChild) {
		if (!person.positionId.includes("uncle")) {
			removeChildCard(personChildren)
			store.dispatch(updatePerson({
				...person,
				positionId: person.positionId,
				name: "Add child",
				from: "",
				livesIn: "",
				birthDate: "",
				deathDate: "",
				gender: "",
				parents: person.parents,
				description: "",
				avatar: "",
				profileId: ""
			}))
			store.dispatch(setSelectedPersonId(null))
			removeChildCard()
		}
		if (person.positionId.includes("uncle")) {
			removeChildCard(personChildren)
			store.dispatch(updatePerson({
				...person,
				positionId: person.positionId,
				name: "Add child ",
				from: "",
				livesIn: "",
				birthDate: "",
				deathDate: "",
				gender: "",
				parents: person.parents,
				description: "",
				avatar: "",
				hide: true,
				profileId: ""
			}))
		}
	}

	if (person.parents.length !== 0 && parentGenders.every(p => p === "") && addChildToParentCard === "") {
		const hiddenChildren = personChildren.filter(child => child.hide)
		if (personChildren.length <= 2) {
			if (person.positionId === "person22") {
				if (selectedPersonPartner.hide) {
					removeChildCard()
					hideParents()
					store.dispatch(updatePerson({
						...person,
						positionId: person.positionId,
						name: "Add parent",
						from: "",
						livesIn: "",
						birthDate: "",
						deathDate: "",
						gender: "",
						parents: person.parents,
						avatar: "",
						description: "",
						hide: true,
						profileId: ""
					}))
					store.dispatch(setSelectedPersonId(null))
				} else {
					removeChildCard()
					hideParents()
					store.dispatch(updatePerson({
						...selectedPersonPartner,
						positionId: selectedPersonPartner.positionId,
						name: "Add parent",
						from: "",
						livesIn: "",
						birthDate: "",
						deathDate: "",
						gender: "",
						parents: selectedPersonPartner.parents,
						avatar: "",
						description: "",
						hide: true,
						profileId: ""
					}))
					store.dispatch(updatePerson({
						...person,
						positionId: person.positionId,
						name: selectedPersonPartner.name,
						from: selectedPersonPartner.from,
						livesIn: selectedPersonPartner.livesIn,
						birthDate: selectedPersonPartner.birthDate,
						deathDate: selectedPersonPartner.deathDate,
						gender: selectedPersonPartner.gender,
						parents: person.parents,
						avatar: selectedPersonPartner.avatar,
						description: selectedPersonPartner.description,
						hide: selectedPersonPartner.hide,
						profileId: selectedPersonPartner.profileId
					}))
					store.dispatch(setSelectedPersonId(null))
				}
			} else {
				removeChildCard()
				hideParents()
				store.dispatch(updatePerson({
					...person,
					positionId: person.positionId,
					name: "Add parent",
					from: "",
					livesIn: "",
					birthDate: "",
					deathDate: "",
					gender: "",
					parents: person.parents,
					avatar: "",
					description: "",
					hide: true,
					profileId: ""
				}))
				store.dispatch(setSelectedPersonId(null))
			}
		}
		if (personChildren.length === 5 && hiddenChildren.length >= 3) {
			hideParents()
			store.dispatch(updatePerson({
				...person,
				positionId: person.positionId,
				name: "Add parent",
				from: "",
				livesIn: "",
				birthDate: "",
				deathDate: "",
				gender: "",
				parents: person.parents,
				avatar: "",
				description: "",
				hide: true,
				profileId: ""
			}))
			store.dispatch(setSelectedPersonId(null))
		}
	}
}

export const MobileDeletePerson = ({
	person, personChildren, childGenders, parentGenders,
	addChildToParentCard, selectedPersonPartner, token
}) => {
	const { t } = useTranslation()
	const deleteAndUpload = async () => {
		deletePerson(
			person,
			personChildren,
			childGenders,
			parentGenders,
			addChildToParentCard,
			selectedPersonPartner
		)
		const { people } = store.getState().familyTree
		const result = await saveUserTree(people, token)
		if (result.status === "error") {
			message.error(t("something-went-wrong"))
		}
	}

	const showConfirm = async () => {
		confirm({
			title: `${t("are-you-sure-to-delete-this-person")}`,
			icon: <ExclamationCircleOutlined />,
			okText: `${t("yes")}`,
			cancelText: `${t("no")}`,
			okType: "danger",
			onOk() {
				deleteAndUpload()
			}
		})
	}

	return (
		<div className={styles.delete_person_section}>
			{!person.isUser && (
				<div>

					<Button
						className={styles.delete_btn}
						icon={<DeleteOutlined />}
						onClick={showConfirm}
					>
						{t("delete-peson")}
					</Button>
				</div>
			)}
		</div>
	)
}