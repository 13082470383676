import React from "react"
import { useTranslation } from "react-i18next"
import { useSelector } from "react-redux"
import { getUsersByIds } from "shared/utils"
import styles from "./empty-messages-buttons.module.css"

export const EmptyMessagesButtons = ({
	setCurrentUserConnections, setIsShowModal, chatlist, setIsShowModalChat
}) => {
	const { user } = useSelector(s => s)
	const { currentUser } = user

	const { t } = useTranslation()

	return (
		<>
			<div>
				<b>{t("SEND-MESSAGES")}</b>
			</div>
			<div>{t("start-chating-with-your-connections")}</div>
			<button
				className={styles.button_wrap}
				type="button"
				onClick={async () => {
					const usersResponse = await getUsersByIds([
						...currentUser.friends, ...currentUser.family
					])
					if (usersResponse.status === "successful") {
						setCurrentUserConnections(usersResponse.users)
						setIsShowModal(true)
					}
				}}
			>
				{t("start-new-group-chat")}
			</button>
			<button
				className={styles.button_wrap}
				type="button"
				onClick={async () => {
					const currentUserCha = []
					const newUserCha = []
					chatlist.forEach(chat => {
						if (chat.users.length === 2 && !chat.roomName) {
							chat.users.forEach(u => {
								if (u._id !== user.currentUser._id) { currentUserCha.push(u._id) }
							})
						}
					})
					const usersToSelect = [...currentUser.friends, ...currentUser.family]
					usersToSelect.forEach(user => {
						if (typeof currentUserCha.find((u) => u === user) === "undefined") {
							newUserCha.push(user)
						}
					})
					const usersResponse = await getUsersByIds([...newUserCha])
					if (usersResponse.status === "successful") {
						setCurrentUserConnections(usersResponse.users)
						setIsShowModalChat(true)
					}
				}}
			>
				{t("start-new-chat")}
			</button>
		</>
	)
}