import React from "react"
import { CameraOutlined } from "@ant-design/icons"

export const NavBtnVideo = (props) => {
	const {
		CameraBtnDisabled,
		videoTrecksEnabled,
		videoDisabled
	} = props

	return (
		<div className="btn-nav">
			<button
				type="button"
				className={`btn-button ${!videoTrecksEnabled ? "disabled" : ""}`}
				onClick={CameraBtnDisabled}
				disabled={videoDisabled}
				name="camera"
			>
				<CameraOutlined
					className="btn-icon"
				/>
			</button>
			{/* <button
				type="button"
				className={`btn-button ${!videoTrecksEnabled ? "disabled" : ""}`}
				onClick={CameraBtnDisabled}
				disabled={videoDisabled}
				name="microfone"
			>
				<AudioMutedOutlined
					className="btn-icon"
				/>
			</button>
			<button
				type="button"
				className={`btn-button ${!videoTrecksEnabled ? "disabled" : ""}`}
				onClick={CameraBtnDisabled}
				disabled={videoDisabled}
				name="rotateCamera"
			>
				<CameraRotate
					className="btn-icon"
				/>
			</button> */}
		</div>
	)
}