import React from "react"
import { v4 as uuidv4 } from "uuid"
import {
	message, Upload, Image
} from "antd"
import { getImageUri } from "shared/utils"
import { DeleteIcon } from "shared/icons"
import { useTranslation } from "react-i18next"
import { IMAGE_MAXIMUM_SIZE } from "shared/constants"
import "emoji-mart/css/emoji-mart.css"
import styles from "../post-main-content.module.css"

export const PostMediaEditComponent = ({
	setVideoFiles,
	newImages,
	setNewImages,
	images,
	fileName,
	formData,
	setFileName,
	videoFiles,
	videos,
	setImageFiles,
	imageFiles,
	inputVideo
}) => {
	const { t } = useTranslation()

	const getBase64 = file => new Promise((resolve, reject) => {
		const reader = new FileReader()
		reader.readAsDataURL(file)
		reader.onload = () => resolve(reader.result)
		reader.onerror = error => reject(error)
	})

	const getImage = async ({ file }) => {
		if (file) {
			if (file.size > IMAGE_MAXIMUM_SIZE) {
				message.error(t("photo-size-exceeded"))
			} else {
				const base64Image = await getBase64(file.originFileObj)
				const newImage = base64Image.split(",")
				if (fileName.length + images.length + videos.length === 5) {
					message.error(`${t("maximum-number-of-photos-reached")}`)
				} else if (newImages.includes(newImage[1])) {
					message.error(`${t("image-already-added")}`)
				} else {
					setNewImages([...newImages, newImage[1]])
					setImageFiles([...imageFiles, file.originFileObj])
				}
			}
		}
	}

	return (
		<div className={styles.post_add_photo_btn_container}>
			{newImages.length > 0 && (
				<div className={styles.new_images_block}>
					{newImages.map((newImage, index) => (
						<div key={uuidv4()} className={styles.new_image}>
							<Image
								width={70}
								height={70}
								src={getImageUri(newImage)}
							/>
							<button
								className={styles.new_image_delete}
								type="button"
								onClick={async () => {
									setNewImages(newImages.filter(img => img !== newImage))
									imageFiles.splice(index, 1)
									setImageFiles(imageFiles)
								}}
							>
								<DeleteIcon />
							</button>
						</div>
					))}
				</div>
			)}
			{fileName.length > 0 && (
				<div className={styles.input_form_video_block}>
					{fileName.map((file, index) => (
						<div key={uuidv4()} className={styles.input_form_video}>
							<button
								className={styles.input_form_delete_video_button}
								type="button"
								onClick={async () => {
									formData.delete(`duration${file}`)
									videoFiles.splice(index, 1)
									setVideoFiles(videoFiles)
									setFileName(fileName.filter(f => f !== file))
								}}
							>
								<DeleteIcon />
							</button>
							<p className={styles.input_form_file_name}>{file}</p>
						</div>
					))}
				</div>
			)}
			<Upload
				showUploadList={false}
				onChange={getImage}
				customRequest={() => null}
				accept="image/*"
			>
				<button
					className={styles.post_add_photo_btn}
					style={{ height: "40px" }}
					type="button"
				>
					{t("+-add-photo")}
				</button>
			</Upload>
			<button
				className={styles.post_add_photo_btn}
				style={{ height: "40px", marginTop: "10px" }}
				type="button"
				onClick={() => {
					inputVideo.current.click()
				}}
			>
				{t("+-add-video")}
			</button>
		</div>
	)
}