import React from "react"
import { AvatarCom } from "./index"
import { Loader } from "./loader"

export const VideoContainer = (props) => {
	const {
		callAccepted,
		callEnded,
		myVideo,
		userVideo,
		selectedConnections,
		stream,
		loader,
		videoTrecksEnabled,
		personName,
		videoCameraUser
	} = props

	const myOffUserOnCamera = callAccepted && !callEnded && !videoTrecksEnabled
	const myOnUserOffCamera = callAccepted && !callEnded && !videoCameraUser && videoTrecksEnabled

	const myVideoClass = !myOnUserOffCamera ? "video my-video" : "video user-video"

	return (
		<>
			<div
				className={callAccepted && !callEnded ? myVideoClass : "video video-start-window"}
				style={{
					display: `${myOffUserOnCamera ? "none" : "block"}`
				}}
			>
				{stream && (
					<video
						playsInline
						muted
						autoPlay
						ref={myVideo}
						style={{
							borderRadius: "16px",
							width: "100%",
							backgroundColor: "rgba(0, 0, 0, 0.45)",
							border: "2px solid #00875A",
							display: `${!videoTrecksEnabled ? "none" : "block"}`
						}}
					/>
				)}
				{!videoTrecksEnabled && (
					<AvatarCom
						avatar={selectedConnections.avatar}
						personName={personName}
					/>
				)}
			</div>
			{
				callAccepted && !callEnded ? (
					<div
						className="video user-video"
						style={{
							display: `${myOnUserOffCamera ? "none" : "block"}`
						}}
					>
						<video
							autoPlay
							playsInline
							ref={userVideo}
							style={{
								borderRadius: "16px",
								width: "100%",
								height: "100%",
								backgroundColor: "rgba(0, 0, 0, 0.45)",
								border: "2px solid #00875A",
								display: `${!videoCameraUser ? "none" : "block"}`
							}}
						>
							<track default kind="captions" srcLang="en" />
						</video>
						{!videoCameraUser && (
							<AvatarCom
								avatar={selectedConnections.avatar}
								personName={personName}
							/>
						)}
					</div>
				) : null
			}
			{loader && (
				<Loader
					style={{
						position: "absolute",
						top: 0,
						left: 0,
						width: "100%",
						height: "100%"
					}}
				/>
			)}
		</>
	)
}