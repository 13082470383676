import React from "react"
import ImgCrop from "antd-img-crop"
import Upload from "antd/lib/upload/index"
import { getImageUri } from "shared/utils"
import { AddAvatarIcon, PlusIcon } from "./personal-info-icons"
import styles from "../personal-info.module.css"

export const getAvatar = (avatarChange, avatarUrl) => (
	<ImgCrop
		quality={0.1}
		rotate
	>
		<Upload
			showUploadList={false}
			onChange={avatarChange}
		>
			{avatarUrl
				? (
					<>
						<img
							src={getImageUri(avatarUrl)}
							onError={({ currentTarget }) => {
								currentTarget.onerror = null
								currentTarget.src = getImageUri("./user.png")
							}}
							alt="avatar"
							className={styles.user_preview_avatar_img}
						/>
					</>
				)
				: (
					<div className={styles.user_preview_avatar_add}>
						<AddAvatarIcon />
						<div className={styles.avatar_add_icon}><PlusIcon /></div>
					</div>
				)}
		</Upload>
	</ImgCrop>
)