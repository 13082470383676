import React from "react"
import { BackToTopIcon } from "shared/icons"
import styles from "./back-to-top-btn.module.css"

export const BackToTopButton = ({ scrollPosition, scrollbar }) => {
	const scrollTop = () => {
		scrollbar.current.scrollToTop()
	}

	return (
		<>
			{ scrollPosition && (
				<button
					type="button"
					className={styles.back_to_top_btn}
					onClick={() => scrollTop()}
					title="To start"
				>
					<BackToTopIcon />
				</button>
			)}
		</>
	)
}