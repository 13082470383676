import React, { useState, useEffect, useRef } from "react"
import {
	Redirect, useLocation, Route, Switch,
	useHistory
} from "react-router-dom"
import { store } from "app/redux/store"
import { Modal, Button } from "antd"
import CustomScroll from "react-scrollbars-custom"
import { BackToTopButton } from "shared/back-to-top-btn"
import { setPeople } from "modules/family-tree/ducks"
import { useTranslation } from "react-i18next"
import { useSelector } from "react-redux"
import { AppSpinner } from "shared/app-spinner/app-spinner"
import { ProfileHeader } from "./components/profile-card"
import { getProfile } from "./api"
import { ProfileFeedContent } from "./components/profile-feed"
import { ProfileConnectionsContent } from "./components/profile-connections"
import { ProfileTree } from "./components/profile-tree"
import styles from "./connection-profile-card.module.css"
import { UserProfilePhotos } from "./components/profile-photo"
import { ProfileVideoAlbum } from "./components/profile-videos/profile-album/profile-aldum"

export const ConnectionProfileCard = () => {
	const location = useLocation()
	const personId = location.userId
	const [userProfileInfo, setUserProfileInfo] = useState({ user: null })
	const [scrollPosition, setScrollPosition] = useState(false)
	const [page, setPage] = useState(1)
	const [totalPages, setTotalPages] = useState(1)
	const { t } = useTranslation()
	const { user } = useSelector(s => s)
	const { currentUser } = user
	const history = useHistory()
	useEffect(() => {
		if (personId) {
			if (personId === currentUser._id.toString()) {
				history.push({ pathname: "/user-profile" })
			} else {
				const getData = async () => {
					const result = await getProfile(personId, true)
					setUserProfileInfo(result.profile)
					if (result.profile.userTree && result.profile.userTree.length > 0) {
						store.dispatch(setPeople(result.profile.userTree))
					}
				}
				getData()
			}
		}
	}, [location.userId, personId])

	const scrollbar = useRef()

	if (!personId) {
		return <Redirect to="/connections" />
	}
	return (
		<>
			{userProfileInfo.user !== null ? (
				<div className={styles.wrap}>
					<CustomScroll
						ref={scrollbar}
						onScroll={(values) => {
							if ((values.scrollHeight - values.scrollTop) === values.clientHeight) {
								if (page >= 1 && page < totalPages) {
									setPage(page + 1)
								}
							}
							if (values.scrollTop > 300) {
								setScrollPosition(true)
							} else {
								setScrollPosition(false)
							}
						}}
					>
						<div className={styles.content_wrap}>
							<ProfileHeader profile={userProfileInfo} />
							<Switch>
								<Route exact path="/profile/feed">
									<ProfileFeedContent
										profile={userProfileInfo}
										page={page}
										setTotalPages={setTotalPages}
										totalPages={totalPages}
									/>
								</Route>
								<Route exact path="/profile/connections">
									<ProfileConnectionsContent profile={userProfileInfo} />
								</Route>
								<Route exact path="/profile/tree">
									<ProfileTree profile={userProfileInfo} />
								</Route>
								<Route exact path="/profile/photos">
									<UserProfilePhotos profile={userProfileInfo} />
								</Route>
								<Route exact path="/profile/videos">
									<ProfileVideoAlbum profile={userProfileInfo} />
								</Route>
							</Switch>
						</div>
					</CustomScroll>
				</div>
			) : <AppSpinner />}
			<BackToTopButton scrollPosition={scrollPosition} scrollbar={scrollbar} />
			<Modal
				title={t("Disable")}
				visible={currentUser.blacklist?.includes(userProfileInfo._id)}
				footer={false}
			>
				<p>{t("This-user-has-added-you-to-the-blacklist")}</p>
				<Button type="primary" onClick={() => history.push({ pathname: "/connections" })}>{t("Ok")}</Button>
			</Modal>
		</>
	)
}