import React, { useState } from "react"
import {
	Modal, Input, Card, Avatar, message, Upload
} from "antd"
import { UserOutlined } from "@ant-design/icons"
import { getImageUri } from "shared/utils"
import { useTranslation } from "react-i18next"
import { socket } from "app/socket"
import { useSelector } from "react-redux"
import { EmptyConnectionsImage } from "shared/empty-screen-images"
import { IMAGE_MAXIMUM_SIZE } from "shared/constants"
import { ChatAvatar } from "../chat-avatar"
import styles from "./create-group-chat.module.css"

const { Meta } = Card

export const CreateGroupChat = ({
	isShowModal, setIsShowModal, currentUserConnections, isAddUser, roomId, chatMembers
}) => {
	const [chatName, setChatName] = useState("")
	const [selectedConnections, setSelectedConnections] = useState([])
	const [isNameFieldEmpty, setIsNameFieldEmpty] = useState(false)
	const [chatAvatar, setChatAvatar] = useState("")

	const { user } = useSelector(s => s)
	const { currentUser } = user

	const { t } = useTranslation()

	const getBase64 = file => new Promise((resolve, reject) => {
		const reader = new FileReader()
		reader.readAsDataURL(file)
		reader.onload = () => resolve(reader.result)
		reader.onerror = error => reject(error)
	})

	const selectPhotos = async ({ file }) => {
		if (file.status !== "uploading") {
			if (file.size > IMAGE_MAXIMUM_SIZE) {
				message.error(t("photo-size-exceeded"))
			} else {
				const base64Image = await getBase64(file.originFileObj)
				const newImage = base64Image.split(",")
				setChatAvatar(newImage[1])
			}
		}
	}

	const renderGroupChatavatar = () => {
		if (chatAvatar) {
			return <ChatAvatar friendAvatar={getImageUri(chatAvatar)} online={null} />
		}
		return (
			<div className={styles.avatar_groupchat}>
				{chatName && chatName.length > 0 ? chatName[0].toUpperCase() : "?"}
			</div>
		)
	}
	return (
		<Modal
			footer={
				(
					<div className={styles.footer}>
						<button
							className={isNameFieldEmpty ? `${styles.button_create_chat_true}` : `${styles.button_create_chat_error}`}
							type="button"
							onClick={() => {
								if (!isAddUser) {
									if (chatName === "") {
										message.error(t("please-enter-chat-name"))
										return
									}
									socket.emit("START_CHAT",
										{
											roomName: chatName,
											recipients: selectedConnections,
											userId: currentUser._id,
											chatAvatar
										})
									setIsShowModal(false)
									setChatName("")
									setChatAvatar("")
									setSelectedConnections([])
								}
								if (isAddUser) {
									if (!isNameFieldEmpty) {
										message.error(t("please-choose-a-new-member"))
										return
									}
									socket.emit("ADD_USERS_TO_CHAT",
										{
											roomId,
											newRecipients: selectedConnections
										})
									selectedConnections.forEach(u => {
										socket.emit("ROOM:NEW_MESSAGE", {
											userId: u, chatId: roomId, text: "JOINED_CHAT", image: null, system: true
										})
									})

									setIsShowModal(false)
									setChatName("")
									setSelectedConnections([])
								}
								setIsNameFieldEmpty(false)
							}}
						>
							{isAddUser ? t("add-members") : t("create")}
						</button>
					</div>
				)
			}
			title={(
				<div>
					{isAddUser ? t("add-new-members") : t("name-your-group-chat-and-add-people")}
				</div>
			)}
			visible={isShowModal}
			className={styles.modal}
			keyboard
			onCancel={() => {
				setIsNameFieldEmpty(false)
				setIsShowModal(false)
				setChatName("")
				setSelectedConnections([])
			}}
		>
			<div>
				{!isAddUser && (
					<>
						<Input
							maxLength="40"
							placeholder={t("enter-chat-name")}
							className={styles.input_field}
							value={chatName}
							onChange={(e) => {
								setChatName(e.target.value.trimLeft())
								if (e.target.value.trimLeft() === "") {
									setIsNameFieldEmpty(false)
								} else {
									setIsNameFieldEmpty(true)
								}
							}}
						/>
						<div className={styles.avatar_field}>
							<Upload
								onChange={selectPhotos}
								showUploadList={false}
							>
								<button type="button" className={styles.btns}>
									+
									{" "}
									{chatAvatar === "" ? t("add-avatar") : t("change-avatar")}
								</button>
							</Upload>
							{renderGroupChatavatar()}
						</div>
					</>
				)}
				{isAddUser && currentUserConnections.length === 0
					&& (
						<div className={styles.background_no_participants}>
							<EmptyConnectionsImage />
						</div>
					)}
				{(isAddUser || chatName !== "")
					&& currentUserConnections.length > 0
					&& (
						<div className={styles.list_friends_chat}>
							{currentUserConnections.map(user => (
								<div
									key={user._id}
									role="button"
									tabIndex={0}
									onClick={() => {
										if (selectedConnections.includes(user._id)) {
											if (selectedConnections.length === chatMembers.length) {
												setIsNameFieldEmpty(false)
											}
											setSelectedConnections(selectedConnections.filter(id => id !== user._id))
										} else {
											if (selectedConnections.length > 8) {
												message.error(t("added-maximum-number-of-participants"))
												return
											}
											setIsNameFieldEmpty(true)
											setSelectedConnections([...selectedConnections, user._id])
										}
									}}
								>
									<Card
										size="small"
										style={{
											margin: "4px 0",
											padding: "4px",
											width: "100%",
											borderRadius: "6px",
											background: selectedConnections.includes(user._id) ? "#F2F2F2" : "#FFFFFF"
										}}
									>
										<Meta
											avatar={(
												<Avatar
													style={{
														background: "rgba(136, 133, 133, 0.5)",
														display: "flex",
														alignItems: "center",
														justifyContent: "center"
													}}
													src={!user.avatar ? (
														<UserOutlined style={{
															fontSize: "28px", width: "100%", height: "100%", display: "flex", alignItems: "center", justifyContent: "center"
														}}
														/>
													) : getImageUri(user.avatar)}
												/>
											)}
											title={`${user.firstName} ${user.lastName}`}
											description={!user.location.name ? "" : user.location.name}
										/>
									</Card>
								</div>
							))}
						</div>
					)}
			</div>
		</Modal>
	)
}
