export const saveUserTree = async (
  userTree, token
) => {
  try {
    const treeInfo = {
      userTree
    }
    const res = await fetch("/api/user-tree/save-user-tree", {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Authorization: token
      },
      body: JSON.stringify(treeInfo)
    })
    const json = await res.json()
    return json
  } catch (error) {
    const message = "Something went wrong"
    return message
  }
}