import React, { useState } from "react"
import { UserAvatar } from "shared/avatar"
import { Popover } from "antd"
import { useHistory } from "react-router-dom"
import { socket } from "app/socket"
import { inviteAsFriend, inviteAsFamily } from "modules/search-people/api"
import { useTranslation } from "react-i18next"
import styles from "./search-people-user-card.module.css"

export const SearchPeopleUserCard = ({ user, currentUser }) => {
	const userInFriends = user.friends.includes(currentUser._id)
	const userInFamily = user.family.includes(currentUser._id)
	const { t } = useTranslation()
	const history = useHistory()

	const [
		userHaveFriendRequest,
		setUserHaveFriendRequest
	] = useState(user.invitesAsFriend.includes(currentUser._id))
	const [
		userHaveFamilyRequest,
		setUserHaveFamilyRequest
	] = useState(user.invitesAsFamily.includes(currentUser._id))

	const [visiblePopover, setVisiblePopover] = useState(false)

	const popoverContent = (
		<div className={styles.buttons}>
			<button
				type="button"
				className={
					(userHaveFriendRequest
						|| userInFriends)
						|| (userHaveFamilyRequest
							|| userInFamily)
						? styles.request_note
						: styles.confirm_button
				}
				disabled={userHaveFriendRequest
					|| userInFriends
					|| currentUser.invitesAsFriend.includes(user._id)}
				onClick={async () => {
					await inviteAsFriend(user._id)
					setUserHaveFriendRequest(true)
					socket.emit("SEND_FRIEND_REQUEST", { userId: user._id })
				}}
			>
				{t("a-friend")}
				{" "}

			</button>
			<div>
				<button
					type="button"
					className={(userHaveFriendRequest
						|| userInFriends)
						|| (userHaveFamilyRequest
							|| userInFamily)
						? styles.request_note
						: styles.confirm_button}
					disabled={userHaveFamilyRequest
						|| userInFamily
						|| currentUser.invitesAsFamily.includes(user._id)}
					onClick={async () => {
						await inviteAsFamily(user._id)
						setUserHaveFamilyRequest(true)
						socket.emit("SEND_FAMILY_REQUEST", { userId: user._id })
					}}
				>
					{t("a-family")}
					{" "}
				</button>
			</div>
		</div>
	)
	return (
		<div
			className={styles.wrap}
			onMouseLeave={() => setVisiblePopover(false)}
		>
			<UserAvatar
				user={user}
				style={{
					width: "60px", height: "60px", fontSize: "56px", color: "#fff"
				}}
			/>
			<div className={styles.user_info}>
				<div className={styles.user_name}>
					<div>
						{user.firstName}
						{" "}
						{user.lastName}
						{" "}
						{user.maidenName && user.maidenName !== "" ? `(${user.maidenName})` : ""}
					</div>
					<div className={styles.user_livesIn}>
						{user.location.name}
					</div>
				</div>
				<div className={styles.confirm_btns}>
					<Popover placement="bottom" content={popoverContent} trigger="click" visible={visiblePopover}>
						<button
							className={
								(userHaveFriendRequest
									|| userInFriends)
									|| (userHaveFamilyRequest
										|| userInFamily)
									? styles.request_note
									: styles.confirm_button
							}
							type="button"
							onClick={() => {
								setVisiblePopover(!visiblePopover)
							}}
						>
							{userHaveFriendRequest
								|| userInFriends
								|| userHaveFamilyRequest
								|| userInFamily
								? t("request-sended")
								: t("invite-as")}
						</button>
					</Popover>
					<button
						type="button"
						className={styles.confirm_button}
						onClick={() => {
							history.push({ pathname: "/profile", userId: user._id })
						}}
					>
						{t("view-profile")}
					</button>
				</div>
			</div>
		</div>
	)
}