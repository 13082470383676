import React from "react"
import { useDispatch, useSelector } from "react-redux"
import { Checkbox } from "antd"
import { setAdaptiveZoom } from "modules/family-tree/ducks"
import { useTranslation } from "react-i18next"
import styles from "./adaptive-zoom.module.css"

export const AdaptiveZoom = () => {
	const { adaptiveZoom } = useSelector(s => s.familyTree)
	const dispatch = useDispatch()
	const { t } = useTranslation()

	return (
		<div className={styles.zoom_panel}>
			<div className={styles.adaptive_zoom}>
				<Checkbox
					className={styles.checkbox}
					onChange={() => {
						dispatch(setAdaptiveZoom(!adaptiveZoom))
						if (!adaptiveZoom) {
							window.diagramInstance.reset()
							window.diagramInstance.fitToPage()
						}
					}}
					checked={adaptiveZoom}
				>
					{t("adaptive-zoom")}
				</Checkbox>
			</div>
		</div>
	)
}