/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useState, useEffect } from "react"
import { useTranslation } from "react-i18next"
import {
	Upload, Button, Modal, message
} from "antd"
import { useSelector } from "react-redux"
import { v4 as uuidv4 } from "uuid"
import CustomScroll from "react-scrollbars-custom"
import { getImageUri } from "shared/utils"
import { getPhotos, uploadPhoto, deletePhoto } from "modules/photos/api"
import { DeleteOutlined, LeftCircleOutlined } from "@ant-design/icons"
import { NavLink } from "react-router-dom"
import { AddPhotoImage } from "shared/empty-screen-images"
import { FullScreenImage } from "shared/full-screen-image"
import { AppSpinner } from "shared/app-spinner/app-spinner"
import { IMAGE_MAXIMUM_SIZE } from "shared/constants"
import styles from "./album.module.css"

export const Album = ({ match }) => {
	const { albumId } = match.params
	const { t } = useTranslation()
	const { user } = useSelector(s => s)
	const { currentUser } = user
	const [responseDate, setResponseDate] = useState([])
	const [albumName, setAlbumName] = useState(null)
	const [isLoading, setIsLoading] = useState(false)
	const [isActive, setIsActive] = useState(false)
	const [selectedPhotoPath, setSelectedPhotoPath] = useState(null)
	const [allPhotos, setAllPhotos] = useState([])
	const [currentPhoto, setCurrentPhoto] = useState(null)
	const [isChangePhoto, setIsChangePhoto] = useState(false)
	const getBase64 = file => new Promise((resolve, reject) => {
		const reader = new FileReader()
		reader.readAsDataURL(file)
		reader.onload = () => resolve(reader.result)
		reader.onerror = error => reject(error)
	})

	const selectPhotos = async ({ file }) => {
		if (file.status !== "uploading") {
			if (file.size > IMAGE_MAXIMUM_SIZE) {
				message.error(t("photo-size-exceeded"))
			} else {
				const base64Image = await getBase64(file.originFileObj)
				const newImage = base64Image.split(",")
				const response = await uploadPhoto(newImage[1], albumId)
				const updatedAlbum = response.data.user.photoGallery.find(album => album.id === albumId)
				setAlbumName(updatedAlbum.name)
				setResponseDate(updatedAlbum.photos)
			}
		}
	}

	useEffect(() => {
		const get = async () => {
			setIsLoading(true)
			const res = await getPhotos(albumId)
			setAlbumName(res.data.name)
			setResponseDate(res.data.user)
			setIsLoading(false)
			return res
		}
		get()
	}, [albumId, isChangePhoto])

	const videoDelete = async () => {
		const res = await deletePhoto(currentPhoto, albumId)
		const updatedAlbum = res.data.user.photoGallery
			.find(album => album.id === albumId)
		setAlbumName(updatedAlbum.name)
		setResponseDate(updatedAlbum.photos)
		setCurrentPhoto(null)
	}
	return (
		<>
			<div className={styles.container}>
				{!isLoading ? (
					<CustomScroll>
						<div
							className={styles.header}
						>
							<div
								style={{ paddingLeft: "32px" }}
							>
								<NavLink
									to="/photo"
									className={styles.header_back_btn}
									activeStyle={{
										color: "#626262"
									}}
								>
									<LeftCircleOutlined style={{ cursor: "pointer" }} />
								</NavLink>
								<span style={{ paddingLeft: "24px" }}>{albumName}</span>
							</div>
							<Upload
								onChange={selectPhotos}
								showUploadList={false}
							>
								{responseDate.length > 0 && (
									<Button
										className={styles.confirm_button}
									>
										{t("upload-photos")}
									</Button>
								)}
							</Upload>
						</div>
						<div className={styles.main_content_container}>
							<Modal title={t("delete-photo")} visible={currentPhoto} onOk={videoDelete} onCancel={() => setCurrentPhoto(null)}>
								<p>{t("are-you-sure-that-you-want-to-delete-the-photo")}</p>
							</Modal>
							{responseDate.length === 0
								&& (
									<div className={styles.main_no_albums}>
										<AddPhotoImage />
										<Upload
											onChange={selectPhotos}
											showUploadList={false}
										>
											<Button
												className={styles.confirm_button}
											>
												{t("upload-photos")}
											</Button>
										</Upload>
									</div>
								)}
							{responseDate?.length > 0 && responseDate.map(photo => (
								<div key={uuidv4()} className={styles.delete_btn_container}>
									<button
										type="button"
										className={styles.delete_btn}
										onClick={() => setCurrentPhoto(photo)}
									>
										<DeleteOutlined />
									</button>
									<div className={styles.image_content}>
										<div
											style={{
												position: "absolute",
												top: "0",
												left: "0",
												width: "100%",
												height: "100%",
												border: "1px solid #EAEEF9",
												borderRadius: "8px",
												display: "flex",
												alignItems: "center",
												justifyContent: "center",
												cursor: "pointer",
												backgroundImage: `url(${getImageUri(photo.path)})`,
												backgroundPosition: "center center",
												backgroundSize: "cover"
											}}
											onClick={() => {
												setIsActive(true)
												setSelectedPhotoPath(getImageUri(photo.path))
												setAllPhotos(responseDate.map(photo => (photo.path)))
											}}
										/>
									</div>
								</div>
							))}
						</div>
					</CustomScroll>
				)
					: <AppSpinner style={{ width: "100%", height: "100%" }} />}

			</div>
			<FullScreenImage
				setIsChangePhoto={setIsChangePhoto}
				currentPhoto={currentPhoto}
				albumName={albumName}
				isActive={isActive}
				selectedPhoto={selectedPhotoPath}
				allPhotos={allPhotos}
				setIsActive={setIsActive}
				setSelectedPhoto={setSelectedPhotoPath}
				isPhotoWithComments
				albumId={albumId}
				ownerId={currentUser._id}
				userId={currentUser._id}
				photoFiles={responseDate}
			/>
		</>
	)
}