/* eslint-disable no-case-declarations */
export const SET_USER_IN_TREE = "SET_USER_IN_TREE"
export const SET_DEFAULT_STATE = "SET_DEFAULT_STATE"
export const SET_PEOPLE = "SET_PEOPLE"
export const ADD_CHILD = "ADD_CHILD"
export const ADD_PARENT = "ADD_PARENT"
export const REMOVE_CHILD = "REMOVE_CHILD"
export const REMOVE_PARENT = "REMOVE_PARENT"
export const SET_PERSON = "SET_PERSON"
export const UPDATE_PERSON = "UPDATE_PERSON"
export const ADD_RIGHT_BRANCH_UNCLE = "ADD_RIGHT_BRANCH_UNCLE"
export const ADD_LEFT_BRANCH_UNCLE = "ADD_LEFT_BRANCH_UNCLE"

export const setUserInTree = user => ({
	type: SET_USER_IN_TREE,
	user
})

export const setDefaultState = () => ({
	type: SET_DEFAULT_STATE
})

export const setPeople = people => ({
	type: SET_PEOPLE,
	people
})

export const addChild = (addChildElement) => ({
	type: ADD_CHILD,
	addChildElement
})

export const addParent = (firstParent, secondParent, childId) => ({
	type: ADD_PARENT,
	firstParent,
	secondParent,
	childId
})

export const addLeftBranchUncle = (addChildElement) => ({
	type: ADD_LEFT_BRANCH_UNCLE,
	addChildElement
})

export const addRightBranchUncle = (addChildElement) => ({
	type: ADD_RIGHT_BRANCH_UNCLE,
	addChildElement
})

export const removeChild = positionId => ({
	type: REMOVE_CHILD,
	positionId
})

export const removeParent = positionId => ({
	type: REMOVE_PARENT,
	positionId
})

export const setPerson = positionId => ({
	type: SET_PERSON,
	positionId
})

export const updatePerson = person => ({
	type: UPDATE_PERSON,
	person
})

const initialState = [
	{
		positionId: "person11",
		isUser: true,
		name: "User",
		from: "",
		livesIn: "",
		birthDate: "",
		deathDate: "",
		gender: "male",
		parents: ["person21", "person22"],
		description: "",
		avatar: "",
		profileId: ""
	},
	{
		positionId: "uncle21",
		name: "Add child ",
		from: "",
		livesIn: "",
		birthDate: "",
		deathDate: "",
		partner: "",
		gender: "",
		parents: ["person32", "person31"],
		description: "",
		avatar: "",
		hide: true,
		profileId: ""
	},
	{
		positionId: "uncle22",
		name: "Add child ",
		from: "",
		livesIn: "",
		birthDate: "",
		deathDate: "",
		partner: "",
		gender: "",
		parents: ["person32", "person31"],
		description: "",
		avatar: "",
		hide: true,
		profileId: ""
	},
	{
		positionId: "uncle23",
		name: "Add child ",
		from: "",
		livesIn: "",
		birthDate: "",
		deathDate: "",
		partner: "",
		gender: "",
		parents: ["person32", "person31"],
		description: "",
		avatar: "",
		hide: true,
		profileId: ""
	},
	{
		positionId: "uncle24",
		name: "Add child ",
		lastAdded: true,
		from: "",
		livesIn: "",
		birthDate: "",
		deathDate: "",
		partner: "",
		gender: "",
		parents: ["person32", "person31"],
		description: "",
		avatar: "",
		hide: true,
		profileId: ""
	},
	{
		positionId: "person21",
		name: "Add parent",
		from: "",
		livesIn: "",
		birthDate: "",
		deathDate: "",
		partner: "person22",
		gender: "",
		parents: ["person32", "person31"],
		description: "",
		avatar: "",
		hide: true,
		profileId: ""
	},
	{
		positionId: "person22",
		name: "Add parent",
		from: "",
		livesIn: "",
		birthDate: "",
		deathDate: "",
		partner: "person21",
		gender: "",
		parents: ["person33", "person34"],
		description: "",
		avatar: "",
		hide: true,
		profileId: ""
	},
	{
		positionId: "uncle25",
		name: "Add child ",
		from: "",
		livesIn: "",
		birthDate: "",
		deathDate: "",
		partner: "",
		gender: "",
		parents: ["person33", "person34"],
		description: "",
		avatar: "",
		hide: true,
		profileId: ""
	},
	{
		positionId: "uncle26",
		name: "Add child ",
		from: "",
		livesIn: "",
		birthDate: "",
		deathDate: "",
		partner: "",
		gender: "",
		parents: ["person33", "person34"],
		description: "",
		avatar: "",
		hide: true,
		profileId: ""
	},
	{
		positionId: "uncle27",
		name: "Add child ",
		from: "",
		livesIn: "",
		birthDate: "",
		deathDate: "",
		partner: "",
		gender: "",
		parents: ["person33", "person34"],
		description: "",
		avatar: "",
		hide: true,
		profileId: ""
	},
	{
		positionId: "uncle28",
		name: "Add child ",
		from: "",
		livesIn: "",
		birthDate: "",
		deathDate: "",
		partner: "",
		gender: "",
		parents: ["person33", "person34"],
		description: "",
		avatar: "",
		hide: true,
		profileId: ""
	},
	{
		positionId: "person31",
		name: "Add parent",
		from: "",
		livesIn: "",
		birthDate: "",
		deathDate: "",
		partner: "person32",
		gender: "",
		parents: ["person41", "person42"],
		description: "",
		avatar: "",
		hide: true,
		profileId: ""
	},
	{
		positionId: "person32",
		name: "Add parent",
		from: "",
		livesIn: "",
		birthDate: "",
		deathDate: "",
		partner: "person31",
		gender: "",
		parents: ["person43", "person44"],
		description: "",
		avatar: "",
		hide: true,
		profileId: ""
	},
	{
		positionId: "person33",
		name: "Add parent",
		from: "",
		livesIn: "",
		birthDate: "",
		deathDate: "",
		gender: "",
		partner: "person34",
		parents: ["person45", "person46"],
		description: "",
		avatar: "",
		hide: true,
		profileId: ""
	},
	{
		positionId: "person34",
		name: "Add parent",
		from: "",
		livesIn: "",
		birthDate: "",
		deathDate: "",
		gender: "",
		partner: "person33",
		parents: ["person47", "person48"],
		description: "",
		avatar: "",
		hide: true,
		profileId: ""
	},
	{
		positionId: "person41",
		name: "Add parent",
		from: "",
		livesIn: "",
		birthDate: "",
		deathDate: "",
		gender: "",
		parents: [],
		description: "",
		avatar: "",
		hide: true,
		withoutChild: true,
		lastGeneration: true,
		profileId: ""
	},
	{
		positionId: "person42",
		name: "Add parent",
		from: "",
		livesIn: "",
		birthDate: "",
		deathDate: "",
		gender: "",
		parents: [],
		description: "",
		avatar: "",
		hide: true,
		withoutChild: true,
		lastGeneration: true,
		profileId: ""
	},
	{
		positionId: "person43",
		name: "Add parent",
		from: "",
		livesIn: "",
		birthDate: "",
		deathDate: "",
		gender: "",
		parents: [],
		description: "",
		avatar: "",
		hide: true,
		withoutChild: true,
		lastGeneration: true,
		profileId: ""
	},
	{
		positionId: "person44",
		name: "Add parent",
		from: "",
		livesIn: "",
		birthDate: "",
		deathDate: "",
		gender: "",
		parents: [],
		description: "",
		avatar: "",
		hide: true,
		withoutChild: true,
		lastGeneration: true,
		profileId: ""
	},
	{
		positionId: "person45",
		name: "Add parent",
		from: "",
		livesIn: "",
		birthDate: "",
		deathDate: "",
		gender: "",
		parents: [],
		description: "",
		avatar: "",
		hide: true,
		withoutChild: true,
		lastGeneration: true,
		profileId: ""
	},
	{
		positionId: "person46",
		name: "Add parent",
		from: "",
		livesIn: "",
		birthDate: "",
		deathDate: "",
		gender: "",
		parents: [],
		description: "",
		avatar: "",
		hide: true,
		withoutChild: true,
		lastGeneration: true,
		profileId: ""
	},
	{
		positionId: "person47",
		name: "Add parent",
		from: "",
		livesIn: "",
		birthDate: "",
		deathDate: "",
		gender: "",
		parents: [],
		description: "",
		avatar: "",
		hide: true,
		withoutChild: true,
		lastGeneration: true,
		profileId: ""
	},
	{
		positionId: "person48",
		name: "Add parent",
		from: "",
		livesIn: "",
		birthDate: "",
		deathDate: "",
		gender: "",
		parents: [],
		description: "",
		avatar: "",
		hide: true,
		withoutChild: true,
		lastGeneration: true,
		profileId: ""
	}

]

export const people = (state = initialState, action) => {
	switch (action.type) {
		case SET_PEOPLE:
			return [...action.people.map(p => {
				const lastAddedUncle = action.people.find(person => person.lastAdded)
				if (!lastAddedUncle) {
					if (p.positionId === "uncle24") {
						p.lastAdded = true
					}
				}
				return p
			})]
		case SET_USER_IN_TREE:
			return state.map(p => {
				if (p.isUser) { return action.user }
				return p
			})
		case SET_DEFAULT_STATE:
			return initialState
		case UPDATE_PERSON:
			return state.map(p => {
				if (p.positionId === action.person.positionId) { return action.person }
				return p
			})
		case SET_PERSON:
			return state.map(p => {
				if (p.positionId === action.positionId) {
					const child = state.find(p => p.parents.includes(action.positionId) && !p.positionId.includes("uncle"))
					if (child) {
						const parentWithGender = state.find(
							p => (
								p.positionId === child.parents[0] || p.positionId === child.parents[1]) && p.gender
						)
						if (parentWithGender) {
							p.gender = parentWithGender.gender === "male" ? "female" : "male"
							p.name = parentWithGender.gender === "male" ? `Mother of ${child.name}` : `Father of ${child.name}`
						} else {
							p.gender = "male"
							p.name = `Father of ${child.name}`
						}
					}
					if (!child) {
						const currentChild = state.find(p => p.positionId === action.positionId)
						const currentChildParents = []
						currentChild.parents.forEach(p => {
							const parent = state.find(person => person.positionId === p)
							currentChildParents.push(parent)
						})
						const currentChildParentsId = currentChildParents.map(p => p.positionId)
						const previousLeftSibling = state.find(
							p => p.isLastAddedChild && p.positionId !== action.positionId
						)
						const previousLeftUncle = state.find(
							p => p.lastAdded && p.positionId !== action.positionId
						)
						if (previousLeftUncle && currentChildParentsId.includes("person31") && !action.positionId.includes("uncle")) {
							delete previousLeftUncle.lastAdded
						}
						if (previousLeftSibling && currentChildParents[0].positionId === "person21") {
							delete previousLeftSibling.isLastAddedChild
						}
						p.gender = "unknown"
						if (currentChildParents.length === 1) {
							if (currentChildParents[0].positionId === "person21" || currentChildParents[0].positionId === "person22") {
								const parentPartner = state.find(
									p => p.positionId === currentChildParents[0].partner
								)
								const isPartnerHidden = parentPartner.hide
								if (isPartnerHidden) {
									p.name = `Child of ${currentChildParents[0].name}`
								} else if (currentChildParents[0].positionId === "person21") {
									p.name = `Child of ${parentPartner.name} and ${currentChildParents[0].name}`
								} else { p.name = `Child of ${currentChildParents[0].name} and ${parentPartner.name}` }
							} else {
								p.name = currentChildParents[0].name !== "" ? `Child of ${currentChildParents[0].name}` : "Child "
							}
						}
						if (currentChildParents.length === 2) {
							const hiddenParent = currentChildParents.find(p => p.hide)
							const unhiddenParent = currentChildParents.find(p => !p.hide)
							if (hiddenParent) {
								p.name = `Child of ${unhiddenParent.name}`
							} else {
								p.name = `Child of ${currentChildParents[0].name} and ${currentChildParents[1].name}`
							}
						}
					}
				}
				return p
			})
		case ADD_CHILD:
			return [action.addChildElement, ...state]
		case ADD_PARENT:
			return [...state.map(person => {
				if (person.positionId === action.childId) {
					person.parents.push(action.firstParent.positionId)
					person.parents.push(action.secondParent.positionId)
				}
				return person
			}), action.firstParent, action.secondParent]
		case ADD_RIGHT_BRANCH_UNCLE:
			const newRightBranchState = []
			state.forEach(p => {
				newRightBranchState.push(p)
				if (p.positionId === "person22") {
					newRightBranchState.push(action.addChildElement)
				}
			})
			return [...newRightBranchState]
		case ADD_LEFT_BRANCH_UNCLE:
			const newLeftBranchState = []
			state.forEach(p => {
				newLeftBranchState.push(p)
				if (p.lastAdded) {
					newLeftBranchState.push(action.addChildElement)
				}
			})
			return [...newLeftBranchState]
		case REMOVE_CHILD:
			return state.filter(child => child.positionId !== action.positionId)
		case REMOVE_PARENT:
			return state.filter(parent => parent.positionId !== action.positionId)
		default:
			return state
	}
}
