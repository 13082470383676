/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react"
import { useSelector } from "react-redux"
import { store } from "app/redux/store"
import { updateUser } from "app/redux/user"
import { v4 as uuidv4 } from "uuid"
import { getUserConnections } from "shared/right-panel/api"
import { NavLink } from "react-router-dom"
import { removeFromFriends, removeFromFamily } from "modules/friends/api"
import { EmptyConnectionsImage } from "shared/empty-screen-images"
import CustomScroll from "react-scrollbars-custom"
import { useTranslation } from "react-i18next"
import { AppSpinner } from "shared/app-spinner/app-spinner"
import { FriendsInput } from "./components"
import styles from "./friends.module.css"
import { FriendCard } from "./components/friend-card"

export const Friends = () => {
	const { user } = useSelector(s => s)
	const { currentUser } = user
	const { t } = useTranslation()

	const [friendsOrFamilySelected, setFriendsOrFamilySelected] = useState("family")
	const [userConnections, setUserConnections] = useState([])
	const [userSuggestions, setUserSuggestions] = useState([])
	const [nameInput, setNameInput] = useState("")

	useEffect(() => {
		const getData = async () => {
			const result = await getUserConnections()
			setUserConnections([...result.result.family, ...result.result.friends])
			setUserSuggestions([...result.result.suggestions])
		}
		getData()
	}, [currentUser.family, currentUser.friends])

	useEffect(() => {
		if (userConnections.length > (currentUser.friends.length + currentUser.family.length)) {
			let unUpdatedFamily = userConnections
				.filter(connection => !currentUser.friends.find(fr => fr === connection._id.toString())
					&& !currentUser.family.find(fam => fam === connection._id.toString())
					&& connection.family.find(id => id === currentUser._id.toString()))
			let unUpdatedFriends = userConnections
				.filter(connection => !currentUser.friends.find(fr => fr === connection._id.toString())
					&& !currentUser.family.find(fam => fam === connection._id.toString())
					&& connection.friends.find(id => id === currentUser._id.toString()))

			unUpdatedFamily = unUpdatedFamily.map(u => u._id)
			unUpdatedFriends = unUpdatedFriends.map(u => u._id)

			store.dispatch(updateUser({
				...currentUser,
				family: [...currentUser.family, ...unUpdatedFamily],
				friends: [...currentUser.friends, ...unUpdatedFriends]
			}))
		}
	}, [userConnections])
	const removeFamilyMember = async (parentId) => {
		await removeFromFamily(parentId)
	}

	const removeFriend = async (friendId) => {
		await removeFromFriends(friendId)
	}

	const friendsContent = () => {
		const emptyMessage = friendsOrFamilySelected === "family" ? `${t("looks-like-you-haven't-added-any-relatives-yet-Start-your-search")}` : `${t("looks-like-you-haven't-added-any-friends-yet-Start-your-search")}`
		const emptySuggestions = t("looks-like-there-is-no-connection-suggestions-for-you-yet-Start-your-search")
		const emptyComponent = (
			<div className={styles.content_e}>
				<div>
					<EmptyConnectionsImage />
				</div>
				<div>
					{friendsOrFamilySelected === "suggestions" ? `${emptySuggestions}` : `${emptyMessage}`}
					{" "}
					<NavLink to="/search-people">{t("now")}</NavLink>
				</div>
			</div>
		)
		const persons = friendsOrFamilySelected === "family" ? currentUser.family : currentUser.friends
		const deleteFunction = friendsOrFamilySelected === "family" ? removeFamilyMember : removeFriend

		if (friendsOrFamilySelected === "family") {
			if (persons.length === 0) {
				return emptyComponent
			}
			if (persons.length !== 0
				&& userConnections.length !== 0
				&& userConnections.length === currentUser.family.length + currentUser.friends.length) {
				const personsFilter = persons
					.map(u => userConnections
						.find(p => p._id === u))
					.filter(u => (`${u.firstName} ${u.lastName}`).toLowerCase().includes(nameInput.toLowerCase())
						|| (`${u.firstName} ${u.maidenName}`).toLowerCase().includes(nameInput.toLowerCase()))
				return (
					<CustomScroll>
						<div className={styles.content}>
							{
								personsFilter.map(person => (
									<FriendCard
										key={uuidv4()}
										user={person}
										currentUser={currentUser}
										removeConnection={deleteFunction}
										friendsOrFamilySelected={friendsOrFamilySelected}
									/>
								))
							}
						</div>
					</CustomScroll>
				)
			}
		}
		if (friendsOrFamilySelected === "friends") {
			if (persons.length === 0) {
				return emptyComponent
			}
			if (persons.length && userConnections.length
				&& userConnections.length === currentUser.family.length + currentUser.friends.length) {
				const personsFilter = persons
					.map(u => userConnections
						.find(p => p._id === u))
					.filter(u => (`${u.firstName} ${u.lastName}`).toLowerCase().includes(nameInput.toLowerCase())
						|| (`${u.firstName} ${u.maidenName}`).toLowerCase().includes(nameInput.toLowerCase()))
				return (
					<CustomScroll>
						<div className={styles.content}>
							{
								personsFilter.map(person => (
									<FriendCard
										key={uuidv4()}
										user={person}
										currentUser={currentUser}
										removeConnection={deleteFunction}
										friendsOrFamilySelected={friendsOrFamilySelected}
									/>
								))
							}
						</div>
					</CustomScroll>
				)
			}
		}
		if (friendsOrFamilySelected === "suggestions") {
			if (userSuggestions.length === 0) {
				return emptyComponent
			}
			if (userSuggestions && userSuggestions.length !== 0) {
				return (
					<CustomScroll>
						<div className={styles.content}>
							{
								userSuggestions.map(person => (
									<FriendCard
										key={uuidv4()}
										user={person}
										currentUser={currentUser}
										removeConnection={deleteFunction}
										friendsOrFamilySelected={friendsOrFamilySelected}
									/>
								))
							}
						</div>
					</CustomScroll>
				)
			}
		}
		return <AppSpinner style={{ width: "100%", height: "100%" }} />
	}

	return (
		<div className={styles.wrap}>
			<FriendsInput
				currentUser={currentUser}
				nameInput={nameInput}
				setNameInput={setNameInput}
			/>
			<div className={styles.container}>
				<div className={styles.results_header}>
					<button
						type="button"
						className={friendsOrFamilySelected === "family" ? `${styles.header_button} ${styles.button_selected}` : styles.header_button}
						onClick={() => {
							setFriendsOrFamilySelected("family")
						}}
					>
						{t("FAMILY")}
					</button>
					<button
						type="button"
						className={friendsOrFamilySelected === "friends" ? `${styles.header_button} ${styles.button_selected}` : styles.header_button}
						onClick={() => {
							setFriendsOrFamilySelected("friends")
						}}
					>
						{t("FRIENDS")}
					</button>
					{currentUser.suggestions.length > 0 && (
						<button
							type="button"
							className={friendsOrFamilySelected === "suggestions" ? `${styles.header_button} ${styles.button_selected}` : styles.header_button}
							onClick={() => {
								setFriendsOrFamilySelected("suggestions")
							}}
						>
							{t("SUGGESTIONS")}
						</button>
					)}
				</div>
				{friendsContent()}
			</div>
		</div>
	)
}